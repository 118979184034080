import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import {
  GT_TAB_CAMDET_WORK,
  GT_TAB_CAMSEG_WORK,
  GT_TAB_RADSEG_WORK,
} from "../../lib/constants/constant";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { workClctCntQuery, workClctListQuery } from "./Work.stores";
import { WORK_CLCT_COLS } from "./Work.constants";
import { workClctFilter } from "./Work.apis";

const WorkClctPage = () => {
  const {
    urlParams,
    queryParams: { tab, t },
  } = useDynamicRouting();
  const { sensor_code, gt_code } = urlParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    workClctCntQuery({ ...urlParams, tab, t })
  );

  return (
    <CommonTemplate
      listQuery={workClctListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      filterApi={workClctFilter}
      fullColumnData={WORK_CLCT_COLS[`${gt_code}`].tab}
      columnData={WORK_CLCT_COLS[`${gt_code}`].tab}
      hideColumn={WORK_CLCT_COLS[`${gt_code}`].hide}
      tabData={[{ value: "1", ko: "전체" }]}
      groupColumnData={
        sensor_code === "rad"
          ? GT_TAB_RADSEG_WORK
          : gt_code === "det"
            ? GT_TAB_CAMDET_WORK
            : GT_TAB_CAMSEG_WORK
      }
    />
  );
};

export default WorkClctPage;
