import { atom, selectorFamily } from "recoil";
import { condClctCnt, condClctList, condCnt, condIds, condList, condPreview } from "./Cond.apis";
import { reloadTriggerState } from "../../lib/stores";

export const condClctCntQuery = selectorFamily({
  key: "condClctCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await condClctCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const condClctListQuery = selectorFamily({
  key: "condClctListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await condClctList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const condPreviewQuery = selectorFamily({
  key: "condPreviewQuery",
  get: (params) => async () => {
    const res = await condPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const condCntQuery = selectorFamily({
  key: "condCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await condCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const condIdsQuery = selectorFamily({
  key: "condIdsQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await condIds(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const condListQuery = selectorFamily({
  key: "condListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await condList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const condObjsState = atom({
  key: "condObjsState",
  default: [],
});
