import { selector, selectorFamily } from "recoil";
import { reloadTriggerState } from "../../lib/stores";
import {
  mainList,
  datasetInfo,
  learnedHitmapPoints,
  datasetHitmapPoints,
  datasetList,
  segFilterList,
  checkFilterList,
  dataCountsInCoordinates,
  updateThumbnailCounts,
  thumbnailsInCoordinates,
  imgPreview,
} from "./Main.apis";

export const mainListQuery = selector({
  key: "mainListQuery",
  get: async ({ get }) => {
    get(reloadTriggerState);

    const res = await mainList();

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const datasetInfoQuery = selectorFamily({
  key: "datasetInfoQuery",
  get: (params) => async () => {
    const res = await datasetInfo(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const learnedHitmapPointsQuery = selectorFamily({
  key: "learnedHitmapPointsQuery",
  get: (params) => async () => {
    const res = await learnedHitmapPoints(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const datasetHitmapPointsQuery = selectorFamily({
  key: "datasetHitmapPointsQuery",
  get: (params) => async () => {
    const res = await datasetHitmapPoints(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const datasetListQuery = selectorFamily({
  key: "datasetListQuery",
  get: (params) => async () => {
    const res = await datasetList(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const segFilterListQuery = selectorFamily({
  key: "segFilterListQuery",
  get: (params) => async () => {
    const res = await segFilterList();

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const checkFilterListQuery = selectorFamily({
  key: "checkFilterListQuery",
  get: () => async () => {
    const res = await checkFilterList();

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const dataCountsInCoordinatesQuery = selectorFamily({
  key: "dataCountsInCoordinatesQuery",
  get: (params) => async () => {
    const res = await dataCountsInCoordinates(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const updateThumbnailCountsQuery = selectorFamily({
  key: "updateThumbnailCountsQuery",
  get: (combinedParams) => async () => {
    const { params, body } = combinedParams;
    const res = await updateThumbnailCounts(params, body);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const thumbnailsInCoordinatesQuery = selectorFamily({
  key: "thumbnailsInCoordinatesQuery",
  get: (params) => async () => {
    const res = await thumbnailsInCoordinates(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const imgPreviewQuery = selectorFamily({
  key: "imgPreviewQuery",
  get: (params) => async () => {
    const res = await imgPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});
