import { CommonButton } from "../molecules/Buttons";

export const ForbiddenTemplate = () => {
  return (
    <div className="flex h-[calc(100%-57px)] w-full flex-col items-center justify-center gap-[40px] text-[14px] text-gray700">
      <div>접근 권한이 없습니다. 관리자에게 문의하세요.</div>
      <CommonButton name="메인으로" onClick={() => (window.location.href = "/main")} />
    </div>
  );
};
