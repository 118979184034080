import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { CONDITIONS } from "../../lib/constants/constant";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { condClctCntQuery, condClctListQuery } from "./Cond.stores";
import { COND_CAM_CMP_STATUS, COND_CAM_STATUS, COND_CLCT_COLS } from "./Cond.constants";
import { condClctFilter } from "./Cond.apis";

const CondClctPage = () => {
  const {
    urlParams,
    queryParams: { tab, t },
  } = useDynamicRouting();

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    condClctCntQuery({ ...urlParams, tab, t })
  );

  return (
    <CommonTemplate
      listQuery={condClctListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      filterApi={condClctFilter}
      tabData={[
        { value: "2", ko: "대기" },
        { value: "3", ko: "완료" },
      ]}
      fullColumnData={COND_CLCT_COLS.tab}
      columnData={COND_CLCT_COLS.tab}
      hideColumn={COND_CLCT_COLS.hide}
      groupColumnData={tab === "3" ? COND_CAM_CMP_STATUS : COND_CAM_STATUS}
      statusCodeData={CONDITIONS}
    />
  );
};

export default CondClctPage;
