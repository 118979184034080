import CommonTemplate from "../../components/templates/CommonTemplate";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { histListQuery } from "./Hist.stores";
import { histFilter } from "./Hist.apis";
import { HIST_DATA_COLS, makeImgTypeList } from "./Hist.constants";

const HistDataPage = () => {
  const { urlParams, queryParams } = useDynamicRouting();

  return (
    <CommonTemplate
      listQuery={histListQuery}
      filterApi={histFilter}
      fullColumnData={HIST_DATA_COLS.tab}
      columnData={HIST_DATA_COLS.tab}
      hideColumn={HIST_DATA_COLS.hide.table.tab}
      hideThumbColumn={HIST_DATA_COLS.hide.thumb.tab}
      viewData
      imgTypeData={makeImgTypeList()}
    />
  );
};

export default HistDataPage;
