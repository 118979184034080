import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useModals } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { isDwlState } from "../../../lib/stores";
import { CommonButton } from "../../molecules/Buttons";
import { ConfirmModal, EmptyModal } from "../../molecules/CommonModals";

// 출력: 다운로드 버튼
export const DownloadAllAction = ({ count, api, dlUrl, actionName, icon, type }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };
  const { tab, view, sort, sortType, page, ...params } = queryParams;

  const [failType, setFailType] = useState("");
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [successCnt, setSuccessCnt] = useState(null);
  const setIsDwl = useSetRecoilState(isDwlState);

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetOpen = () => {
    setFailType("");
    setIsDwl(false);
    setSuccessCnt(null);
    setDownloadUrl(null);
    setModalStatus(null);

    count === 0 ? openModal() : handleClick();
  };

  const handleClick = async () => {
    setIsDwl(true);
    openModal();
    setModalStatus("loading");

    try {
      const data = await api({ ...urlInfo, ...tab }, params);

      data.success_cnt?.toString() && setSuccessCnt(data.success_cnt.toString());
      const url = `${process.env.REACT_APP_SERVER_BASE_URL}${dlUrl}${data.encrypt}/${data.type}`;
      setDownloadUrl(url);
      setModalStatus("hasValue");
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name={actionName} onClick={resetOpen} icon={icon} type={type} />

      {modal &&
        (count === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title={`${actionName} 정보 확인`}
            count={count}
            detail="다운로드 가능한 데이터만 다운로드됩니다."
            modalStatus={modalStatus}
            failType={failType}
            downloadInfo={{ downloadUrl, successCnt }}
          />
        ))}
    </>
  );
};
