import { useHotkeys } from "react-hotkeys-hook";
import { SelectCheckbox, SelectItem } from "../atoms/SelectBox";
import { EmptyImg } from "../atoms/Status";
import GetPreview from "../organisms/GetPreview";
import { CheckQnaAnswer } from "../../pages/check/CheckActions";
import { ROWS_PER_PAGE } from "../../lib/constants/constant";

const QnaItem = (props) => {
  const { index, item, currentPage, previewQuery, answer, handleSelectQnaItem, selected } = props;

  let isShiftDown = false;
  useHotkeys("shift", () => (isShiftDown = true), { keydown: true });
  useHotkeys("shift", () => (isShiftDown = false), { keyup: true });

  return (
    <li
      key={item.id}
      className="m-[16px] flex h-[450px] w-[816px] flex-col justify-between border border-gray400 p-[16px]"
    >
      <div className="flex h-[30px] justify-between">
        <div className="flex items-center">
          {!answer ? (
            <SelectItem index={index} item={item}>
              <SelectCheckbox
                onChange={() => handleSelectQnaItem(item.id, isShiftDown)}
                checked={selected}
                value={item.id}
              />
              <span className="pl-[8px]">
                ID - {item.id}
                {item.workset_name ? `(${item.workset_name})` : ""}
              </span>
            </SelectItem>
          ) : (
            <span className="pl-[8px] text-gray800">
              ID - {item.id}
              {item.workset_name ? `(${item.workset_name})` : ""}
            </span>
          )}
        </div>

        <div className="flex items-center gap-[8px]">
          {item.status === 1 ? "답변대기" : "답변완료"}
          {answer && item.status === 1 && <CheckQnaAnswer item={item} />}
        </div>
      </div>

      <div className="flex justify-between">
        {item.thumb ? (
          <GetPreview
            previewQuery={previewQuery}
            index={index + (currentPage - 1) * ROWS_PER_PAGE}
            qna
            view="thumb"
          >
            <img
              className="max-h-[376px] w-full object-contain"
              src={`data:image/webp;base64,${item.thumb}`}
              alt={item.filename}
            />
          </GetPreview>
        ) : (
          <EmptyImg cn="!w-[378px] !h-[378px]" />
        )}

        {/* question */}
        <div className="flex w-[calc(100%-398px)] flex-col justify-between text-gray900">
          <div className="flex h-[179px] flex-col gap-[8px] border border-gray400 py-[12px]">
            <div className="flex items-center justify-between px-[12px]">
              <div className="text-[14px] font-bold">Q.</div>
              <div>
                {item?.post_t || "-"} [{item?.worker?.display_name || "-"}]
              </div>
            </div>
            <textarea
              className="h-[127px] resize-none overflow-y-auto border-none pr-[4px] pl-[12px]"
              readOnly
              value={item?.question?.replace(/(<br>|<br\/>|<br \/>)/gim, "\r\n")}
              wrap="hard"
              rows="5"
              cols="20"
            />
          </div>

          {/* answer */}
          <div className="flex h-[179px] flex-col gap-[8px] border border-gray400 py-[12px]">
            <div className="flex items-center justify-between px-[12px]">
              <div className="text-[14px] font-bold">A.</div>
              <div>
                {item?.answer_t || "-"} [{item?.checker?.display_name || "-"}]
              </div>
            </div>
            <textarea
              className="h-[127px] resize-none overflow-y-auto border-none pr-[4px] pl-[12px]"
              readOnly
              value={item?.answer?.replace(/(<br>|<br\/>|<br \/>)/gim, "\r\n")}
              wrap="hard"
              rows="5"
              cols="20"
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default QnaItem;
