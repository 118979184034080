import { useEffect, useState, useRef, Children, cloneElement } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { CommonButton } from "../../components/molecules/Buttons";
import { SelectCheckbox } from "../../components/atoms/SelectBox";
import { setOperations } from "../../lib/utils";
import { useBatchIdProcessor, useModals, useReload } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import {
  vldClctCmp,
  vldClctPri,
  vldDelCheck,
  vldExtraDwlLink,
  vldGetInfo,
  vldSensorList,
  vldMaskUpdate,
  vldMaskDelete,
  vldSegAuto,
  vldDetAuto,
  vldMapUl,
  vldOrigAllDwlLink,
  vldOrigDwlLink,
  vldPropUl,
  vldInf,
  vldInfModel,
  vldReset,
  vldSet,
  vldAllTagChange,
  vldAutoReset,
} from "./Vld.apis";
import { EmptyModal, SaveModal, ConfirmModal } from "../../components/molecules/CommonModals";
import ModalContainer from "../../components/molecules/ModalContainer";
import {
  SelectBox,
  SelectItem,
  SelectName,
  SelectRadio,
  SelectTitle,
  SelectWrap,
} from "../../components/atoms/SelectBox";
import { vldPropListQuery, vldTagsState, vldMaskFileListQuery } from "./Vld.stores";
import {
  ConfirmButton,
  DelCheckButton,
  DownloadButton,
  DownloadAllButton,
  FloatingInsertButton,
  FloatingButton,
  SelectInsertButton,
  TableButton,
} from "../../components/organisms/actions";
import { ModalBottom } from "../../components/molecules/Modals";
import { RangeNumberInput } from "../../components/atoms/CommonAtoms";

export const VldPri = ({ selectedItems }) => {
  const data = ["0", "1", "2", "3", "4", "5"];

  return (
    <SelectInsertButton
      body={{ clct_ids: selectedItems }}
      count={selectedItems.length}
      api={vldClctPri}
      actionName="우선순위 선정"
      icon="IC_tag_w"
      selectList={data}
      selectName={{ key: "pri", ko: "우선순위" }}
    />
  );
};

export const VldPriReset = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={vldClctPri}
      hasPath
      body={{ clct_ids: selectedItems, pri: null }}
      actionName="우선순위 리셋"
      icon="IC_reset_w"
      type="secondary"
    />
  );
};

export const VldSetsCmp = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={vldClctCmp}
      hasPath
      body={{ clct_ids: selectedItems }}
      actionName="선별 확정"
      icon="IC_complete_w"
    />
  );
};

export const SetVldTag = ({ selectedItems, tag }) => {
  const vldTags = useRecoilValue(vldTagsState);
  const filteredVldTags = vldTags.filter((item) => selectedItems.includes(item));

  const [data, setData] = useState({});

  const filterData = () => {
    const body = { ids: selectedItems };
    setData(body);
  };

  useEffect(() => {
    filterData();
  }, [selectedItems, vldTags]);

  return (
    <ConfirmButton
      count={selectedItems.length}
      detail={`(${tag === "can" ? "대기" : "학습용"} 데이터 : ${filteredVldTags.length}개)`}
      api={vldSet}
      hasPath
      tag={tag}
      body={data}
      actionName="선별"
      icon="IC_complete_w"
    />
  );
};

export const VldMaskRgs = ({ set_id }) => {
  const [selectedObj, setSelectedObj] = useState(null);
  const modalRef = useRef(null);

  return (
    <TableButton
      ref={modalRef}
      cols={[
        {
          name: "sensor_name",
          cols: "sensorName",
          ko: "센서명",
        },
        {
          name: "filename",
          cols: "filename",
          ko: "파일명",
        },
        {
          type: "image",
          name: "mask_thumb",
          ko: "마스크 이미지",
        },
        {
          type: "member",
          name: "vld_mask_rep",
          ko: "업로드 담당자",
        },
        {
          name: "vld_mask_ul_t",
          ko: "업로드 시간",
        },
      ]}
      select
      selectedObj={selectedObj}
      setSelectedObj={setSelectedObj}
      listQuery={vldMaskFileListQuery}
      actionName="마스크 파일 업로드"
      listName="마스크 파일"
      icon="IC_upload_w"
      showIndex={false}
    >
      <FloatingInsertButton
        listApi={vldSensorList}
        api={vldMaskUpdate}
        actionName="업로드"
        accept="png"
        icon="IC_upload_w"
        type="modal_btn"
        afterSuccessCallback={() => {
          modalRef.current?.reload();
        }}
      />
      <DeleteMasks
        count={selectedObj?.selectedItems?.length ?? 0}
        detail="선택한 마스크 파일을 삭제합니다."
        api={vldMaskDelete}
        body={{ ids: selectedObj?.selectedItems ?? [] }}
        actionName="마스크 삭제"
        icon="IC_delete_w"
        type="modal_btn"
        afterSuccessCallback={() => {
          modalRef.current?.reload();
        }}
      />
    </TableButton>
  );
};

const DeleteMasks = ({
  count,
  detail,
  api,
  body,
  actionName,
  icon,
  type,
  afterSuccessCallback = () => {},
}) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [failType, setFailType] = useState("");
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetOpen = () => {
    setFailType("");
    setModalStatus(null);
    openModal();
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      await api(urlInfo, body);
      setModalStatus("hasValue");
      afterSuccessCallback();
      closeModal();
    } catch (err) {
      console.log(err);
      let err_detail = err?.response?.data?.detail || "";

      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name={actionName} onClick={resetOpen} icon={icon} type={type} />

      {modal &&
        (count === 0 ? (
          <EmptyModal closeModal={closeModal} blackBackground={20} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title={actionName}
            count={count}
            detail={detail}
            modalStatus={modalStatus}
            failType={failType}
            handleClick={handleClick}
            blackBackground={20}
          />
        ))}
    </>
  );
};

export const VldListButton = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <>
        {open && <div className="fixed inset-0 z-[20]" onClick={() => setOpen(false)}></div>}
        <div className="relative">
          <CommonButton
            name={title}
            onClick={() => setOpen((prev) => !prev)}
            cn={"min-w-[126px] !justify-start"}
          />

          {open && (
            <ul className="top-100 absolute z-[20] w-full divide-y divide-[#2B63C1] border border-[#2B63C1] !bg-[white]">
              {Children.map(children, (child, index) => (
                <li key={index}>{cloneElement(child, { callback: () => setOpen(false) })}</li>
              ))}
            </ul>
          )}
        </div>
      </>
    </>
  );
};

export const VldSelectedListButton = ({ title, selectedItems, children }) => {
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  return (
    <>
      {modal && <div className="fixed inset-0 z-[20]" onClick={() => closeModal()}></div>}
      <div className="relative">
        <CommonButton
          cn={"min-w-[126px] !justify-start"}
          name={title}
          onClick={modal ? () => closeModal() : () => openModal()}
        />

        {modal && selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} blackBackground={20} />
        ) : (
          modal && (
            <ul className="top-100 absolute z-[20] w-full divide-y divide-[#2B63C1] border border-[#2B63C1] !bg-[white]">
              {Children.map(children, (child, index) => (
                <li key={index}>{cloneElement(child, { callback: () => closeModal() })}</li>
              ))}
            </ul>
          )
        )}
      </div>
    </>
  );
};

const AUTO_STEPS = { ONE: 1, TWO: 2, FOUR: 4, EIGHT: 8, SIXTEEN: 16 };
export const VldSegAuto = ({
  title,
  subText,
  set_id,
  action,
  type,
  afterActionCallback,
  callback,
}) => {
  const { listReload } = useReload();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const [confidence, setConfidence] = useState(0.5);
  const [step, setStep] = useState(AUTO_STEPS.ONE);
  const [blur, setBlur] = useState(false);
  const [failType, setFailType] = useState("");

  const handleClick = async () => {
    if (confidence < 0.5 || confidence > 1.0 || !Object.values(AUTO_STEPS).includes(step)) {
      setFailType(`Confidence 값은 0.5 이상 1.0 이하의 값만 입력이 가능합니다.\nStep 값은 1, 2, 4, 8, 16 값만 선택 가능합니다.
      `);
      return;
    }
    setModalStatus("loading");

    try {
      await vldSegAuto({ set_id, action }, { conf: confidence, step, blur_check: blur });
      resetState();
      closeModal();
      afterActionCallback();
      callback();
      setModalStatus(null);
      listReload();
    } catch (e) {
      setFailType(e.response?.data?.detail ?? "자동 선별 중 오류가 발생했습니다.");
      setModalStatus("hasError");
    }
  };

  const resetState = () => {
    setConfidence(0.5);
    setStep(AUTO_STEPS.ONE);
    setFailType("");
  };

  return (
    <>
      <CommonButton name={title} onClick={openModal} type={type} />

      {modal ? (
        <ModalContainer
          title={title}
          modalType="save_modal"
          closeModal={() => {
            closeModal();
            callback();
            resetState();
          }}
        >
          {subText && <div className="px-[16px]">{subText}</div>}
          <div className="mt-[10px]">
            <RangeNumberInput
              label="Confidence"
              value={confidence}
              handleChange={(v) => {
                setConfidence(v);
              }}
              min={0.5}
              max={1.0}
              step={0.01}
            />
            <div className="whtiespace-normal flex h-[48px] items-center px-[16px]">
              <span className="mr-[45px]">Step</span>
              {Object.values(AUTO_STEPS).map((autostep) => {
                return (
                  <SelectItem key={`step-${autostep}`} styleType="list" cn="ml-0">
                    <SelectRadio
                      onChange={(e) => {
                        setStep(Number(e.target.value));
                      }}
                      checked={autostep === step}
                      value={autostep}
                    />
                    <span className="ml-[5px]">{autostep}</span>
                  </SelectItem>
                );
              })}
            </div>
            <div className="whtiespace-normal flex h-[48px] items-center px-[16px]">
              <span className="mr-[60px]">Blur</span>
              <SelectItem>
                <SelectCheckbox
                  onChange={() => setBlur((prev) => !prev)}
                  checked={blur}
                  value={blur}
                />
              </SelectItem>
            </div>
          </div>
          <ModalBottom hasStatus={{ modalStatus, failType }}>
            <CommonButton name="확인" onClick={() => handleClick()} type="modal_btn" />
          </ModalBottom>
        </ModalContainer>
      ) : null}
    </>
  );
};

export const VldAutoCancel = ({ title, afterActionCallback, callback, type }) => {
  const handleCallback = () => {
    if (callback) callback();
    if (afterActionCallback) afterActionCallback();
  };
  return (
    <ConfirmButton
      cn={"whitespace-nowrap"}
      actionName={title}
      api={vldAutoReset}
      detail={`${title} 하시겠습니까?`}
      hasPath
      callback={handleCallback}
      type={type}
    />
  );
};

export const VldDetAuto = ({
  title,
  subText,
  set_id,
  action,
  type,
  afterActionCallback,
  callback,
}) => {
  const { listReload } = useReload();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const mainText = `${title} 하시겠습니까?`;
  const [failType, setFailType] = useState("");

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      await vldDetAuto({ action, set_id });
      closeModal();
      afterActionCallback();
      callback();
      setModalStatus(null);
      listReload();
    } catch (e) {
      setModalStatus("hasError");
      setFailType(e.response?.data?.detail ?? `${title} 중 오류가 발생했습니다.`);
    }
  };

  return (
    <>
      <CommonButton name={title} onClick={openModal} type={type} />

      {modal ? (
        <ModalContainer
          title={title}
          closeModal={() => {
            closeModal();
            callback();
          }}
        >
          <ul className="h-[48px] px-[16px]">
            <li>{mainText}</li>
            {subText && <li>{subText}</li>}
          </ul>
          <ModalBottom hasStatus={{ modalStatus, failType }}>
            <CommonButton name="확인" onClick={() => handleClick()} type="modal_btn" />
          </ModalBottom>
        </ModalContainer>
      ) : null}
    </>
  );
};

export const VldDetAutoCancel = ({ title, afterActionCallback }) => {
  return (
    <ConfirmButton
      actionName={title}
      api={vldAutoReset}
      detail={"자동추론을 취소하겠습니까?"}
      hasPath
      callback={afterActionCallback}
    />
  );
};

export const VldInf = ({ selectedItems, contents }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { process } = useBatchIdProcessor();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [floatingItemContents, setFloatingItemContents] = useState(null);

  const [modelList, setModelList] = useState([]);
  const [failType, setFailType] = useState("");
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [required, setRequired] = useState(null);

  const resetOpen = () => {
    // 0. 상태 리셋
    setModalStatus(null);
    setValue(null);
    setOpen(false);
    setRequired(false);

    // 1. 모델 정보 가져오기
    getInfModel();

    // 2. 진행 중인 작업 리스트 생성
    addItem();

    // 3. 모달 열기
    openModal();
  };

  const getInfModel = async () => {
    setModalStatus("loading");

    try {
      const data = await vldInfModel(urlInfo);
      console.log(data);

      setModelList(data.results);
      // latest인 아이템을 default로 세팅
      setValue(data.results.find((item) => item.latest === true)?.model || null);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const addItem = () => {
    // 선택한 아이디의 파일명을 찾기위해 정보 가져오기
    const files = contents.filter((item) => selectedItems.includes(item.id, 0));
    const len = files.length;

    let uploadArr = [];
    for (let i = 0; i < len; i++) {
      uploadArr = [
        ...uploadArr,
        {
          id: files[i].id,
          filename: files[i].filename,
          floatingStatus: "추론",
        },
      ];
    }

    setFloatingItemContents(uploadArr);
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!value) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    }
  };

  const handleClick = async (rowId) => {
    closeModal();

    // 선택한 모델의 아이디롤 찾아서 보내기
    const inf_id = modelList.find((item) => item.model === value).id;

    process({
      rowId,
      resType: "id",
      len: selectedItems.length,
      urlInfo,
      api: vldInf,
      body: { ids: selectedItems, inf_id },
    });
  };

  return (
    <>
      <CommonButton name="모델 선택 및 추론" onClick={resetOpen} icon="IC_stats_w" />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            title="모델 선택 정보 확인"
            count={selectedItems.length}
            closeModal={closeModal}
            modalStatus={modalStatus}
            handleClick={handleClick}
            value={value}
            checkInput={checkInput}
            panelname="모델 선택 및 추론"
            floatingItemContents={floatingItemContents}
            failType={failType}
          >
            <SelectWrap required={required && !value}>
              <SelectName name="모델" required={required && !value} />
              <SelectTitle onClick={() => setOpen(!open)} name={value} styleType="modal" />
              <SelectBox open={open} styleType="modal">
                {modelList.map((item, i) => (
                  <SelectItem key={i} item={item.model} pagination styleType="list">
                    <SelectRadio
                      onChange={(e) => {
                        setValue(e.target.value);
                        setOpen(false);
                      }}
                      checked={value === item.model}
                      value={item.model}
                    />
                  </SelectItem>
                ))}
              </SelectBox>
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};

export const VldReset = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={vldReset}
      hasPath
      body={{ ids: selectedItems }}
      actionName="선별 리셋"
      icon="IC_reset_w"
      type="secondary"
    />
  );
};

export const DownloadOrigAll = ({ count }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadAllButton
      count={count}
      api={vldOrigAllDwlLink}
      dlUrl={`/vld/rad/seg/datas/${set_id}/orig/dwl/`}
      actionName="전체 원본 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadOrig = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={vldOrigDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/vld/rad/seg/datas/${set_id}/orig/dwl/`}
      actionName="원본 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGps = ({ count }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={count}
      api={vldExtraDwlLink}
      hasPath
      dlUrl={`/vld/rad/seg/datas/${set_id}/extra/dwl/`}
      actionName="GPS/IMU 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const VldMapUpload = () => {
  return (
    <FloatingButton
      api={vldMapUl}
      actionName="참고 데이터 업로드"
      accept="png"
      icon="IC_upload_w"
    />
  );
};

export const VldPropList = () => {
  const modalRef = useRef(null);
  const FULL_COLUMNS = [
    { name: "sensor_name", ko: "센서번호" },
    { name: "filename", ko: "파일명" },
    {
      type: "member",
      name: "uploader",
      ko: "업로드 담당자",
    },
    { name: "prop_ul_t", ko: "업로드 시간" },
  ];

  return (
    <TableButton
      ref={modalRef}
      cols={FULL_COLUMNS}
      listQuery={vldPropListQuery}
      actionName="센서설정 데이터 리스트"
      listName="센서설정 데이터"
      icon="IC_table_b"
      type="unfill"
    >
      <VldPropUpload />
    </TableButton>
  );
};

export const VldPropUpload = () => {
  return (
    <FloatingInsertButton
      listApi={vldGetInfo}
      api={vldPropUl}
      actionName="센서설정 데이터 업로드"
      accept="json"
      icon="IC_upload_w"
      type="modal_btn"
    />
  );
};

export const VldDelCheck = ({ contents, selectedItems }) => {
  return (
    <DelCheckButton
      api={vldDelCheck}
      contents={contents}
      selectedItems={selectedItems}
      type="secondary"
      icon="IC_delete_w"
    />
  );
};
