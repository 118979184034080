import { IMG_TYPE_LIST } from "../../lib/constants/constant";

export const HIST_CLCT_COLS = {
  tab: ["data", "path", "port", "berth", "vessel", "date", "module"],
};

export const HIST_DATA_COLS = {
  tab: ["filename", "del_rep", "del_date", "del_cuz"],
  hide: {
    table: {
      tab: [],
    },
    thumb: {
      tab: ["filename"],
    },
  },
};

export const makeImgTypeList = () => IMG_TYPE_LIST.filter((x) => ["orig_thumb"].includes(x.thumb));
