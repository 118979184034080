import { useSetRecoilState } from "recoil";
import { previewCurrentState, previewInfoState, previewState } from "../../lib/stores";
import { ThumbImg } from "../atoms/CommonAtoms";

const GetPreview = (props) => {
  const { children, previewQuery, index, view, qna, tag, currentImgType } = props;

  const setPreview = useSetRecoilState(previewState); // 미리보기 출력 여부
  const setPreviewInfo = useSetRecoilState(previewInfoState); // 미리보기 고정정보
  const setPreviewCurrent = useSetRecoilState(previewCurrentState); // 미리보기 현재정보

  const handleClick = () => {
    setPreviewCurrent({
      index,
      currentImgType,
    });
    setPreviewInfo({
      tag,
      qna,
      previewQuery,
    });
    setPreview(true);
  };

  return (
    <div className="mt-auto" onClick={previewQuery ? handleClick : null}>
      {view === "thumb" ? (
        <ThumbImg cn={`${qna ? "w-[378px] h-[378px]" : "w-[400px] h-[400px]"}`}>
          {children}
        </ThumbImg>
      ) : (
        children
      )}
    </div>
  );
};

export default GetPreview;
