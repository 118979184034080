import { atom, selectorFamily } from "recoil";
import {
  statsSensor,
  statsDist,
  statsMonth,
  statsMonthWork,
  statsTotal,
  statsCond,
  statsCondValues,
  statsDistValues,
  statsMonthWorkValues,
  statsMonthValues,
} from "./Stats.apis";
import { reloadTriggerState } from "../../lib/stores";

export const statsTotalQuery = selectorFamily({
  key: "statsTotalQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await statsTotal(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsSensorQuery = selectorFamily({
  key: "statsSensorQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await statsSensor(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsCondState = atom({
  key: "statsCondState",
  default: null,
});

export const statsCondValuesQuery = selectorFamily({
  key: "statsCondValuesQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await statsCondValues(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsCondQuery = selectorFamily({
  key: "statsCondQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);
      const path = get(statsCondState);

      const res = await statsCond({ ...params, ...path });

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsMonthState = atom({
  key: "statsMonthState",
  default: null,
});

export const statsMonthValuesQuery = selectorFamily({
  key: "statsMonthValuesQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await statsMonthValues(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsMonthQuery = selectorFamily({
  key: "statsMonthQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);
      const path = get(statsMonthState);

      const res = await statsMonth({ ...params, ...path });

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsMonthWorkState = atom({
  key: "statsMonthWorkState",
  default: null,
});

export const statsMonthWorkValuesQuery = selectorFamily({
  key: "statsMonthWorkValuesQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await statsMonthWorkValues(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsMonthWorkQuery = selectorFamily({
  key: "statsMonthWorkQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);
      const path = get(statsMonthWorkState);

      const res = await statsMonthWork({ ...params, ...path });

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsDistState = atom({
  key: "statsDistState",
  default: null,
});

export const statsDistValuesQuery = selectorFamily({
  key: "statsDistValuesQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await statsDistValues(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const statsDistQuery = selectorFamily({
  key: "statsDistQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);
      const path = get(statsDistState);

      const res = await statsDist({ ...params, ...path });

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});
