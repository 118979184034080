import { axiosInstance } from "../../components/templates/RootErrorBoundary";

// 학습 화면 데이터셋 리스트 조회
export const lrnClctList = async ({ sensor_code, gt_code, tab, ...params }) => {
  const { data } = await axiosInstance.get(`/lrn/${sensor_code}/${gt_code}/sets/${tab}/`, {
    params,
  });

  return data;
};

// 학습 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const lrnClctFilter = async ({ sensor_code, gt_code, tab, col }, params) => {
  const { data } = await axiosInstance.get(
    `/lrn/${sensor_code}/${gt_code}/sets/${tab}/cols/${col}/`,
    { params }
  );

  return data;
};

// 학습 화면 탭별 데이터셋 수 조회
export const lrnClctCnt = async ({ sensor_code, gt_code, t }) => {
  const { data } = await axiosInstance.get(`/lrn/${sensor_code}/${gt_code}/sets/cnt/`, {
    params: { t },
  });

  return data;
};

// 다운로드 링크 조회
export const lrnExportDwlLink = async ({ sensor_code, gt_code }, source, body) => {
  const { data } = await axiosInstance.post(
    `/lrn/${sensor_code}/${gt_code}/sets/export/link/`,
    body,
    {
      cancelToken: source.current.token,
    }
  );

  return data;
};

// 센서융합 다운로드 링크 조회
export const lrnFusionDwlLink = async (body) => {
  const { data } = await axiosInstance.post(`lrn/rad/seg/sets/fusion/export/link/`, body);

  return data;
};

// 학습 화면 mask create
// cs, rs
export const lrnMask = async ({ sensor_code, gt_code }, body) => {
  const { data } = await axiosInstance.post(`/lrn/${sensor_code}/${gt_code}/sets/mask/`, body);

  return data;
};

// 학습 화면 데이터 리스트 id 조회
export const lrnIds = async ({ sensor_code, gt_code, set_id, tab, view, page, ...params }) => {
  const { data } = await axiosInstance.get(`/lrn/${sensor_code}/${gt_code}/datas/${set_id}/ids/`, {
    params,
  });

  return data;
};

// 학습 화면 데이터 리스트 조회
export const lrnList = async ({ sensor_code, gt_code, set_id, tab, view, ...params }) => {
  const { data } = await axiosInstance.get(
    `/lrn/${sensor_code}/${gt_code}/datas/${set_id}/${view}/`,
    { params }
  );

  return data;
};

// 학습 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const lrnFilter = async ({ sensor_code, gt_code, set_id, col }, params) => {
  const { data } = await axiosInstance.get(
    `/lrn/${sensor_code}/${gt_code}/datas/${set_id}/cols/${col}/`,
    { params }
  );

  return data;
};

// 학습 화면 원본 미리보기
export const lrnPreview = async ({ sensor_code, gt_code, id, img_type }) => {
  const { data } = await axiosInstance.get(
    `/lrn/${sensor_code}/${gt_code}/datas/${id}/pre/${img_type}/`
  );

  return data;
};
