import { useRef, useState } from "react";
import { useFileUploader, useModals } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { checkFile } from "../../../lib/utils";
import {
  SelectWrap,
  SelectBox,
  SelectItem,
  SelectName,
  SelectRadio,
  SelectTitle,
} from "../../atoms/SelectBox";
import { CommonButton } from "../../molecules/Buttons";
import { SaveModal, AcceptModal } from "../../molecules/CommonModals";

// 입력: 파일 업로드
// 출력: 응답 받은 센서번호 선택박스
// 입력: 센서 번호
// 출력: 진행중인 작업에 추가
export const FloatingInsertAction = ({
  listApi,
  api,
  actionName,
  accept,
  listData,
  multiple,
  icon,
  type,
  afterSuccessCallback,
}) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { process } = useFileUploader();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const fileInput = useRef();
  const [floatingItemContents, setFloatingItemContents] = useState(null);
  const [selectFile, setSelectFile] = useState(null);

  const [sensorList, setSensorList] = useState([]);
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [checkFileResult, setCheckFileResult] = useState(false);

  // 1. 버튼 클릭시 파일 선택 창 열기
  const handleFileInput = async (e) => {
    setCheckFileResult(false);
    const files = e.target.files;
    const newFileList = Array.from(files);
    const len = files.length;

    // 선택된 파일이 없으면 return
    if (len < 1) return null;

    // 조건에 부합하지 않으면 실패 사유 출력
    const isFailed = checkFile(len, files, accept);
    if (isFailed) {
      setCheckFileResult(isFailed);
      openModal();
    } else {
      // 2. 진행중인 작업 리스트 생성
      let arr = [];
      for (let i = 0; i < len; i++) {
        arr = [
          ...arr,
          {
            filename: files[i].name,
            floatingStatus: "대기",
          },
        ];
      }

      setFloatingItemContents(arr);

      // 3. 선택한 파일 담기
      setSelectFile(newFileList);

      resetOpen();
    }
  };

  // 4. 파일 선택 시 모달 상태 리셋
  const resetOpen = () => {
    // 5. api 호촐
    if (listApi) {
      getSensorList();
    } else {
      setSensorList(listData);
    }
    setValue(null);
    setFailType(null);
    setRequired(false);
    setModalStatus(null);

    // 6. 모달 열기
    openModal();
  };

  const getSensorList = async () => {
    setModalStatus("loading");

    try {
      const data = await listApi(urlInfo);

      console.log(data);
      setSensorList(data.values.sensor_name);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  // 7. 입력창 체크
  const checkInput = () => {
    setModalStatus("hasError");

    if (!value) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    }
  };

  const handleUploadFile = async (rowId) => {
    closeModal();

    process({
      rowId,
      urlInfo,
      api,
      selectFile,
      sensor_name: value,
      afterSuccessCallback,
    });
  };

  return (
    <>
      <input
        type="file"
        name="file"
        ref={fileInput}
        onChange={handleFileInput}
        onClick={(e) => (e.target.value = null)}
        style={{ display: "none" }}
        multiple={multiple}
      />
      <CommonButton
        name={actionName}
        onClick={() => fileInput.current.click()}
        icon={icon}
        type={type}
      />

      {modal &&
        (checkFileResult ? (
          <AcceptModal
            closeModal={closeModal}
            accept={accept}
            checkFileResult={checkFileResult}
            blackBackground={20}
          />
        ) : (
          <SaveModal
            title={`${actionName} 정보 확인`}
            count={selectFile.length}
            closeModal={closeModal}
            modalStatus={modalStatus}
            handleClick={handleUploadFile}
            value={value}
            checkInput={checkInput}
            panelname={actionName}
            floatingItemContents={floatingItemContents}
            failType={failType}
            blackBackground={20}
          >
            <SelectWrap required={required && !value}>
              <SelectName name="센서번호" />
              <SelectTitle
                onClick={() => setOpen(!open)}
                name={value || "선택"}
                styleType="modal"
              />
              <SelectBox open={open} styleType="modal">
                {sensorList.map((item, i) => (
                  <SelectItem key={i} item={item} pagination styleType="list">
                    <SelectRadio
                      onChange={(e) => {
                        setValue(e.target.value);
                        setOpen(false);
                      }}
                      checked={value === item.toString()}
                      value={item}
                    />
                  </SelectItem>
                ))}
              </SelectBox>
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};
