import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { currentUserState, userInfoQuery } from "../../lib/stores";
import { Loader } from "../../components/atoms/Status";
import { DashBoardForChecker } from "./DashboardForChecker";
import { DashBoardForWorker } from "./DashboardForWorker";

const MainPage = () => {
  const { state } = useRecoilValueLoadable(userInfoQuery);
  const { internal } = useRecoilValue(currentUserState);

  if (state === "loading") {
    return <Loader />;
  }

  return (
    <div className="flex h-full w-full flex-row flex-wrap content-start items-start gap-[20px] bg-gray100">
      {internal ? <DashBoardForChecker /> : <DashBoardForWorker />}
    </div>
  );
};

export default MainPage;
