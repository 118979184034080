import { axiosInstance } from "../../components/templates/RootErrorBoundary";

// 검수 화면 탭별 데이터 수 조회
export const checkClctCnt = async ({ sensor_code, gt_code, t }) => {
  const { data } = await axiosInstance.get(`/check/${sensor_code}/${gt_code}/sets/cnt/`, {
    params: { t },
  });

  return data;
};

// 검수 화면 데이터셋 리스트 조회
export const checkClctList = async ({ sensor_code, gt_code, tab, ...params }) => {
  const { data } = await axiosInstance.get(`/check/${sensor_code}/${gt_code}/sets/${tab}/`, {
    params,
  });

  return data;
};

// 검수 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const checkClctFilter = async ({ sensor_code, gt_code, col, tab }, params) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/sets/${tab}/cols/${col}/`,
    { params }
  );

  return data;
};

// 검수 화면 검수 완료
export const checkCmp = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/cmp/`,
    body
  );

  return data;
};

// 검수 대금지급날짜 입력
export const checkPay = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/pay/`,
    body
  );

  return data;
};

// 검수 화면 (전체 검수 완료시) mask 파일 생성
export const checkMaskCreate = async ({ sensor_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/seg/datas/${set_id}/cmp/mask/`,
    body
  );

  return data;
};

// 검수자 변경 요청 checker list
export const checkGetInfo = async ({ sensor_code, gt_code }) => {
  const { data } = await axiosInstance.get(`/check/${sensor_code}/${gt_code}/datas/values/`);

  return data;
};

// 검수자 변경 요청
export const checkModifyCheckerReq = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/checker/req/`,
    body
  );

  return data;
};

// 검수자 변경
export const checkModifyChecker = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/checker/opt/`,
    body
  );

  return data;
};

// 검수자 변경
export const checkModifyCheckerAll = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/checker/`,
    body
  );

  return data;
};

// 작업 셋 리스트 조회
export const checkWorkSetList = async ({ sensor_code, gt_code, set_id, t }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/sets/`,
    {
      params: { t },
    }
  );

  return data;
};

// 작업 셋 수정
export const checkModifyWorkSet = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/sets/mod/`,
    body
  );

  return data;
};

// 검수 화면 작업회수
export const checkCancelDirect = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/cncl/direct/`,
    body
  );

  return data;
};

// 작업 셋 배분회수
export const checkDistResetWorkSet = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/sets/reset/`,
    body
  );

  return data;
};

// 검수 화면 작업 시작 리셋
export const checkWorkStartReset = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/start/reset/`,
    body
  );

  return data;
};

// 검수 화면 원본 다운로드 링크 조회
export const checkOrigAllDwlLink = async ({ sensor_code, gt_code, set_id, tab }, params) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/orig/dwl/link/${tab}/`,
    null,
    { params }
  );

  return data;
};

// 검수 화면 원본 다운로드 링크 조회
export const checkOrigDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/orig/dwl/link/`,
    body
  );

  return data;
};

// 원본 미리보기
export const checkPreview = async ({ sensor_code, gt_code, id, img_type }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/datas/${id}/pre/${img_type}/`
  );
  return data;
};

// 검수 화면 작업회수
export const checkCancel = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/cncl/`,
    body
  );

  return data;
};

// 검수 화면 탭별 데이터 수 조회
export const checkCnt = async ({ sensor_code, gt_code, set_id, t }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/cnt/`,
    { params: { t } }
  );

  return data;
};

// 검수 화면 검수 통과
export const checkPass = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/pass/`,
    body
  );

  return data;
};

// 검수 리셋
export const checkReset = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/reset/`,
    body
  );

  return data;
};

// 검수 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const checkFilter = async ({ sensor_code, gt_code, set_id, tab, col }, params) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/cols/${col}/`,
    { params }
  );

  return data;
};

// 검수 화면 데이터 리스트 전체 Id 조회
export const checkIds = async ({ sensor_code, gt_code, set_id, tab, view, page, ...params }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/ids/`,
    { params }
  );

  return data;
};

// 검수 화면 데이터 리스트 조회
export const checkList = async ({ sensor_code, gt_code, set_id, tab, view, ...params }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/${view}/`,
    { params }
  );

  return data;
};

// cs
// 검수 화면 gt파일 다운로드 링크 조회
export const checkGtPsdAllDwlLink = async ({ set_id, tab }, params) => {
  const { data } = await axiosInstance.post(
    `/check/cam/seg/datas/${set_id}/gt/dwl/link/${tab}/`,
    null,
    { params }
  );
  return data;
};

// cs
// 검수 화면 gt파일 다운로드 링크 조회
export const checkGtPsdDwlLink = async ({ set_id }, body) => {
  const { data } = await axiosInstance.post(`/check/cam/seg/datas/${set_id}/gt/dwl/link/`, body);
  return data;
};

// cd
// 검수 화면 전체 원본+라벨 blend파일 다운로드 링크 조회
export const checkBlendAllDwlLink = async ({ set_id, tab }, params) => {
  const { data } = await axiosInstance.post(
    `/check/cam/det/datas/${set_id}/blend/dwl/link/${tab}/`,
    null,
    { params }
  );
  return data;
};

// cd
// 검수 화면 원본+라벨 blend파일 다운로드 링크 조회
export const checkBlendDwlLink = async ({ set_id }, body) => {
  const { data } = await axiosInstance.post(`/check/cam/det/datas/${set_id}/blend/dwl/link/`, body);
  return data;
};

// cd
// 검수 화면 전체 라벨 파일 다운로드 링크 조회
export const checkLabelAllDwlLink = async ({ set_id, tab }, params) => {
  const { data } = await axiosInstance.post(
    `/check/rad/seg/datas/${set_id}/gt/dwl/link/${tab}/`,
    null,
    { params }
  );
  return data;
};

// cd
// 검수 화면 라벨 파일 다운로드 링크 조회
export const checkLabelDwlLink = async ({ set_id }, body) => {
  const { data } = await axiosInstance.post(`/check/rad/seg/datas/${set_id}/gt/dwl/link/`, body);
  return data;
};

// cd
// 검수 화면 gt파일 다운로드 링크 조회
export const checkGtJsonAllDwlLink = async ({ set_id, tab }, params) => {
  const { data } = await axiosInstance.post(
    `/check/cam/det/datas/${set_id}/gt/dwl/link/${tab}/`,
    null,
    { params }
  );
  return data;
};

// cd
// 검수 화면 gt파일 다운로드 링크 조회
export const checkGtJsonDwlLink = async ({ set_id }, body) => {
  const { data } = await axiosInstance.post(`/check/cam/det/datas/${set_id}/gt/dwl/link/`, body);
  return data;
};

// cs
// 검수 화면 검수 반려
export const checkReturn = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/return/`,
    body
  );

  return data;
};

export const checkRework = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/rework/`,
    body
  );

  return data;
};

// cd, rs
// 검수 화면 정산 탭 파일 업로드
export const checkGtReUl = async (
  { set_id, sensor_code, gt_code },
  body,
  { onUploadProgress },
  source
) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/cmp/ul/`,
    body,
    {
      onUploadProgress,
      cancelToken: source.current.token,
    }
  );

  return data;
};

// 검수 화면 가이드파일 다운로드 링크 조회
export const checkClctGuideDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/clct_guides/${set_id}/dwl/link/`,
    body
  );

  return data;
};

// 검수 화면 가이드파일 다운로드 링크 조회
export const checkGuideDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/guides/${set_id}/dwl/link/`,
    body
  );

  return data;
};

// 검수 화면 가이드파일 삭제
export const checkClctGuideDelete = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/clct_guides/${set_id}/del/`,
    body
  );

  return data;
};

// 검수 화면 가이드파일 삭제
export const checkGuideDelete = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/guides/${set_id}/del/`,
    body
  );

  return data;
};

// 검수 화면 작업가이드 파일 업로드
export const checkClctGuideUl = async (
  { sensor_code, gt_code, set_id },
  body,
  { onUploadProgress },
  source
) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/clct_guides/${set_id}/ul/`,
    body,
    {
      onUploadProgress,
      cancelToken: source.current.token,
    }
  );
  return data;
};

// 검수 화면 작업가이드 파일 업로드
export const checkGuideUl = async (
  { sensor_code, gt_code, set_id },
  body,
  { onUploadProgress },
  source
) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/guides/${set_id}/ul/`,
    body,
    {
      onUploadProgress,
      cancelToken: source.current.token,
    }
  );
  return data;
};

// 검수 화면 QnA 답변 등록
export const checkQnaAnswer = async ({ sensor_code, gt_code, id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/qnas/${id}/answer/`,
    body
  );

  return data;
};

// QnA 이미지 미리보기
export const checkQnaPreview = async ({ sensor_code, gt_code, id }) => {
  const { data } = await axiosInstance.get(`/check/${sensor_code}/${gt_code}/qnas/${id}/pre/`);
  return data;
};

// 검수 화면 ref 리스트 id 조회
export const checkRefIds = async ({ sensor_code, gt_code, set_id, tab, page, ...params }) => {
  const { data } = await axiosInstance.get(`/check/${sensor_code}/${gt_code}/qnas/${set_id}/ids/`, {
    params,
  });
  return data;
};

// 검수 화면 ref 리스트 조회
export const checkRefList = async ({ sensor_code, gt_code, set_id, tab, ...params }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/${
      tab === "1" ? "clct_guides" : tab === "2" ? "guides" : "qnas"
    }/${set_id}/`,
    { params }
  );
  return data;
};

// 검수 화면 ref 탭별 데이터 수 조회
export const checkRefCnt = async ({ sensor_code, gt_code, set_id, t }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/guides/${set_id}/cnt/`,
    { params: { t } }
  );

  return data;
};

// 검수 화면 Ref (필터링시) 해당 컬럼 값 리스트 조회
export const checkRefFilter = async ({ sensor_code, gt_code, set_id, tab, col }, params) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/${
      tab === "1" ? "clct_guides" : "guides"
    }/${set_id}/cols/${col}/`,
    { params }
  );

  return data;
};

// (배분 -> 검수) 화면 배분 셋 추가
export const checkAdd = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/add/`,
    body
  );

  return data;
};

// (배분 -> 검수) 화면 배분 셋 추가하기위한 작업 셋 리스트 조회
export const checkDistWorkSetList = async ({ sensor_code, gt_code, set_id }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/sets/values/`
  );

  return data;
};

// (배분 -> 검수) 화면 배분 셋 제외
export const checkRemove = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/rm/`,
    body
  );

  return data;
};

// (배분 -> 검수) 데이터 폐기 확인
export const checkDelCheck = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/del/check/`,
    body
  );

  return data;
};

// (배분 -> 검수) 화면 배분 리셋
export const checkDistReset = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/dist/reset/`,
    body
  );

  return data;
};

// (배분 -> 검수) 배분정보 입력
export const checkInfo = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/info/`,
    body
  );

  return data;
};

// 미리보기(검수) - 작업 난이도 변경
export const checkLevelChange = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/pre/diff/`,
    { diff_objs: body }
  );
  return data;
};

// (배분 -> 검수) 배분정보 입력 리스트
export const checkInfoValues = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/info/values/`,
    body
  );

  return data;
};

// (배분 -> 검수) 작업 셋 생성
export const checkCreateWorkSet = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/sets/`,
    body
  );

  return data;
};

// (배분 -> 검수) 작업 셋 삭제
export const checkDelWorkSet = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/sets/del/`,
    body
  );

  return data;
};

// (배분 -> 검수) 작업 셋 배분
export const checkWorkSet = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/${gt_code}/datas/${set_id}/sets/dist/`,
    body
  );

  return data;
};

// 배분 화면 참고 원본영상 리스트 조회
export const checkOrigVideoList = async ({ sensor_code, set_id, t }) => {
  const { data } = await axiosInstance.get(`/check/${sensor_code}/seg/datas/${set_id}/videos/`, {
    params: { t },
  });

  return data;
};

// (배분 -> 검수) 화면 참고 원본영상 제작
export const checkMakeOrigVideo = async ({ sensor_code, set_id }, body, source) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/seg/datas/${set_id}/videos/add/`,
    body,
    {
      cancelToken: source.current.token,
    }
  );

  return data;
};

// (배분 -> 검수) 화면 (참고 원본영상 제작시) 입력값 리스트 조회
export const checkOrigVideoGetInfo = async ({ sensor_code, set_id }) => {
  const { data } = await axiosInstance.get(
    `/check/${sensor_code}/seg/datas/${set_id}/videos/values/`
  );

  return data;
};

// (배분 -> 검수) 참고 원본영상 다운로드
export const checkOrigVideoDwlLink = async ({ set_id, sensor_code }, body) => {
  const { data } = await axiosInstance.post(
    `/check/${sensor_code}/seg/datas/${set_id}/videos/dwl/link/`,
    body
  );

  return data;
};

// 카메라 세그멘테이션 작업 화면 err check
export const checkInspect = async ({ gt_code, set_id }, body, source) => {
  const { data } = await axiosInstance.post(`/check/cam/${gt_code}/datas/${set_id}/insp/`, body, {
    cancelToken: source.current.token,
  });

  return data;
};

export const checkInspectDetail = async ({ gt_code, id, t }) => {
  const { data } = await axiosInstance.get(`/check/cam/${gt_code}/datas/${id}/insp/errs/`, {
    params: { t },
  });

  return data;
};
