import { Fragment } from "react";
import clsx from "clsx";
import MANUAL from "../../../lib/constants/manual";
import ModalContainer from "../../molecules/ModalContainer";
import { CommonButton } from "../../molecules/Buttons";

const ManualModal = (props) => {
  const { closeModal, title, permissions } = props;

  const renderImg = (src) => {
    return (
      <div className="w-full pt-[16px]">
        <img src={src} alt="img" className="w-full border border-gray600" />
      </div>
    );
  };

  const renderBtn = (index, name, type, cond) => {
    return (
      <div key={index} className={clsx("h-[40px] pl-0", cond && "pr-[8px]")}>
        <CommonButton type={type} name={name} />
      </div>
    );
  };

  return (
    <ModalContainer modalType="manual" title={title} closeModal={closeModal}>
      <ul className="h-[calc(80vh-84px)] overflow-y-auto px-[16px]">
        <div className="image_box IC_navlue relative my-[20px] mx-auto h-[105px] w-[360px]">
          <div className="image_sprite absolute h-full w-full"></div>
        </div>

        {MANUAL.guide.map((x, i) => (
          <Fragment key={i}>
            <h3 className="my-[12px] text-[14px] font-bold">{x.h3}</h3>
            <ol>
              {x.ol.map((y, i) => (
                <li key={i} className={clsx("pl-[16px]", i !== 0 && "pt-[8px]")}>
                  <u>{y.li}</u>
                  <br />
                  {y.p}
                </li>
              ))}
            </ol>
          </Fragment>
        ))}

        <div className="h-[60px]"></div>
        <h2 className="my-[12px] text-[18px] font-bold">화면 구성 및 기능</h2>
        <p>기본 데이터 조회 화면 구성 및 기능에 대한 설명입니다.</p>
        {MANUAL.layout.map((x, i) => (
          <div key={i} className={clsx(i !== 0 && "pt-[28px]")}>
            {renderImg(x.img)}
            {x.desc.map((y, i) => (
              <Fragment key={i}>
                <h3 className="my-[12px] text-[14px] font-bold">{y.h3}</h3>
                <p>
                  {y.p.map((z, i) => (
                    <Fragment key={i}>
                      {z}
                      {i === y.p.length - 1 ? null : <br />}
                    </Fragment>
                  ))}
                </p>
              </Fragment>
            ))}
          </div>
        ))}

        <div className="h-[60px]"></div>
        <h2 className="my-[12px] text-[18px] font-bold">페이지별 기능</h2>
        <p>각 페이지별 기능에 대한 설명입니다.</p>
        {MANUAL.pages.map((x, i) =>
          x.permission && !permissions.includes(x.permission, 0) ? null : (
            <div key={i} className="pt-[48px]">
              <h3 className="my-[12px] text-[18px] font-bold">{x.h3}</h3>
              <p>{x.p}</p>
              {x.desc?.map((y, i) => (
                <div key={i} className="pt-[24px]">
                  <h4 className="font-bold">{y.h4}</h4>
                  {y.ol ? (
                    <ol>
                      {y.ol?.map((z, i) => (
                        <li key={i} className="pl-[16px] pt-[8px]">
                          <div className="flex items-center gap-[4px]">
                            {i + 1}.
                            {z.btn
                              ? renderBtn(i, z.btn[0], z.btn[1], z.btn[2])
                              : z.btns?.map((a, i) => renderBtn(i, a[0], a[1], a[2]))}
                          </div>
                          <div className="pl-[16px]">
                            {z.pos ? <div>(위치 : {z.pos})</div> : null}
                            {z.p?.map((a, i) => (
                              <div key={i} className="h-[18px]">
                                {a}
                              </div>
                            ))}
                          </div>
                        </li>
                      ))}
                    </ol>
                  ) : (
                    <ul key={i}>
                      {y.ul?.map((z, i) => (
                        <li key={i}>- {z}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )
        )}
      </ul>
    </ModalContainer>
  );
};

export default ManualModal;
