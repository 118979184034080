import { axiosInstance } from "../../components/templates/RootErrorBoundary";

// 환경입력 화면 탭별 데이터 수 조회
export const condClctCnt = async ({ t }) => {
  const { data } = await axiosInstance.get(`/cond/sets/cnt/`, {
    params: { t },
  });

  return data;
};

// 환경입력 화면 데이터셋 리스트 조회
export const condClctList = async ({ tab, sensor_code, ...params }) => {
  const { data } = await axiosInstance.get(`/cond/sets/${tab}/`, {
    params,
  });

  return data;
};

// 환경입력 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const condClctFilter = async ({ tab, col }, params) => {
  const { data } = await axiosInstance.get(`/cond/sets/${tab}/cols/${col}/`, {
    params,
  });

  return data;
};

// 환경입력 화면 원본 미리보기
export const condPreview = async ({ id, img_type }) => {
  const { data } = await axiosInstance.get(`/cond/datas/${id}/pre/${img_type}/`);

  return data;
};

// 환경입력 화면 조건 입력
export const condSave = async ({ set_id }, body) => {
  const { data } = await axiosInstance.post(`/cond/datas/${set_id}/`, body);

  return data;
};

// 환경입력 화면 탭별 데이터 수 조회
export const condCnt = async ({ set_id, t }) => {
  const { data } = await axiosInstance.get(`/cond/datas/${set_id}/cnt/`, {
    params: { t },
  });

  return data;
};

// 데이터 폐기 확인
export const condDelCheck = async ({ set_id }, body) => {
  const { data } = await axiosInstance.post(`/cond/datas/${set_id}/del/check/`, body);

  return data;
};

// 카메라 환경조건입력 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const condFilter = async ({ set_id, tab, col }, params) => {
  const { data } = await axiosInstance.get(`/cond/datas/${set_id}/${tab}/cols/${col}/`, { params });

  return data;
};

// 카메라 세그멘테이션 환경조건입력 화면 데이터 리스트 전체 id 조회
export const condIds = async ({ sensor_code, set_id, tab, view, page, ...params }) => {
  const { data } = await axiosInstance.get(`/cond/datas/${set_id}/${tab}/ids/`, {
    params,
  });

  return data;
};

// 카메라 세그멘테이션 환경조건입력 화면 데이터 리스트 조회
export const condList = async ({ sensor_code, set_id, tab, view, ...params }) => {
  const { data } = await axiosInstance.get(`/cond/datas/${set_id}/${tab}/${view}/`, {
    params,
  });

  return data;
};
