import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { GUIDE_COLS, GUIDE_TAB, REF_TAB_LINK } from "../../lib/constants/constant";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { checkRefFilter } from "./Check.apis";
import {
  CheckGuideDel,
  DownloadClctGuide,
  DownloadGuide,
  UploadClctGuide,
  UploadGuide,
  CheckClctGuideDel,
} from "./CheckActions";
import {
  checkQnaPreviewQuery,
  checkRefCntQuery,
  checkRefIdsQuery,
  checkRefListQuery,
} from "./Check.stores";

const CheckGuidePage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;
  const { tab, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    checkRefCntQuery({ ...urlParams, tab, t })
  );
  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    checkRefIdsQuery({ sensor_code, gt_code, set_id, ...queryParams })
  );

  return (
    <CommonTemplate
      listQuery={checkRefListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
      filterApi={tab !== "3" && checkRefFilter}
      previewQuery={tab === "3" && checkQnaPreviewQuery}
      fullColumnData={GUIDE_COLS.tab}
      columnData={GUIDE_COLS[`tab${tab}`]}
      tabData={GUIDE_TAB}
      refTabData={REF_TAB_LINK.data}
      qnaType={tab === "3" && "a"}
      actions={({ selectedItems }) => (
        <>
          {tab === "1" && <UploadClctGuide />}
          {tab === "2" && <UploadGuide />}
          {tab !== "3" && <SelectCnt count={selectedItems.length} />}
          {tab === "1" && (
            <>
              <DownloadClctGuide selectedItems={selectedItems} />
              <CheckClctGuideDel selectedItems={selectedItems} />
            </>
          )}
          {tab === "2" && (
            <>
              <DownloadGuide selectedItems={selectedItems} />
              <CheckGuideDel selectedItems={selectedItems} />
            </>
          )}
        </>
      )}
    />
  );
};

export default CheckGuidePage;
