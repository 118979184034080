import { atom, selector } from "recoil";
import { userInfo } from "./apis";

// get current user
export const userInfoQuery = selector({
  key: "userInfoQuery",
  get: async ({ get }) => {
    get(infoReloadTriggerState);

    const res = await userInfo();

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

// current user
export const currentUserState = atom({
  key: "currentUserState",
  default: {},
});

// current menu permission
export const currentPermissionState = atom({
  key: "currentPermissionState",
  default: false,
});

// 현재 메뉴 정보
export const currentMenuState = atom({
  key: "currentMenuState",
  default: {},
});

// 현재 필터 정보
export const currentFilterState = atom({
  key: "currentFilterState",
  default: "",
});

// 현재 필터 정보
export const currentFilterPosState = atom({
  key: "currentFilterPosState",
  default: 0,
});

// reload trigger
export const reloadTriggerState = atom({
  key: "reloadTriggerState",
  default: 0,
});

// reload trigger
export const infoReloadTriggerState = atom({
  key: "infoReloadTriggerState",
  default: 0,
});

// modalReload trigger
export const modalReloadTriggerState = atom({
  key: "modalReloadTriggerState",
  default: 0,
});

// 모달 열림 여부
export const modalOpenState = atom({
  key: "modalOpenState",
  default: null,
});

// 이미지 타입 옵션 선택
export const imgTypeDataState = atom({
  key: "imgTypeDataState",
  default: [],
});

// 미리보기 출력 여부
export const previewState = atom({
  key: "previewState",
  default: false,
});

// 미리보기 고정정보
export const previewInfoState = atom({
  key: "previewInfoState",
  default: null,
});

// 미리보기 현재정보
export const previewCurrentState = atom({
  key: "previewCurrentState",
  default: null,
});

// 미리보기 전체 리스트
export const previewListState = atom({
  key: "previewListState",
  default: [],
});

// 진행중인 작업 최소화
export const floatingMinState = atom({
  key: "floatingMinState",
  default: false,
});

// 다운로드 진행중 여부
export const isDwlState = atom({
  key: "isDwlState",
  default: false,
});
