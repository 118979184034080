import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { formatAmountShort } from "../../lib/utils";
import { createColorArr, findDict } from "./StatsPage";

// Line
export const LineChart = (props) => {
  const { contents, xName } = props;

  const formatData = (item) => {
    const labels = item.chart_labels;
    const len = item.chart_data.length;
    const colorArr = createColorArr(len);

    const datasets =
      len > 0
        ? item.chart_data.map((d, i) => {
            return {
              label: findDict(d.label),
              data: d.data,
              borderColor: colorArr[i],
              pointBackgroundColor: colorArr[i],
              pointHoverBorderColor: colorArr[i],
              tension: 0.1,
            };
          })
        : [];

    return {
      axisTitles: {
        x: {
          display: true,
          text: xName,
          font: {
            weight: "bold",
          },
        },
        y: {
          display: false,
          text: "count",
          font: {
            weight: "bold",
          },
        },
      },
      labels,
      datasets,
    };
  };

  const data = formatData(contents);

  return (
    data && (
      <Line
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: data.axisTitles.x,
              grid: {
                drawTicks: false,
              },
              ticks: {
                padding: 10,
              },
            },
            y: {
              title: data.axisTitles.y,
              grid: {
                drawTicks: false,
              },
              ticks: {
                padding: 10,
                callback: formatAmountShort,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
              align: "end",
              labels: { boxHeight: 1 },
            },
            tooltip: {
              backgroundColor: "rgba(0,0,0,0.7)",
              cornerRadius: 2,
              titleColor: "#fff",
              bodyColor: "#fff",
              callbacks: {
                labelColor: (context) => {
                  return {
                    backgroundColor: context.dataset.pointHoverBorderColor,
                  };
                },
              },
            },
          },
        }}
      />
    )
  );
};
