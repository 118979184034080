import { useEffect } from "react";
import { useShiftDownEvent } from "../../lib/hooks";
import { ImageSprite } from "../atoms/CommonAtoms";
import { CommonButton } from "../molecules/Buttons";
import { LoadFail } from "../atoms/Status";

const QnalWrapper = (props) => {
  const { status, children, renderHeader } = props;

  let ulRef;
  const [isShiftDown, useShiftDown] = useShiftDownEvent();
  useShiftDown();

  useEffect(() => {
    const ref = ulRef;
    // shift시 select 방지
    const handleSelectStart = (e) => {
      if (isShiftDown) e.preventDefault();
    };

    ref.addEventListener("selectstart", handleSelectStart);
    return () => {
      ref.removeEventListener("selectstart", handleSelectStart);
    };
  }, [isShiftDown]);

  const goToTop = () => {
    const ref = ulRef;
    ref.scrollTo(0, 0);
  };

  return (
    <div className="list_container relative h-[calc(100vh-248px)] w-full px-[16px]">
      <div className="fixed bottom-[84px] right-[56px] z-10 flex h-[40px] w-[40px] items-center justify-center rounded-[2px] bg-primary">
        <CommonButton onClick={goToTop} name={<ImageSprite iconType="IC_up_w" />} />
      </div>
      <ul
        ref={(el) => (ulRef = el)}
        className="common_table h-full min-h-full w-full overflow-x-hidden"
      >
        <div className="thumb_head sticky top-0 z-20 flex h-[34px] w-full bg-white px-[20px] leading-[34px]">
          {renderHeader()}
        </div>
        {status === "hasError" ? (
          <LoadFail failType="데이터를 불러오는데 실패했습니다." />
        ) : (
          <ul className="flex h-[calc(100%-50px)] w-full flex-row flex-wrap content-start">
            {children}
          </ul>
        )}
      </ul>
    </div>
  );
};

export default QnalWrapper;
