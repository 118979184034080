import { useSetRecoilState } from "recoil";
import clsx from "clsx";
import { useHotkeys } from "react-hotkeys-hook";
import { CommonButton, CommonDownloadButton } from "./Buttons";
import ModalContainer from "./ModalContainer";
import { ModalBottom } from "./Modals";
import FloatingItemCreator from "../organisms/floating/FloatingItemCreator";
import { isDwlState } from "../../lib/stores";

export const ConfirmModal = (props) => {
  const {
    title,
    count,
    detail,
    closeModal,
    modalStatus,
    failType,
    panelname,
    handleUploadFile,
    floatingItemContents,
    downloadInfo,
    handleClick,
    blackBackground,
  } = props;

  const setIsDwl = useSetRecoilState(isDwlState);

  return (
    <ModalContainer
      title={title}
      closeModal={closeModal}
      desc={{
        count,
        text: detail,
      }}
      blackBackground={blackBackground}
    >
      <ModalBottom hasStatus={{ modalStatus, failType }}>
        {floatingItemContents ? (
          <FloatingItemCreator
            panelname={panelname}
            name="확인"
            handleUploadFile={handleUploadFile}
            floatingItemContents={floatingItemContents}
          />
        ) : downloadInfo ? (
          modalStatus === "hasValue" ? (
            <CommonDownloadButton
              downloadUrls={[downloadInfo.downloadUrl]}
              onClick={() => {
                setIsDwl(true);
                closeModal();
              }}
              name={`다운로드 ${
                downloadInfo.successCnt && `(총 ${downloadInfo.successCnt}개의 데이터)`
              }`}
              modalStatus={modalStatus}
              type="modal_btn"
            />
          ) : (
            <CommonButton name="확인" onClick={closeModal} type="modal_btn" />
          )
        ) : handleClick ? (
          <CommonButton name="확인" onClick={handleClick} type="modal_btn" />
        ) : null}
      </ModalBottom>
    </ModalContainer>
  );
};

export const EmptyModal = (props) => {
  const { closeModal, detail, blackBackground } = props;

  useHotkeys(["esc", "enter"], () => closeModal());

  return (
    <ModalContainer title="알림" closeModal={closeModal} blackBackground={blackBackground}>
      <ul className="h-[48px] px-[16px]">
        <li>{detail || "선택된 데이터가 없습니다."}</li>
      </ul>
      <ModalBottom>
        <CommonButton name="확인" onClick={closeModal} type="modal_btn" />
      </ModalBottom>
    </ModalContainer>
  );
};

export const MessageModal = (props) => {
  const { title = "알림", closeModal, detail, blackBackground } = props;

  useHotkeys(["esc", "enter"], () => closeModal());

  return (
    <ModalContainer title={title} closeModal={closeModal} blackBackground={blackBackground}>
      <ul className="h-[48px] px-[16px]">
        <li>{detail || "메시지가 없습니다."}</li>
      </ul>
      <ModalBottom>
        <CommonButton name="확인" onClick={closeModal} type="modal_btn" />
      </ModalBottom>
    </ModalContainer>
  );
};

export const SaveModal = (props) => {
  const {
    title,
    count,
    detail,
    closeModal,
    children,
    modalStatus,
    handleClick,
    value,
    checkInput,
    panelname,
    floatingItemContents,
    failType,
    lrnExport,
    profile,
    blackBackground,
  } = props;

  return (
    <ModalContainer
      modalType="save_modal"
      title={title}
      closeModal={closeModal}
      desc={
        (count || detail) && {
          count,
          text: detail,
        }
      }
      blackBackground={blackBackground}
    >
      <div
        className={clsx(
          "relative mx-[16px] rounded-[2px] border border-gray400 py-[8px] px-[16px]",
          modalStatus,
          modalStatus === "loading" &&
            "before:absolute before:top-0 before:left-0 before:z-10 before:h-full before:w-full before:bg-black/10"
        )}
      >
        <ul className="flex flex-col flex-nowrap justify-between bg-white">{children}</ul>
      </div>
      <ModalBottom hasStatus={{ modalStatus, failType }}>
        {floatingItemContents ? (
          <FloatingItemCreator
            panelname={panelname}
            name="확인"
            needCheckInput={!value}
            beforeClick={checkInput}
            handleUploadFile={handleClick}
            floatingItemContents={floatingItemContents}
          />
        ) : lrnExport && modalStatus === "hasValue" ? (
          <CommonDownloadButton
            downloadUrls={[lrnExport.downloadUrl]}
            onClick={handleClick}
            name={`다운로드 (총 ${lrnExport.successCnt}개의 가공 데이터)`}
            modalStatus={modalStatus}
            type="modal_btn"
          />
        ) : profile ? (
          <>
            <CommonButton
              name="변경"
              onClick={handleClick}
              type={modalStatus === "loading" ? "tertiary_modal_btn" : "modal_btn"}
            />
          </>
        ) : (
          <CommonButton
            name="확인"
            onClick={handleClick}
            type={modalStatus === "loading" ? "tertiary_modal_btn" : "modal_btn"}
          />
        )}
      </ModalBottom>
    </ModalContainer>
  );
};

export const AcceptModal = (props) => {
  const { closeModal, checkFileResult, accept, blackBackground } = props;

  return (
    <ModalContainer
      title="업로드 실패"
      closeModal={closeModal}
      desc={{
        text:
          checkFileResult === "invalidSize"
            ? "2GB이하의 파일만 업로드 가능합니다."
            : checkFileResult === "invalidExt"
              ? `${
                  Array.isArray(accept)
                    ? "zip, psd, png, jpg, pdf, json, txt, 워드프로세스, 프레젠테이션, 스프레드시트 "
                    : accept
                }
            파일만 업로드 가능합니다.`
              : checkFileResult === "invalidName"
                ? "파일명에는 대소문자 영문, 숫자, 특수문자(- _ .)만 사용가능합니다."
                : checkFileResult === "invalidNameKo" &&
                  "파일명에는 대소문자 영문, 한글, 숫자, 특수문자(- _ .)만 사용가능합니다.",
      }}
      blackBackground={blackBackground}
    ></ModalContainer>
  );
};
