import React, { useState, useEffect } from "react";
import { useRecoilValueLoadable } from "recoil";
import { DataGrid } from "@mui/x-data-grid";
import { datasetListQuery } from "../../Main.stores.js";
import { Loader, LoadFail } from "../../../../components/atoms/Status.js";
import { useDynamicRouting } from "../../../../lib/context/useDynamicRouting.js";

export const DatasetList = ({ category, filters, workerForFilter, checkerForFilter, setCount }) => {
  const { linkTo } = useDynamicRouting();

  const combinedSensorGtCode = `${category.sensor}_${category.gtCode}`;

  const [sortModel, setSortModel] = useState({
    field: SORT_BY.SORT.check_req_t.value,
    sort: SORT_BY.SORT_TYPE.desc,
  });

  const { state, contents } = useRecoilValueLoadable(
    datasetListQuery({
      menu: "check",
      sensor_code: category.sensor,
      gt_code: category.gtCode,
      query: createFilterObject({ filters, workerForFilter, checkerForFilter }),
    })
  );

  useEffect(() => {
    if (state === "hasValue") {
      setCount(contents.count);
    }
  }, [state, contents]);

  const columns = [
    {
      field: FIELD.index.value,
      headerName: FIELD.index.label,
      width: "60",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const sortedRows = params.api.getSortedRows();
        const rowIndex = sortedRows.findIndex((row) => row.id === params.row.id);
        return rowIndex + 1;
      },
      sortable: false,
      filterable: false,
    },
    {
      field: FIELD.dataset_name.value,
      headerName: FIELD.dataset_name.label,
      width: "200",
    },
    {
      field: FIELD.date.value,
      headerName: FIELD.date.label,
      width: "100",
      headerAlign: "center",
      align: "center",
    },
    {
      field: FIELD.name.value,
      headerName: FIELD.name.label,
      width: "160",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: FIELD.checkers.value,
      headerName: FIELD.checkers.label,
      width: "80",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return params.row.checkers ? params.row.checkers.join(", ") : "";
      },
    },
    {
      field: FIELD.img_cnt.value,
      headerName: FIELD.img_cnt.label,
      width: "80",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => addCommanToNumber(params.value),
      sortable: false,
    },
    {
      field: FIELD.check_req_t.value,
      headerName: FIELD.check_req_t.label,
      width: "160",
      headerAlign: "center",
      align: "center",
    },
    {
      field: FIELD[`${combinedSensorGtCode}_pri`].value,
      headerName: FIELD[`${combinedSensorGtCode}_pri`].label,
      width: "110",
      renderCell: (params) => {
        // 배열을 쉼표로 구분된 문자열로 변환
        return params.value
          ? [...Array(params.value)].map((_, index) => (
              <span
                key={index}
                className={params.value === 5 ? "text-[#2878FF]" : "text-[#676767]"}
                style={{ marginRight: "2px" }}
              >
                ●
              </span>
            ))
          : "-";
      },
    },
  ];

  const handleRowDoubleClick = ({ row }) => {
    if (row.dataset_id === undefined) return;
    linkTo({
      pathname: `/check${category.urlPath}/${row.dataset_id}/${row.dataset_name}_${row.date}`,
      search: `?tab=6&view=table&page=1&setName=${row.name}`,
    });
  };

  const handleSortModelChange = (newModel) => {
    if (newModel.length === 0) {
      setSortModel({ field: sortModel.field, sort: SORT_BY.SORT_TYPE.desc });
    } else {
      setSortModel(newModel[0]);
    }
  };

  return (
    <div className="flex-grow overflow-auto">
      {state === "hasError" ? (
        <ErrorOverlay />
      ) : (
        <div className="h-full w-full bg-white">
          <DataGrid
            style={{ minWidth: "1088px" }}
            rows={contents.results ?? []}
            columns={columns}
            loading={state === "loading"}
            getRowId={(row) => row.id}
            columnHeaderHeight={32}
            rowHeight={40}
            disableColumnMenu
            hideFooter
            onRowDoubleClick={handleRowDoubleClick}
            sortingOrder={["desc", "asc", null]}
            sortModel={[sortModel]}
            onSortModelChange={handleSortModelChange}
            slots={{
              columnSortedDescendingIcon: DownwardIcon,
              columnSortedAscendingIcon: UpwardIcon,
              columnUnsortedIcon: SortableIcon, // 새로 추가된 prop
              loadingOverlay: LoadingOverlay,
              noRowsOverlay: EmptyOverlay,
            }}
            sx={customTableStyle}
          />
        </div>
      )}
    </div>
  );
};

const DownwardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3 5H13M3 8.4968H10M3 12L6 11.9947"
      stroke="#2B63C1"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UpwardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3 5H6.00176M3 8.4968H10.0041M3 12H13"
      stroke="#2B63C1"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SortableIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3254 2.62034L8.00001 2.34143L7.67461 2.62034L4.17461 5.62034C3.96495 5.80005 3.94067 6.1157 4.12038 6.32537C4.30009 6.53503 4.61574 6.55931 4.8254 6.3796L8.00001 3.65851L11.1746 6.3796C11.3843 6.55931 11.6999 6.53503 11.8796 6.32537C12.0593 6.1157 12.0351 5.80005 11.8254 5.62034L8.3254 2.62034ZM4.8254 9.62034C4.61574 9.44063 4.30009 9.46491 4.12038 9.67457C3.94067 9.88424 3.96495 10.1999 4.17461 10.3796L7.67461 13.3796L8.00001 13.6585L8.3254 13.3796L11.8254 10.3796C12.0351 10.1999 12.0593 9.88424 11.8796 9.67457C11.6999 9.46491 11.3843 9.44063 11.1746 9.62034L8.00001 12.3414L4.8254 9.62034Z"
      fill="#808080"
    />
  </svg>
);

const LoadingOverlay = () => (
  <div className="flex h-full items-center justify-center">
    <Loader />
  </div>
);

const ErrorOverlay = () => (
  <div className="flex h-full items-center justify-center">
    <LoadFail failType="데이터를 불러오는데 실패했습니다." />
  </div>
);

const EmptyOverlay = () => (
  <div className="flex h-full items-center justify-center">
    <div className="text-[16px] leading-[20px] tracking-[-0.32px] text-[#BEBEBE]">
      검수 대기 데이터가 없습니다.
    </div>
  </div>
);

const addCommanToNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const createFilterObject = ({ filters, workerForFilter, checkerForFilter }) => {
  const filterObject = { page: 1 };
  if (filters.length > 0) {
    filterObject.dataset = filters.map((filter) => filter.join("/")).join(",");
  }
  if (workerForFilter) {
    filterObject.worker = workerForFilter;
  }
  if (checkerForFilter) {
    filterObject.checker = checkerForFilter;
  }
  return filterObject;
};

const FIELD = {
  dataset_id: {
    value: "dataset_id",
    label: "데이터셋 ID",
  },
  index: {
    value: "index",
    label: "번호",
  },
  dataset_name: {
    value: "dataset_name",
    label: "데이터셋",
  },
  date: {
    value: "date",
    label: "날짜",
  },
  cam_seg_pri: {
    value: "cam_seg_pri",
    label: "우선순위",
  },
  cam_det_pri: {
    value: "cam_det_pri",
    label: "우선순위",
  },
  rad_seg_pri: {
    value: "rad_seg_pri",
    label: "우선순위",
  },
  name: {
    value: "name",
    label: "작업셋",
  },
  checkers: {
    value: "checkers",
    label: "검수자",
  },
  img_cnt: {
    value: "img_cnt",
    label: "검수대기",
  },
  check_req_t: {
    value: "check_req_t",
    label: "최근 검수 요청 일시",
  },
};

const SORT_BY = {
  SORT: {
    [FIELD.dataset_name.value]: {
      value: FIELD.dataset_name.value,
      apiKey: "datasetName",
    },
    [FIELD.date.value]: {
      value: FIELD.date.value,
      apiKey: "date",
    },
    [FIELD.cam_seg_pri.value]: {
      value: FIELD.cam_seg_pri.value,
      apiKey: "camSegPri",
    },
    [FIELD.cam_det_pri.value]: {
      value: FIELD.cam_det_pri.value,
      apiKey: "camDetPri",
    },
    [FIELD.rad_seg_pri.value]: {
      value: FIELD.rad_seg_pri.value,
      apiKey: "radSegPri",
    },
    [FIELD.check_req_t.value]: {
      value: FIELD.check_req_t.value,
      apiKey: "checkReqT",
    },
  },
  SORT_TYPE: {
    asc: "asc",
    desc: "desc",
  },
};

const customTableStyle = {
  "& .MuiDataGrid-columnHeader": {
    background: "#F2F2F2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    fontFamily: "Spoqa Han Sans Neo",
    color: "#676767",
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "-0.24px",
    fontWeight: "bold",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader--sorted": {
    color: "#2B63C1", // 선택된 정렬 열의 텍스트 색상
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    width: "100%",
    padding: "0 8px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    display: "flex",
    alignItems: "center",
    overflow: "visible", // 변경된 부분
  },
  "& .MuiDataGrid-sortIcon": {
    marginLeft: "4px",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Spoqa Han Sans Neo",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#333333",
    lineHeight: "15px",
    letterSpacing: "-0.24px",
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell": {
    padding: "0 16px",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "auto",
  },
};
