import React, { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import clsx from "clsx";
import { formatAmountComma } from "../../lib/utils";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { useModals } from "../../lib/hooks";
import {
  CONDITIONS,
  ORIGS,
  ROWS_PER_PAGE,
  SENSORS,
  VLD_AUTO_VALUE,
} from "../../lib/constants/constant";
import { SelectCheckbox, SelectItem } from "../atoms/SelectBox";
import { ImageSprite, StepIndicator } from "../atoms/CommonAtoms";
import { ErrorInspectButton } from "./actions";
import GetPreview from "./GetPreview";
import { workInspectDetailQuery } from "../../pages/work/Work.stores";
import { checkInspectDetailQuery } from "../../pages/check/Check.stores";
import ModalContainer from "../../components/molecules/ModalContainer";
import { ModalBottom } from "../../components/molecules/Modals";
import { CommonButton } from "../../components/molecules/Buttons";

export const RenderRow = React.memo(
  ({
    row,
    index,
    columns,
    handleSelectTableRow,
    selected,
    previewQuery,
    tag,
    currentPage,
    currentMenu,
    linkPath,
    statusCodeData,
    groupColumnData,
    currentImgType,
    main,
  }) => {
    const linkRefs = useRef({});

    let isShiftDown = false;
    useHotkeys("shift", () => (isShiftDown = true), { keydown: true });
    useHotkeys("shift", () => (isShiftDown = false), { keyup: true });

    return (
      <tr
        className="cursor-pointer hover:bg-gray100"
        onClick={() => handleSelectTableRow && handleSelectTableRow(row.id, isShiftDown)}
        onContextMenu={(e) => e.preventDefault()}
        onDoubleClick={() => linkRefs.current[row.id]?.click()}
      >
        <td align="center">{index + 1 + (currentPage - 1) * ROWS_PER_PAGE}</td>
        {!main && (
          <td className="px-[8px] py-[4px]" align="center">
            <SelectItem>
              <SelectCheckbox checked={selected} tr />
            </SelectItem>
          </td>
        )}

        {previewQuery && (
          <td className="cursor-pointer" onClick={(e) => e.stopPropagation()}>
            <GetPreview
              previewQuery={previewQuery}
              index={index + (currentPage - 1) * ROWS_PER_PAGE}
              currentImgType={currentImgType}
              tag={tag}
            >
              <ImageSprite boxSize="scale_small" iconType="IC_preview_s" />
            </GetPreview>
          </td>
        )}

        {columns?.map((col, i) => {
          switch (col.type) {
            case "member":
              return RenderStrategies.member(i, row[col.name]);
            case "arr":
              return RenderStrategies.array(i, row[col.name]);
            case "filesize":
              return RenderStrategies.fileSize(i, row[col.name]);
            case "group":
              return RenderStrategies.group(
                col.name === "cond_cnts"
                  ? CONDITIONS
                  : col.name === "sensor_cnts"
                    ? SENSORS
                    : col.name === "img_cnts"
                      ? ORIGS
                      : [],
                row[col.name]
              );
            case "insp":
              return RenderStrategies.inspector(i, row, currentMenu.menu.en);
            case "insp_result":
              return RenderStrategies.emphasize(i, row[col.name]);
            case "num":
            case "strong":
              return RenderStrategies.number(i, row[col.name]);
            case "total":
              return RenderStrategies.total(i, row[col.name]);
            case "cnts":
              return RenderStrategies.group(
                groupColumnData,
                row[col.name],
                (col) => col.type === "strong"
              );
            case "code":
              return RenderStrategies.statusCode(i, row[col.name], statusCodeData);
            case "vldAuto":
              return RenderStrategies.vldAuto(i, row[col.name]);
            case "step":
              return RenderStrategies.step(i, row[col.name]);
            case "link":
              return (
                <RenderLinkCol
                  key={i}
                  row={row}
                  linkPath={linkPath}
                  main={main}
                  linkRef={(el) => (linkRefs.current[row.id] = el)}
                />
              );
            // 추후에 왼쪽 영역으로 텍스트를 사용하는 케이스 적용 시, 아래의 케이스 활성화
            // case "left":
            //   return RenderStrategies.left(i, row[col.name]);
            default:
              return RenderStrategies.common(i, row[col.name]);
          }
        })}
        <td></td>
      </tr>
    );
  }
);

const RenderLinkCol = React.memo(({ row, linkPath, main, linkRef }) => {
  const { modal, openModal, closeModal } = useModals(null);
  const { urlParams, linkTo } = useDynamicRouting();
  const { gt_code, menu } = urlParams;

  let clct_name = "";

  if (menu === "validation") {
    const keys = ["dataset_name", "date"];
    keys.forEach((k) => (clct_name += row[k] && `${row[k]}${k !== "date" ? "_" : ""}`));
  } else {
    const keys = ["path", "port", "berth", "vessel", "date"];
    keys.forEach((k) => (clct_name += row[k] && `${row[k]}${k !== "date" ? "_" : ""}`));
  }

  const handleClick = () => {
    const existAutoSet =
      row?.seg_auto_status === VLD_AUTO_VALUE.AUTO || row?.det_auto_status === VLD_AUTO_VALUE.AUTO;
    if (existAutoSet) {
      openModal();
    } else {
      linkTo({
        pathname: `${linkPath.path}${linkPath.sensorCode ? `/${linkPath.sensorCode}` : ""}/${row.id}/${clct_name}${main?.ref ? main.ref : ""}`,
        search: new URLSearchParams(
          main?.tab
            ? {
                ...linkPath.query.data,
                tab: main.tab,
              }
            : linkPath.query.data
        ).toString(),
      });
    }
  };

  const gtCodeLabel = gt_code === "seg" ? "선별" : "추론";

  return (
    <td align="center">
      <div ref={linkRef} onClick={handleClick}>
        <ImageSprite iconType="IC_link_b" />
      </div>
      {modal && (
        <ModalContainer title={`자동 ${gtCodeLabel} 진행중`} closeModal={closeModal}>
          <ul className="h-[48px] px-[16px]">
            <li>자동 {gtCodeLabel} 진행 중인 데이터 셋은 진입이 불가합니다.</li>
          </ul>
          <ModalBottom>
            <CommonButton name="확인" onClick={closeModal} type="modal_btn" />
          </ModalBottom>
        </ModalContainer>
      )}
    </td>
  );
});

const RenderStrategies = {
  left(i, data) {
    return (
      <td key={i} className="text-left">
        {data ? data : "-"}
      </td>
    );
  },
  emphasize(i, data, strong = false) {
    return (
      <td key={i}>
        <span className={clsx(data ? "" : "text-gray400", strong ? "font-bold text-primary" : "")}>
          {formatAmountComma(data)}
        </span>
      </td>
    );
  },

  group(group, data, strongPredicate = (col) => false) {
    return (
      group?.map((col, index) => {
        const cell = data?.[col.value] ? data[col.value] : 0;
        return this.emphasize(index, cell, strongPredicate(col));
      }) ?? []
    );
  },

  statusCode(i, data, statusCodes) {
    if (!statusCodes || !data)
      return (
        <td key={i} className="text-error">
          데이터 없음
        </td>
      );

    const statusCode = statusCodes.find((item) => item.value === `${data}`);
    const status = statusCode?.ko ?? `STATUS_CODE_${data}`;

    return <td key={i}>{status}</td>;
  },

  vldAuto(i, data) {
    const style =
      data === 1
        ? "text-[#676767]"
        : data === 2
          ? "bg-[#C4C4C4] text-[white]"
          : data === 3
            ? "bg-primary text-[white]"
            : data === 4
              ? "bg-[#081A51] text-[white]"
              : "";

    return (
      <td key={i}>
        <span className={`inline-block rounded-[2px] px-[8px] ${style}`}>
          {data === 1
            ? "X"
            : data === 2
              ? "예약"
              : data === 3
                ? "진행중"
                : data === 4
                  ? "완료"
                  : "-"}
        </span>
      </td>
    );
  },

  member(i, data) {
    return <td key={i}>{data ? data.display_name : "-"}</td>;
  },

  array(i, data) {
    return (
      <td key={i}>
        {data.length > 0
          ? data.map((item, index) => (index + 1 !== data.length ? `${item}, ` : item))
          : "-"}
      </td>
    );
  },

  fileSize(i, data) {
    return <td key={i}>{data}GB</td>;
  },

  inspector(i, data, menu) {
    return (
      <React.Fragment key={i}>
        {menu === "Work" && (
          <td
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {INSPECT_RESULTS.some((result) => data[result] > 0) ? (
              <ErrorInspectButton
                id={{ id: data.id }}
                listQuery={workInspectDetailQuery}
                actionName={`에러 검사 결과 (${data.filename})`}
                detail="레이어별 에러 타입과 에러 유무 검사 결과입니다."
              />
            ) : (
              "-"
            )}
          </td>
        )}
        {menu === "Check" && (
          <td
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {data.errs_exist ? (
              <ErrorInspectButton
                id={{ id: data.id }}
                listQuery={checkInspectDetailQuery}
                actionName={`에러 검사 결과 (${data.filename})`}
                detail="레이어별 에러 타입과 에러 유무 검사 결과입니다."
              />
            ) : (
              "-"
            )}
          </td>
        )}
      </React.Fragment>
    );
  },

  number(i, data) {
    return (
      <td key={i} align="right">
        {data === 0 ? "0" : formatAmountComma(data)}
      </td>
    );
  },

  total(i, data) {
    const obj = [
      {
        value: "eval",
        ko: "평가용 데이터",
      },
      {
        value: "val",
        ko: "학습용 데이터",
      },
      {
        value: "total",
        ko: "총합",
      },
    ];
    const temp = obj.find((x) => x.value === data)?.ko || data;
    return <td key={i}>{data ? temp : "-"}</td>;
  },

  step(i, data) {
    return <td key={i}>{data && data > 0 ? <StepIndicator step={data} /> : null}</td>;
  },

  common(i, data) {
    return (
      <td key={i}>
        {data === true ? "O" : data === false ? "X" : data === 0 ? "0" : data ? data : "-"}
      </td>
    );
  },
};

const INSPECT_RESULTS = [
  "empty_pixels",
  "obj_numbering_err",
  "layer_naming_err",
  "empty_layer",
  "labeling_err",
  "opacity_err",
  "invisible_layer",
  "group_err",
  "hidden_err",
  "duplicate_warn",
  "bg_err",
];
