import { useRecoilValue } from "recoil";
import { currentUserState } from "../../lib/stores";
import ErrorBoundary from "./ErrorBoundary";
import { useContext, useEffect } from "react";
import { NAVLUEContext } from "../../App";
import {
  removeToken,
  sentryCapture,
  // sentryCaptureApi
} from "../../lib/utils";
import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const access = sessionStorage.getItem("access");
  config.headers.Authorization = `Bearer ${access}`;

  return config;
});

export const ApiErrorBoundary = ({ children, user }) => {
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => {
        console.log("interceptors -> res");

        return response;
      },
      async (error) => {
        console.log("interceptors -> err");

        error.response?.status !== 400 &&
          error.response?.status !== 401 &&
          sentryCapture(error, user);

        // 토큰 만료시 토큰 삭제
        if (error.response?.status === 401 && error.response?.data?.code === "token_not_valid") {
          console.log(error.response);

          removeToken();
        }

        return Promise.reject(error);
      }
    );

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, [user]);

  return children;
};

export const RootErrorBoundary = ({ children }) => {
  const user = useRecoilValue(currentUserState);
  const { dispatch } = useContext(NAVLUEContext);

  // unhandled 에러 발생시 진행중인 작업을 리셋하고 2000ms 뒤에 에러화면으로 이동시킴
  const setHasError = () => {
    console.log("setHasError");
    dispatch({ type: "progressRows", data: [] });

    setTimeout(() => {
      window.location.replace("/error");
    }, 2000);
  };

  return (
    <ErrorBoundary setHasError={setHasError} user={user}>
      <ApiErrorBoundary user={user}>{children}</ApiErrorBoundary>
    </ErrorBoundary>
  );
};
