import { useEffect, useState } from "react";
import { RenderHeader } from "../../../components/organisms/RenderHeader";
import TableWrapper from "../../../components/organisms/TableWrapper";
import { RenderRow } from "../../../components/organisms/RenderRow";
import { COLUMN_LIST } from "../../../lib/constants/constant";
import MENU_LIST from "../../../lib/constants/menulist";

const RecentList = (props) => {
  const { data, state, contents } = props;

  const [menu, setMenu] = useState({});
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    MENU_LIST.map((i) =>
      i.menu_list.find(
        (j) =>
          data?.path?.includes(j.path) &&
          setMenu({
            category: i.category,
            menu: j,
          })
      )
    );
    setColumns(
      COLUMN_LIST.filter((item) => data.col.includes(item.name, 0)).map((item) => {
        return {
          type: item.type,
          name: item.name,
          ko: item.ko,
        };
      })
    );
  }, [data]);

  return (
    data && (
      <div className="w-full bg-white pb-[20px]">
        <div className="h-[56px] px-[16px] text-[16px] font-bold leading-[56px] text-gray900">
          {data.title}
        </div>
        <TableWrapper
          main
          state={state}
          contents={contents.length > 0 && contents}
          renderHeader={() => {
            return <RenderHeader columns={columns} state={state} contents={contents} main />;
          }}
        >
          {contents.length > 0 &&
            contents.map((item, index) => (
              <RenderRow
                key={index}
                row={item}
                columns={columns}
                index={index}
                currentPage={1}
                linkPath={menu.menu}
                main={{ ref: data.ref || null, tab: data.tab || "1" }}
              />
            ))}
        </TableWrapper>
      </div>
    )
  );
};

export default RecentList;
