import { useEffect } from "react";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { imgPreviewQuery } from "../../../Main.stores.js";
import { Loader, LoadFail } from "../../../../../components/atoms/Status.js";
import { imgTypeDataState, previewListState } from "../../../../../lib/stores.js";
import { thumbnailsInCoordinatesQuery, updateThumbnailCountsQuery } from "../../../Main.stores.js";
import { Thumbnail } from "./Thumbnail.jsx";

export const IMG_TYPE_LIST = [
  { thumb: "auto_thumb", pre: "auto", ko: "Auto" },
  { thumb: "orig_thumb", pre: "orig", ko: "원본" },
  { thumb: "inf_thumb", pre: "inf", ko: "추론" },
];

export const ThumbnailGrid = ({
  category,
  selectedImgStatus,
  selectedDataset,
  selectedCell,
  page,
  setTotalPage,
  setCountsByImgStatus,
  handleSetHoveredThumbnail,
}) => {
  const { state: countState, contents: countContents } = useRecoilValueLoadable(
    updateThumbnailCountsQuery({
      params: {
        sensor_code: category.sensor,
        gt_code: category.gtCode,
        clct_id: selectedDataset.id,
      },
      body: {
        coords: [
          [selectedCell.xMin, selectedCell.yMin],
          [selectedCell.xMax, selectedCell.yMax],
        ],
      },
    })
  );

  const { state, contents } = useRecoilValueLoadable(
    thumbnailsInCoordinatesQuery({
      sensor_code: category.sensor,
      gt_code: category.gtCode,
      clct_id: selectedDataset.id,
      query: { page, imgStatus: selectedImgStatus.code },
      coords: [
        [selectedCell.xMin, selectedCell.yMin],
        [selectedCell.xMax, selectedCell.yMax],
      ],
    })
  );

  const setImgTypeData = useSetRecoilState(imgTypeDataState);
  const setPreviewList = useSetRecoilState(previewListState);

  useEffect(() => {
    if (state === "hasValue") {
      setTotalPage(Math.max(Math.trunc(contents.count / 500), 1));
      setPreviewList(contents.results.map(({ id }) => id));
    }
  }, [state, contents]);

  useEffect(() => {
    if (countState === "hasValue") {
      setCountsByImgStatus(classifyByImgStatus(countContents.results));
    }
  }, [countState, countContents]);

  useEffect(() => {
    setImgTypeData(IMG_TYPE_LIST);
  }, []);

  return (
    <>
      {state === "loading" ? (
        <div className="flex h-full items-center justify-center">
          <Loader />
        </div>
      ) : state === "hasError" ? (
        <div className="flex h-full items-center justify-center">
          <LoadFail failType="데이터를 불러오는데 실패했습니다." />
        </div>
      ) : contents.results.length ? (
        <div
          className="h-full w-full gap-[12px] overflow-y-scroll"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gridAutoRows: "min-content",
          }}
        >
          {contents.results.map(
            ({ id, filename, auto_thumb, valid, auto, added, excluded, x, y }, index) => (
              <Thumbnail
                key={id}
                filename={filename}
                auto_thumb={auto_thumb}
                valid={valid}
                auto={auto}
                added={added}
                coords={{ x, y }}
                index={index}
                currentPage={page}
                excluded={excluded}
                previewQuery={({ img_type, id }) =>
                  imgPreviewQuery({
                    img_type,
                    id,
                    sensor_code: category.sensor,
                    gt_code: category.gtCode,
                  })
                }
                currentImgType={IMG_TYPE_LIST[0]}
              />
            )
          )}
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <div className="text-[16px] leading-[20px] tracking-[-0.32px] text-[#BEBEBE]">
            자동 선별 데이터가 없습니다.
          </div>
        </div>
      )}
    </>
  );
};

const classifyByImgStatus = (thumbnails) => {
  const countsByImgStatus = {
    all_cnt: 0,
    auto_cnt: 0,
    lrn_cnt: 0,
    val_cnt: 0,
    added_cnt: 0,
    excluded_cnt: 0,
  };

  Object.keys(thumbnails).forEach((key) => {
    if (key in countsByImgStatus) {
      countsByImgStatus[key] = thumbnails[key];
    }
  });

  return countsByImgStatus;
};
