import { useState, useEffect, useMemo, useRef } from "react";
import { useRecoilValueLoadable } from "recoil";
import { Category, CATEGORY } from "../Category.js";
import { segFilterListQuery } from "../../Main.stores.js";
import { FeatureSpace } from "./FeatureSpace";
import { SummaryAndDatasetList } from "./SummaryAndDatasetList";
import { ThumbnailView } from "./ThumbnailView";
import { FilterBox } from "../FilterBox/index.jsx";

export const SegmentationStatus = ({ visible = false }) => {
  const [category, setCategory] = useState(
    CATEGORY[`${Category.SENSOR.CAMERA}_${Category.GT_CODE.SEGMENTATiON}`]
  );

  const { contents: filterDataContents } = useRecoilValueLoadable(segFilterListQuery());
  const filterData = useMemo(() => filterDataContents ?? [], [filterDataContents]);

  const [hoveredDataset, setHoveredDataset] = useState(null);
  const [selectedDataset, setselectedDataset] = useState(null);
  // const [selectedSector, setSelectedSector] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [filters, setFilters] = useState([]);
  const [numberOfLearnedPoints, setNumberOfLearnedPoints] = useState(0);

  const featureSpaceRef = useRef(null);

  const storeSelectedDataset = (selectedDataset) => {
    setselectedDataset(selectedDataset);
    featureSpaceRef.current.activateInteraction(selectedDataset.id);
  };

  const resetSelectedDataset = () => {
    if (selectedCell) {
      setSelectedCell(null);
      featureSpaceRef.current.handleResetCell();
      return;
    }

    setselectedDataset(null);
    // setSelectedSector(null);
    featureSpaceRef.current.deactivateInteraction();
  };

  const handleSetFilters = (filters) => {
    setFilters(filters);
    setSelectedCell(null);
    featureSpaceRef.current.handleResetCell();
    setselectedDataset(null);
    // setSelectedSector(null);
    featureSpaceRef.current.deactivateInteraction();
  };

  useEffect(() => {
    if (visible) {
      handleSetFilters([]);
    }
  }, [visible]);

  return (
    <div
      className={`${visible ? "block" : "hidden"} flex flex-1 flex-col gap-[16px] overflow-auto bg-[white] p-[16px]`}
    >
      {/* 필터링 그룹 */}
      <div className="flex items-center">
        {/* 왼쪽 - 필터링*/}
        <div className="flex h-[30px] gap-[8px]">
          <FilterBox
            filterData={filterData}
            filters={filters}
            setFilters={handleSetFilters}
            totalDataCount={numberOfLearnedPoints}
          />
        </div>
        {/* 오른쪽 - 사이드 설명 */}
        <div className="ml-auto">
          <div className="text-[12px] text-[#333]">
            선별 대기 중인 데이터셋을 학습 데이터셋 커버리지와 비교 및 샘플 데이터를 확인할 수
            있습니다.
          </div>
        </div>
      </div>

      {/* 테이블 그룹 */}

      <div className="flex flex-1 gap-[16px] overflow-auto">
        <div className="flex h-full flex-1 gap-[16px]">
          <FeatureSpace
            ref={featureSpaceRef}
            category={category}
            filters={filters}
            hoveredDataset={hoveredDataset}
            selectedDataset={selectedDataset}
            // selectedSector={selectedSector}
            // setSelectedSector={setSelectedSector}
            selectedCell={selectedCell}
            setSelectedCell={setSelectedCell}
            setNumberOfLearnedPoints={setNumberOfLearnedPoints}
          />
          {selectedDataset ? (
            <ThumbnailView
              category={category}
              selectedCell={selectedCell}
              selectedDataset={selectedDataset}
              resetSelectedDataset={resetSelectedDataset}
            />
          ) : (
            <SummaryAndDatasetList
              category={category}
              setCategory={setCategory}
              filters={filters}
              setHoveredDataset={setHoveredDataset}
              storeSelectedDataset={storeSelectedDataset}
            />
          )}
        </div>
      </div>
    </div>
  );
};
