import { Filter } from "./Filter.jsx";
import { Badge } from "./Badge.jsx";

export const FilterBox = ({ filterData, filters, setFilters, totalDataCount }) => {
  return (
    <div className="flex h-[30px] gap-[8px]">
      <Filter
        filterData={filterData}
        filters={filters}
        setFilters={setFilters}
        totalDataCount={totalDataCount}
      />
      {filters.map((filter, idx) => (
        <Badge
          key={idx}
          value={filter.join("_")}
          handleClick={() => {
            setFilters((prev) => prev.filter((f) => f !== filter));
          }}
        />
      ))}
    </div>
  );
};
