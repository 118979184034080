import { axiosInstance } from "../../components/templates/RootErrorBoundary";

// 선별 화면 탭별 데이터 수 조회
export const vldClctCnt = async ({ sensor_code, gt_code, t }) => {
  const { data } = await axiosInstance.get(`/vld/${sensor_code}/${gt_code}/sets/cnt/`, {
    params: { t },
  });

  return data;
};

// 선별 화면 데이터셋 리스트 조회
export const vldClctList = async ({ sensor_code, gt_code, tab, ...params }) => {
  const { data } = await axiosInstance.get(`/vld/${sensor_code}/${gt_code}/sets/${tab}/`, {
    params,
  });

  return data;
};

// 선별 화면 전체 선별 완료
export const vldClctCmp = async ({ sensor_code, gt_code }, body) => {
  const { data } = await axiosInstance.post(`/vld/${sensor_code}/${gt_code}/sets/cmp/`, body);

  return data;
};

// 선별 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const vldClctFilter = async ({ tab, sensor_code, gt_code, col }, params) => {
  const { data } = await axiosInstance.get(
    `/vld/${sensor_code}/${gt_code}/sets/${tab}/cols/${col}/`,
    { params }
  );

  return data;
};

// 선별 화면 데이터셋 우선순위 지정
export const vldClctPri = async ({ sensor_code, gt_code }, body) => {
  const { data } = await axiosInstance.post(`/vld/${sensor_code}/${gt_code}/sets/pri/`, body);

  return data;
};

// 선별 화면 원본 미리보기
export const vldPreview = async ({ sensor_code, gt_code, id, img_type }) => {
  const { data } = await axiosInstance.get(
    `/vld/${sensor_code}/${gt_code}/datas/${id}/pre/${img_type}/`
  );

  return data;
};

// 선별 화면 탭별 데이터 수 조회
export const vldCnt = async ({ sensor_code, gt_code, set_id, t }) => {
  const { data } = await axiosInstance.get(`/vld/${sensor_code}/${gt_code}/datas/${set_id}/cnt/`, {
    params: { t },
  });

  return data;
};

// 선별 마스크 파일 조회
export const vldMaskList = async ({ set_id }) => {
  return axiosInstance.get(`vld/cam/seg/datas/${set_id}/mask/`);
};

// 선별 마스크 파일 업데이트
export const vldMaskUpdate = async ({ set_id }, body, { onUploadProgress }, source) => {
  const { data } = await axiosInstance.post(`vld/cam/seg/datas/${set_id}/mask/ul/`, body, {
    onUploadProgress,
    cancelToken: source.current.token,
  });

  return data;
};

export const vldMaskDelete = async ({ set_id }, body) => {
  return await axiosInstance.post(`vld/cam/seg/datas/${set_id}/mask/del/`, body);
};

export const vldSensorList = async ({ set_id }) => {
  const { data } = await axiosInstance.get(`vld/cam/seg/datas/${set_id}/mask/values/`);

  return data;
};

// cam/seg 선별 자동화 요청
// action: auto || book
export const vldSegAuto = async ({ set_id, action }, body) => {
  return axiosInstance.post(`vld/cam/seg/datas/${set_id}/${action}/`, body);
};

// cam/det 자동 추론 요청
// action: auto || book
export const vldDetAuto = async ({ set_id, action }) => {
  return axiosInstance.post(`vld/cam/det/datas/${set_id}/${action}/`);
};

// 자동 선별 예약 여부 확인
export const vldAutoCheck = async ({ gt_code, set_id }) => {
  return axiosInstance.get(`vld/cam/${gt_code}/datas/${set_id}/auto/check/`);
};

// 자동 선별 취소 여부 확인
export const vldAutoReset = async ({ gt_code, set_id }) => {
  return axiosInstance.post(`vld/cam/${gt_code}/datas/${set_id}/book/reset/`);
};

// 선별 분포 이미지 다운로드 링크 조회
export const vldAutoResultDwlLink = async ({ set_id }) => {
  const { data } = await axiosInstance.get(
    `/vld/cam/seg/datas/${set_id}/auto/dwl/link/
      `
  );

  return data;
};

// 선별 화면 유효 선별
export const vldSet = async ({ sensor_code, gt_code, set_id, tag }, body) => {
  const { data } = await axiosInstance.post(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/${tag}/`,
    body
  );

  return data;
};

// 선별 화면 선별 취소
export const vldReset = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/reset/`,
    body
  );

  return data;
};

// 선별 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const vldFilter = async ({ sensor_code, gt_code, set_id, tab, col }, params) => {
  const { data } = await axiosInstance.get(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/cols/${col}/`,
    { params }
  );

  return data;
};

// 선별 화면 데이터 리스트 조회
export const vldIds = async ({ sensor_code, set_id, tab, gt_code, view, page, ...params }) => {
  const { data } = await axiosInstance.get(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/ids/`,
    { params }
  );

  return data;
};

// 선별 화면 데이터 리스트 조회
export const vldList = async ({ sensor_code, set_id, tab, gt_code, view, ...params }) => {
  const { data } = await axiosInstance.get(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/${view}/`,
    { params }
  );

  return data;
};

// 선별 학습용 태그 유형 변경
export const vldAllTagChange = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/tag/`,
    body
  );

  return data;
};

// 미리보기(선별) - 학습/평가용 태그 변경
export const vldTagChange = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/pre/tag/`,
    { tag_objs: body }
  );
  return data;
};

// 선별 화면 추론 모델 리스트 조회
export const vldInfModel = async ({ sensor_code, gt_code }) => {
  const { data } = await axiosInstance.get(`/vld/${sensor_code}/${gt_code}/datas/inf/mo/`);

  return data;
};

// 선별 화면 딥러닝 추론
export const vldInf = async ({ sensor_code, gt_code, set_id }, body, source) => {
  const { data } = await axiosInstance.post(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/inf/`,
    body,
    {
      cancelToken: source.current.token,
    }
  );

  return data;
};

// 레이다 세그멘테이션 선별 화면 속성파일 업로드
export const vldPropUl = async ({ set_id }, body, { onUploadProgress }, source) => {
  const { data } = await axiosInstance.post(`/vld/rad/seg/datas/${set_id}/props/ul/`, body, {
    onUploadProgress,
    cancelToken: source.current.token,
  });
  return data;
};

// 레이다 세그멘테이션 선별 화면 map 파일 업로드
export const vldMapUl = async ({ set_id }, body, { onUploadProgress }, source) => {
  const { data } = await axiosInstance.post(`/vld/rad/seg/datas/${set_id}/map/ul/`, body, {
    onUploadProgress,
    cancelToken: source.current.token,
  });

  return data;
};

// 레이다 세그멘테이션 선별 화면 원본 다운로드 링크 조회
export const vldOrigAllDwlLink = async ({ set_id, tab }, params) => {
  const { data } = await axiosInstance.post(
    `/vld/rad/seg/datas/${set_id}/orig/dwl/link/${tab}/`,
    null,
    {
      params,
    }
  );

  return data;
};

// 레이다 세그멘테이션 선별 화면 원본 다운로드 링크 조회
export const vldOrigDwlLink = async ({ set_id }, body) => {
  const { data } = await axiosInstance.post(`/vld/rad/seg/datas/${set_id}/orig/dwl/link/`, body);

  return data;
};

// 레이다 세그멘테이션 선별 화면 gps+imu 파일 export 링크 조회
export const vldExtraDwlLink = async ({ set_id }) => {
  const { data } = await axiosInstance.get(`/vld/rad/seg/datas/${set_id}/extra/dwl/link/`);

  return data;
};

// 센서설정 데이터 업로드시 센서번호 리스트 조회
export const vldGetInfo = async ({ set_id }) => {
  const { data } = await axiosInstance.get(`/vld/rad/seg/datas/${set_id}/values/`);

  return data;
};

// 센서설정 데이터 리스트 조회
export const vldPropList = async ({ set_id, t }) => {
  const { data } = await axiosInstance.get(`/vld/rad/seg/datas/${set_id}/props/`, {
    params: { t },
  });

  return data;
};

// 데이터 폐기 확인
export const vldDelCheck = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/vld/${sensor_code}/${gt_code}/datas/${set_id}/del/check/`,
    body
  );

  return data;
};
