import { selectorFamily } from "recoil";
import { reloadTriggerState } from "../../lib/stores";
import { histClctList, histList } from "./Hist.apis";

export const histClctListQuery = selectorFamily({
  key: "histClctListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await histClctList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const histListQuery = selectorFamily({
  key: "histListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await histList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});
