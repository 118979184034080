import { Route, Redirect } from "react-router-dom";
import { checkLogin } from "../../lib/utils";
import Layout from "./Layout";

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!checkLogin()) {
          return <Redirect to={{ pathname: "/" }} />;
        } else {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
