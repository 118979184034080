import { useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { ImageSprite } from "../../atoms/CommonAtoms";
import { CommonButton } from "../../molecules/Buttons";

const View = (props) => {
  const { data } = props;
  const { listReload } = useReload();
  const {
    queryParams: { view },
    setQueryParams,
  } = useDynamicRouting();

  return data.map((item, index) => (
    <CommonButton
      key={index}
      type={view !== item && "tertiary"}
      cn="h-[30px] w-[30px] p-0"
      onClick={() => (view === item ? listReload() : setQueryParams({ view: item }))}
      name={
        item === "table" ? (
          <ImageSprite iconType="IC_table_w" />
        ) : (
          <ImageSprite iconType="IC_thumb_w" />
        )
      }
    />
  ));
};

export default View;
