import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import clsx from "clsx";
import { currentFilterPosState, currentFilterState } from "../../lib/stores";
import { useShiftDownEvent } from "../../lib/hooks";
import { condObjsState } from "../../pages/condition/Cond.stores";
import { Empty, LoadFail, Loader } from "../atoms/Status";
import { ImageSprite } from "../atoms/CommonAtoms";
import { CommonButton } from "../molecules/Buttons";

const ThumbnailWrapper = (props) => {
  const { tab, state, contents, children, renderHeader, failType } = props;

  const setCondObjs = useSetRecoilState(condObjsState);
  const setcurrentFilterPos = useSetRecoilState(currentFilterPosState);
  const [currentFilter, setcurrentFilter] = useRecoilState(currentFilterState);
  const [isShiftDown, useShiftDown] = useShiftDownEvent();
  useShiftDown();

  let ulRef;
  let headRef;

  useEffect(() => {
    const ref = ulRef;
    ref.addEventListener("wheel", handleScroll);
    return () => {
      ref.removeEventListener("wheel", handleScroll);
    };
  }, []);

  useEffect(() => {
    // contents 변경 시
    // 스크롤 위치 리셋
    goToTop();
    // 태그 state 리셋
    if (contents) {
      setCondObjs(
        contents?.map(({ id, snow, rain, fog, light, backlight, night, smoke }) => {
          return { id, snow, rain, fog, light, backlight, night, smoke };
        })
      );
    }
  }, [contents]);

  useEffect(() => {
    const ref = ulRef;
    const handleSelectStart = (e) => {
      // shift시 select 방지
      if (isShiftDown) e.preventDefault();
    };

    ref.addEventListener("selectstart", handleSelectStart);
    return () => {
      ref.removeEventListener("selectstart", handleSelectStart);
    };
  }, [isShiftDown]);

  useEffect(() => {
    const headRefEl = headRef;
    headRefEl.addEventListener("scroll", handleScrollHead);
    return () => {
      headRefEl.removeEventListener("scroll", handleScrollHead);
    };
  }, [currentFilter]);

  // 스크롤 1step 당 썸네일 아이템 요소 높이 만큼 위치가 변경되어야 영상처럼 보이게 됨
  const handleScroll = (e) => {
    e.preventDefault();

    // 썸네일 리스트 요소
    const el = document.getElementsByClassName("common_table")[0];
    // 썸네일 리스트에서 현재 스크롤 위치
    const pos = el.scrollTop;

    // 썸네일 아이텀 요소 높이
    const h = document.getElementsByClassName("thumb_item")[0]?.clientHeight || 423;
    // 썸네일 아이템 요소 높이 + 상하 여백
    const step = h + 40;

    // 스크롤 방향
    const deltaY = e.deltaY < 0 ? -1 : 1;
    // 다음 위치 계산
    const nextPos = pos + step * deltaY;

    // 다음 위치 적용
    el.scrollTo(0, nextPos);
  };

  const handleScrollHead = (e) => {
    e.preventDefault();

    // 필터링 헤더에서 스크롤(가로)시 필터링 선택박스 닫힘
    if (currentFilter) {
      setcurrentFilter(null);
      setcurrentFilterPos(null);
    }
  };

  const goToTop = () => {
    const ref = ulRef;
    ref.scrollTo(0, 0);
  };

  return (
    <div className={clsx("relative mt-[8px] px-[16px]")}>
      {state === "loading" && (
        <div className="fixed left-0 top-0 z-50 h-full w-full">
          <Loader />
        </div>
      )}
      {/* 맨위로 */}
      <button
        className="fixed bottom-[84px] right-[56px] z-10 flex h-[40px] w-[40px] items-center justify-center rounded-[2px] bg-primary"
        onClick={goToTop}
      >
        <ImageSprite iconType="IC_up_w" />
        <span className="for-a11y">맨 위로</span>
      </button>
      {/* 필터링을 위한 테이블 헤더 */}
      <div
        ref={(el) => (headRef = el)}
        className={clsx(
          "scroll_head thumb_head",
          "h-[43px] overflow-y-auto border-t border-gray400"
        )}
      >
        <table>{renderHeader()}</table>
      </div>
      {/* 썸네일 리스트 */}
      <ul
        ref={(el) => (ulRef = el)}
        className={clsx(
          "common_table w-full overflow-x-hidden",
          // TableHead : 43px, filter : 38px, tab : 28px,
          // viewport height에서 각 요소만큼 뺀 나머지가 썸네일 리스트 높이가 됨

          tab ? "h-[calc(100vh-228px-43px-38px-28px)]" : "h-[calc(100vh-228px-43px-38px)]"
        )}
      >
        <ul className="flex h-[calc(100%-50px)] w-full flex-row flex-wrap content-start">
          {state === "hasError" ? (
            <LoadFail failType={failType || "데이터를 불러오는데 실패했습니다."} />
          ) : state === "hasValue" && !contents ? (
            <Empty />
          ) : (
            children
          )}
        </ul>
      </ul>
    </div>
  );
};

export default ThumbnailWrapper;
