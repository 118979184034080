import { useRef, useState } from "react";
import { useFileUploader, useModals } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { checkFile } from "../../../lib/utils";
import { CommonButton } from "../../molecules/Buttons";
import { ConfirmModal, AcceptModal } from "../../molecules/CommonModals";

// 입력: 파일 업로드
// 출력: 진행중인 작업에 추가
export const FloatingAction = ({ api, actionName, accept, ko, icon }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { process } = useFileUploader();
  const { modal, openModal, closeModal, modalStatus } = useModals(null);

  const fileInput = useRef();
  const [floatingItemContents, setFloatingItemContents] = useState(null);
  const [selectFile, setSelectFile] = useState(null);
  const [checkFileResult, setCheckFileResult] = useState(false);

  // 1. 버튼 클릭시 파일 선택 창 열기
  const handleFileInput = async (e) => {
    setCheckFileResult(false);
    const files = e.target.files;
    const newFileList = Array.from(files);
    const len = files.length;

    // 선택된 파일이 없으면 return
    if (len < 1) return null;

    // 조건에 부합하지 않으면 실패 사유 출력
    const isFailed = checkFile(len, files, accept, ko);
    if (isFailed) {
      setCheckFileResult(isFailed);
    } else {
      // 2. 진행중인 작업 리스트 생성
      let arr = [];
      for (let i = 0; i < len; i++) {
        arr = [
          ...arr,
          {
            filename: files[i].name,
            floatingStatus: "대기",
          },
        ];
      }
      setFloatingItemContents(arr);

      // 3. 선택한 파일 담기
      setSelectFile(newFileList);
    }

    // 4. 모달 열기
    openModal();
  };

  const handleUploadFile = async (rowId) => {
    closeModal();

    process({
      rowId,
      urlInfo,
      api,
      selectFile,
    });
  };

  return (
    <>
      <input
        type="file"
        name="file"
        ref={fileInput}
        onChange={handleFileInput}
        onClick={(e) => (e.target.value = null)}
        style={{ display: "none" }}
        multiple
      />
      <CommonButton name={actionName} icon={icon} onClick={() => fileInput.current.click()} />

      {modal &&
        (checkFileResult ? (
          <AcceptModal closeModal={closeModal} accept={accept} checkFileResult={checkFileResult} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title={`${actionName} 정보 확인`}
            count={selectFile?.length}
            modalStatus={modalStatus}
            panelname={actionName}
            handleUploadFile={handleUploadFile}
            floatingItemContents={floatingItemContents}
          />
        ))}
    </>
  );
};
