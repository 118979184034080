import { useContext, useRef } from "react";
import axios from "axios";
import { NAVLUEContext } from "../../App";

const CancelToken = axios.CancelToken;

export const useHookBatchIdProcessor = () => {
  const uploadSource = useRef(null);
  const { progressRows, dispatch } = useContext(NAVLUEContext);

  const process = async ({ rowId, resType, len, urlInfo, api, body }) => {
    console.log("====================== > hook floating promise start");

    // progressRows에서 현재 진행중인 작업 찾기
    const onGoingItem = progressRows.find((item) => item.id === rowId);

    // onUploadProgress 함수 담기
    const config = {
      onUploadProgress: function (progressEvent) {
        onGoingItem.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        dispatch({ type: "progressRows", data: progressRows });
      },
    };

    // cancel token, status 설정
    uploadSource.current = CancelToken.source();
    onGoingItem.token = uploadSource.current;
    onGoingItem.status = resType === "filename" ? "업로드 진행중" : "진행중";
    onGoingItem.sensorName = body.sensor_name ? true : false;
    dispatch({ type: "progressRows", data: progressRows });

    try {
      let data;
      if (resType === "filename") {
        // file upload의 경우 uploadprogress config 함께 보냄
        data = await api(urlInfo, body, config, uploadSource);
      } else {
        data = await api(urlInfo, body, uploadSource);
      }

      console.log(data);

      const formatStatus = (item) =>
        (item.status === "success" ? "성공" : "실패") +
        (item.fail_type ? `(${item.fail_type})` : "");

      const findItem = (data, type) => {
        for (let k = 0; k < len; k++) {
          let item = data.results.find((x) => x[type] === onGoingItem.contents[k][type]);
          onGoingItem.contents[k].floatingStatus = formatStatus(item);
        }
      };

      if (resType === "filename" || resType === "id") {
        // filename : file upload의 경우 response의 filename으로 비교
        // id : id를 보낸 경우 response의 id와 비교
        findItem(data, resType);
      } else {
        // video 제작은 sensor_name 하나면 보내기떄문에 filename, id 가 없고 결과가 항상 1개임
        onGoingItem.contents[0].floatingStatus = formatStatus(data.results[0]);
      }

      onGoingItem.status = resType === "filename" ? "전송 완료" : "완료";
    } catch (err) {
      console.error(err);
      if (axios.isCancel(err)) {
        console.log("cancel");
        console.log("=========================== > 취소 감지", rowId);
        onGoingItem.status = "취소";

        for (let k = 0; k < len; k++) {
          onGoingItem.contents[k].floatingStatus = `취소`;
        }
      } else {
        console.log(err?.response);
        let err_detail = err?.response?.data?.detail || "";
        const status = `실패 ${err_detail && `(${err_detail})`}`;
        if (resType) {
          onGoingItem.status = status;
          for (let k = 0; k < len; k++) {
            onGoingItem.contents[k].floatingStatus = "실패";
          }
        } else {
          onGoingItem.status = "실패";
          onGoingItem.contents[0].floatingStatus = status;
        }
      }
    }

    onGoingItem.done = true;
    dispatch({ type: "progressRows", data: progressRows });
  };

  return { process };
};
