import React, { createContext, useContext, useState } from "react";

const TooltipContext = createContext();

export const TooltipProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);

  return (
    <TooltipContext.Provider value={{ isOpen, setIsOpen, content, setContent }}>
      {children}
    </TooltipContext.Provider>
  );
};

export const Tooltip = ({ children }) => {
  return <div style={{ position: "relative" }}>{children}</div>;
};

export const TooltipTrigger = ({ children }) => {
  const { setIsOpen } = useContext(TooltipContext);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ display: "inline-block" }}
    >
      {children}
    </div>
  );
};

export const TooltipContent = ({ children, className = "" }) => {
  const { isOpen, content, setContent } = useContext(TooltipContext);

  React.useEffect(() => {
    setContent(children);
  }, [children, setContent]);

  return isOpen ? (
    <div className={`fixed z-10 border border-black bg-white text-sm text-white ${className}`}>
      {content}
    </div>
  ) : null;
};
