import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import clsx from "clsx";
import { CONDITIONS, LRN_TAG_VALUE } from "../../lib/constants/constant";
import { condObjsState } from "../../pages/condition/Cond.stores";
import { tagSelector, addTag } from "./StoreTag";

export const DrawCondTag = (props) => {
  const { id } = props;
  const [condObjs, setCondObjs] = useRecoilState(condObjsState);
  const removeTag = (itemId, value) => {
    setCondObjs(
      condObjs.map((obj) =>
        obj.id === itemId
          ? {
              ...obj,
              [value]: !obj[value],
            }
          : obj
      )
    );
  };

  const renderTag = (obj) => {
    const keys = Object.keys(obj);

    return keys.map(
      (k, i) =>
        i !== 0 && (
          <div
            key={i}
            className={clsx(
              "flex h-[30px] cursor-pointer items-center justify-center rounded-[20px] border px-[8px]",
              obj[k] === true
                ? "border-primary bg-primary text-white"
                : "border-gray400 text-gray700"
            )}
            onClick={() => removeTag(id, keys[i])}
          >
            <div>{CONDITIONS.find((x) => x.value === keys[i]).ko}</div>
          </div>
        )
    );
  };

  return (
    <div className="flex gap-[8px]">{condObjs.map((obj) => obj.id === id && renderTag(obj))}</div>
  );
};

export const DrawVldTag = ({ item, tag }) => {
  const itemStatus = item;

  return (
    <>
      <div className="flex gap-[8px]">
        <div
          className={clsx(
            "flex h-[20px] cursor-pointer items-center justify-center whitespace-nowrap border border-[#D0D0D0] p-[2px_8px]",
            itemStatus === LRN_TAG_VALUE.ADD
              ? "text-[#2B63C1]"
              : itemStatus === LRN_TAG_VALUE.EXCEPT
                ? "text-[#E20000]"
                : "text-gray700"
          )}
        >
          <div>
            {itemStatus === LRN_TAG_VALUE.ADD
              ? tag === "val"
                ? "학습용"
                : tag === "eval"
                  ? "평가용"
                  : tag
              : itemStatus === LRN_TAG_VALUE.EXCEPT
                ? "제외"
                : "대기"}
          </div>
        </div>
      </div>
    </>
  );
};

export const LabelTag = (props) => {
  /**
    @typedef {Object} Tag
    @property {string} name
    @property {boolean} active

    @type {Object}
    @property {Tag[]} tags
  */
  const { tags } = props;

  if (!tags) return null;

  return (
    <div className="flex gap-[8px]">
      {tags.map((tag, i) => {
        return tag?.name ? (
          <div
            key={i}
            className={`flex h-[30px] items-center justify-center whitespace-pre-wrap rounded-[20px] border px-[8px] ${tag.active ? "border-primary bg-primary text-white" : "border-gray400 text-gray700"}`}
          >
            {tag.name}
          </div>
        ) : null;
      })}
    </div>
  );
};

/**
    @param {Object} props.conds 
  */
export const CondTag = (props) => {
  const { conds } = props;

  if (checkAllFalsy(conds)) return null;

  return (
    <div className="flex gap-[8px]">
      {CONDITIONS.map((x, i) => {
        return (
          conds[x.value] && (
            <div
              key={i}
              className={clsx(
                "flex h-[30px] items-center justify-center whitespace-pre-wrap rounded-[20px] border border-gray400 px-[8px] text-gray700"
              )}
            >
              <div>{x.ko}</div>
            </div>
          )
        );
      })}
    </div>
  );
};

const checkAllFalsy = (obj) => !Object.values(obj).some((value) => value);
