import { selectorFamily } from "recoil";
import { modalReloadTriggerState, reloadTriggerState } from "../../lib/stores";
import {
  workClctCnt,
  workClctList,
  workCnt,
  workIds,
  workInspectDetail,
  workList,
  workPreview,
  workQnaPreview,
  workRefCnt,
  workRefIds,
  workRefList,
  workVideoList,
} from "./Work.apis";

export const workClctCntQuery = selectorFamily({
  key: "workClctCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await workClctCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const workClctListQuery = selectorFamily({
  key: "workClctListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await workClctList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const workPreviewQuery = selectorFamily({
  key: "workPreviewQuery",
  get: (params) => async () => {
    const res = await workPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const workCntQuery = selectorFamily({
  key: "workCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await workCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const workIdsQuery = selectorFamily({
  key: "workIdsQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await workIds(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const workListQuery = selectorFamily({
  key: "workListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await workList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const workInspectDetailQuery = selectorFamily({
  key: "workInspectDetailQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(modalReloadTriggerState);

      if (params.modal) {
        const res = await workInspectDetail(params);

        if (res.error) {
          throw res.error;
        }
        return res;
      } else {
        throw new Error("modal 필수");
      }
    },
});

export const workVideoListQuery = selectorFamily({
  key: "workVideoListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(modalReloadTriggerState);

      if (params.modal) {
        const res = await workVideoList(params);

        if (res.error) {
          throw res.error;
        }
        return res;
      } else {
        throw new Error("modal 필수");
      }
    },
});

export const workQnaPreviewQuery = selectorFamily({
  key: "workQnaPreviewQuery",
  get: (params) => async () => {
    const res = await workQnaPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const workRefCntQuery = selectorFamily({
  key: "workRefCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await workRefCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const workRefIdsQuery = selectorFamily({
  key: "workRefIdsQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      if (params.tab === "3") {
        const res = await workRefIds(params);

        if (res.error) {
          throw res.error;
        }
        return res;
      } else {
        return { state: null, contents: null };
      }
    },
});

export const workRefListQuery = selectorFamily({
  key: "workRefListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await workRefList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});
