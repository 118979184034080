import { useCallback, useState } from "react";
import { ROWS_PER_PAGE } from "../constants/constant";

export const useHookPages = (initialState) => {
  const [pages, setPages] = useState(initialState);

  const makePages = useCallback((data) => {
    if (data !== 0) {
      let temp = [];
      const totalPage = Math.ceil(data / ROWS_PER_PAGE);
      for (let i = 1; i <= totalPage; i++) {
        temp = temp.concat(i.toString());
      }
      setPages(temp);
    } else {
      setPages([]);
    }
  }, []);

  return [pages, makePages];
};
