import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { VldPri, VldPriReset, VldSetsCmp } from "./VldActions";
import { vldClctFilter } from "./Vld.apis";
import {
  VLD_CLCT_COLS,
  VLD_RADSEG_TAB,
  VLD_CAMDET_TAB,
  VLD_CAMSEG_TAB,
  VLD_RADSEG_CMP_TAB,
  VLD_CAMDET_CMP_TAB,
  VLD_CAMSEG_CMP_TAB,
} from "./Vld.constants";
import { vldClctCntQuery, vldClctListQuery } from "./Vld.stores";

const VldClctPage = () => {
  const {
    urlParams,
    queryParams: { tab, t },
  } = useDynamicRouting();
  const { sensor_code, gt_code } = urlParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    vldClctCntQuery({ ...urlParams, tab, t })
  );

  return (
    <CommonTemplate
      listQuery={vldClctListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      filterApi={vldClctFilter}
      fullColumnData={VLD_CLCT_COLS[`${sensor_code}_${gt_code}`].tab}
      columnData={VLD_CLCT_COLS[`${sensor_code}_${gt_code}`][`tab${tab}`]}
      hideColumn={VLD_CLCT_COLS[`${sensor_code}_${gt_code}`].hide[`tab${tab}`]}
      tabData={[
        { value: "2", ko: "대기" },
        { value: "3", ko: "완료" },
      ]}
      groupColumnData={
        tab === "3"
          ? sensor_code === "rad"
            ? VLD_RADSEG_CMP_TAB
            : gt_code === "det"
              ? VLD_CAMDET_CMP_TAB
              : VLD_CAMSEG_CMP_TAB
          : sensor_code === "rad"
            ? VLD_RADSEG_TAB
            : gt_code === "det"
              ? VLD_CAMDET_TAB
              : VLD_CAMSEG_TAB
      }
      actions={({ selectedItems }) => (
        <>
          <SelectCnt count={selectedItems.length} />
          <VldPri selectedItems={selectedItems} />
          <VldPriReset selectedItems={selectedItems} />
          {tab !== "3" && <VldSetsCmp selectedItems={selectedItems} />}
        </>
      )}
    />
  );
};

export default VldClctPage;
