import GetPreview from "../../../../../components/organisms/GetPreview";
import IC_empty from "../../../../../assets/images/IC_empty.png";
import clsx from "clsx";

export const Thumbnail = ({
  filename,
  auto_thumb,
  valid,
  auto,
  added,
  excluded,
  coords,
  currentPage,
  index,
  previewQuery,
  currentImgType,
}) => {
  return (
    <GetPreview
      previewQuery={previewQuery}
      index={index + (currentPage - 1) * 500}
      currentImgType={currentImgType}
    >
      <div className="group relative h-full w-full cursor-pointer">
        <div className="opacity-0 group-hover:opacity-100">
          <div className="absolute inset-0 z-10 border-[6px] border-[#2878ff] bg-[transparent]"></div>
          <div className="absolute top-[12px] left-[12px] right-[12px] z-10 truncate text-[15px] font-bold text-[white]">
            {filename}
          </div>
        </div>
        {/* 탈락 */}
        {added && (
          <div className="absolute border-t-[40px] border-r-[40px] border-t-[#00bf40] border-r-transparent">
            <span className="for-a11y">추가</span>
          </div>
        )}
        {/* 추가 */}
        {excluded && (
          <>
            <div className="absolute border-t-[40px] border-r-[40px] border-t-[#FF2323] border-r-transparent"></div>
            <div className="absolute inset-0 bg-[rgba(255,35,35,0.24)]"></div>
            <span className="for-a11y">탈락</span>
          </>
        )}
        <img
          className={clsx("w-full", "cursor-pointer")}
          src={`${process.env.REACT_APP_SERVER_BASE_URL}/${auto_thumb}`}
          alt={filename}
          onContextMenu={(e) => e.preventDefault()}
          onError={(e) => {
            e.target.src = IC_empty;
            e.target.className = "w-auto m-auto";
          }}
        />
      </div>
    </GetPreview>
  );
};
