import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { condCntQuery, condIdsQuery, condListQuery, condPreviewQuery } from "./Cond.stores";
import { condFilter } from "./Cond.apis";
import { CondDel, ResetCond, CondAddTag, SetCondTag, ModifyCondTag } from "./CondActions";
import { COND_CAM_TAB, makeImgTypeList, COND_DATA_COLS } from "./Cond.constants";

const CondDataPage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { set_id } = urlParams;
  const { tab, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    condCntQuery({ ...urlParams, tab, t })
  );
  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    condIdsQuery({ set_id, ...queryParams })
  );

  const renderActions = ({ view, selectedItems, setSelectedItems, contents }) => {
    return (
      <>
        <SelectCnt count={selectedItems.length} />
        {(tab === "2" || tab === "3") && view === "thumb" && (
          <CondAddTag selectedItems={selectedItems} />
        )}
        {tab === "2" && view === "thumb" && <SetCondTag selectedItems={selectedItems} />}
        {tab === "3" && view === "thumb" && (
          <ModifyCondTag contents={contents.results} selectedItems={selectedItems} />
        )}
        {tab === "3" && <ResetCond selectedItems={selectedItems} />}
        <CondDel contents={contents.results} selectedItems={selectedItems} />
      </>
    );
  };

  return (
    <CommonTemplate
      listQuery={condListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
      filterApi={condFilter}
      previewQuery={condPreviewQuery}
      fullColumnData={COND_DATA_COLS.tab}
      columnData={COND_DATA_COLS.tab}
      hideColumn={COND_DATA_COLS.hide.table[`tab${tab}`]}
      hideThumbColumn={COND_DATA_COLS.hide.thumb[`tab${tab}`]}
      tabData={COND_CAM_TAB}
      viewData
      imgTypeData={makeImgTypeList()}
      tagType={tab !== "1" && "cond"}
      actions={(data) => renderActions(data)}
    />
  );
};

export default CondDataPage;
