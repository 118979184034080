import { useState } from "react";
import { PreLine } from "../../atoms/CommonAtoms";
import ModalContainer from "../../molecules/ModalContainer";
import { CommonButton } from "../../molecules/Buttons";

const ChangelogModal = (props) => {
  const { title, closeModal, contents } = props;

  const [viewFull, setViewFull] = useState(null);
  const handleClick = (index) => {
    viewFull === index ? setViewFull(null) : setViewFull(index);
  };

  return (
    <ModalContainer modalType="open_source_modal" title={title} closeModal={closeModal}>
      <ul className="h-[422px] overflow-y-auto px-[16px]">
        {contents &&
          contents.map((item, index) => (
            <li className="flex flex-col items-start gap-[4px] pb-[4px]" key={index}>
              <div className="pb-[4px] font-bold">{item.version}</div>
              <div>{item.date}</div>
              <CommonButton
                cn="h-[30px] font-normal text-[12px] border border-gray400 !text-gray700 bg-[unset]"
                onClick={() => handleClick(index)}
                name="변경 로그"
              />
              {viewFull === index && <PreLine text={item.full} />}
              <br />
            </li>
          ))}
      </ul>
    </ModalContainer>
  );
};

export default ChangelogModal;
