import { atom, selectorFamily } from "recoil";
import { modalReloadTriggerState, reloadTriggerState } from "../../lib/stores";
import {
  vldClctCnt,
  vldClctList,
  vldCnt,
  vldIds,
  vldList,
  vldPreview,
  vldPropList,
  vldMaskList,
} from "./Vld.apis";

export const vldClctCntQuery = selectorFamily({
  key: "vldClctCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await vldClctCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const vldClctListQuery = selectorFamily({
  key: "vldClctListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await vldClctList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const vldPreviewQuery = selectorFamily({
  key: "vldPreviewQuery",
  get: (params) => async () => {
    const res = await vldPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const vldCntQuery = selectorFamily({
  key: "vldCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await vldCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const vldIdsQuery = selectorFamily({
  key: "vldIdsQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await vldIds(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const vldListQuery = selectorFamily({
  key: "vldListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await vldList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const vldPropListQuery = selectorFamily({
  key: "vldPropListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(modalReloadTriggerState);

      if (params.modal) {
        const res = await vldPropList(params);

        if (res.error) {
          throw res.error;
        }
        return res;
      } else {
        throw new Error("modal 필수");
      }
    },
});

export const vldMaskFileListQuery = selectorFamily({
  key: "vldMaskFileListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(modalReloadTriggerState);

      if (params.modal) {
        const res = await vldMaskList(params).then((res) => res.data);

        if (res.error) {
          throw res.error;
        }
        return res;
      } else {
        throw new Error("modal 필수");
      }
    },
});

export const vldTagsState = atom({
  key: "vldTagsState",
  default: [],
});
