import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useModals } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { isDwlState } from "../../../lib/stores";
import { CommonButton } from "../../molecules/Buttons";
import { ConfirmModal, EmptyModal } from "../../molecules/CommonModals";

// 입력: 선택한 아이템
// 출력: 다운로드 버튼
export const DownloadAction = ({
  count,
  detail,
  api,
  hasPath,
  body,
  dlUrl,
  actionName,
  icon,
  type,
  blackBackground,
}) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [failType, setFailType] = useState("");
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [successCnt, setSuccessCnt] = useState("");
  const setIsDwl = useSetRecoilState(isDwlState);

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetOpen = () => {
    setFailType("");
    setIsDwl(false);
    setSuccessCnt("");
    setDownloadUrl(null);
    setModalStatus(null);

    count === 0 ? openModal() : handleClick();
  };

  const handleClick = async () => {
    setIsDwl(true);
    openModal();
    setModalStatus("loading");

    try {
      let data;
      if (hasPath) {
        data = await api(urlInfo, body);
      } else {
        data = await api(body);
      }

      const url = `${process.env.REACT_APP_SERVER_BASE_URL}${dlUrl}${data.encrypt}/${data.type}`;
      setDownloadUrl(url);
      if (actionName === "학습 리스트 다운로드" && data.success_cnt) {
        setSuccessCnt(data.success_cnt);
      }
      setModalStatus("hasValue");
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name={actionName} onClick={resetOpen} icon={icon} type={type} />

      {modal &&
        (count !== undefined && count === 0 ? (
          <EmptyModal closeModal={closeModal} blackBackground={blackBackground} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title={`${actionName} 정보 확인`}
            count={count}
            detail={detail}
            modalStatus={modalStatus}
            failType={failType}
            downloadInfo={{ downloadUrl, successCnt }}
            blackBackground={blackBackground}
          />
        ))}
    </>
  );
};
