import { useRecoilValueLoadable } from "recoil";
import Gradient from "javascript-color-gradient";
import { StatsBox, StatsItem, StatsItemRowSet } from "../../components/molecules/StatsItems";
import Tab from "../../components/organisms/search/Tab";
import { formatAmountComma } from "../../lib/utils";
import { LineChart } from "./LineChart";
import { STATS_DICT, distRadHeader, STATS_COLUMN_LIST, yearHeader } from "./Stats.constants";
import {
  statsCondState,
  statsCondQuery,
  statsCondValuesQuery,
  statsDistState,
  statsDistQuery,
  statsDistValuesQuery,
  statsMonthState,
  statsMonthQuery,
  statsMonthValuesQuery,
  statsMonthWorkState,
  statsMonthWorkQuery,
  statsMonthWorkValuesQuery,
  statsSensorQuery,
  statsTotalQuery,
} from "./Stats.stores";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { BarChart } from "./BarChart";
import { PieChart } from "./PieChart";
import {
  pathHeader,
  portHeader,
  berthHeader,
  vesselHeader,
  monthHeader,
  condHeader,
  infHeader,
  monthWorkHeader,
  distHeader,
  checkPortValHeader,
  checkSailValHeader,
} from "./Stats.constants";

export const createColorArr = (len) =>
  new Gradient().setColorGradient("#1a237e", "#42b3d5", "#dcedc8").setMidpoint(len).getColors();

export const findDict = (value) => STATS_DICT.find((item) => item.value === value)?.name || value;

export const findColumns = (cols) =>
  STATS_COLUMN_LIST.filter((item) => cols.includes(item.name, 0));

const StatsPage = () => {
  const { queryParams } = useDynamicRouting();
  const { tab } = queryParams;

  const totalValue = useRecoilValueLoadable(statsTotalQuery(queryParams));
  const sensorValue = useRecoilValueLoadable(statsSensorQuery(queryParams));
  const condValue = useRecoilValueLoadable(statsCondQuery(queryParams));
  const monthValue = useRecoilValueLoadable(statsMonthQuery(queryParams));
  const monthWorkValue = useRecoilValueLoadable(statsMonthWorkQuery(queryParams));
  const distValue = useRecoilValueLoadable(statsDistQuery(queryParams));

  const TABS = {
    TOTAL: "1",
    CAM_SEG: "2",
    CAM_DET: "3",
    RAD_SEG: "4",
  };

  return (
    <>
      <Tab
        data={[
          { value: TABS.TOTAL, ko: "Total" },
          { value: TABS.CAM_SEG, ko: "Camera Segmentation" },
          { value: TABS.CAM_DET, ko: "Camera Detection" },
          { value: TABS.RAD_SEG, ko: "Radar Segmentation" },
        ]}
      />

      <div className="h-[calc(100%-28px)] w-full bg-gray100">
        <div className="flex flex-col gap-[16px] py-[16px]">
          {/* Camera Total */}
          {tab === TABS.TOTAL && (
            <>
              <div className="flex h-[300px] flex-row gap-[16px]">
                {["cam_seg", "cam_det", "rad_seg"].map((x, i) => (
                  <StatsBox key={i} title={findDict(x)} state={totalValue?.state} type="count">
                    <div>
                      <span className="text-[40px] font-bold">
                        {formatAmountComma(totalValue?.contents?.total?.[x][0])}
                      </span>
                      <span className="pb-[6px] text-[18px] font-bold">
                        &nbsp;&nbsp;/&nbsp;&nbsp;
                        {formatAmountComma(totalValue?.contents?.total?.[x][1])}
                      </span>
                    </div>
                  </StatsBox>
                ))}
              </div>
              <StatsItem
                title="연간 학습 데이터 누적 추이"
                header={yearHeader}
                state={totalValue?.state}
                contents={totalValue?.contents?.year}
              >
                <LineChart contents={totalValue?.contents?.year} xName="연도" />
              </StatsItem>
            </>
          )}

          {/* Camera Segmentation */}
          {tab === TABS.CAM_SEG && (
            <>
              <StatsItem
                title="학습 데이터 월간 작업량"
                hasOption={{
                  query: statsMonthValuesQuery,
                  optionState: statsMonthState,
                }}
                header={monthHeader}
                state={monthValue?.state}
                contents={monthValue?.contents?.month}
                stats="total_row"
              >
                <LineChart contents={monthValue?.contents?.month} xName="월" />
              </StatsItem>

              <StatsItemRowSet h="300">
                <StatsItem
                  h="300"
                  halfSet
                  title="학습 데이터 수집경로 비율"
                  header={pathHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.path}
                  stats="total_row"
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.path} />
                </StatsItem>
                <StatsItem
                  h="300"
                  halfSet
                  title="학습 데이터 항만 비율"
                  header={portHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.port}
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.port} />
                </StatsItem>
              </StatsItemRowSet>

              <StatsItemRowSet h="600">
                <StatsItem
                  h="600"
                  halfSet
                  title="운항 학습 데이터 선박 또는 지역 비율"
                  header={vesselHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.sail_vessel}
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.sail_vessel} />
                </StatsItem>
                <StatsItem
                  h="600"
                  halfSet
                  title="생성 학습 데이터 선박 또는 지역 비율"
                  header={vesselHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.gen_vessel}
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.gen_vessel} />
                </StatsItem>
                <StatsItem
                  h="600"
                  halfSet
                  title="항만 학습 데이터 선석 비율"
                  header={berthHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.berth}
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.berth} />
                </StatsItem>
              </StatsItemRowSet>

              <StatsItem
                title="학습 데이터 선석별 수집환경 비율"
                hasOption={{
                  query: statsCondValuesQuery,
                  optionState: statsCondState,
                }}
                header={condHeader}
                state={condValue?.state}
                contents={condValue?.contents?.cond}
                sortBy="data"
              >
                <PieChart contents={condValue?.contents?.cond} name="cond" />
              </StatsItem>
              <StatsItem
                title="선석별 데이터 선별 유형"
                header={infHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.inf}
                sortBy="val"
              >
                <BarChart contents={sensorValue?.contents?.inf} xName="선석" stacked sort />
              </StatsItem>

              <StatsItem
                title="작업자 월별 작업량"
                hasOption={{
                  query: statsMonthWorkValuesQuery,
                  optionState: statsMonthWorkState,
                }}
                header={monthWorkHeader}
                state={monthWorkValue?.state}
                contents={monthWorkValue?.contents?.month_work}
              >
                <BarChart contents={monthWorkValue?.contents?.month_work} xName="작업자" stacked />
              </StatsItem>

              <StatsItem
                title="[항만] 학습용 데이터 선석별 배분/검수 현황"
                header={checkPortValHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.port_val_check}
                stats="total_row"
              />

              <StatsItem
                title="[운항] 학습용 데이터 선박 또는 지역별 배분/검수 현황"
                header={checkSailValHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.sail_val_check}
                stats="total_row"
              />

              <StatsItem
                title="[생성] 학습용 데이터 선박 또는 지역별 배분/검수 현황"
                header={checkSailValHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.gen_val_check}
                stats="total_row"
              />

              <StatsItem
                title="데이터 검수완료 현황"
                hasOption={{
                  query: statsDistValuesQuery,
                  optionState: statsDistState,
                }}
                header={tab === "4" ? distRadHeader : distHeader}
                state={distValue?.state}
                contents={distValue?.contents?.dist}
              />
            </>
          )}

          {/* Camera Detection */}
          {tab === TABS.CAM_DET && (
            <>
              <StatsItem
                title="학습 데이터 월간 작업량"
                hasOption={{
                  query: statsMonthValuesQuery,
                  optionState: statsMonthState,
                }}
                header={monthHeader}
                state={monthValue?.state}
                contents={monthValue?.contents?.month}
                stats="total_row"
              >
                <LineChart contents={monthValue?.contents?.month} xName="월" />
              </StatsItem>

              <StatsItemRowSet h="300">
                <StatsItem
                  h="300"
                  halfSet
                  title="학습 데이터 수집경로 비율"
                  header={pathHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.path}
                  stats="total_row"
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.path} />
                </StatsItem>
                <StatsItem
                  h="300"
                  halfSet
                  title="학습 데이터 항만 비율"
                  header={portHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.port}
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.port} />
                </StatsItem>
              </StatsItemRowSet>

              <StatsItemRowSet h="600">
                <StatsItem
                  h="600"
                  halfSet
                  title="학습 데이터 선박 또는 지역 비율"
                  header={vesselHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.vessel}
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.vessel} />
                </StatsItem>
                <StatsItem
                  h="600"
                  halfSet
                  title="학습 데이터 선석 비율"
                  header={berthHeader}
                  state={sensorValue?.state}
                  contents={sensorValue?.contents?.berth}
                  sortBy="lrn_data"
                >
                  <PieChart contents={sensorValue?.contents?.berth} />
                </StatsItem>
              </StatsItemRowSet>

              <StatsItem
                title="학습 데이터 선석별 수집환경 비율"
                hasOption={{
                  query: statsCondValuesQuery,
                  optionState: statsCondState,
                }}
                header={condHeader}
                state={condValue?.state}
                contents={condValue?.contents?.cond}
                sortBy="data"
              >
                <PieChart contents={condValue?.contents?.cond} name="cond" />
              </StatsItem>
              <StatsItem
                title="선석별 데이터 선별 유형"
                header={infHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.inf}
                sortBy="val"
              >
                <BarChart contents={sensorValue?.contents?.inf} xName="선석" stacked sort />
              </StatsItem>

              <StatsItem
                title="작업자 월별 작업량"
                hasOption={{
                  query: statsMonthWorkValuesQuery,
                  optionState: statsMonthWorkState,
                }}
                header={monthWorkHeader}
                state={monthWorkValue?.state}
                contents={monthWorkValue?.contents?.month_work}
              >
                <BarChart contents={monthWorkValue?.contents?.month_work} xName="작업자" stacked />
              </StatsItem>

              <StatsItem
                title="[항만] 학습용 데이터 선석별 배분/검수 현황"
                header={checkPortValHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.port_val_check}
                stats="total_row"
              />

              <StatsItem
                title="[운항] 학습용 데이터 선박 또는 지역별 배분/검수 현황"
                header={checkSailValHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.sail_val_check}
                stats="total_row"
              />
              <StatsItem
                title="데이터 검수완료 현황"
                hasOption={{
                  query: statsDistValuesQuery,
                  optionState: statsDistState,
                }}
                header={tab === "4" ? distRadHeader : distHeader}
                state={distValue?.state}
                contents={distValue?.contents?.dist}
              />
            </>
          )}

          {/* Radar Segmentation */}
          {tab === TABS.RAD_SEG && (
            <>
              <StatsItem
                title="학습 데이터 월간 작업량"
                hasOption={{
                  query: statsMonthValuesQuery,
                  optionState: statsMonthState,
                }}
                header={monthHeader}
                state={monthValue?.state}
                contents={monthValue?.contents?.month}
                stats="total_row"
              >
                <LineChart contents={monthValue?.contents?.month} xName="월" />
              </StatsItem>

              <StatsItem
                halfSet
                title="학습 데이터 선박 또는 지역 비율"
                header={vesselHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.vessel}
                sortBy="lrn_data"
              >
                <PieChart contents={sensorValue?.contents?.vessel} />
              </StatsItem>

              <StatsItem
                title="작업자 월별 작업량"
                hasOption={{
                  query: statsMonthWorkValuesQuery,
                  optionState: statsMonthWorkState,
                }}
                header={monthWorkHeader}
                state={monthWorkValue?.state}
                contents={monthWorkValue?.contents?.month_work}
              >
                <BarChart contents={monthWorkValue?.contents?.month_work} xName="작업자" stacked />
              </StatsItem>

              <StatsItem
                title="[운항] 학습용 데이터 선박 또는 지역별 배분/검수 현황"
                header={checkSailValHeader}
                state={sensorValue?.state}
                contents={sensorValue?.contents?.sail_val_check}
                stats="total_row"
              />
              <StatsItem
                title="데이터 검수완료 현황"
                hasOption={{
                  query: statsDistValuesQuery,
                  optionState: statsDistState,
                }}
                header={tab === "4" ? distRadHeader : distHeader}
                state={distValue?.state}
                contents={distValue?.contents?.dist}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StatsPage;
