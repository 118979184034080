export const Badge = ({ value, handleClick }) => {
  return (
    <button
      className="flex h-[30px] flex-row items-center gap-[8px] rounded-[16px] bg-[#2878FF] px-[12px] text-[#fff]"
      onClick={handleClick}
    >
      <p className="h-[16px]">{value}</p>
      <p className="h-[16px] w-[16px]">X</p>
    </button>
  );
};
