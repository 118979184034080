import { Loader, LoadFail } from "../atoms/Status";

export const ModalTop = ({ children }) => {
  return <div className="flex h-[64px] items-center justify-between px-[16px]">{children}</div>;
};

export const ModalBottom = ({ children, hasStatus }) => {
  return (
    <div className="flex h-[72px] items-center px-[16px]">
      {children}
      {hasStatus && (
        <div>
          {hasStatus.modalStatus === "loading" ? (
            <Loader />
          ) : hasStatus.modalStatus === "hasError" ? (
            <LoadFail failType={hasStatus.failType} />
          ) : null}
        </div>
      )}
    </div>
  );
};
