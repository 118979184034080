import { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { useSetRecoilState } from "recoil";
import { useModals } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { isDwlState } from "../../../lib/stores";
import { CommonButton, CommonDownloadButton } from "../../molecules/Buttons";
import ModalContainer from "../../molecules/ModalContainer";
import { ModalBottom } from "../../molecules/Modals";
import {
  SelectBox,
  SelectItem,
  SelectName,
  SelectRadio,
  SelectTitle,
  SelectWrap,
} from "../../atoms/SelectBox";

export const BatchDownloadAction = ({
  downloadLinkApi,
  worksetListApi,
  dlUrl,
  actionName,
  icon,
  type,
}) => {
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  return (
    <>
      <CommonButton name={actionName} onClick={openModal} icon={icon} type={type} />

      {modal && (
        <BatchModal
          downloadLinkApi={downloadLinkApi}
          worksetListApi={worksetListApi}
          downloadOrigin={dlUrl}
          title={actionName}
          detail={`브라우저에서는 동시에 6개의 zip파일만 다운로드 가능합니다. 
          모든 파일이 다운로드 되기 전에 페이지를 새로고침, 종료하지 마세요.`}
          closeModal={closeModal}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />
      )}
    </>
  );
};

const BatchModal = ({
  downloadLinkApi,
  worksetListApi,
  downloadOrigin,
  title,
  detail,
  closeModal,
  modalStatus,
  setModalStatus,
}) => {
  const setIsDwl = useSetRecoilState(isDwlState);

  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };
  const { tab, view, sort, sortType, page, ...params } = queryParams;

  const [downloadFiles, setDownloadFiles] = useState([]);
  const [successCnt, setSuccessCnt] = useState(0);

  const [failType, setFailType] = useState("");

  const [selectedWorkset, setSelectedWorkset] = useState(null);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [worksetList, setWorksetList] = useState([]);

  const fetchWorksetList = async () => {
    try {
      const data = await worksetListApi(urlInfo);
      setWorksetList(data.results);
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const fetchDownloadLinks = useCallback(async () => {
    setModalStatus("loading");
    try {
      const res = await downloadLinkApi(
        { ...urlInfo, ...tab },
        { ...params, workset_id: selectedWorkset.id }
      );

      setDownloadFiles(
        res.map((data) => ({
          url: `${process.env.REACT_APP_SERVER_BASE_URL}${downloadOrigin}${data.encrypt}/${data.type}`,
          name: data.name,
        }))
      );
      setSuccessCnt(res.length);
      setModalStatus("hasValue");
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  }, [selectedWorkset]);

  useEffect(() => {
    fetchWorksetList();
  }, []);

  useEffect(() => {
    if (selectedWorkset) {
      fetchDownloadLinks();
    }
  }, [selectedWorkset, fetchDownloadLinks]);

  return (
    <ModalContainer
      modalType="save_modal"
      title={title}
      closeModal={closeModal}
      desc={{
        text: detail,
      }}
    >
      <div
        className={clsx(
          "relative mx-[16px] rounded-[2px] border border-gray400 px-[16px] py-[8px]",
          modalStatus,
          modalStatus === "loading" &&
            "before:absolute before:left-0 before:top-0 before:z-10 before:h-full before:w-full before:bg-black/10"
        )}
      >
        <ul className="flex flex-col flex-nowrap justify-between bg-white">
          <SelectWrap required={!selectedWorkset}>
            <SelectName name="작업 셋 이름" />
            <SelectTitle
              onClick={() => setDropdownOpened((open) => !open)}
              name={selectedWorkset?.name ?? "선택"}
              styleType="modal"
            />
            <SelectBox open={dropdownOpened} styleType="modal">
              {worksetList?.map((workset) => (
                <SelectItem key={workset.id} styleType="list">
                  <SelectRadio
                    onChange={() => {
                      setSelectedWorkset(workset);
                      setDropdownOpened(false);
                    }}
                    checked={workset.id === selectedWorkset?.id}
                    value={workset.id}
                    bool={workset.id === selectedWorkset?.id}
                  />
                  <span className={clsx("ml-[12px]")}>{workset.name}</span>
                </SelectItem>
              ))}
            </SelectBox>
          </SelectWrap>
        </ul>
      </div>

      <ul className="mt-[10px] pl-[16px]">
        {downloadFiles.map((downloadFile) => (
          <li className="relative left-[15px] pl-[8px] leading-[25px] text-primary underline">
            <div className="absolute left-0 top-[50%] mr-[4px] mt-[2px] inline-block h-[4px] w-[4px] translate-y-[-50%] rounded-[2px] bg-gray900"></div>
            <a href={downloadFile.url} download="file.zip" className="hover:opacity-60">
              {downloadFile.name}
            </a>
          </li>
        ))}
      </ul>

      <ModalBottom hasStatus={{ modalStatus, failType }}>
        {modalStatus === "hasValue" ? (
          <CommonDownloadButton
            downloadUrls={downloadFiles.map((downloadFile) => downloadFile.url)}
            onClick={() => {
              setIsDwl(true);
              closeModal();
            }}
            name={`다운로드 ${successCnt && `(총 ${successCnt}개의 zip 파일)`}`}
            modalStatus={modalStatus}
            type="modal_btn"
          />
        ) : (
          <CommonButton
            name="확인"
            onClick={() => {
              closeModal();
            }}
            type="modal_btn"
          />
        )}
      </ModalBottom>
    </ModalContainer>
  );
};
