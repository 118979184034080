import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { modalOpenState } from "../../../lib/stores";
import {
  SelectButton,
  SelectBox,
  SelectItem,
  SelectRadio,
  SelectTitle,
  SelectUl,
  SelectWrap,
} from "../../atoms/SelectBox";

const Sort = ({ data }) => {
  const sortData = data.filter((item) => item.sort !== false);

  const [open, setOpen] = useRecoilState(modalOpenState);
  const {
    queryParams: { sort, sortType },
    setQueryParams,
  } = useDynamicRouting();

  const [value, setValue] = useState({
    sort,
    sortType,
  });

  const handleClick = () => {
    setQueryParams({ sort: value.sort, sortType: value.sortType });
    setOpen(false);
  };

  useEffect(() => {
    setValue({ sort, sortType });
  }, [sort, sortType]);

  return (
    <SelectWrap single styleType="sort">
      <SelectTitle
        onClick={() => (open === "sort" ? setOpen(null) : setOpen("sort"))}
        name="정렬"
      />
      {open === "sort" && (
        <SelectBox open={open === "sort"} styleType="sort">
          <SelectUl>
            <SelectItem item={{ ko: "오름차순" }} styleType="list" cn="truncate">
              <SelectRadio
                onChange={(e) => setValue({ ...value, sortType: e.target.value })}
                checked={value.sortType === "asc"}
                value="asc"
              />
            </SelectItem>
            <SelectItem item={{ ko: "내림차순" }} styleType="list" cn="truncate">
              <SelectRadio
                onChange={(e) => setValue({ ...value, sortType: e.target.value })}
                checked={value.sortType === "desc"}
                value="desc"
              />
            </SelectItem>
          </SelectUl>
          <SelectUl cn="max-h-[236px] border-t border-gray400">
            {sortData?.length > 0 &&
              sortData.map((item, index) => (
                <SelectItem key={index} item={item} index={index} styleType="list" cn="truncate">
                  <SelectRadio
                    onChange={(e) => setValue({ ...value, sort: e.target.value })}
                    checked={value.sort === item.col}
                    value={item.col}
                  />
                </SelectItem>
              ))}
          </SelectUl>
          <SelectButton onClick={handleClick} />
        </SelectBox>
      )}
    </SelectWrap>
  );
};

export default Sort;
