import { useState, useMemo, useCallback } from "react";
import ModalContainer from "../../molecules/ModalContainer";
import { ModalBottom } from "../../molecules/Modals";
import { CommonButton } from "../../molecules/Buttons";
import { formatAmountComma } from "../../../lib/utils";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { ImageSprite } from "../../atoms/CommonAtoms";
import { useStorage } from "../../../lib/storage";

const Refine = (props) => {
  const { statusCodeData, count, columns } = props;
  const [openInitailizeFiltersModal, setOpenInitailizeFiltersModal] = useState(false);

  const { urlParams, queryParams, delQueryParams } = useDynamicRouting();
  const { menu, sensor_code, gt_code } = urlParams;
  const filterStorage = useStorage("filter");

  // 적용된 필터링값 중에 해당 컬럼을 포함하고있고, 필터링이 가능한 컬럼만 출력
  const filterQueryObj = columns
    .filter((item) => Object.keys(queryParams).includes(item.col))
    .map((item) => {
      return { ...item, value: queryParams[item.col] };
    });

  const len = filterQueryObj.length;

  const showInitailizeFiltersButton = useMemo(
    () =>
      !urlParams.set_id &&
      filterStorage.exist({
        menu,
        sensor_code,
        gt_code,
        level: "DATASET",
        isGuide: false,
      }),
    [urlParams, filterStorage]
  );

  const formatCode = (v) =>
    statusCodeData?.find((data) => data.value === v)?.ko ?? `STATUS_CODE_${v}`;

  const formatDateTime = (v) => `${v.slice(0, -2)} ${v.slice(-2)}'`;

  const formatFilter = (value, icon, col) =>
    value === "true"
      ? "O"
      : value === "false"
        ? "X"
        : value === "none"
          ? "(비어있음)"
          : icon === "IC_datetime_s" || icon === "IC_time_s"
            ? formatDateTime(value)
            : col === "status" || col === "sensorType" || col === "vldType"
              ? formatCode(value)
              : value;

  const formatFilters = (x) => {
    const values = x.value.split(",");
    const firstValue = formatFilter(values[0], x.icon, x.col);

    return `${firstValue}${values.length > 1 ? " 외 " + (values.length - 1) : ""}`;
  };

  const removeAllDatasetFilters = useCallback(() => {
    filterStorage.remove({ menu, sensor_code, gt_code, level: "DATASET", isGuide: false });
  }, [urlParams]);

  return (
    <>
      <div className="flex w-full justify-between pb-[4px]">
        <div className="transparent_scroll flex flex-none gap-[8px] overflow-x-hidden hover:overflow-x-auto">
          {len > 0 && (
            <div className="flex h-[30px] w-auto flex-none items-center justify-center rounded-[20px] border border-gray400 px-[8px] text-gray700">
              {"필터링 결과 : "}
              {count === 0 ? "0" : count ? formatAmountComma(count) : "..."}개
            </div>
          )}
          {len > 0 &&
            filterQueryObj?.map((x, i) => (
              <div
                key={i}
                className="flex h-[30px] w-auto flex-none cursor-pointer items-center justify-center rounded-[20px] border border-gray400 px-[8px] text-gray700"
                onClick={() => delQueryParams([x.col, "page"])}
              >
                <ImageSprite boxSize="scale_small" iconType="IC_close_s" />
                {x.ko}
                {" : "}
                {formatFilters(x)}
              </div>
            ))}
        </div>
        {showInitailizeFiltersButton && (
          <div
            className="flex h-[30px] w-auto flex-none cursor-pointer items-center justify-center rounded-[20px] border border-gray400 px-[8px] text-gray700"
            onClick={() => {
              setOpenInitailizeFiltersModal(true);
            }}
          >
            데이터 셋 필터링 초기화
          </div>
        )}
      </div>
      {openInitailizeFiltersModal && (
        <InitailizeFiltersModal
          title={`데이터 셋 필터링 초기화`}
          detail={`각 데이터 셋에 설정한 필터 값이 모두 초기화됩니다. 
            초기화 하시겠습니까?`}
          closeModal={() => {
            setOpenInitailizeFiltersModal(false);
          }}
          removeFilters={removeAllDatasetFilters}
        />
      )}
    </>
  );
};

export const InitailizeFiltersModal = ({ title, detail, closeModal, removeFilters }) => {
  const [openInitializeCompleteModal, setOpenInitializeCompleteModal] = useState(false);
  return (
    <>
      <ModalContainer title={title} closeModal={closeModal}>
        <ul className="h-[48px] px-[16px]">
          <li>{detail}</li>
        </ul>
        <ModalBottom>
          <CommonButton
            name="확인"
            onClick={() => {
              closeModal();
              removeFilters();
              setOpenInitializeCompleteModal(true);
            }}
            type="modal_btn"
          />
        </ModalBottom>
      </ModalContainer>
      {openInitializeCompleteModal && (
        <InitailizationCompleteModal
          title="데이터 셋 필터링 초기화 완료"
          detail="데이터 셋 전체의 필터 값이 초기화되었습니다."
          closeModal={() => {
            setOpenInitializeCompleteModal(false);
          }}
        />
      )}
    </>
  );
};

export const InitailizationCompleteModal = ({ title, detail, closeModal }) => {
  return (
    <ModalContainer title={title} closeModal={closeModal}>
      <ul className="h-[48px] px-[16px]">
        <li>{detail}</li>
      </ul>
      <ModalBottom>
        <CommonButton name="확인" onClick={closeModal} type="modal_btn" />
      </ModalBottom>
    </ModalContainer>
  );
};

export default Refine;
