import React, { useEffect, useState, useMemo } from "react";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { SelectCheckbox, SelectItem } from "../atoms/SelectBox";
import { ThumbnailItem } from "../molecules/ThumbnailItem";
import QnaItem from "../molecules/QnaItem";
import ThumbnailList from "../organisms/search/ThumbnailList";
import ThumbnailWrapper from "../organisms/ThumbnailWrapper";
import { RenderHeader } from "../organisms/RenderHeader";
import Pagination from "../organisms/search/Pagination";
import TableWrapper from "../organisms/TableWrapper";
import { RenderRow } from "../organisms/RenderRow";
import QnaWrapper from "../organisms/QnaWrapper";
import HideCol from "../organisms/search/HideCol";
import Refine from "../organisms/search/Refine";
import View from "../organisms/search/View";
import Sort from "../organisms/search/Sort";
import Tab from "../organisms/search/Tab";
import { COLUMN_LIST } from "../../lib/constants/constant";
import { useGroupColumn, usePages, useSelectTableRows } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import {
  currentMenuState,
  currentPermissionState,
  imgTypeDataState,
  previewListState,
} from "../../lib/stores";

const CommonTemplate = (props) => {
  const {
    previewQuery,
    listQuery,
    state_cnt,
    contents_cnt,
    contents_ids,
    filterApi,

    tabData, // 탭
    refTabData, // 데이터-가이드 전환 탭
    viewData, // 리스트 뷰 여부
    imgTypeData, // 이미지 타입
    groupColumnData, // 그룹 컬럼
    fullColumnData, // 전체 컬럼
    columnData, // 컬럼
    hideColumn, // 숨김 컬럼
    hideThumbColumn, // 썸네일 숨김 컬럼
    statusCodeData, // 상태 코드

    qnaType, // q, a
    tagType, // cond, val, eval
    setVldTags, // vld reset
    actions,
    actionsNoPermit,
  } = props;

  // url info
  const { urlParams, queryParams, setQueryParams, delQueryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;
  const { tab, view, page, sort } = queryParams;

  const { spliceCol } = useGroupColumn();

  // table뷰의 columns 리스트 만들기
  const effectiveColumns = useMemo(
    () =>
      columnData
        .map((c) => COLUMN_LIST.find((item) => item.name === c))
        .filter((c) => c !== undefined),
    [columnData]
  );

  const fullColumns = useMemo(
    () =>
      fullColumnData
        .map((c) => COLUMN_LIST.find((item) => item.name === c))
        .filter((c) => c !== undefined),
    [fullColumnData]
  );

  // hideThumbColumn 값이 있다면 thumb뷰의 columns 리스트 만들기
  const thumbcolumns = useMemo(() => {
    if (hideThumbColumn) {
      return columnData
        .filter((value) => !hideThumbColumn.includes(value))
        .map((c) => COLUMN_LIST.find((item) => item.name === c))
        .filter((c) => c !== undefined);
    } else {
      return [];
    }
  }, [columnData, hideThumbColumn]);

  const groupedEffectiveColumns = spliceCol([...effectiveColumns], groupColumnData);
  const groupedFullColumns = spliceCol([...fullColumns], groupColumnData);

  // recoil state
  const setImgTypeData = useSetRecoilState(imgTypeDataState);
  const currentMenu = useRecoilValue(currentMenuState);
  const currentPermission = useRecoilValue(currentPermissionState);
  const setPreviewList = useSetRecoilState(previewListState);
  const { state, contents } = useRecoilValueLoadable(
    listQuery({ sensor_code, gt_code, set_id, ...queryParams })
  );
  const [prevTable, setPrevTable] = useState(null);
  const [prevThumb, setPrevThumb] = useState(null);
  const nextTable = state === "hasValue" ? contents : prevTable;
  const nextThumb = state === "hasValue" ? contents : prevThumb;

  // user selected
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedObj, setSelectedObj] = useState({
    selectedItems: [],
    lastSelectedItem: null,
  });
  const [currentImgType, setCurrentImgType] = useState(imgTypeData ? imgTypeData[0] : null);

  // contents
  const [pages, makePages] = usePages([]);
  const all_checked =
    (contents.results?.length && contents.results?.length === selectedObj.selectedItems?.length) ||
    false;
  const { handleClickRow, handleClickHeaderCheckbox } = useSelectTableRows(
    contents.results?.length > 0 && contents.results,
    setSelectedObj,
    "obj"
  );

  useEffect(() => {
    state === "hasValue" && (view === "thumb" ? setPrevThumb(contents) : setPrevTable(contents));
  }, [state]);

  useEffect(() => {
    setImgTypeData(imgTypeData);
  }, [imgTypeData]);

  useEffect(() => {
    setPreviewList(contents_ids);
  }, [contents_ids]);

  useEffect(() => {
    if (setVldTags) {
      // 선별 대기(3) 탭 일 때 vld_type이 학습용인 데이터 ids 갯수 확인
      if (tab === "3") {
        const items = contents?.results;
        if (items?.length) {
          const resultArr = items
            .filter((item) => item?.vld_type === 2)
            .map((item) => {
              return item.id;
            });
          setVldTags(resultArr);
        }
      } else {
        setVldTags([]);
      }
    }
    setSelectedObj({
      selectedItems: [],
      lastSelectedItem: null,
    });
    if (state === "hasValue" && contents.count > 0) {
      makePages(contents.count);
    } else if (state === "hasValue" && contents.count === 0) {
      makePages([]);
    } else if (state === "hasError") {
      makePages([]);
      // 존재하지 않는 페이지 -> 1페이지로 이동
      if (contents?.response?.data?.detail === "Invalid page.") {
        setQueryParams({ page: "1" });
      }
      // 존재하지 않는 컬럼 정렬 -> 정렬 param 삭제
      if (
        !effectiveColumns
          .filter((item) => item.sort !== false)
          .map((item) => item.col)
          .includes(sort, 0)
      ) {
        delQueryParams(["sort", "sortType"]);
      }
    }
  }, [state, contents]);

  return (
    <>
      {tabData && (
        <Tab
          data={tabData}
          state={state_cnt}
          contents={state_cnt === "hasValue" && contents_cnt.cnts}
          link={refTabData}
        />
      )}
      <div className="mx-[16px]">
        <div className="flex justify-between">
          <div className="flex min-h-[54px] items-center gap-[8px] pb-[8px] pt-[16px]">
            {viewData && <View data={["table", "thumb"]} />}
            {!qnaType &&
              (!viewData || view === "table" ? (
                <HideCol
                  data={effectiveColumns}
                  hideColumn={hideColumn}
                  updateColumn={(data) =>
                    setSelectedColumns(
                      effectiveColumns.filter((item) => data.includes(item.name, 0))
                    )
                  }
                />
              ) : (
                <ThumbnailList
                  data={imgTypeData}
                  currentImgType={currentImgType}
                  setCurrentImgType={setCurrentImgType}
                />
              ))}
            <Sort data={groupedEffectiveColumns} />
          </div>
          <div className="flex min-h-[54px] items-center gap-[8px] bg-white pb-[8px] pl-[16px] pt-[16px]">
            {currentPermission
              ? actions?.({
                  view,
                  selectedItems: selectedObj.selectedItems,
                  setSelectedItems: setSelectedObj,
                  contents,
                  state_cnt,
                  contents_cnt,
                })
              : actionsNoPermit?.({
                  view,
                  selectedItems: selectedObj.selectedItems,
                  setSelectedItems: setSelectedObj,
                  contents,
                  state_cnt,
                  contents_cnt,
                })}
          </div>
        </div>
        <Refine
          count={contents?.count}
          statusCodeData={statusCodeData}
          columns={groupedFullColumns}
        />
      </div>

      {qnaType && tab === "3" ? (
        <QnaWrapper
          status={state}
          renderHeader={() => {
            return (
              currentPermission &&
              qnaType === "q" &&
              contents.results?.length > 0 && (
                <SelectItem>
                  <SelectCheckbox
                    onChange={(e) => handleClickHeaderCheckbox(e, all_checked)}
                    checked={all_checked}
                    value={all_checked}
                  />
                  <div className="pl-[8px]">전체 선택</div>
                </SelectItem>
              )
            );
          }}
        >
          {contents.results?.length > 0 &&
            contents.results.map((item, index) => (
              <QnaItem
                key={index}
                index={index}
                item={item}
                currentPage={Number(page)}
                previewQuery={previewQuery}
                answer={qnaType === "a"}
                selected={selectedObj.selectedItems?.includes(item.id) ? true : false}
                handleSelectQnaItem={handleClickRow}
              />
            ))}
        </QnaWrapper>
      ) : !viewData || view === "table" ? (
        <TableWrapper
          tab={tabData && tab}
          state={state}
          contents={nextTable?.results?.length > 0 && nextTable.results}
          failType={contents?.response?.data?.detail}
          renderHeader={() => {
            return (
              <RenderHeader
                columns={selectedColumns}
                state={state}
                contents={contents.results?.length > 0 && contents.results}
                allChecked={all_checked}
                setSelectedItems={setSelectedObj}
                filterApi={filterApi}
                statusCodeData={statusCodeData}
                groupColumnData={groupColumnData}
                previewQuery={previewQuery}
              />
            );
          }}
        >
          {nextTable?.results?.length > 0 &&
            nextTable.results.map((item, index) => (
              <RenderRow
                key={index}
                row={item}
                state={state}
                columns={selectedColumns}
                previewQuery={previewQuery}
                tag={tagType}
                index={index}
                handleSelectTableRow={handleClickRow}
                selected={selectedObj.selectedItems?.includes(item.id) ? true : false}
                currentPage={Number(page)}
                currentMenu={currentMenu}
                linkPath={currentMenu.menu}
                statusCodeData={statusCodeData}
                groupColumnData={groupColumnData}
                currentImgType={currentImgType}
              />
            ))}
        </TableWrapper>
      ) : (
        <ThumbnailWrapper
          tab={tabData && tab}
          state={state}
          contents={nextThumb?.results?.length > 0 && nextThumb.results}
          failType={contents?.response?.data?.detail}
          renderHeader={() => {
            return (
              <RenderHeader
                columns={thumbcolumns}
                state={state}
                contents={contents.results?.length > 0 && contents.results}
                allChecked={all_checked}
                setSelectedItems={setSelectedObj}
                filterApi={filterApi}
                statusCodeData={statusCodeData}
                groupColumnData={groupColumnData}
              />
            );
          }}
        >
          {nextThumb?.results?.length > 0 &&
            nextThumb.results.map((item, index) => (
              <ThumbnailItem
                key={index}
                index={index}
                item={item}
                currentPage={Number(page)}
                previewQuery={previewQuery}
                tag={tagType}
                selected={selectedObj.selectedItems?.includes(item.id) ? true : false}
                handleSelectThumbItem={handleClickRow}
                currentImgType={currentImgType}
              />
            ))}
        </ThumbnailWrapper>
      )}
      <Pagination data={pages} />
    </>
  );
};

export default CommonTemplate;
