import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import Hitmap from "./Hitmap.js"; // Hitmap 클래스 임포트

const HitmapCanvas = memo(
  forwardRef(function HitmapCanvas(
    {
      category,
      // setSelectedSector,
      setSelectedCell,
    },
    ref
  ) {
    const containerRef = useRef(null);
    const baseCanvasRef = useRef(null);
    const overlayCanvasRef = useRef(null);
    const hitmapRef = useRef(null);

    const [cursorStyle, setCursorStyle] = useState("default");
    const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
      if (containerRef.current && baseCanvasRef.current && overlayCanvasRef.current) {
        const updateSize = () => {
          const { width, height } = containerRef.current.getBoundingClientRect();
          setCanvasSize({ width, height });
        };

        updateSize(); // 초기 사이즈 설정

        const resizeObserver = new ResizeObserver(updateSize);
        resizeObserver.observe(containerRef.current);

        return () => resizeObserver.disconnect();
      }
    }, []);

    useEffect(() => {
      if (
        baseCanvasRef.current &&
        overlayCanvasRef.current &&
        canvasSize.width > 0 &&
        canvasSize.height > 0
      ) {
        if (!hitmapRef.current) {
          // Hitmap 인스턴스가 없는 경우에만 새로 생성
          hitmapRef.current = new Hitmap({
            baseCanvasRef: baseCanvasRef.current,
            overlayCanvasRef: overlayCanvasRef.current,
            category,
            width: canvasSize.width,
            height: canvasSize.height,
          });
          hitmapRef.current.registerCursorStyleSetter(setCursorStyle);
        } else {
          // 이미 Hitmap 인스턴스가 있는 경우 업데이트
          hitmapRef.current.updateCanvasAndSize({
            baseCanvasRef: baseCanvasRef.current,
            overlayCanvasRef: overlayCanvasRef.current,
            width: canvasSize.width,
            height: canvasSize.height,
          });
        }
      }
    }, [category, canvasSize]);

    useImperativeHandle(
      ref,
      () => ({
        drawlearnedPoints: (points) => {
          if (!hitmapRef.current) {
            hitmapRef.current = new Hitmap({
              baseCanvasRef: baseCanvasRef.current,
              overlayCanvasRef: overlayCanvasRef.current,
              category,
              width: canvasSize.width,
              height: canvasSize.height,
            });
            hitmapRef.current.registerCursorStyleSetter(setCursorStyle);
          }
          hitmapRef.current.drawlearnedPoints(points);
        },
        clearlearnedPoints: () => {
          if (hitmapRef.current) {
            hitmapRef.current.clearlearnedPoints();
          }
        },
        drawDataset: (dataset) => {
          if (hitmapRef.current) {
            hitmapRef.current.drawDataset(dataset);
          }
        },
        clearDataset: () => {
          if (hitmapRef.current) {
            hitmapRef.current.clearDataset();
          }
        },
        drawPoint: ({ coords, auto, added, excluded }) => {
          if (hitmapRef.current) {
            hitmapRef.current.drawPoint({ coords, auto, added, excluded });
          }
        },
        clearPoint: ({ coords }) => {
          if (hitmapRef.current) {
            hitmapRef.current.clearPoint({ coords });
          }
        },
        activateInteraction: (datasetId) => {
          if (hitmapRef.current) {
            hitmapRef.current.activateInteraction(datasetId);
          }
        },
        deactivateInteraction: () => {
          if (hitmapRef.current) {
            hitmapRef.current.deactivateInteraction();
          }
        },
        handleResetSector: () => {
          if (hitmapRef.current) {
            hitmapRef.current.resetSector();
          }
        },
        handleResetCell: () => {
          if (hitmapRef.current) {
            hitmapRef.current.resetCell();
          }
        },
      }),
      [canvasSize]
    );

    const handleCanvasClick = useCallback(
      (event) => {
        if (hitmapRef.current) {
          const previousSelectedCell = hitmapRef.current.selectedCell;
          hitmapRef.current.handleCanvasClick(event);

          // const previousSelectedSector = hitmapRef.current.selectedSector;
          // if (previousSelectedSector !== hitmapRef.current.selectedSector) {
          //   setSelectedSector(hitmapRef.current.selectedSector);
          // }

          if (previousSelectedCell !== hitmapRef.current.selectedCell) {
            setSelectedCell(hitmapRef.current.selectedCell);
          }
        }
      },
      [
        // setSelectedSector,
        setSelectedCell,
      ]
    );

    const handleCanvasMouseMove = useCallback((event) => {
      if (hitmapRef.current) {
        hitmapRef.current.handleCanvasEnter(event);
      }
    }, []);

    const handleCanvasMouseLeave = useCallback(() => {
      if (hitmapRef.current) {
        hitmapRef.current.handleCanvasLeave();
      }
    }, []);

    return (
      <div ref={containerRef} className="relative flex-grow">
        <canvas
          className="absolute"
          ref={baseCanvasRef}
          width={canvasSize.width}
          height={canvasSize.height}
        />
        <canvas
          className="absolute"
          ref={overlayCanvasRef}
          width={canvasSize.width}
          height={canvasSize.height}
          onClick={handleCanvasClick}
          onMouseMove={handleCanvasMouseMove}
          onMouseLeave={handleCanvasMouseLeave}
          style={{ cursor: cursorStyle }}
        />
      </div>
    );
  }),
  (prevProps, nextProps) => prevProps.category === nextProps.category
);

export default HitmapCanvas;
