import CommonTemplate from "../../components/templates/CommonTemplate";
import { histClctListQuery } from "./Hist.stores";
import { histClctFilter } from "./Hist.apis";
import { HIST_CLCT_COLS } from "./Hist.constants";

const HistClctPage = () => {
  return (
    <CommonTemplate
      listQuery={histClctListQuery}
      filterApi={histClctFilter}
      fullColumnData={HIST_CLCT_COLS.tab}
      columnData={HIST_CLCT_COLS.tab}
    />
  );
};

export default HistClctPage;
