import { useCallback } from "react";

export const useSelectItems = (data, setSelectedItems, type) => {
  // 전체 클릭
  const handleClickHeaderCheckbox = (e, allChecked) => {
    e.target.blur();

    const items = type === "obj" ? data.map((item) => item.id) : data;

    if (items) {
      if (allChecked) {
        setSelectedItems({ selectedItems: [], lastSelectedItem: null });
      } else {
        setSelectedItems({
          selectedItems: items.map((item) => item),
          lastSelectedItem: null,
        });
      }
    }
  };

  // 행 클릭
  const handleClickRow = useCallback(
    (id, isShiftDown) => {
      // 숫자로 변환
      const value = type === "obj" ? Number(id) : id;
      const items = type === "obj" ? data.map((item) => item.id) : data;

      setSelectedItems(({ selectedItems, lastSelectedItem }) => {
        // 이미 선택됐었던 아이템인지 확인
        const hasBeenSelected = selectedItems.includes(value);

        // shift를 누르고 있는 상태라면
        if (isShiftDown) {
          // 지금 선택한 아이템의 index 찾기
          const currentSelectedIndex = items.findIndex((item) => item === value);
          // 마지막에 선택한 아이템의 index 찾기
          const lastSelectedIndex = items.findIndex((item) => item === lastSelectedItem);

          // 새로운 선택한 아이템 리스트
          const newSelectedItems = items
            .slice(
              Math.min(lastSelectedIndex, currentSelectedIndex),
              Math.max(lastSelectedIndex, currentSelectedIndex) + 1
            )
            .map((item) => item);

          // 최종 선택한 아이템 리스트 합침
          const selections = [...new Set([...selectedItems, ...newSelectedItems])];

          // 이미 선택됐었던 아이템이라면
          if (hasBeenSelected) {
            return {
              selectedItems: selections.filter((item) => !newSelectedItems.includes(item)),
              lastSelectedItem: value,
            };
          }

          return { selectedItems: selections, lastSelectedItem: value };
        } else {
          return {
            selectedItems: hasBeenSelected
              ? selectedItems.filter((item) => item !== value)
              : [...selectedItems, value],
            lastSelectedItem: value,
          };
        }
      });
    },
    [data]
  );

  return {
    handleClickHeaderCheckbox,
    handleClickRow,
  };
};
