import clsx from "clsx";
import LinearProgress from "@mui/material/LinearProgress";
import { ImageSprite, ThumbImg } from "./CommonAtoms";

export const Loader = ({ styleType }) => {
  // loader spinner (사각형)
  return (
    <div
      className={clsx(
        "flex h-[calc(100%-57px)] w-full items-center justify-center",
        styleType === "nav" && "flex-1",
        styleType === "login" && "h-full",
        styleType === "preview" && "absolute top-0 left-0 z-10 h-full",
        styleType === "filter" && "h-[140px]",
        styleType === "autoVld" && "absolute top-[50%] left-[70%]"
      )}
    >
      <div
        className={clsx(
          "spinner",
          "my-[100px] mx-auto h-[40px] w-[40px]",
          styleType === "nav" || styleType === "login" || styleType === "preview"
            ? "bg-white"
            : "bg-primary"
        )}
      ></div>
    </div>
  );
};

export const LoaderLinear = ({ variant, value, hidden, children }) => {
  // loader linear (라인)

  // variant="determinate" : 진행률 바 출력
  // hidden : 진행률 value 출력 여부
  return (
    <div className="flex h-[calc(100%-57px)] w-auto items-center justify-center gap-[4px]">
      <LinearProgress variant={variant} value={value} style={{ width: 100 }} />
      <span className="w-[34px] text-right">
        {!hidden && `${value === undefined ? 99 : value}%`}
      </span>
      {children}
    </div>
  );
};

export const LoadFail = ({ failType }) => {
  // 데이터 로드에 실패했을 때
  return (
    <div className="flex h-[calc(100%-57px)] w-full items-center justify-center text-[14px] text-error">
      {failType || "에러. 관리자에게 문의해 주세요."}
    </div>
  );
};

export const Empty = () => {
  // 데이터가 없을 때
  return (
    <div className="flex h-[calc(100%-34px)] w-full items-center justify-center text-[14px] text-gray700">
      데이터가 없습니다.
    </div>
  );
};

export const EmptyImg = ({ cn }) => {
  // 이미지가 없을 떄
  return (
    <ThumbImg cn={`justify-center w-[400px] h-[400px] ${cn}`}>
      <ImageSprite boxSize="scale_large" iconType="IC_empty_img" />
    </ThumbImg>
  );
};
