import { useHookPages } from "./useHookPages";
import { useHookModals } from "./useHookModals";
import { useHookReload } from "./useHookReload";
import { useSelectItems } from "./useHookSelectItems";
import { useHookShiftDown } from "./useHookShiftDown";
import { useHookIdProcessor } from "./useHookIdProcessor";
import { useHookFileUploader } from "./useHookFileUploader";
import { useHookBatchIdProcessor } from "./useHookBatchIdProcessor";
import { useHookGroupColumn } from "./useHookGroupColumn";
import useHookUpdate from "./useHookUpdate";

// single file uploader
export const useFileUploader = useHookFileUploader;

// single id processor
export const useIdProcessor = useHookIdProcessor;

// batch id processor
export const useBatchIdProcessor = useHookBatchIdProcessor;

// 페이지 리스트 생성
export const usePages = useHookPages;

// 다중선택
export const useSelectTableRows = useSelectItems;

// reload
export const useReload = useHookReload;

// 모달 상태
export const useModals = useHookModals;

// shift 다중선택 hot key
export const useShiftDownEvent = useHookShiftDown;

// 그룹 컬럼은 하위 컬럼으로 바꿈
export const useGroupColumn = useHookGroupColumn;

// 강제 rerender
export const useUpdate = useHookUpdate;
