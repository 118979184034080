import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export const useHookShiftDown = () => {
  const [isShiftDown, setIsShiftDown] = useState(false);

  const useShiftDown = () => {
    useHotkeys("shift", () => setIsShiftDown(true), { keydown: true });
    useHotkeys("shift", () => setIsShiftDown(false), { keyup: true });
  };

  return [isShiftDown, useShiftDown];
};
