import React, { useState, useMemo } from "react";
import { useRecoilValueLoadable } from "recoil";
import { datasetInfoQuery } from "../../../Main.stores";
import clsx from "clsx";

export const Summary = ({ category, isSelected = false, handleClick }) => {
  const { state, contents } = useRecoilValueLoadable(
    datasetInfoQuery({ sensor_code: category.sensor, gt_code: category.gtCode })
  );

  const summary = useMemo(() => {
    if (state === "hasValue") {
      return {
        workRate: contents.cmp_ratio,
        autoCount: contents.auto_cnt ?? 0,
        validationCount: contents.vld_cnt ?? 0,
      };
    } else {
      return {
        workRate: 0,
        autoCount: 0,
        validationCount: 0,
      };
    }
  }, [state, contents]);

  return (
    <div
      className={clsx(
        "flex h-[152px] flex-1 cursor-pointer flex-col justify-between",
        isSelected ? "border border-[#2B63C1] bg-primary3" : "border border-[#e1e1e1] bg-[#fff]",
        "p-[24px] hover:border-[#2B63C1] hover:bg-primary3"
      )}
      onClick={handleClick}
    >
      <div className="text-[20px] font-bold leading-[25px] tracking-[-0.24px] text-[#333]">
        {category.title}
      </div>
      <div className="flex justify-between">
        <div>
          <span className="text-[48px] font-bold leading-[48px] tracking-[-0.96px] text-[#333]">
            {summary.workRate}
          </span>
          <span className="text-[32px] font-bold leading-[32px] tracking-[-0.64px] text-[#333]">
            %
          </span>
        </div>
        <div className="flex flex-col justify-center gap-[6px]">
          <div className="flex">
            <div className="flex h-[19px] min-w-[38px] items-center rounded-[2px] bg-[#081A51] px-[8px] py-[2px] text-[12px] leading-[12px] tracking-[-0.24px] text-white">
              자동선별 완료
            </div>
            <div className="w-[56px] text-end font-bold">{summary.autoCount}</div>
          </div>
          <div className="flex">
            <div className="flex h-[19px] min-w-[38px] items-center rounded-[2px] bg-[#081A51] px-[8px] py-[2px] text-[12px] leading-[12px] tracking-[-0.24px] text-white">
              최종선별 완료
            </div>
            <div className="w-[56px] text-end font-bold">{summary.validationCount}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
