import React, { useEffect } from "react";
import clsx from "clsx";
import { useShiftDownEvent } from "../../lib/hooks";
import { Empty, Loader, LoadFail } from "../atoms/Status";

const TableWrapper = (props) => {
  const { tab, state, contents, children, renderHeader, failType, main } = props;

  let ulRef;
  const [isShiftDown, useShiftDown] = useShiftDownEvent();
  useShiftDown();

  useEffect(() => {
    const ref = ulRef;
    const handleSelectStart = (e) => {
      // shift시 select 방지
      if (isShiftDown) e.preventDefault();
    };

    ref.addEventListener("selectstart", handleSelectStart);
    return () => {
      ref.removeEventListener("selectstart", handleSelectStart);
    };
  }, [isShiftDown]);

  return (
    <div className={clsx("relative mt-[8px] px-[16px]")} ref={(el) => (ulRef = el)}>
      {state === "loading" && (
        <div className="fixed left-0 top-0 z-50 h-full w-full">
          <Loader />
        </div>
      )}
      <div
        className={clsx(
          "scroll_head",
          "overflow-y-auto border-t border-gray400",
          // filter : 38px, tab : 28px,
          main
            ? "h-auto min-h-[50px]"
            : tab
              ? "h-[calc(100vh-228px-38px-28px)]"
              : "h-[calc(100vh-228px-38px)]"
        )}
      >
        <table>
          {renderHeader()}
          {state === "hasError" ||
            (state === "hasValue" && !contents ? null : (
              <tbody className="whitespace-nowrap">{children}</tbody>
            ))}
        </table>

        {state === "hasError" ? (
          <LoadFail failType={failType || "데이터를 불러오는데 실패했습니다."} />
        ) : state === "hasValue" && !contents ? (
          <>
            {main && <div className="h-[20px]"></div>}
            <Empty />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TableWrapper;
