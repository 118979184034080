import * as d3 from "d3";
import { axiosInstance } from "../../../../../components/templates/RootErrorBoundary";

class Hitmap {
  constructor({ baseCanvasRef, overlayCanvasRef, category, width, height }) {
    this.baseCanvasRef = baseCanvasRef;
    this.overlayCanvasRef = overlayCanvasRef;
    this.category = category;
    this.width = width;
    this.height = height;

    this.scales = { x: null, y: null, opacity: null };
    this.originalScales = { x: null, y: null };

    this.learnedPoints = [];
    this.additionalDataset = null;

    this.datasetId = null;

    // this.selectedSector = null;

    this.selectedCell = null;

    this.hoveredGrid = null;
    this.countCache = new Map();
    this.counts = null;

    this.debounceTimer = null;
    this.debounceDelay = 300;

    this.isInteractionActive = false;
    this.isMouseOverCanvas = false;

    this.setCursorStyle = null;
    this.animationFrameId = null;

    this.initializeScales();
  }

  initializeScales() {
    const xScale = d3.scaleLinear().range([0, this.width]);
    const yScale = d3.scaleLinear().range([this.height, 0]);
    const opacityScale = d3.scaleLinear().domain([1, 10]).range([0.1, 1]).clamp(true);

    this.scales = { x: xScale, y: yScale, opacity: opacityScale };
    this.originalScales = { x: xScale.copy(), y: yScale.copy() };
  }

  updateCanvasAndSize({ baseCanvasRef, overlayCanvasRef, width, height }) {
    this.baseCanvasRef = baseCanvasRef;
    this.overlayCanvasRef = overlayCanvasRef;
    this.width = width;
    this.height = height;

    this.initializeScales();
    // 스케일 업데이트
    this.updateScales();

    // 캔버스 크기 조정
    this.baseCanvasRef.width = width;
    this.baseCanvasRef.height = height;
    this.overlayCanvasRef.width = width;
    this.overlayCanvasRef.height = height;

    // 데이터 다시 그리기
    this.drawBaseChart();
    this.drawOverlayChart();
  }

  updateScales() {
    // 고정된 도메인 설정 (0에서 1000까지)
    const fixedDomain = [0, 1000];

    this.scales.x.domain(fixedDomain).range([0, this.width]);
    this.scales.y.domain(fixedDomain).range([this.height, 0]);

    // 원본 스케일도 동일하게 설정
    this.originalScales.x = this.scales.x.copy();
    this.originalScales.y = this.scales.y.copy();
  }

  drawGrid(context) {
    context.strokeStyle = "#E5E7EB";
    context.lineWidth = 1;
    const gridSize = 10;
    const xStep = this.width / gridSize;
    const yStep = this.height / gridSize;

    for (let i = 1; i < gridSize; i++) {
      context.beginPath();
      context.moveTo(i * xStep, 0);
      context.lineTo(i * xStep, this.height);
      context.stroke();

      context.beginPath();
      context.moveTo(0, i * yStep);
      context.lineTo(this.width, i * yStep);
      context.stroke();
    }

    context.strokeRect(0, 0, this.width, this.height);
  }

  drawBaseChart() {
    const context = this.baseCanvasRef.getContext("2d");
    context.clearRect(0, 0, this.width, this.height);

    this.learnedPoints.forEach((d) => {
      // if (this.selectedSector) {
      //   if (
      //     d.x < this.selectedSector.xMin ||
      //     d.x > this.selectedSector.xMax ||
      //     d.y < this.selectedSector.yMin ||
      //     d.y > this.selectedSector.yMax
      //   ) {
      //     return;
      //   }
      // }

      const x = this.scales.x(d.x);
      const y = this.scales.y(d.y);

      context.beginPath();
      context.arc(x, y, 3, 0, 2 * Math.PI);
      context.fillStyle = `rgba(0, 0, 0, ${this.scales.opacity(d.cnt)})`;
      context.fill();
    });
  }

  drawOverlayChartWithoutTooltip() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    this.animationFrameId = requestAnimationFrame(() => {
      const context = this.overlayCanvasRef.getContext("2d");
      context.clearRect(0, 0, this.width, this.height);

      if (this.isInteractionActive) {
        this.drawGrid(context);
      }

      if (this.additionalDataset) {
        this.drawAdditionalDataset(context);
      }

      if (this.isMouseOverCanvas && this.hoveredGrid) {
        this.drawGridOutline(context);
      }

      if (this.selectedCell) {
        this.drawSelectedCellOutline(context);
      }
    });
  }

  drawOverlayChart() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    this.animationFrameId = requestAnimationFrame(() => {
      const context = this.overlayCanvasRef.getContext("2d");
      context.clearRect(0, 0, this.width, this.height);

      if (this.isInteractionActive) {
        this.drawGrid(context);
      }

      if (this.additionalDataset) {
        this.drawAdditionalDataset(context);
      }

      if (this.isMouseOverCanvas && this.hoveredGrid) {
        this.drawGridOutline(context);
      }

      if (this.selectedCell) {
        this.drawSelectedCellOutline(context);
      }

      if (this.isMouseOverCanvas && this.counts) {
        this.drawTooltip(context);
      }
    });
  }

  _drawCircle(context, points, color, highlight = false) {
    context.save(); // 현재 컨텍스트 상태 저장

    if (highlight) {
      context.shadowColor = "rgba(0, 0, 0, 0.5)";
      context.shadowBlur = 4;
      context.lineWidth = 2;
    } else {
      context.lineWidth = 0.5;
    }

    points.forEach((point) => {
      const x = this.scales.x(point.x);
      const y = this.scales.y(point.y);

      context.beginPath();
      context.arc(x, y, highlight ? 10 : 3, 0, 2 * Math.PI);
      context.strokeStyle = color;
      context.stroke();
    });

    context.restore(); // 이전 컨텍스트 상태로 복원
  }

  _drawTriangle(context, points, color, highlight = false) {
    context.save(); // 현재 컨텍스트 상태 저장

    if (highlight) {
      context.shadowColor = "rgba(0, 0, 0, 0.5)";
      context.shadowBlur = 4;
      context.lineWidth = 2;
    } else {
      context.lineWidth = 0.5;
    }

    points.forEach((point) => {
      const x = this.scales.x(point.x);
      const y = this.scales.y(point.y);
      const size = 4;

      context.beginPath();
      context.moveTo(x, y - size);
      context.lineTo(x - (size * Math.sqrt(3)) / 2, y + size / 2);
      context.lineTo(x + (size * Math.sqrt(3)) / 2, y + size / 2);
      context.closePath();
      context.strokeStyle = color;
      context.stroke();
    });

    context.restore(); // 이전 컨텍스트 상태로 복원
  }

  drawAdditionalDataset(context) {
    if (this.additionalDataset.auto)
      this._drawCircle(context, this.additionalDataset.auto, "#2878FF");
    if (this.additionalDataset.added)
      this._drawTriangle(context, this.additionalDataset.added, "#00BF40");
    if (this.additionalDataset.excluded)
      this._drawCircle(context, this.additionalDataset.excluded, "#FF6363");
  }

  drawGridOutline(context) {
    const { gridX, gridY } = this.hoveredGrid;
    const gridSize = 10;
    const xStep = this.width / gridSize;
    const yStep = this.height / gridSize;

    context.strokeStyle = "#2878FF";
    context.lineWidth = 2;
    context.strokeRect(gridX * xStep, gridY * yStep, xStep, yStep);
  }

  drawSelectedCellOutline(context) {
    const { gridX, gridY } = this.selectedCell;
    const gridSize = 10;
    const xStep = this.width / gridSize;
    const yStep = this.height / gridSize;

    context.strokeStyle = "#2878FF";
    context.lineWidth = 2;
    context.strokeRect(gridX * xStep, gridY * yStep, xStep, yStep);
  }

  drawTooltip(context) {
    if (!this.counts || !this.hoveredGrid) return;

    const { gridX, gridY } = this.hoveredGrid;
    const gridSize = 10;
    const xStep = this.width / gridSize;
    const yStep = this.height / gridSize;

    const padding = { x: 16, y: 8 };
    const borderRadius = 6;
    const lineHeight = 17;

    context.font = '12px "Spoqa Han Sans Neo"';

    const tooltipData = [
      { key: "학습 데이터", value: this.counts.lrn_cnt },
      { key: "선별 데이터", value: this.counts.val_cnt },
    ];

    const textWidth = Math.max(
      ...tooltipData.map((item) => context.measureText(`${item.key}: ${item.value}`).width)
    );

    const tooltipWidth = textWidth + padding.x * 2;
    const tooltipHeight = tooltipData.length * lineHeight + padding.y * 2;

    let tooltipX = gridX * xStep + xStep / 2 - tooltipWidth / 2;
    let tooltipY = gridY * yStep - tooltipHeight - 10;

    tooltipX = Math.max(0, Math.min(tooltipX, this.width - tooltipWidth));
    tooltipY = tooltipY < 0 ? gridY * yStep + yStep + 10 : tooltipY;

    // Draw tooltip background
    context.fillStyle = "#FFF";
    this._roundRect(context, tooltipX, tooltipY, tooltipWidth, tooltipHeight, borderRadius);
    context.fill();

    // Draw tooltip border
    context.strokeStyle = "#D0D0D0";
    context.lineWidth = 1;
    this._roundRect(context, tooltipX, tooltipY, tooltipWidth, tooltipHeight, borderRadius);
    context.stroke();

    // Draw tooltip text
    context.fillStyle = "#333";
    context.textAlign = "left";
    context.textBaseline = "middle";

    tooltipData.forEach((item, index) => {
      const y = tooltipY + padding.y + (index + 0.5) * lineHeight + 2;
      context.fillText(`${item.key}: ${item.value}`, tooltipX + padding.x, y);
    });
  }

  _roundRect(context, x, y, width, height, radius) {
    context.beginPath();
    context.moveTo(x + radius, y);
    context.lineTo(x + width - radius, y);
    context.quadraticCurveTo(x + width, y, x + width, y + radius);
    context.lineTo(x + width, y + height - radius);
    context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    context.lineTo(x + radius, y + height);
    context.quadraticCurveTo(x, y + height, x, y + height - radius);
    context.lineTo(x, y + radius);
    context.quadraticCurveTo(x, y, x + radius, y);
    context.closePath();
  }

  drawPoint({ coords, auto, added, excluded }) {
    const context = this.overlayCanvasRef.getContext("2d");
    const point = [coords]; // coords를 배열로 감싸서 _drawCircle 및 _drawTriangle 메소드와 호환되게 함

    if (excluded) {
      this._drawCircle(context, point, "#FF6363", true);
    } else if (added) {
      this._drawTriangle(context, point, "#00BF40", true);
    } else if (auto) {
      this._drawCircle(context, point, "#2878FF", true);
    }

    // 새로 그린 포인트가 기존 차트와 함께 표시되도록 전체 차트를 다시 그립니다.
    this.drawOverlayChart();
  }

  clearPoint({ coords }) {
    const context = this.overlayCanvasRef.getContext("2d");
    const x = this.scales.x(coords.x);
    const y = this.scales.y(coords.y);

    // 점 주변의 작은 영역을 지웁니다.
    // 여기서는 6x6 픽셀 영역을 지우지만, 필요에 따라 조절할 수 있습니다.
    context.clearRect(x - 3, y - 3, 6, 6);

    // 전체 차트를 다시 그려 지워진 영역을 업데이트합니다.
    this.drawOverlayChart();
  }

  handleCanvasClick(event) {
    const rect = this.overlayCanvasRef.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    const gridSize = 10;
    const xStep = this.width / gridSize;
    const yStep = this.height / gridSize;
    const gridX = Math.floor(mouseX / xStep);
    const gridY = Math.floor(mouseY / yStep);

    if (gridX >= 0 && gridX < gridSize && gridY >= 0 && gridY < gridSize) {
      const xMin = Math.trunc(this.scales.x.invert(gridX * xStep));
      const xMax = Math.trunc(this.scales.x.invert((gridX + 1) * xStep));
      const yMin = Math.trunc(this.scales.y.invert((gridY + 1) * yStep));
      const yMax = Math.trunc(this.scales.y.invert(gridY * yStep));

      // if (this.selectedSector) {
      //   // selectedSector가 있을 때, selectedCell 업데이트
      //   this.selectedCell = { gridX, gridY, xMin, xMax, yMin, yMax };
      //   this.drawOverlayChart();
      // } else if (this.isInteractionActive) {
      //   // 기존의 확대 로직
      //   const newXScale = d3.scaleLinear().domain([xMin, xMax]).range([0, this.width]);
      //   const newYScale = d3.scaleLinear().domain([yMin, yMax]).range([this.height, 0]);

      //   this.scales.x = newXScale;
      //   this.scales.y = newYScale;
      //   this.selectedSector = { gridX, gridY, xMin, xMax, yMin, yMax };

      //   this.drawBaseChart();
      //   this.drawOverlayChart();
      // }
      if (this.isInteractionActive) {
        this.selectedCell = { gridX, gridY, xMin, xMax, yMin, yMax };
        this.drawOverlayChart();
      }
    }
  }

  handleCanvasEnter(event) {
    this.isMouseOverCanvas = true;
    if (!this.isInteractionActive) return;

    const rect = this.overlayCanvasRef.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    const gridSize = 10;
    const xStep = this.width / gridSize;
    const yStep = this.height / gridSize;
    const gridX = Math.floor(mouseX / xStep);
    const gridY = Math.floor(mouseY / yStep);

    if (gridX >= 0 && gridX < gridSize && gridY >= 0 && gridY < gridSize) {
      this.hoveredGrid = { gridX, gridY };
      this.drawOverlayChartWithoutTooltip();

      const xMin = Math.trunc(this.scales.x.invert(gridX * xStep));
      const xMax = Math.trunc(this.scales.x.invert((gridX + 1) * xStep));
      const yMin = Math.trunc(this.scales.y.invert((gridY + 1) * yStep));
      const yMax = Math.trunc(this.scales.y.invert(gridY * yStep));

      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(() => {
        this.fetchDataCounts({ xMin, yMin, xMax, yMax });
      }, this.debounceDelay);
    } else {
      this.clearHoverState();
    }
  }

  handleCanvasLeave() {
    this.isMouseOverCanvas = false;
    this.clearHoverState();
  }

  clearHoverState() {
    this.hoveredGrid = null;
    this.counts = null;
    this.drawOverlayChart();
  }

  async fetchDataCounts({ xMin, yMin, xMax, yMax }) {
    const cacheKey = `${this.datasetId}_${xMin}_${yMin}_${xMax}_${yMax}`;

    if (this.countCache.has(cacheKey)) {
      this.counts = this.countCache.get(cacheKey);
      this.drawOverlayChart();
      return;
    }

    try {
      const {
        data: { results },
      } = await axiosInstance.post(
        `/main/vld/${this.category.sensor}/${this.category.gtCode}/dist/${this.datasetId}/cnt/`,
        {
          coords: [
            [xMin, yMin],
            [xMax, yMax],
          ],
        }
      );

      this.countCache.set(cacheKey, results);
      this.counts = results;
      this.drawOverlayChart();
    } catch (error) {
      console.error("Error fetching grid data:", error);
    }
  }

  resetSector() {
    this.scales.x = this.originalScales.x.copy();
    this.scales.y = this.originalScales.y.copy();
    // this.selectedSector = null;
    this.selectedCell = null;
    this.drawBaseChart();
    this.drawOverlayChart();
  }

  resetCell() {
    this.selectedCell = null;
    this.drawOverlayChart();
  }

  drawlearnedPoints(points) {
    this.learnedPoints = points;
    this.updateScales();
    this.drawBaseChart();
  }

  clearlearnedPoints() {
    const context = this.baseCanvasRef.getContext("2d");
    context.clearRect(0, 0, this.width, this.height);
    this.learnedPoints = [];
  }

  drawDataset(dataset) {
    this.additionalDataset = dataset;
    this.drawOverlayChart();
  }

  clearDataset() {
    this.additionalDataset = null;
    this.drawOverlayChart();
  }

  activateInteraction(datasetId) {
    this.isInteractionActive = true;
    this.datasetId = datasetId;
    this.drawOverlayChart();
    if (this.setCursorStyle) {
      this.setCursorStyle("pointer");
    }
  }

  deactivateInteraction() {
    this.datasetId = null;
    this.clearDataset();
    this.isInteractionActive = false;
    this.resetSector();
    if (this.setCursorStyle) {
      this.setCursorStyle("default");
    }
  }

  // 커서 스타일 설정 함수 등록
  registerCursorStyleSetter(setterFunction) {
    this.setCursorStyle = setterFunction;
  }

  // 선택된 셀 설정 함수 등록
  registerSelectedCellSetter(setterFunction) {
    this.setSelectedCell = setterFunction;
  }
}

export default Hitmap;
