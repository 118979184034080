import { axiosInstance } from "../../components/templates/RootErrorBoundary";

// 원본추출 화면 탭별 데이터 수 조회
export const extrClctCnt = async ({ t }) => {
  const { data } = await axiosInstance.get(`/clct/extr/sets/cnt/`, {
    params: { t },
  });

  return data;
};

// 원본추출 화면 데이터셋 리스트 조회
export const extrClctList = async ({ tab, ...params }) => {
  const { data } = await axiosInstance.get(`/clct/extr/sets/${tab}/`, {
    params,
  });

  return data;
};

// 원본추출 화면 로그파일 업로드 정보 확인
export const extrLogCheck = async (body) => {
  const { data } = await axiosInstance.post("/clct/extr/sets/check/", body);

  return data;
};

// 원본추출 화면 전체 추출 완료
export const extrCmp = async (body) => {
  const { data } = await axiosInstance.post("/clct/extr/sets/cmp/", body);

  return data;
};

// 원본추출 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const extrClctFilter = async ({ col, tab }, params) => {
  const { data } = await axiosInstance.get(`/clct/extr/sets/${tab}/cols/${col}/`, {
    params,
  });

  return data;
};

// 원본추출 화면 로그파일 업로드
export const extrLogUl = async (body, { onUploadProgress }, source) => {
  // 하나씩 전송
  const { data } = await axiosInstance.post("/clct/extr/sets/ul/", body, {
    onUploadProgress,
    cancelToken: source.current.token,
  });

  return data;
};

// 원본추출 화면 로그파일 추출
export const extrLog = async ({ set_id }, body, source) => {
  const { data } = await axiosInstance.post(`/clct/extr/datas/${set_id}/`, body, {
    cancelToken: source.current.token,
  });

  return data;
};

// 원본추출 화면 탭별 데이터 수 조회
export const extrCnt = async ({ set_id, t }) => {
  const { data } = await axiosInstance.get(`/clct/extr/datas/${set_id}/cnt/`, {
    params: { t },
  });

  return data;
};

// 원본추출 화면 bag 데이터 삭제
export const extrDataDel = async ({ set_id }, body) => {
  const { data } = await axiosInstance.post(`/clct/extr/datas/${set_id}/del/`, body);

  return data;
};

// 원본추출 화면 로그파일 리스트 조회
export const extrList = async ({ set_id, tab, ...params }) => {
  const { data } = await axiosInstance.get(`/clct/extr/datas/${set_id}/${tab}/`, {
    params,
  });

  return data;
};

// 원본추출 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const extrFilter = async ({ set_id, tab, col }, params) => {
  const { data } = await axiosInstance.get(`/clct/extr/datas/${set_id}/${tab}/cols/${col}/`, {
    params,
  });

  return data;
};
