import page_screen_01 from "../../assets/images/page_screen_01.png";
import page_screen_02 from "../../assets/images/page_screen_02.png";
import page_screen_03 from "../../assets/images/page_screen_03.png";
import page_screen_04 from "../../assets/images/page_screen_04.png";

const MANUAL = {
  guide: [
    {
      h3: "사용 팁",
      ol: [
        {
          li: "매뉴얼 열기",
          p: "메뉴 리스트 하단 [navlue 매뉴얼] 클릭하여 언제든 매뉴얼을 열어 볼 수 있습니다.",
        },
        {
          li: "찾기",
          p: "ctrl + f로 검색하여 기능, 설명을 매뉴얼에서 찾을 수 있습니다.",
        },
      ],
    },
    {
      h3: "목차",
      ol: [
        {
          li: "화면 구성 및 기능",
          p: "헤더, 페이지 타이틀, 탭, 뷰, 컬럼, 이미지 종류, 정렬, 필터링, 이미지 미리보기, 플로팅 창, 작업 패널",
        },
        {
          li: "페이지별 기능",
          p: "나의 대시보드, 데이터 추출, 데이터 등록, 수집환경 입력, 데이터 삭제 내역, 학습 데이터 선별, 데이터 작업, 데이터 검수, 학습 데이터 관리, 통계 대시보드",
        },
      ],
    },
  ],
  layout: [
    {
      img: page_screen_01,
      desc: [
        {
          h3: "헤더",
          p: ["- 왼쪽 : 메뉴", "- 오른쪽 : 접속시간, 사용자 정보, 로그아웃"],
        },
        {
          h3: "페이지 타이틀",
          p: [
            "현재 페이지 위치 및 데이터 셋 정보 등을 나타냅니다.",
            "클릭 시 리스트를 업데이트합니다.",
          ],
        },
        {
          h3: "탭",
          p: [
            "- 탭 이름(데이터 수) : 클릭 시 리스트를 업데이트합니다.",
            "- 전체 : 상태에 상관없이 모든 데이터를 조회합니다.",
            "- 상태별 탭 : 데이터 상태별로 조회합니다. 각 탭으로 이동하여 데이터 상태에 따라 다른 기능을 사용할 수 있습니다.",
          ],
        },
        {
          h3: "뷰",
          p: [
            "- 테이블 뷰 : 테이블 형태로 데이터를 조회합니다.",
            "- 썸네일 뷰 : 쌈네일 리스트를 조회합니다.",
          ],
        },
        {
          h3: "컬럼 선택 (테이블 뷰)",
          p: ["선택한 테이블 컬럼을 숨기기/해제할 수 있습니다."],
        },
        {
          h3: "이미지 종류 선택 (썸네일 뷰)",
          p: ["썸네일 리스트에서 보고싶은 이미지 종류를 선택합니다."],
        },
        {
          h3: "정렬",
          p: ["데이터를 원하는 조건을 정렬합니다."],
        },
      ],
    },
    {
      img: page_screen_02,
      desc: [
        {
          h3: "필터링",
          p: [
            "테이블 헤더를 클릭하여 원하는 조건을 선택해 리스트를 필터링합니다.",
            "필터링 태그를 클릭하여 필터링 조건을 해제할 수 있습니다.",
          ],
        },
        {
          h3: "데이터 리스트로 이동",
          p: [
            "데이터 셋 리스트에서 선택한 데이터 셋의 데이터 리스트로 이동합니다.",
            "또는 더블클릭으로 이동할 수 습니다.",
          ],
        },
      ],
    },
    {
      img: page_screen_03,
      desc: [
        {
          h3: "이미지 미리보기",
          p: [
            "- 테이블 뷰 : 맨 오른쪽 이미지 아이콘을 클릭합니다.",
            "- 썸네일 뷰 : 썸네일 이미지를 클릭합니다.",
          ],
        },
        {
          h3: "이미지 이동",
          p: ["좌우 화살표 버튼, 키보드 방향키로 이미지를 이동합니다."],
        },
        {
          h3: "이미지 확대/축소",
          p: ["마우스 드래그로 이미지를 움직이며, 마우스 휠로 확대/축소합니다."],
        },
        {
          h3: "이미지 조정",
          p: [
            "- 밝기 (-10 ~ 10) : 밝기를 조정합니다.",
            "- 대비 (-10 ~ 10) : 대비를 조정합니다.",
            "- 회색조 (0 ~ 10) : 흑백 전환 정도를 조정합니다.",
          ],
        },
        {
          h3: "이미지 미리보기 닫기",
          p: ["우측 상단 X 아이콘 또는 키보드 esc로 닫을 수 있습니다."],
        },
      ],
    },
    {
      img: page_screen_04,
      desc: [
        {
          h3: "플로팅 창",
          p: [
            "업로드, 원본 추출, 모델 선택 및 추론(이하 작업) 등의 진행 상황이 화면 우측하단에 나타납니다.",
            "- 플로팅 창 최소화/최대화 : 플로팅 창을 최소화/최대화합니다.",
            "- 플로팅 창 닫기 : 작업 패널이 0개일때 자동으로 사라집니다.",
            "- 플로팅 창 사라지는 경우 : 브라우저 새로고침하거나 닫을 경우 플로팅 창이 사라지며, 사라진 다시 열 수 없습니다.",
          ],
        },
        {
          h3: "작업 패널",
          p: [
            "각 작업의 정보가 출력됩니다.",
            "- 작업추가 : 새로운 작업을 시작할 때마다 플로팅 패널이 추가됩니다.",
            "- 작업위치 [작업명] : 작업위치 및 작업명이 나타납니다.",
            "- 진행상태 : 작업 진행상태 및 결과를 알 수 있습니다.",
            "- 작업 패널 최소화/최대화 : 각 작업 패널을 최소화/최대화합니다.",
            "- 작업 취소 : 진행 중인 작업을 취소할 수 있습니다.",
            "- 작업 패널 닫기 : 완료 또는 취소된 작업의 작업 패널을 닫을 수 있습니다. 닫은 작업 패널을 다시 열 수 없습니다.",
          ],
        },
      ],
    },
  ],
  pages: [
    {
      h3: "Main > 나의 대시보드",
      p: "새로 업데이트된 데이터 셋 또는 작업 셋 리스트(최대 10개)를 확인할 수 있습니다.",
      desc: [
        {
          ul: [
            "수집환경 입력 : 최근 수집 또는 등록된 데이터 셋 리스트",
            "학습 데이터 선별 : 최근 수집 또는 등록된 데이터 셋 리스트",
            "데이터 작업 - 작업대기 : 최근 작업대기인 데이터 셋 리스트",
            "데이터 작업 - 작업중 : 최근 작업중인 데이터 셋 리스트",
            "데이터 작업 - 검수반려 : 최근 검수반려된 데이터 셋 리스트",
            "데이터 작업 - 작업회수 : 최근 작업회수된 데이터 셋 리스트",
            "데이터 작업 - QnA 답변 : 최근 등록된 QnA 답변이 있는 데이터 셋 리스트",
            "데이터 검수 - 검수대기 : 최근 검수 요청이 있는 데이터가 있는 데이터 셋 리스트",
            "데이터 검수 - 작업회수 : 최근 작업회수 요청이 있는 데이터가 있는 데이터 셋 리스트",
            "데이터 검수 - QnA 질문 : 최근 등록된 QnA 질문이 있는 데이터 셋 리스트",
          ],
        },
      ],
    },
    {
      permission: "clct_extr",
      h3: "Collection > 데이터 추출",
      p: "log 파일을 업로드하고 데이터를 추출할 수 있습니다.",
      desc: [
        {
          h4: "데이터 셋 리스트",
          ol: [
            {
              btn: ["log 데이터 업로드"],
              p: [
                "업로드할 파일을 선택 한 후 모달창에 나오는 수집정보를 확인합니다.",
                "업로드 완료 후에는 수집정보를 수정할 수 없으니 업로드 전 반드시 확인한 후 업로드를 시작합니다.",
              ],
            },
            {
              btn: ["등록완료"],
              p: ["데이터의 추출이 전부 완료되면 데이터 셋을 추출 완료합니다."],
            },
          ],
        },
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["데이터 추출"],
              pos: "추출대기 탭",
              p: ["업로드한 파일로부터 원본 데이터들을 추출합니다."],
            },
            {
              btn: ["데이터 삭제", "secondary"],
              pos: "추출대기 탭",
              p: ["업로드한 데이터를 삭제합니다."],
            },
          ],
        },
      ],
    },
    {
      permission: "clct_rgs",
      h3: "Collection > 데이터 등록",
      p: "가상, 오픈 데이터 등 데이터를 직접 등록할 수 있습니다.",
      desc: [
        {
          h4: "데이터 셋 리스트",
          ol: [
            {
              btn: ["데이터 셋 등록"],
              p: [
                "수접 정보를 입력하여 데이터 셋을 생성합니다.",
                " ",
                "- 수집경로 : 수집경로를 선택합니다. (P: 항만, S: 운항)",
                "- (P)항만 : (경로: 항만만 해당) 항만을 선택합니다.",
                "- (P)선석 : (경로: 항만만 해당) 선석을 선택합니다.",
                "- (S)선박 또는 지역 : (경로: 운항만 해당) 선박 또는 지역을 입력합니다.",
                "- 모듈명 : 모듈명을 입력합니다.",
                "- 수집날짜 : 수집날짜를 선택합니다.",
              ],
            },
            {
              btn: ["데이터 셋 등록완료"],
              p: ["데이터 셋의 등록을 완료합니다."],
            },
            {
              btn: ["데이터 셋 삭제", "secondary"],
              p: ["등록한 데이터 셋을 삭제합니다."],
            },
          ],
        },
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["원본 데이터 업로드"],
              p: ["원본 데이터를 업로드합니다.", " ", "- 센서번호 : 센서번호를 입력합니다."],
            },
            {
              btn: ["원본 데이터 삭제", "secondary"],
              p: [
                "업로드한 원본 데이터를 삭제합니다.",
                "삭제된 데이터는 복구할 수 없습니다.",
                " ",
                "- 비밀번호 : 계정 비밀번호를 입력해야 데이터를 삭제할 수 있습니다.",
              ],
            },
          ],
        },
      ],
    },
    {
      permission: "cam_cond",
      h3: "Camera > 수집환경 입력",
      p: "카메라 데이터의 수집환경을 입력할 수 있습니다.",
      desc: [
        {
          h4: "데이터 리스트",
          ol: [
            {
              btns: [
                ["눈", "unfill", true],
                ["비", "unfill", true],
                ["안개", "unfill", true],
                ["조명", "unfill", true],
                ["역광", "unfill", true],
                ["밤", "unfill", true],
              ],
              pos: "수집환경 입력 대기, 수집환경 입력 완료 탭 - 썸네일 뷰",
              p: [
                "- 수집환경 : 눈, 비, 안개, 조명, 역광, 밤",
                " ",
                "데이터에 수집환경 태그를 추가할 수 있습니다.",
                "데이터 선택 후 단축키 s, r, f, l, b, n로도 태그를 추가할 수 있습니다.",
              ],
            },
            {
              btn: ["수집환경 입력"],
              pos: "수집환경 입력 대기 탭 - 썸네일 뷰",
              p: ["태그를 추가한 후 수집환경 정보를 저장합니다."],
            },
            {
              btn: ["수집환경 수정"],
              pos: "수집환경 입력 완료 탭 - 썸네일 뷰",
              p: ["태그를 추가한 후 수집환경 정보를 수정합니다."],
            },
            {
              btn: ["수집환경 리셋", "secondary"],
              pos: "수집환경 입력 완료 탭",
              p: ["입력한 수집환경 정보를 리셋합니다."],
            },
            {
              btn: ["데이터 삭제", "secondary"],
              p: [
                "손상된 데이터를 삭제할 수 있습니다.",
                "삭제된 데이터는 복구할 수 없습니다.",
                " ",
                "- 비밀번호 : 계정 비밀번호를 입력해야 데이터를 삭제할 수 있습니다.",
              ],
            },
          ],
        },
      ],
    },
    // cam seg
    {
      permission: "cam_seg_vld",
      h3: "Camera Segmentation > 학습 데이터 선별",
      p: "평가용 및 학습용 데이터를 선별할 수 있습니다.",
      desc: [
        {
          h4: "데이터 셋 리스트",
          ol: [
            {
              btn: ["우선순위 설정"],
              p: ["데이터 셋에 배분 우선순위를 설정할 수 있습니다."],
            },
            {
              btn: ["우선순위 리셋", "secondary"],
              p: ["우선순위를 리셋할 수 있습니다."],
            },
            {
              btn: ["선별완료"],
              pos: "대기 탭",
              p: ["선별을 전부 완료하면 데이터 셋 선별을 완료합니다."],
            },
          ],
        },
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["학습용 데이터 선정 탭으로 이동"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: [
                "평가 데이터 선정을 건너뛰고 [평가 데이터 선정 탭]의 데이터가 전부 [학습용 데이터 선정 탭]으로 이동합니다.",
              ],
            },
            {
              btn: ["평가용 태그", "unfill"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: ["평가용 데이터로 선정되면 태그를 추가합니다."],
            },
            {
              btn: ["선별"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: ["태그를 추가한 후 선별 정보를 저장합니다."],
            },
            {
              btn: ["모델 선택 및 추론"],
              pos: "학습용 데이터 선정 탭",
              p: ["모델을 선택하고 추론을 진행합니다."],
            },
            {
              btn: ["학습용 태그", "unfill"],
              pos: "학습용 데이터 선정 탭 - 썸네일 뷰",
              p: ["학습용 데이터로 선별되면 태그를 추가합니다."],
            },
            {
              btn: ["선별"],
              pos: "학습용 데이터 선정 탭 - 썸네일 뷰",
              p: ["태그를 추가한 후 선별 정보를 저장합니다."],
            },
            {
              btn: ["선별 리셋", "secondary"],
              pos: "학습용 데이터 선정 탭, 선별완료 탭",
              p: ["선별 정보를 바로 이전 단계로 되돌립니다."],
            },
            {
              btn: ["데이터 삭제", "secondary"],
              p: [
                "손상된 데이터를 삭제할 수 있습니다.",
                "삭제된 데이터는 복구할 수 없습니다.",
                " ",
                "- 비밀번호 : 계정 비밀번호를 입력해야 데이터를 삭제할 수 있습니다.",
              ],
            },
          ],
        },
      ],
    },
    {
      permission: "cam_seg_work",
      h3: "Camera Segmentation > 작업",
      p: "데이터의 작업/검수 상태를 확인하고 데이터를 다운로드/업로드할 수 있습니다.",
      desc: [
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["작업회수 요청", "secondary"],
              pos: "전체 탭",
              p: [
                "작업회수를 요청할 수 있습니다.",
                "- 요청 사유 : 작업회수 요청 사유를 선택합니다.",
              ],
            },
            {
              btn: ["원본 데이터 다운로드"],
              pos: "작업대기 탭",
              p: ["원본 데이터를 다운로드합니다."],
            },
            {
              btn: ["작업시작"],
              pos: "작업대기 탭",
              p: ["원본 데이터 다운로드 후 작업을 시작합니다."],
            },
            {
              btn: ["psd 데이터 업로드"],
              pos: "작업중 탭",
              p: ["작업한 라벨 데이터를 업로드합니다."],
            },
            {
              btn: ["에러검사"],
              pos: "작업중 탭",
              p: ["업로드한 라벨 데이터에 대해 에러검사를 진행합니다."],
            },
            {
              btn: ["에러 참고 데이터 다운로드"],
              pos: "작업중 탭",
              p: ["에러가 존재할 경우 에러 참고 데이터를 다운로드할 수 있습니다."],
            },
            {
              btn: ["psd 데이터 다운로드"],
              pos: "작업중 탭",
              p: ["라벨 데이터를 다운로드할 수 있습니다."],
            },
            {
              btn: ["검수요청"],
              pos: "작업중 탭",
              p: ["에러검사에 통과 후 검수를 요청할 수 있습니다."],
            },
            {
              btn: ["psd 데이터 업로드"],
              pos: "검수반려 탭",
              p: [
                "검수반려가 됐을 경우 수정한 라벨 데이터를 업로드합니다.",
                "업로드가 완료되면 작업중 탭으로 이동하여 다시 에러검사를 진행합니다.",
              ],
            },
          ],
        },
        {
          h4: "작업 가이드 및 QnA",
          ol: [
            {
              btn: ["참고 영상 리스트", "unfill"],
              pos: "데이터 셋 가이드 탭",
              p: ["참고 영상 리스트가 출력됩니다."],
            },
            {
              btn: ["참고 영상 다운로드"],
              pos: "참고 영상 리스트 모달창 내",
              p: ["선택한 참고 영상을 다운로드할 수 있습니다."],
            },
            {
              btn: ["작업 가이드 다운로드"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: ["작업 가이드를 다운로드할 수 있습니다."],
            },
            {
              btn: ["질문등록"],
              pos: "QnA 탭",
              p: [
                "작업과 관련된 QnA를 등록할 수 있습니다.",
                " ",
                "- 작업 셋 선택 : 질문에 해당되는 작업 셋을 선택합니다.",
                "- 이미지 업로드 : 질문과 관련된 이미지를 업로드할 수 있습니다.",
                "- 질문을 입력해 주세요. : 질문을 입력합니다.",
              ],
            },
            {
              btn: ["질문삭제", "secondary"],
              pos: "QnA 탭",
              p: ["등록한 QnA를 삭제할 수 있습니다."],
            },
          ],
        },
      ],
    },
    {
      permission: "cam_seg_check",
      h3: "Camera Segmentation > 데이터 검수",
      p: "데이터의 작업/검수 상태를 확인하고 데이터를 다운로드/업로드할 수 있습니다.",
      desc: [
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["작업 셋 리스트", "unfill"],
              p: ["작업 셋 리스트가 출력됩니다."],
            },
            {
              btn: ["생성"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 생성합니다.", " ", "- 작업 셋 이름 : 작업 셋 이름을 입력합니다."],
            },
            {
              btn: ["수정"],
              pos: "작업 셋 리스트 모달창 내",
              p: [
                "작업 셋 이름을 수정합니다.",
                " ",
                "- 작업 셋 이름 : 수정할 작업 셋 이름을 입력합니다.",
              ],
            },
            {
              btn: ["배분"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 배분합니다."],
            },
            {
              btn: ["회수", "secondary"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 회수합니다."],
            },
            {
              btn: ["삭제", "secondary"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 삭제합니다."],
            },
            {
              btn: ["참고 영상 리스트", "outline"],
              pos: "전체 탭",
              p: ["참고 영상 리스트가 출력됩니다."],
            },
            {
              btn: ["참고 영상 생성"],
              pos: "참고 영상 리스트 모달창 내",
              p: [
                "선택한 센서의 원본 이미지로 영상을 생성합니다.",
                " ",
                "- 센서번호 : 센서번호를 선택합니다.",
              ],
            },
            {
              btn: ["참고 영상 다운로드"],
              pos: "참고 영상 리스트 모달창 내",
              p: ["선택한 참고 영상을 다운로드할 수 있습니다."],
            },
            {
              btn: ["전체 원본 데이터 다운로드"],
              pos: "전체 탭",
              p: [
                "조회된 데이터 전체의 원본 데이터를 다운로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["원본 데이터 다운로드"],
              pos: "전체 탭",
              p: [
                "선택한 데이터의 원본 데이터를 다운로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["작업회수", "secondary"],
              pos: "전체 탭",
              p: ["검수자가 직접 배분된 작업을 회수할 수 있습니다."],
            },
            {
              btn: ["배분정보 입력 및 삭제"],
              pos: "배분대기 탭",
              p: [
                "데이터의 배분정보를 입력합니다.",
                " ",
                "- 난이도 : 난이도를 선택합니다.",
                "- 단가(장) : 데이터 장당 단가를 입력합니다.",
                "- 검수자 : 검수자를 선택합니다.",
                "- 작업자 : 작업자를 선택합니다.",
                "- 라벨버전 : 라벨버전을 선택합니다.",
              ],
            },
            {
              btn: ["작업 셋에 추가"],
              p: [
                "데이터를 선택한 작업 셋에 추가합니다.",
                " ",
                "- 작업 셋 이름 : 작업 셋 이름을 선택합니다.",
              ],
            },
            {
              btn: ["작업 셋에서 제외", "secondary"],
              pos: "배분대기 탭",
              p: ["데이터를 작업 셋에서 뺍니다."],
            },
            {
              btn: ["데이터 삭제", "secondary"],
              pos: "배분대기 탭",
              p: [
                "손상된 데이터를 삭제할 수 있습니다.",
                "삭제된 데이터는 복구할 수 없습니다.",
                " ",
                "- 비밀번호 : 계정 비밀번호를 입력해야 데이터를 삭제할 수 있습니다.",
              ],
            },
            {
              btn: ["배분회수", "secondary"],
              pos: "작업대기 탭",
              p: ["배분된 작업을 회수합니다."],
            },
            {
              btn: ["검수자 변경 요청"],
              pos: "작업대기, 작업중, 검수대기, 검수반려 탭",
              p: [
                "검수자 변경 요청을 할 수 있습니다.",
                " ",
                "- 검수자 : 변경할 검수자를 선택합니다.",
              ],
            },
            {
              btn: ["작업시작 취소", "secondary"],
              pos: "작업중 탭",
              p: ["작업시작을 취소하고 작업대기로 변경합니다."],
            },
            {
              btn: ["검수통과"],
              pos: "검수대기 탭",
              p: ["데이터를 선택하여 검수통과 처리합니다."],
            },
            {
              btn: ["검수반려"],
              pos: "검수대기 탭",
              p: ["데이터를 선택하여 검수반려 처리합니다."],
            },
            {
              btn: ["전체 psd 데이터 다운로드"],
              pos: "검수대기, 검수통과 탭",
              p: [
                "조회된 데이터 전체의 라벨 데이터를 다운로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["psd 데이터 다운로드"],
              pos: "검수대기, 검수통과 탭",
              p: [
                "선택한 데이터의 라벨 데이터를 다운로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["검수리셋", "secondary"],
              pos: "검수반려, 검수통과 탭",
              p: [
                "검수를 리셋하여 검수 대기로 되돌립니다.",
                "검수 완료된 작업 셋은 검수리셋을 할 수 없습니다.",
              ],
            },
            {
              btn: ["psd 데이터 업로드"],
              pos: "검수통과 탭",
              p: [
                "검수 통과된 라벨 데이터를 검수자가 업로드할 수 있으며,",
                "기존 라벨 데이터 파일이 새로 업로드된 파일로 갱신됩니다.",
              ],
            },
            {
              btn: ["대금지급 날짜 입력"],
              pos: "검수통과 탭",
              p: ["대금지급 날짜를 입력할 수 있습니다."],
            },
            {
              btn: ["학습반영"],
              pos: "검수통과 탭",
              p: ["학습리스트에 추가합니다."],
            },
            {
              btn: ["작업회수 확인", "secondary"],
              pos: "작업회수 탭",
              p: [
                "작업자가 작업회수 요청한 데이터를 작업회수 완료 처리할 수 있습니다.",
                "작업회수 완료 처리된 데이터는 작업배분 리스트에서 다시 배분할 수 있습니다.",
                " ",
                "- 회수 수락 여부 : 회수 수락 여부를 선택합니다.",
              ],
            },
            {
              btn: ["전체 검수자 변경 확인"],
              pos: "검수자 변경 탭",
              p: [
                "본인에게 들어온 검수자 변경 요청 전체를 수락하거나 거절할 수 있습니다.",
                " ",
                "- 변경 수락 여부 : 변경 수락 여부를 선택합니다.",
              ],
            },
            {
              btn: ["검수자 변경 확인"],
              pos: "검수자 변경 탭",
              p: [
                "본인에게 들어온 검수자 변경 요청을 수락하거나 거절할 수 있습니다.",
                " ",
                "- 변경 수락 여부 : 변경 수락 여부를 선택합니다.",
              ],
            },
          ],
        },
        {
          h4: "작업 가이드 및 QnA",
          ol: [
            {
              btn: ["작업 가이드 업로드"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: ["작업 가이드를 업로드합니다."],
            },
            {
              btn: ["작업 가이드 다운로드"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: [
                "작업 가이드를 업로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["작업 가이드 삭제", "secondary"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: ["작업 가이드를 삭제합니다."],
            },
            {
              btn: ["답변등록"],
              pos: "QnA 탭, QnA 카드 내",
              p: [
                "작업자가 등록한 QnA에 답변을 등록할 수 있습니다.",
                " ",
                "- 이미지 업로드 : 답변과 관련된 이미지를 업로드할 수 있습니다. 기존에 등록된 이미지가 갱신됩니다.",
                "- 답변을 입력해 주세요. : 답변을 입력합니다.",
              ],
            },
          ],
        },
      ],
    },
    {
      permission: "cam_seg_lrn",
      h3: "Camera Segmentation > 학습 데이터 관리",
      p: "학습 리스트를 관리할 수 있습니다.",
      desc: [
        {
          h4: "데이터 셋 리스트",
          ol: [
            {
              btn: ["학습 리스트 다운로드"],
              p: ["학습 리스트 정보를 다운로드합니다."],
            },
            {
              btn: ["mask 데이터 생성"],
              p: ["mask 데이터를 생성합니다."],
            },
          ],
        },
      ],
    },
    // cam det
    {
      permission: "cam_det_vld",
      h3: "Camera Detection > 학습 데이터 선별",
      p: "평가용 및 학습용 데이터를 선별할 수 있습니다.",
      desc: [
        {
          h4: "데이터 셋 리스트",
          ol: [
            {
              btn: ["우선순위 설정"],
              p: ["데이터 셋에 배분 우선순위를 설정할 수 있습니다."],
            },
            {
              btn: ["우선순위 리셋", "secondary"],
              p: ["우선순위를 리셋할 수 있습니다."],
            },
            {
              btn: ["선별완료"],
              pos: "대기 탭",
              p: ["선별을 전부 완료하면 데이터 셋 선별을 완료합니다."],
            },
          ],
        },
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["학습용 데이터 선정 탭으로 이동"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: [
                "평가 데이터 선정을 건너뛰고 [평가 데이터 선정 탭]의 데이터가 전부 [학습용 데이터 선정 탭]으로 이동합니다.",
              ],
            },
            {
              btn: ["평가용 태그", "unfill"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: ["평가용 데이터로 선정되면 태그를 추가합니다."],
            },
            {
              btn: ["선별"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: ["태그를 추가한 후 선별 정보를 저장합니다."],
            },
            {
              btn: ["모델 선택 및 추론"],
              pos: "학습용 데이터 선정 탭",
              p: ["모델을 선택하고 추론을 진행합니다."],
            },
            {
              btn: ["학습용 태그", "unfill"],
              pos: "학습용 데이터 선정 탭 - 썸네일 뷰",
              p: ["학습용 데이터로 선별되면 태그를 추가합니다."],
            },
            {
              btn: ["선별"],
              pos: "학습용 데이터 선정 탭 - 썸네일 뷰",
              p: ["태그를 추가한 후 선별 정보를 저장합니다."],
            },
            {
              btn: ["선별 리셋", "secondary"],
              pos: "학습용 데이터 선정 탭, 선별완료 탭",
              p: ["선별 정보를 바로 이전 단계로 되돌립니다."],
            },
            {
              btn: ["데이터 삭제", "secondary"],
              p: [
                "손상된 데이터를 삭제할 수 있습니다.",
                "삭제된 데이터는 복구할 수 없습니다.",
                " ",
                "- 비밀번호 : 계정 비밀번호를 입력해야 데이터를 삭제할 수 있습니다.",
              ],
            },
          ],
        },
      ],
    },
    {
      permission: "cam_det_work",
      h3: "Camera Detection > 작업",
      p: "데이터의 작업/검수 상태를 확인하고 데이터를 다운로드/업로드할 수 있습니다.",
      desc: [
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["작업회수 요청", "secondary"],
              pos: "전체 탭",
              p: [
                "작업회수를 요청할 수 있습니다.",
                "- 요청 사유 : 작업회수 요청 사유를 선택합니다.",
              ],
            },
            {
              btn: ["원본 데이터 다운로드"],
              pos: "작업대기 탭",
              p: ["원본 데이터를 다운로드합니다."],
            },
            {
              btn: ["작업시작"],
              pos: "작업대기 탭",
              p: ["원본 데이터 다운로드 후 작업을 시작합니다."],
            },
            {
              btn: ["라벨 데이터 업로드"],
              pos: "작업중 탭",
              p: ["작업한 라벨 데이터를 업로드합니다."],
            },
          ],
        },
        {
          h4: "작업 가이드 및 QnA",
          ol: [
            {
              btn: ["작업 가이드 다운로드"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: ["작업 가이드를 다운로드할 수 있습니다."],
            },
            {
              btn: ["질문등록"],
              pos: "QnA 탭",
              p: [
                "작업과 관련된 QnA를 등록할 수 있습니다.",
                " ",
                "- 작업 셋 선택 : 질문에 해당하는 작업 셋을 선택합니다.",
                "- 이미지 업로드 : 질문과 관련된 이미지를 업로드할 수 있습니다.",
                "- 질문을 입력해 주세요. : 질문을 입력합니다.",
              ],
            },
            {
              btn: ["질문삭제", "secondary"],
              pos: "QnA 탭",
              p: ["등록한 QnA를 삭제할 수 있습니다."],
            },
          ],
        },
      ],
    },
    {
      permission: "cam_det_check",
      h3: "Camera Detection > 데이터 검수",
      p: "데이터의 작업/검수 상태를 확인하고 데이터를 다운로드/업로드할 수 있습니다.",
      desc: [
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["작업 셋 리스트", "unfill"],
              p: ["작업 셋 리스트가 출력됩니다."],
            },
            {
              btn: ["생성"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 생성합니다.", " ", "- 작업 셋 이름 : 작업 셋 이름을 입력합니다."],
            },
            {
              btn: ["수정"],
              pos: "작업 셋 리스트 모달창 내",
              p: [
                "작업 셋 이름을 수정합니다.",
                " ",
                "- 작업 셋 이름 : 수정할 작업 셋 이름을 입력합니다.",
              ],
            },
            {
              btn: ["배분"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 배분합니다."],
            },
            {
              btn: ["회수", "secondary"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 회수합니다."],
            },
            {
              btn: ["삭제", "secondary"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 삭제합니다."],
            },
            {
              btn: ["작업회수", "secondary"],
              pos: "전체 탭",
              p: ["검수자가 직접 배분된 작업을 회수할 수 있습니다."],
            },
            {
              btn: ["배분정보 입력 및 삭제"],
              pos: "배분대기 탭",
              p: [
                "데이터의 배분정보를 입력합니다.",
                " ",
                "- 난이도 : 난이도를 선택합니다.",
                "- 단가(객체) : 데이터 객체당 단가를 입력합니다.",
                "- 검수자 : 검수자를 선택합니다.",
                "- 작업자 : 작업자를 선택합니다.",
                "- 라벨버전 : 라벨버전을 선택합니다.",
                "- 트래킹 여부 : 트래킹 여부를 선택합니다.",
              ],
            },
            {
              btn: ["작업 셋에 추가"],
              p: [
                "데이터를 선택한 작업 셋에 추가합니다.",
                " ",
                "- 작업 셋 이름 : 작업 셋 이름을 선택합니다.",
              ],
            },
            {
              btn: ["작업 셋에서 제외", "secondary"],
              pos: "배분대기 탭",
              p: ["데이터를 작업 셋에서 뺍니다."],
            },
            {
              btn: ["데이터 삭제", "secondary"],
              pos: "배분대기 탭",
              p: [
                "손상된 데이터를 삭제할 수 있습니다.",
                "삭제된 데이터는 복구할 수 없습니다.",
                " ",
                "- 비밀번호 : 계정 비밀번호를 입력해야 데이터를 삭제할 수 있습니다.",
              ],
            },
            {
              btn: ["배분회수", "secondary"],
              pos: "작업대기 탭",
              p: ["배분된 작업을 회수합니다."],
            },
            {
              btn: ["검수자 변경 요청"],
              pos: "작업대기, 작업중 탭",
              p: [
                "검수자 변경 요청을 할 수 있습니다.",
                " ",
                "- 검수자 : 변경할 검수자를 선택합니다.",
              ],
            },
            {
              btn: ["작업시작 취소", "secondary"],
              pos: "작업중 탭",
              p: ["작업시작을 취소하고 작업대기로 변경합니다."],
            },
            {
              btn: ["라벨 데이터 업로드"],
              pos: "작업중 탭",
              p: ["검수완료한 라벨 데이터를 업로드합니다."],
            },
            {
              btn: ["검수통과"],
              pos: "작업중 탭",
              p: ["데이터를 선택하여 검수통과 처리합니다."],
            },
            {
              btn: ["전체 원본+라벨 데이터 다운로드"],
              pos: "작업중, 검수통과 탭",
              p: [
                "조회된 데이터 전체의 원본+라벨 데이터를 다운로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["원본+라벨 데이터 다운로드"],
              pos: "작업중, 검수통과 탭",
              p: [
                "선택한 데이터의 원본+라벨 데이터를 다운로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["검수리셋", "secondary"],
              pos: "검수통과 탭",
              p: [
                "검수를 리셋하여 검수 대기로 되돌립니다.",
                "검수 완료된 작업 셋은 검수리셋을 할 수 없습니다.",
              ],
            },
            {
              btn: ["라벨 데이터 업로드"],
              pos: "검수통과 탭",
              p: [
                "검수통과된 라벨 데이터를 검수자가 업로드할 수 있으며,",
                "기존 라벨 데이터 파일이 새로 업로드된 파일로 갱신됩니다.",
              ],
            },
            {
              btn: ["대금지급 날짜 입력"],
              pos: "검수통과 탭",
              p: ["대금지급 날짜를 입력할 수 있습니다."],
            },
            {
              btn: ["작업회수 확인", "secondary"],
              pos: "작업회수 탭",
              p: [
                "작업자가 작업회수 요청한 데이터를 작업회수 완료 처리할 수 있습니다.",
                "작업회수 완료 처리된 데이터는 작업배분 리스트에서 다시 배분할 수 있습니다.",
                " ",
                "- 회수 수락 여부 : 회수 수락 여부를 선택합니다.",
              ],
            },
            {
              btn: ["전체 검수자 변경 확인"],
              pos: "검수자 변경 탭",
              p: [
                "본인에게 들어온 검수자 변경 요청 전체를 수락하거나 거절할 수 있습니다.",
                " ",
                "- 변경 수락 여부 : 변경 수락 여부를 선택합니다.",
              ],
            },
            {
              btn: ["검수자 변경 확인"],
              pos: "검수자 변경 탭",
              p: [
                "본인에게 들어온 검수자 변경 요청을 수락하거나 거절할 수 있습니다.",
                " ",
                "- 변경 수락 여부 : 변경 수락 여부를 선택합니다.",
              ],
            },
          ],
        },
        {
          h4: "작업 가이드 및 QnA",
          ol: [
            {
              btn: ["작업 가이드 업로드"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: ["작업 가이드를 업로드합니다."],
            },
            {
              btn: ["작업 가이드 다운로드"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: [
                "작업 가이드를 업로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["작업 가이드 삭제", "secondary"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: ["작업 가이드를 삭제합니다."],
            },
            {
              btn: ["답변등록"],
              pos: "QnA 탭, QnA 카드 내",
              p: [
                "작업자가 등록한 QnA에 답변을 등록할 수 있습니다.",
                " ",
                "- 이미지 업로드 : 답변과 관련된 이미지를 업로드할 수 있습니다. 기존에 등록된 이미지가 갱신됩니다.",
                "- 답변을 입력해 주세요. : 답변을 입력합니다.",
              ],
            },
          ],
        },
      ],
    },
    {
      permission: "cam_det_lrn",
      h3: "Camera Detection > 학습 데이터 관리",
      p: "학습 리스트를 관리할 수 있습니다.",
      desc: [
        {
          h4: "데이터 셋 리스트",
          ol: [
            {
              btn: ["학습 리스트 다운로드"],
              p: ["학습 리스트 정보를 다운로드합니다."],
            },
          ],
        },
      ],
    },
    // rad seg
    {
      permission: "rad_seg_vld",
      h3: "Radar Segmentation > 학습 데이터 선별",
      p: "평가용 및 학습용 데이터를 선별할 수 있습니다.",
      desc: [
        {
          h4: "데이터 셋 리스트",
          ol: [
            {
              btn: ["우선순위 설정"],
              p: ["데이터 셋에 배분 우선순위를 설정할 수 있습니다."],
            },
            {
              btn: ["우선순위 리셋", "secondary"],
              p: ["우선순위를 리셋할 수 있습니다."],
            },
            {
              btn: ["선별완료"],
              pos: "대기 탭",
              p: ["선별을 전부 완료하면 데이터 셋 선별을 완료합니다."],
            },
          ],
        },
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["센서설정 데이터 리스트", "unfill"],
              pos: "전체 탭",
              p: ["센서설정 데이터 리스트가 출력됩니다."],
            },
            {
              btn: ["센서설정 데이터 업로드"],
              pos: "센서설정 데이터 리스트 모달창 내",
              p: [
                "Radar 데이터의 센서설정 정보가 담긴 파일을 업로드합니다.",
                " ",
                "- 센서번호 : 센서번호를 선택합니다.",
              ],
            },
            {
              btn: ["참고 데이터 업로드"],
              pos: "전체 탭",
              p: [
                "참고 데이터를 업로드합니다.",
                "원본 데이터명과 일치하는 참고 데이터만 업로드할 수 있습니다.",
              ],
            },
            {
              btn: ["GPS/IMU 데이터 다운로드"],
              pos: "전체 탭",
              p: ["GPS/IMU 데이터를 다운로드합니다."],
            },
            {
              btn: ["전체 원본 데이터 다운로드"],
              pos: "전체 탭",
              p: [
                "조회된 데이터 전체의 원본 데이터를 다운로드 합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["원본 데이터 다운로드"],
              pos: "전체 탭",
              p: [
                "선택한 데이터의 원본 데이터를 다운로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["학습용 데이터 선정 탭으로 이동"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: [
                "평가 데이터 선정을 건너뛰고 [평가 데이터 선정 탭]의 데이터가 전부 [학습용 데이터 선정 탭]으로 이동합니다.",
              ],
            },
            {
              btn: ["평가용 태그", "unfill"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: ["평가용 데이터로 선정되면 태그를 추가합니다."],
            },
            {
              btn: ["선별"],
              pos: "평가용 데이터 선정 탭 - 썸네일 뷰",
              p: ["태그를 추가한 후 선별 정보를 저장합니다."],
            },
            {
              btn: ["학습용 태그", "unfill"],
              pos: "학습용 데이터 선정 탭 - 썸네일 뷰",
              p: ["학습용 데이터로 선별되면 태그를 추가합니다."],
            },
            {
              btn: ["선별"],
              pos: "학습용 데이터 선정 탭 - 썸네일 뷰",
              p: ["태그를 추가한 후 선별 정보를 저장합니다."],
            },
            {
              btn: ["선별 리셋", "secondary"],
              pos: "학습용 데이터 선정 탭, 선별완료 탭",
              p: ["선별 정보를 바로 이전 단계로 되돌립니다."],
            },
            {
              btn: ["데이터 삭제", "secondary"],
              p: [
                "손상된 데이터를 삭제할 수 있습니다.",
                "삭제된 데이터는 복구할 수 없습니다.",
                " ",
                "- 비밀번호 : 계정 비밀번호를 입력해야 데이터를 삭제할 수 있습니다.",
              ],
            },
          ],
        },
      ],
    },
    {
      permission: "rad_seg_work",
      h3: "Radar Segmentation > 작업",
      p: "데이터의 작업/검수 상태를 확인하고 데이터를 다운로드/업로드할 수 있습니다.",
      desc: [
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["작업회수 요청", "secondary"],
              pos: "전체 탭",
              p: [
                "작업회수를 요청할 수 있습니다.",
                "- 요청 사유 : 작업회수 요청 사유를 선택합니다.",
              ],
            },
            {
              btn: ["원본 데이터 다운로드"],
              pos: "작업대기 탭",
              p: ["원본 데이터를 다운로드합니다."],
            },
            {
              btn: ["참고 데이터 다운로드"],
              pos: "작업대기 탭",
              p: ["참고 데이터를 다운로드합니다."],
            },
            {
              btn: ["가공용 데이터 다운로드"],
              pos: "작업대기 탭",
              p: ["가공용 데이터를 다운로드합니다."],
            },
            {
              btn: ["작업시작"],
              pos: "작업대기 탭",
              p: ["원본 데이터 다운로드 후 작업을 시작합니다."],
            },
          ],
        },
        {
          h4: "작업 가이드 및 QnA",
          ol: [
            {
              btn: ["참고 영상 리스트", "unfill"],
              pos: "데이터 셋 가이드 탭",
              p: ["참고 영상 리스트가 출력됩니다."],
            },
            {
              btn: ["참고 영상 다운로드"],
              pos: "참고 영상 리스트 모달창 내",
              p: ["선택한 참고 영상을 다운로드할 수 있습니다."],
            },
            {
              btn: ["작업 가이드 다운로드"],
              pos: "작업 가이드 탭",
              p: ["작업 가이드를 다운로드할 수 있습니다."],
            },
            {
              btn: ["질문등록"],
              pos: "QnA 탭",
              p: [
                "작업과 관련된 QnA를 등록할 수 있습니다.",
                " ",
                "- 작업 셋 선택 : 질문에 해당하는 작업 셋을 선택합니다.",
                "- 이미지 업로드 : 질문과 관련된 이미지를 업로드할 수 있습니다.",
                "- 질문을 입력해 주세요. : 질문을 입력합니다.",
              ],
            },
            {
              btn: ["질문삭제", "secondary"],
              pos: "QnA 탭",
              p: ["등록한 QnA를 삭제할 수 있습니다."],
            },
          ],
        },
      ],
    },
    {
      permission: "rad_seg_check",
      h3: "Radar Segmentation > 데이터 검수",
      p: "데이터의 작업/검수 상태를 확인하고 데이터를 다운로드/업로드할 수 있습니다.",
      desc: [
        {
          h4: "데이터 리스트",
          ol: [
            {
              btn: ["작업 셋 리스트", "unfill"],
              p: ["작업 셋 리스트가 출력됩니다."],
            },
            {
              btn: ["생성"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 생성합니다.", " ", "- 작업 셋 이름 : 작업 셋 이름을 입력합니다."],
            },
            {
              btn: ["수정"],
              pos: "작업 셋 리스트 모달창 내",
              p: [
                "작업 셋 이름을 수정합니다.",
                " ",
                "- 작업 셋 이름 : 수정할 작업 셋 이름을 입력합니다.",
              ],
            },
            {
              btn: ["배분"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 배분합니다."],
            },
            {
              btn: ["회수", "secondary"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 회수합니다."],
            },
            {
              btn: ["삭제", "secondary"],
              pos: "작업 셋 리스트 모달창 내",
              p: ["작업 셋을 삭제합니다."],
            },
            {
              btn: ["참고 영상 리스트", "outline"],
              pos: "전체 탭",
              p: ["참고 영상 리스트가 출력됩니다."],
            },
            {
              btn: ["참고 영상 생성"],
              pos: "참고 영상 리스트 모달창 내",
              p: [
                "선택한 센서의 원본 이미지로 영상을 생성합니다.",
                " ",
                "- 센서번호 : 센서번호를 선택합니다.",
              ],
            },
            {
              btn: ["참고 영상 다운로드"],
              pos: "참고 영상 리스트 모달창 내",
              p: ["선택한 참고 영상을 다운로드할 수 있습니다."],
            },
            {
              btn: ["작업회수", "secondary"],
              pos: "전체 탭",
              p: ["검수자가 직접 배분된 작업을 회수할 수 있습니다."],
            },
            {
              btn: ["배분정보 입력 및 삭제"],
              pos: "배분대기 탭",
              p: [
                "데이터의 배분정보를 입력합니다.",
                " ",
                "- 난이도 : 난이도를 선택합니다.",
                "- 단가(장) : 데이터 장당 단가를 입력합니다.",
                "- 검수자 : 검수자를 선택합니다.",
                "- 작업자 : 작업자를 선택합니다.",
                "- 라벨버전 : 라벨버전을 선택합니다.",
              ],
            },
            {
              btn: ["작업 셋에 추가"],
              p: [
                "데이터를 선택한 작업 셋에 추가합니다.",
                " ",
                "- 작업 셋 이름 : 작업 셋 이름을 선택합니다.",
              ],
            },
            {
              btn: ["작업 셋에서 제외", "secondary"],
              pos: "배분대기 탭",
              p: ["데이터를 작업 셋에서 뺍니다."],
            },
            {
              btn: ["데이터 삭제", "secondary"],
              pos: "배분대기 탭",
              p: [
                "손상된 데이터를 삭제할 수 있습니다.",
                "삭제된 데이터는 복구할 수 없습니다.",
                " ",
                "- 비밀번호 : 계정 비밀번호를 입력해야 데이터를 삭제할 수 있습니다.",
              ],
            },
            {
              btn: ["배분회수", "secondary"],
              pos: "작업대기 탭",
              p: ["배분된 작업을 회수합니다."],
            },
            {
              btn: ["검수자 변경 요청"],
              pos: "작업대기, 작업중 탭",
              p: [
                "검수자 변경 요청을 할 수 있습니다.",
                " ",
                "- 검수자 : 변경할 검수자를 선택합니다.",
              ],
            },
            {
              btn: ["작업시작 취소", "secondary"],
              pos: "작업중 탭",
              p: ["작업시작을 취소하고 작업대기로 변경합니다."],
            },
            {
              btn: ["라벨 데이터 업로드"],
              pos: "작업중 탭",
              p: ["검수완료한 라벨 데이터를 업로드합니다."],
            },
            {
              btn: ["검수통과"],
              pos: "작업중 탭",
              p: ["데이터를 선택하여 검수통과 처리합니다."],
            },
            {
              btn: ["검수리셋", "secondary"],
              pos: "검수통과 탭",
              p: [
                "검수를 리셋하여 검수 대기로 되돌립니다.",
                "검수 완료된 작업 셋은 검수리셋을 할 수 없습니다.",
              ],
            },
            {
              btn: ["라벨 데이터 업로드"],
              pos: "검수통과 탭",
              p: [
                "검수통과된 라벨 데이터를 검수자가 업로드할 수 있으며,",
                "기존 라벨 데이터 파일이 새로 업로드된 파일로 갱신됩니다.",
              ],
            },
            {
              btn: ["대금지급 날짜 입력"],
              pos: "검수통과 탭",
              p: ["대금지급 날짜를 입력할 수 있습니다."],
            },
            {
              btn: ["학습반영"],
              pos: "검수통과 탭",
              p: ["학습리스트에 추가합니다."],
            },
            {
              btn: ["작업회수 확인", "secondary"],
              pos: "작업회수 탭",
              p: [
                "작업자가 작업회수 요청한 데이터를 작업회수 완료 처리할 수 있습니다.",
                "작업회수 완료 처리된 데이터는 작업배분 리스트에서 다시 배분할 수 있습니다.",
                " ",
                "- 회수 수락 여부 : 회수 수락 여부를 선택합니다.",
              ],
            },
            {
              btn: ["전체 검수자 변경 확인"],
              pos: "검수자 변경 탭",
              p: [
                "본인에게 들어온 검수자 변경 요청 전체를 수락하거나 거절할 수 있습니다.",
                " ",
                "- 변경 수락 여부 : 변경 수락 여부를 선택합니다.",
              ],
            },
            {
              btn: ["검수자 변경 확인"],
              pos: "검수자 변경 탭",
              p: [
                "본인에게 들어온 검수자 변경 요청을 수락하거나 거절할 수 있습니다.",
                " ",
                "- 변경 수락 여부 : 변경 수락 여부를 선택합니다.",
              ],
            },
          ],
        },
        {
          h4: "작업 가이드 및 QnA",
          ol: [
            {
              btn: ["작업 가이드 업로드"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: ["작업 가이드를 업로드합니다."],
            },
            {
              btn: ["작업 가이드 다운로드"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: [
                "작업 가이드를 업로드합니다.",
                "100개 이하는 파일이 직접 다운로드되며, 100개 초과는 파일 경로 리스트가 다운로드 됩니다.",
              ],
            },
            {
              btn: ["작업 가이드 삭제", "secondary"],
              pos: "데이터 셋 가이드, 작업 셋 가이드 탭",
              p: ["작업 가이드를 삭제합니다."],
            },
            {
              btn: ["답변등록"],
              pos: "QnA 탭, QnA 카드 내",
              p: [
                "작업자가 등록한 QnA에 답변을 등록할 수 있습니다.",
                " ",
                "- 이미지 업로드 : 답변과 관련된 이미지를 업로드할 수 있습니다. 기존에 등록된 이미지가 갱신됩니다.",
                "- 답변을 입력해 주세요. : 답변을 입력합니다.",
              ],
            },
          ],
        },
      ],
    },
    {
      permission: "rad_seg_lrn",
      h3: "Radar Segmentation > 학습 데이터 관리",
      p: "학습 리스트를 관리할 수 있습니다.",
      desc: [
        {
          h4: "데이터 셋 리스트",
          ol: [
            {
              btn: ["학습 리스트 다운로드"],
              p: ["학습 리스트 정보를 다운로드합니다."],
            },
            {
              btn: ["센서융합 데이터 다운로드"],
              p: ["센서융합 데이터를 다운로드합니다."],
            },
            {
              btn: ["mask 데이터 생성"],
              p: ["mask 데이터를 생성합니다."],
            },
          ],
        },
      ],
    },
    {
      permission: "clct_anlyz",
      h3: "Analysis > 통계 대시보드",
      p: "데이터 통계를 확인할 수 있습니다.",
      desc: [
        {
          h4: "Total",
          ul: [
            "Camera Segmention 학습 데이터",
            "Camera Detection 학습 데이터",
            "Radar Segmentation 학습 데이터",
            "연간 학습 데이터 누적 추이",
          ],
        },
        {
          h4: "Camera Segmention",
          ul: [
            "학습 데이터 월간 작업량",
            "학습 데이터 수집경로 비율",
            "학습 데이터 항만 비율",
            "학습 데이터 선석 비율",
            "학습 데이터 선박 또는 지역 비율",
            "학습 데이터 선석별 수집환경 비율",
            "선석별 데이터 선별 유형",
            "작업자 월별 작업량",
            "[항만] 학습용 데이터 선석별 배분/검수 현황",
            "[운항] 학습용 데이터 선박 또는 지역별 배분/검수 현황",
            "데이터 검수완료 현황",
          ],
        },
        {
          h4: "Camera Detection",
          ul: [
            "학습 데이터 월간 작업량",
            "학습 데이터 수집경로 비율",
            "학습 데이터 항만 비율",
            "학습 데이터 선석 비율",
            "학습 데이터 선박 또는 지역 비율",
            "학습 데이터 선석별 수집환경 비율",
            "선석별 데이터 선별 유형",
            "작업자 월별 작업량",
            "[항만] 학습용 데이터 선석별 배분/검수 현황",
            "[운항] 학습용 데이터 선박 또는 지역별 배분/검수 현황",
            "데이터 검수완료 현황",
          ],
        },
        {
          h4: "Radar Segmentation",
          ul: [
            "학습 데이터 월간 작업량",
            "학습 데이터 선박 또는 지역 비율",
            "작업자 월별 작업량",
            "[운항] 학습용 데이터 선박 또는 지역별 배분/검수 현황",
            "데이터 검수완료 현황",
          ],
        },
      ],
    },
  ],
};

export default MANUAL;
