import { useState } from "react";
import clsx from "clsx";
import { Tab, Tabs } from "@mui/material";
import { CheckStatus } from "./CheckStatus";
import { SegmentationStatus } from "./SegmentationStatus";

const TAB = {
  SEGMENTATION: "segmentation",
  CHECK: "check",
};

export const DashBoardForChecker = () => {
  const [tab, setTab] = useState(TAB.SEGMENTATION);

  return (
    <>
      <div className="flex h-full w-full flex-col">
        <Tabs
          sx={{ position: "absolute", top: "11px", left: "50%", transform: "translateX(-50%)" }}
          value={tab}
          onChange={(_, newValue) => setTab(newValue)}
        >
          <Tab
            sx={tab === TAB.SEGMENTATION ? Style.selected : Style.default}
            value={TAB.SEGMENTATION}
            label="자동 선별 현황"
          />
          <Tab
            sx={tab === TAB.CHECK ? Style.selected : Style.default}
            value={TAB.CHECK}
            label="검수 대기 현황"
          />
        </Tabs>
        <SegmentationStatus visible={tab === TAB.SEGMENTATION} />
        <CheckStatus visible={tab === TAB.CHECK} />
      </div>
    </>
  );
};

const Style = {
  selected: {
    color: "#2B63C1",
    textAlign: "center",
    fontFamily: "Spoqa Han Sans Neo",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px",
    letterSpacing: "-0.32px",
  },
  default: {
    textAlign: "center",
    fontFamily: "Spoqa Han Sans Neo",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "-0.32px",
  },
};
