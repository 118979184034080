import * as Sentry from "@sentry/react";

// 로그인 여부 확인
export const checkLogin = () => {
  if (sessionStorage.getItem("refresh")) {
    return true;
  } else {
    return false;
  }
};

// 토큰 삭제
export const removeToken = () => {
  sessionStorage.removeItem("access");
  sessionStorage.removeItem("refresh");
  sessionStorage.removeItem("expired");
  window.location.replace("/");
};

export const sentryCapture = (error, user) => {
  Sentry.configureScope((scope) => scope.setContext("Error Detail", error).setUser(user));

  return Sentry.captureException(error);
};

export const formatAmountComma = (value) =>
  value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "-";

export const formatAmountShort = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};

export const getTimeStr = (t) => {
  let h = Math.floor(Math.floor(t / 60));
  let m = Math.floor(t - h * 60);
  let s = Math.floor((t - h * 60 - m) * 60);
  if (h < 10) h = "0" + h;
  if (m < 10) m = "0" + m;
  if (s < 10) s = "0" + s;

  return h + ":" + m + ":" + s;
};

// 업로드 파일 체크
// <확장자 type>
// log : ""
// jpg : image/jpeg
// png : image/png
// psd : image/vnd.adobe.photoshop (window -> "")
// docx : application/vnd.openxmlformats-officedocument.wordprocessingml.document
// pptx : application/vnd.openxmlformats-officedocument.presentationml.presentation
// xlsx : application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
// pdf : application/pdf
// json : application/json
// txt : text/plain
export const checkFile = (len, files, accept, ko) => {
  const invalidExt = () =>
    Array.isArray(accept)
      ? ![...files].every((f) =>
          accept.some((a) => (a === "psd" ? f.name.includes(a) : f.type.includes(a)))
        )
      : accept === "log" || accept === "psd"
        ? ![...files].every((f) => f.name.split(".").pop() === accept)
        : ![...files].every((f) => f.type.includes(accept));

  // 대소문자 영문, 숫자, 특수문자(-_.) 허용
  const regExp = /[^a-zA-Z0-9-_.]/g;
  const invalidName = () => [...files].some((f) => regExp.test(f.name.normalize("NFC")));

  // 한글, 대소문자 영문, 숫자, 특수문자(-_.) 허용
  const regExpKo = /[^a-zA-Z0-9-_.ㄱ-ㅎㅏ-ㅣ가-힣]/g;
  const invalidNameKo = () => [...files].some((f) => regExpKo.test(f.name.normalize("NFC")));

  const invalidSize = () => [...files].some((f) => f.size > 2000000000);

  if (invalidSize()) return "invalidSize";
  else if (invalidExt()) return "invalidExt";
  else if (!ko && invalidName()) return "invalidName";
  else if (ko && invalidNameKo()) return "invalidNameKo";
  else return false;
};

export const checkInputText = (arr, ko) => {
  const regExp = /[^a-zA-Z0-9-_]/g;
  const regExpKo = /[^a-zA-Z0-9-_ㄱ-ㅎㅏ-ㅣ가-힣]/g;

  return [...arr].some((str) => (ko ? regExpKo : regExp).test(str));
};

// 집합 연산
export const setOperations = (oldArr, newArr) => {
  // 매개변수명 바꾸기
  let arr = [];

  if (newArr.length === 0) return arr;

  // 합집합 first + second
  const union = (first, second) => {
    let merged = first.concat(second);
    let unique = merged.filter((item, pos) => merged.indexOf(item) === pos);

    return unique;
  };

  // 여집합 first - second
  const complement = (first, second) => {
    return first.filter((value) => !second.includes(value));
  };

  const unionOfTwoSets = union(oldArr, newArr);
  const difference1 = complement(oldArr, newArr);
  const difference2 = complement(newArr, oldArr);

  if (difference2.length === 0) {
    arr = difference1;
  } else {
    arr = unionOfTwoSets;
  }

  return arr;
};

export const getKrTime = () => {
  // getTimezoneOffset()은 현재 시간과의 차이를 분 단위로 반환
  const offset = new Date().getTimezoneOffset() * 60000;
  // toISOString함수는 UTC시간을 기준으로 반환
  const today = new Date(Date.now() - offset).toISOString().slice(0, 10);

  return today;
};
