import { Fragment, useEffect, useState } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { SelectBox, SelectItem, SelectRadio, SelectTitle, SelectWrap } from "../atoms/SelectBox";

const RenderOptions = ({ hasOption, handleOptionState }) => {
  const { query, optionState } = hasOption;

  const [open, setOpen] = useState(null);

  const {
    queryParams: { tab },
  } = useDynamicRouting();
  const { state, contents } = useRecoilValueLoadable(query({ tab }));
  const [currentOption, setCurrentOption] = useRecoilState(optionState);

  useEffect(() => {
    handleOptionState && handleOptionState(state);
    if (state === "hasValue" && contents?.values) {
      // 선택박스 닫음
      setOpen(null);

      // 첫번째 옵션을 default로 현재 선택된 옵션 state를 업데이트
      const array = Object.entries(contents.values);
      if (array.length > 0) {
        let obj = {};
        array.map(([k, v]) => (obj = { ...obj, [k]: [v[0]].join() }));
        setCurrentOption(obj);
      }
    }
  }, [state, contents]);

  return (
    <div className="flex items-center gap-[8px]">
      {state === "loading" ? (
        <div>옵션 리스트를 불러오고 있습니다.</div>
      ) : state === "hasValue" ? (
        contents?.values ? (
          Object.entries(contents.values).map(([k, v], i) => (
            <Fragment key={i}>
              {k === "years" && <div>(검수 날짜 기준)</div>}
              <SelectWrap single styleType="stats">
                <SelectTitle onClick={() => setOpen(open ? null : k)} name={currentOption?.[k]} />
                {open === k && (
                  <SelectBox open styleType="stats_option">
                    {v.map((item, i) => (
                      <SelectItem key={i} styleType="list" cn="ml-0">
                        <SelectRadio
                          onChange={(e) => {
                            setCurrentOption({
                              ...currentOption,
                              [k]: e.target.value,
                            });
                            setOpen(null);
                          }}
                          checked={currentOption?.[k] === item.toString()}
                          value={item}
                        />
                        <span className="ml-[12px]">{item}</span>
                      </SelectItem>
                    ))}
                  </SelectBox>
                )}
              </SelectWrap>
              <div>{k === "years" ? "년" : k === "months" ? "월" : ""}</div>
            </Fragment>
          ))
        ) : (
          <div>데이터가 없습니다.</div>
        )
      ) : (
        <div>옵션 리스트를 불러오지 못했습니다.</div>
      )}
    </div>
  );
};

export default RenderOptions;
