import { useState } from "react";
import { useModals, useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import {
  SelectWrap,
  SelectBox,
  SelectItem,
  SelectName,
  SelectRadio,
  SelectTitle,
} from "../../atoms/SelectBox";
import { CommonButton } from "../../molecules/Buttons";
import { EmptyModal, SaveModal } from "../../molecules/CommonModals";

// 입력: 선택한 아이템
// 출력: 고정 리스트 선택박스
// 입력: 고정 리스트에서 선택한 값
export const SelectInsertAction = ({
  body,
  count,
  api,
  actionName,
  icon,
  type,
  selectList,
  selectName,
}) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { listReload } = useReload();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);

  const resetOpen = () => {
    setOpen(false);
    setModalStatus(null);
    setValue(null);
    setFailType(null);
    setRequired(false);
    openModal();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setOpen(false);
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!value) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await api(urlInfo, {
        ...body,
        [selectName.key]: value,
      });

      console.log(data);
      closeModal();
      listReload();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  return (
    <>
      <CommonButton name={actionName} onClick={resetOpen} icon={icon} type={type} />

      {modal &&
        (count === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title={`${actionName} 정보 확인`}
            count={count}
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
          >
            <SelectWrap required={required && !value}>
              <SelectName name={selectName.ko} />
              <SelectTitle
                onClick={() => setOpen(!open)}
                name={value || "선택"}
                styleType="modal"
              />
              <SelectBox open={open} styleType="modal">
                {selectList.map((item, i) => (
                  <SelectItem key={i} item={item} pagination styleType="list">
                    <SelectRadio onChange={handleChange} checked={value === item} value={item} />
                  </SelectItem>
                ))}
              </SelectBox>
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};
