import { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useHotkeys } from "react-hotkeys-hook";
import { ConfirmButton, DelCheckButton } from "../../components/organisms/actions";
import { CommonButton } from "../../components/molecules/Buttons";
import { condObjsState } from "./Cond.stores";
import { condSave, condDelCheck } from "./Cond.apis";
import { useModals, useReload } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { CONDITIONS } from "../../lib/constants/constant";
import { EmptyModal } from "../../components/molecules/CommonModals";
import CommonTableWrapper from "../../components/organisms/CommonTableWrapper";
import { ImageSprite } from "../../components/atoms/CommonAtoms";
import ModalContainer from "../../components/molecules/ModalContainer";
import { ModalBottom } from "../../components/molecules/Modals";
import { setOperations } from "../../lib/utils";

export const CondAddTag = ({ selectedItems }) => {
  const [condObjs, setCondObjs] = useRecoilState(condObjsState);

  const updateCondObjs = (value) => {
    // select
    const selectedIds = selectedItems;
    // select && old true
    const oldTrueIds = condObjs
      .filter((item) => selectedIds.includes(item.id, 0) && item[value] === true)
      .map((item) => item.id);
    // select - (select && old true) === 0 ? [] : select
    const newTrueIds = setOperations(oldTrueIds, selectedIds);

    const updateFunc = (bool) =>
      [...condObjs].map((item) =>
        selectedIds.includes(item.id) ? { ...item, [value]: bool } : item
      );

    let newObjs = [];
    if (newTrueIds.length === 0) {
      // []: select 전체가 false
      newObjs = updateFunc(false);
    } else {
      // select: select 전체가 true
      newObjs = updateFunc(true);
    }

    setCondObjs(newObjs);
  };

  useHotkeys(["s", "r", "f", "l", "b", "n", "m"], (e) => {
    switch (e.code) {
      case "KeyS":
        return updateCondObjs("snow");
      case "KeyR":
        return updateCondObjs("rain");
      case "KeyF":
        return updateCondObjs("fog");
      case "KeyL":
        return updateCondObjs("light");
      case "KeyB":
        return updateCondObjs("backlight");
      case "KeyN":
        return updateCondObjs("night");
      case "KeyM":
        return updateCondObjs("smoke");
      default:
        return;
    }
  });

  return CONDITIONS.map((item, index) => (
    <CommonButton
      key={index}
      name={item.ko}
      onClick={() => updateCondObjs(item.value)}
      icon={item.icon_b}
      type="unfill"
    />
  ));
};

export const SetCondTag = ({ selectedItems }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const FULL_COLUMNS = [
    { name: "condition", ko: "수집환경" },
    { name: "cnt", ko: "데이터 수" },
  ];

  const { listReload } = useReload();
  const condObjs = useRecoilValue(condObjsState);
  const [condData, setCondData] = useState([]);

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const [failType, setFailType] = useState("");
  const [checkContents, setCheckContents] = useState(null);

  const filterData = () => {
    const len = selectedItems.length;
    let arr = [];
    for (let i = 0; i < len; i++) {
      arr = arr.concat(condObjs.filter((item) => item.id === selectedItems[i]));
    }

    const temp = CONDITIONS.map((c, i) => {
      return {
        condition: c.ko,
        cnt: arr.filter((item) => item[c.value] === true).length,
      };
    });

    setCondData(arr);
    setCheckContents(temp);
  };

  const resetOpen = () => {
    setModalStatus("hasValue");
    filterData();
    openModal();
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const keysToCheck = ["backlight", "fog", "light", "night", "rain", "smoke", "snow"];
      const cond_objs = condData.map((item) => {
        const newItem = { ...item };
        keysToCheck.forEach((key) => {
          if (newItem[key] === null) {
            newItem[key] = false;
          }
        });
        return newItem;
      });

      const data = await condSave(urlInfo, { cond_objs });

      console.log(data);
      closeModal();
      setModalStatus(null);
      listReload();
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      console.log(err_detail);
      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  return (
    <>
      <CommonButton name="수집환경 입력" onClick={resetOpen} icon="IC_add_w" />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <ModalContainer
            modalType="table_modal"
            title="수집환경 입력 정보 확인"
            closeModal={closeModal}
            desc={{
              count: selectedItems.length,
              text: "수집환경 입력 정보를 확인해 주세요.",
            }}
          >
            <CommonTableWrapper
              header={FULL_COLUMNS}
              state="hasValue"
              contents={checkContents}
              tableModal
            />

            <ModalBottom hasStatus={{ modalStatus, failType }}>
              <CommonButton name="확인" onClick={handleClick} type="modal_btn" />
            </ModalBottom>
          </ModalContainer>
        ))}
    </>
  );
};

export const ModifyCondTag = ({ selectedItems, contents }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const FULL_COLUMNS = [
    { name: "condition", ko: "수집환경" },
    { name: "cnt", ko: "데이터 수" },
  ];

  const { listReload } = useReload();
  const condObjs = useRecoilValue(condObjsState);
  const [condData, setCondData] = useState([]);

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const [failType, setFailType] = useState("");
  const [checkContents, setCheckContents] = useState(null);
  const [beforeContents, setBeforeContents] = useState(null);

  const filterBeforeData = () => {
    const len = selectedItems.length;
    const beforeCondObjs = contents.map(
      ({ id, snow, rain, fog, light, backlight, night, smoke }) => {
        return { id, snow, rain, fog, light, backlight, night, smoke };
      }
    );

    let arr = [];
    for (let i = 0; i < len; i++) {
      arr = arr.concat(beforeCondObjs.filter((item) => item.id === selectedItems[i]));
    }

    const temp = CONDITIONS.map((c, i) => {
      return {
        condition: c.ko,
        cnt: arr.filter((item) => item[c.value] === true).length,
      };
    });

    setBeforeContents(temp);
  };

  const filterData = () => {
    const len = selectedItems.length;
    let arr = [];
    for (let i = 0; i < len; i++) {
      arr = arr.concat(condObjs.filter((item) => item.id === selectedItems[i]));
    }

    const temp = CONDITIONS.map((c, i) => {
      return {
        condition: c.ko,
        cnt: arr.filter((item) => item[c.value] === true).length,
      };
    });

    setCondData(arr);
    setCheckContents(temp);
  };

  const resetOpen = () => {
    setModalStatus("hasValue");
    filterBeforeData();
    filterData();
    openModal();
  };

  const handleClick = async () => {
    setModalStatus("loading");
    console.log(condData);

    try {
      const data = await condSave(urlInfo, { cond_objs: condData });

      console.log(data);
      closeModal();
      setModalStatus(null);
      listReload();
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      console.log(err_detail);
      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  return (
    <>
      <CommonButton name="수집환경 수정" onClick={resetOpen} icon="IC_reset_w" />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <ModalContainer
            modalType="table_modal"
            title="수집환경 수정 정보 확인"
            closeModal={closeModal}
            desc={{
              count: selectedItems.length,
              text: "수집환경 수정 정보를 확인해 주세요.",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <CommonTableWrapper
                  header={FULL_COLUMNS}
                  state="hasValue"
                  contents={beforeContents}
                  tableModal
                />
              </div>
              <ImageSprite iconType="IC_next_k" />
              <div style={{ flex: 1 }}>
                <CommonTableWrapper
                  header={FULL_COLUMNS}
                  state="hasValue"
                  contents={checkContents}
                  tableModal
                />
              </div>
            </div>

            <ModalBottom hasStatus={{ modalStatus, failType }}>
              <CommonButton name="확인" onClick={handleClick} type="modal_btn" />
            </ModalBottom>
          </ModalContainer>
        ))}
    </>
  );
};

export const ResetCond = ({ selectedItems }) => {
  const condObjs = useRecoilValue(condObjsState);
  const seletedObjs = condObjs.filter(({ id }) => selectedItems.includes(id));
  const resetConds = seletedObjs.map(({ id }) => ({
    id,
    snow: null,
    rain: null,
    fog: null,
    light: null,
    backlight: null,
    night: null,
    smoke: null,
  }));

  return (
    <ConfirmButton
      count={selectedItems.length}
      api={condSave}
      hasPath
      body={{ cond_objs: resetConds }}
      actionName="수집환경 리셋"
      icon="IC_reset_w"
      type="secondary"
    />
  );
};

export const CondDel = ({ contents, selectedItems }) => {
  return (
    <DelCheckButton
      api={condDelCheck}
      contents={contents}
      selectedItems={selectedItems}
      type="secondary"
      icon="IC_delete_w"
    />
  );
};
