const PRIVACY_POLICY = `씨드로닉스는 「개인정보보호법」 제15조 제1항 제1호, 제17조 제1항 제1호, 제23조 제1호, 제24조 제1항 제1호에 따라 아래와 같이 개인정보의 수집. 이용에 관하여 귀하의 동의를 얻고자 합니다.

씨드로닉스는 이용자의 사전 동의 없이는 이용자의 개인정보를 함부로 공개하지 않으며, 수집된 정보는 아래와 같이 이용하고 있습니다.
이용자가 제공한 모든 정보는 아래의 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 이를 알리고 동의를 구할 것입니다.

[ 개인정보의 수집 및 이용 동의 ]

1. 개인정보의 수집 및 이용 목적

씨드로닉스는 귀하의 개인정보를 다음의 목적을 위해 처리합니다.
이용자가 제공한 모든 정보는 목적에 필요한 용도 이외로는 사용되지 않으며, 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.

1) 도선사 등 서비스 이용자들이 도선할 부두의 정보를 이용하는데 필요한 정보 접근 권한 부여
2) 사용자 위치 정보, 단말기 고유 식별 정보 등 도선 및 계류 상황 분석 작업을 위해 필요한 관련 단말기 정보의 수집
3) 사용자 어플리케이션 업데이트 프로세스의 원활화 목적

2. 개인정보처리항목

씨드로닉스는 인공지능 접안 모니터링 시스템(AVISS) 서비스 등을 제공하기 위하여 최소한의 개인정보를 수집하고 있으며 처리하는 항목은 다음과 같습니다.

필수항목: 아이디, 비밀번호, 사용자 이름, 핸드폰 번호, 이메일, 소속 회사 이름, 사용자 접속 기록
선택항목: 이메일 수신여부, 문자 수신 여부

3. 개인정보의 처리 및 보유 기간

개인정보의 보유 및 이용기간 씨드로닉스는 원칙적으로 보유기간의 경과, 개인정보의 수집 및 이용목적의 달성 등 그 개인정보가 불필요하게 되었을때에는 지체 없이 파기합니다.
다만, 다른 법령에 따라 보존하여야 하는 경우에는 그러하지 않을 수 있습니다.
불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.

회원정보
- 탈퇴 후 지체 없이 파기

4. 개인정보의 제3자 제공에 관한 사항

씨드로닉스는 귀하의 동의가 있거나 관련 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 「개인정보의 처리목적」에서 고지한 범위를 넘어 귀하의 개인정보를 이용하거나 타인 또는 타기업·기관에 제공하지 않습니다.
다만 아래의 경우에는 예외로 합니다.

① 정보주체로부터 별도의 동의를 받은 경우
② 법령의 특별한 규정이 있는 경우
③ 정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
④ 범죄 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
⑤ 통계작성·학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
⑥ 조약이나 그 밖의 보건정책을 위하여 국가기관에 제공하기 위하여 필요한 경우
⑦ 법원의 재판 업무 수행을 위하여 필요한 경우 씨드로닉스는 위의 경우 타 기관 및 제 3자에게 개인정보를 제공 할 수 있습니다. 이 경우 개인정보를 이용하거나 제공 받고자 하는 기관과 개인에게 이용목적, 이용기간, 이용형태 등을 제한하거나 개인정보의 안전성 확보를 위하여 구체적으로 필요한 조치 마련에 대한 내용을 문서로 요청하고 있으며, 이용 및 제공 목적에 맞는 최소한의 정보만 제공합니다.

5. 개인정보 자체관리에 관한 사항

씨드로닉스는 서비스 이행을 위해 필요한 사용자들의 개인정보를 자체적으로 관리하나, 필요한 경우 제3의 기관에 위탁하여 관리할 수 있습니다.

6. 개인정보 제3의 기관 위탁 관리에 관한 사항

1) 개인정보를 제3의 기관에 위탁하여 관리하게 될 경우 위탁관리 회사명, 위탁하게 되는 사용자 정보의 범위 등에 대하여 사용자에게 미리 고지하며, 고지 절차에는 7조가 준용됩니다.
2) 수집한 정보는 관련 법령 및 표준 개인정보보호 지침에 따라 운영 관리합니다.

7. 개인정보 처리방침 변경에 관한 사항

법령·정책 또는 보안기술의 변경에 따라 개인정보 처리방침(본 약관의 내용 등)의 추가·삭제 및 수정이 있을 시에는 시행하기 최소 7일 전에 본사 AVISS 시스템 공지사항에 고지합니다. 만약 사용자가 이메일 수신 여부, 문자 수신 여부에 동의한 경우, 가입한 이메일 혹은 문자 서비스를 통해 변경 사유 및 내용 등을 공지합니다.

8. 정보주체의 권리·의무 및 그 행사방법에 관한 사항

1) 씨드로닉스는 귀하가 개인정보에 대한 열람, 정정 및 삭제를 요구하는 경우,

개인정보에 대한 열람 및 정정은 사용자 어플리케이션 서비스를 통해 가능합니다.
사용자 어플리케이션 서비스를 통한 개인정보 열람 및 정정이 어려울 경우, 전화·우편·Email 등 기타 신청 방법에 의하여 열람, 정정 절차를 제공합니다.
개인정보 삭제의 경우 정당한 사유가 있을 경우에만 삭제 절차를 제공합니다.

① 개인정보의 열람 : 사용자는 개인정보의 열람을 요구할 수 있으며 열람 목적이 정당한 경우 신속하게 이에 대하여 응대합니다.
② 개인정보의 정정ㆍ삭제 : 씨드로닉스는 귀하의 개인정보에 대한 정정ㆍ삭제 요구 시, 개인정보에 오류가 있다고 판명되는 등 정정ㆍ삭제를 할 필요가 있다고 인정되는 경우에는 지체 없이 정정ㆍ삭제를 하며, 씨드로닉스는 정정ㆍ삭제 내용의 사실 확인이 필요한 경우 증빙자료를 요청할 수 있습니다.

2) 사용자의 대리인이 방문하여 열람, 정정 및 삭제를 요구하는 경우,

사용자의 위임장 및 동의서와 대리인의 신분증명서 등을 확인하여 정당한 대리인인지 여부를 확인합니다.

3) 씨드로닉스는 개인정보의 전부 또는 일부에 대하여 열람, 정정, 삭제를 거절할 정당한 이유가 있는 경우,

10 영업일 이내 귀하에게 이를 통지하고 그 이유를 설명합니다.

4) 동의 철회를 요구하는 경우,

귀하는 회원가입 시 개인정보의 수집ㆍ이용 및 제공에 대해 동의하신 내용을 언제든지 철회하실 수 있습니다. 다만 이 경우 서비스 이용이 제한될 수 있습니다.

5) 회원탈퇴를 하고자 하는 경우,

① 사용자의 소속 회사 또는 기관과 씨드로닉스 간 계약에 의하여 회원 탈퇴 절차가 진행됩니다.
② 씨드로닉스는 계약 만료 이후에도 관련 법령이 정하는 기간 동안 사용자의 개인정보를 보관할 수 있습니다.

9. 개인정보의 안전성 확보조치

씨드로닉스는 개인정보보호법에 따라 다음과 같이 안전성 확보에 필요한 관리적ㆍ기술적 및 물리적 조치를 하고 있습니다.

1) 관리적 조치

개인 식별정보의 보호처리, 개인정보의 접근권한 관리, 비밀번호 관리, 개인정보의 접근 로그 관리, 개인정보의 출력조치, 취급 위탁관리의 보호조치, 개인정보의 파기조치, 모니터링 실시, 비밀누설 금지, 서약서 관리, 정보보호 교육

2) 기술적 조치

정보시스템 보안관리, 계정 및 비밀번호 통제, 정보시스템 접근통제, 네트워크 접근 통제, 서버 접근통제, 데이터베이스 접근통제, 악성소프트웨어 통제, 이동컴퓨터 및 원격작업 통제, 백업 및 로그관리

3) 물리적 조치

보호구역 설정 및 관리, 서버실 보안관리, 장비 보안, 단말기 보안, 사무실 보안

[ 이용자 약관 ]

제 1 장 총칙

제 1 조 (목적)
1. 본 약관은 씨드로닉스가 제공하는 모든 서비스(이하 "서비스")의 이용조건 및 절차, 이용자와 씨드로닉스의 권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.

제 2 조 (약관의 효력과 변경)
1. 씨드로닉스는 귀하가 본 약관 내용에 동의하는 경우 씨드로닉스의 서비스 제공 행위 및 귀하의 서비스 사용 행위에 본 약관이 우선적으로 적용됩니다.
2. 씨드로닉스는 본 약관을 사전 고지 없이 변경할 수 있고 변경된 약관은 AVISS 시스템 내 공지사항, 이메일 등을 통해 회원에게 공지하며, 공지와 동시에 그 효력이 발생됩니다.
이용자가 변경된 약관에 동의하지 않는 경우, 이용자는 본인의 회원등록을 취소할 수 있으며 계속 사용의 경우는 약관 변경에 대한 동의로 간주 됩니다.

제 3 조 (약관 외 준칙)
본 약관에 명시되지 않은 사항은 개인정보보호에 관한 법률, 전기통신기본법, 전기통신사업법, 프로그램보호법 및 기타 관련 법령의 규정에 의합니다.

제 4 조 (용어의 정의)
본 약관에서 사용하는 용어의 정의는 다음과 같습니다.

1. 이용자 : 본 약관에 따라 씨드로닉스가 제공하는 서비스를 받는 자.
2. 가입 : 씨드로닉스가 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위.
3. 회원 : 씨드로닉스에 개인 정보를 제공하여 회원 등록을 한 자로서 씨드로닉스가 제공하는 서비스를 이용할 수 있는 항만공사, 도선사, 부두 운영사 등 
4. 비밀번호 : 이용자와 회원ID가 일치하는지를 확인하고 통신상의 자신의 비밀보호를 위하여 이용자 자신이 선정한 문자와 숫자의 조합.
5. 탈퇴 : 회원이 이용계약을 종료시키는 행위.
6. 접/이안 및 계류 상황 정보 : AVISS(인공지능 접안 모니터링 시스템)으로 촬영한 부두의 영상 정보 등

제 2 장 서비스 제공 및 이용

제 5 조 (이용계약의 성립)
1. 이용계약은 신청자가 온라인으로 씨드로닉스에서 제공하는 소정의 가입신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는 것으로 성립됩니다.

2. 씨드로닉스는 다음 각 호에 해당하는 이용계약에 대하여는 가입을 취소할 수 있습니다. 

① 다른 사람의 명의를 사용하여 신청하였을 때
② 이용계약 신청서의 내용을 허위로 기재하였거나 신청하였을 때
③ 다른 사람의 씨드로닉스 서비스 이용을 방해하거나 그 정보를 도용하는 등의 행위를 하였을 때
④ 씨드로닉스를 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우
⑤ 기타 씨드로닉스가 정한 이용신청요건이 미비 되었을 때

제 6 조 (회원정보 사용에 대한 동의)
회원의 개인정보는 개인정보수집동의 약관에 의하여 보호됩니다.

제 7 조 (이용자의 정보 보안)
1. 가입 신청자가 씨드로닉스 서비스 가입 절차를 완료하는 순간부터 귀하는 입력한 정보의 비밀을 유지할 책임이 있으며, 회원의 ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.
2. ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, 회원의 ID나 비밀번호가 부정하게 사용 되었다는 사실을 발견한 경우에는 즉시 씨드로닉스에 신고하여야 합니다. 신고를 하지 않음으로 인한 모든 책임은 회원 본인에게 있습니다. 종료하지 아니함으로써 제3자가 귀하에 관한 정보를 이용하게 되는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 씨드로닉스는 책임을 부담하지 아니합니다.

제 8 조 (서비스의 중지)
씨드로닉스는 이용자가 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지할 수 있습니다.

제 9 조 (이용자 접근 권한)
1. 씨드로닉스 시스템에 대한 이용자의 접근 권한의 범위에 대하여 부두 운영 주체에게 그 결정권을 위임합니다. 부두 운영 주체는 이용자의 시스템 접근 가능 시간을 설정하거나 제한하는 등의 조치를 취할 수 있습니다.
2. 만약 부두 운영 주체가 특정 이용자(도선사 등)의 접근 권한의 범위를 설정할 수 없는 상황에 한하여 씨드로닉스가 결정권을 갖습니다.
3. 1.의 경우 이용자는 씨드로닉스에게 접근 권한 부여 등과 관련한 요구를 할 수 없습니다.   

제 3 장 의무 및 책임

제 10 조 (씨드로닉스의 의무)
1. 씨드로닉스는 회원의 개인 신상 정보를 본인의 승낙없이 타인에게 누설, 배포하지 않습니다. 다만, 전기통신관련법령 등 관계법령에 의하여 관계 국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.
2. 씨드로닉스는 접/이안 및 계류상황 정보를 이용자들에게 원활하게 제공하기 위하여 적극 협조합니다.
3. 씨드로닉스는 이용자들의 어플리케이션의 사용 환경을 최적으로 유지하기 위하여 적극 노력합니다.
4. 씨드로닉스는 이용자들이 서비스를 사용하면서 제안하는 건의사항을 적극 수용하기위해 노력합니다. 

제 11 조 (회원의 의무)
1. 회원 가입 시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 귀하에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
2. 회원은 씨드로닉스의 사전 승낙 없이 서비스를 이용하여 어떠한 영리 행위도 할 수 없습니다.
3. 국내 항만공사, 부두 운영사 등의 회원은 씨드로닉스가 필요한 도선 및 계류 상황 정보를 씨드로닉스에게 제공하는데 적극 협조해야 합니다.
4. 이용자는 씨드로닉스 회사의 서비스를 통해 취득한 접/이안 및 계류상황 정보를 이용자가 소속된 회사나 기관의 방침 및 관련 국가 법령을 위배하여 제3자에게 제공 및 유출 등을 하여서는 안됩니다.   
5. 이용자는 씨드로닉스 회사의 원활한 서비스 제공을 위하여 필요한 정보제공, 단말기 기능설정 등에 적극 협조해야 합니다.  

제 4 장 기타

제 12 조 (양도금지)
회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없습니다.

제 13 조 (손해배상)
씨드로닉스가 제공한 서비스를 이용하던 중 발생한 사고 또는 기타 손해에 대하여는 이용자가 책임을 집니다. 다만 그 책임 소재가 명백하게 씨드로닉스 측에게 있을 시에는 씨드로닉스가 배상합니다.  

제 14 조 (면책조항)
1. 제9조와 관련하여 이용자 접근 권한 결정권이 부두 운영사에게 위임되었을 경우, 이용자는 씨드로닉스에게 일체 그와 관련한 손해배상 요구 등을 할 수 없습니다.
2. 통항 금지 사유에 이를 정도의 기후장애가 발생한 경우, 부두의 크레인 등이 영상 센서의 시야를 막는 경우, 화재, 정전, 천재지변, 현저한 불가항력적 사유, 기타 사유 등 씨드로닉스가 제공한 서비스의 성능을 보장할 수 없는 특별한 사유가 발생한 경우에는 이용자는 씨드로닉스에게 손해배상을 청구할 수 없습니다.   
3. 이용자의 단말기 또는 네트워크 서비스 등에 문제가 발생하여 씨드로닉스의 서비스 이용이 어려움에 따라 발생한 사고의 경우 이용자는 씨드로닉스에게 손해배상을 청구할 수 없습니다.  

제 15 조 (재판관할)
씨드로닉스와 이용자 간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, 본 분쟁으로 인한 소는 씨드로닉스 본사 소재지 관할 법원에 제기합니다.`;

export default PRIVACY_POLICY;
