import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";

export const FloatingRenderHeader = React.memo(({ columns, wideCol }) => {
  return (
    <TableHead>
      <TableRow style={{ whiteSpace: "nowrap" }}>
        <TableCell className="tablecell_index" variant="head">
          번호
        </TableCell>
        {columns.map((col, idx) => (
          <TableCell key={idx} variant="head" style={col.name === wideCol ? { width: "90%" } : {}}>
            <div>{col.ko}</div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
