import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { GUIDE_COLS, GUIDE_TAB, REF_TAB_LINK } from "../../lib/constants/constant";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import {
  workQnaPreviewQuery,
  workRefCntQuery,
  workRefIdsQuery,
  workRefListQuery,
} from "./Work.stores";
import { workRefFilter } from "./Work.apis";
import {
  DownloadClctGuide,
  DownloadGuide,
  WorkQnaCreate,
  WorkQnaDelete,
  WorkVideoList,
} from "./WorkActions";

const WorkGuidePage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;
  const { tab, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    workRefCntQuery({ ...urlParams, tab, t })
  );
  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    workRefIdsQuery({ sensor_code, gt_code, set_id, ...queryParams })
  );

  return (
    <CommonTemplate
      listQuery={workRefListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
      filterApi={tab !== "3" && workRefFilter}
      previewQuery={tab === "3" && workQnaPreviewQuery}
      fullColumnData={GUIDE_COLS.tab}
      columnData={GUIDE_COLS[`tab${tab}`]}
      tabData={GUIDE_TAB}
      refTabData={REF_TAB_LINK.data}
      qnaType={tab === "3" && "q"}
      actions={({ selectedItems }) => (
        <>
          {tab === "1" && gt_code === "seg" && <WorkVideoList />}
          {tab === "3" && <WorkQnaCreate />}
          <SelectCnt count={selectedItems.length} />
          {tab === "1" && <DownloadClctGuide selectedItems={selectedItems} />}
          {tab === "2" && <DownloadGuide selectedItems={selectedItems} />}
          {tab === "3" && <WorkQnaDelete selectedItems={selectedItems} />}
        </>
      )}
    />
  );
};

export default WorkGuidePage;
