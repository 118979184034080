import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { ConfirmModal } from "../molecules/CommonModals";

class ErrorBoundary extends Component {
  state = {
    error: false,
  };

  componentDidCatch(error, info) {
    console.log("에러가 발생했습니다.");
    console.log(error);
    console.log(info);

    this.props.setHasError();
    this.setState({ error: true });

    Sentry.configureScope((scope) =>
      scope
        .setContext("error boundary", error)
        .setContext("error information", info)
        .setUser(this.props.user)
    );

    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <ConfirmModal title="에러가 발생했습니다" detail="진행 중인 작업이 취소될 수 있습니다." />
          {this.props.children}
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
