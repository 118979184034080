import { Link } from "react-router-dom";
import clsx from "clsx";
import { Loader } from "../../components/atoms/Status";
import { ImageSprite } from "../../components/atoms/CommonAtoms";
import sea from "../../assets/images/sea.mp4";

const LoginForm = (props) => {
  const {
    status,
    errorMsg,
    inputId,
    inputPw,
    setInputId,
    setInputPw,
    checkInput,
    resetInput,
    hide,
  } = props;

  return (
    <section className="flex h-screen w-screen flex-col">
      {!hide && (
        <>
          <div className="z-10 m-auto flex h-[436px] w-[436px] flex-col items-center justify-center bg-transparent px-[38px]">
            <div className="flex h-[160px] items-center justify-center">
              <ImageSprite boxSize="IC_navlue" />
            </div>
            <form className="mb-[40px] flex flex-col" onSubmit={checkInput}>
              <input
                className={clsx(
                  "spoqa",
                  "mb-[12px] h-[44px] w-[360px] rounded-[2px] border border-solid p-[16px] text-[12px] text-gray800",
                  (status === "hasErrorId" || status === "hasError") && "border-error"
                )}
                type="text"
                name="input_id"
                placeholder="아이디"
                value={inputId}
                onChange={(e) => setInputId(e.target.value)}
                onFocus={errorMsg ? () => resetInput() : null}
              />
              <input
                className={clsx(
                  "spoqa",
                  "mb-[12px] h-[44px] w-[360px] rounded-[2px] border border-solid p-[16px] text-[12px] text-gray800",
                  (status === "hasErrorPw" || status === "hasError") && "border-error"
                )}
                type="password"
                name="input_pw"
                placeholder="비밀번호"
                value={inputPw}
                onChange={(e) => setInputPw(e.target.value)}
                onFocus={errorMsg ? () => resetInput() : null}
              />
              <input
                type="submit"
                value="로그인"
                className={clsx(
                  "spoqa",
                  "flex h-[44px] w-[360px] cursor-pointer items-center justify-center gap-[8px] px-[8px] text-[12px] leading-[24px] text-white",
                  status === "loading" ? "border-transparent bg-gray400" : "bg-primary"
                )}
              />
            </form>
            <div
              className={clsx(
                status === "loading" || status !== null ? "h-[80px]" : "h-0",
                "flex items-start justify-center duration-300"
              )}
            >
              {status === "loading" ? (
                <Loader styleType="login" />
              ) : (
                status !== null && <div className="text-error">{errorMsg}</div>
              )}
            </div>
          </div>
          <div className="row absolute bottom-[40px] z-10 flex w-full flex-col items-center justify-center gap-[16px]">
            <Link
              to="/dataset"
              className="px-2 text-[16px] text-white hover:font-bold hover:underline"
            >
              OASIs DATASET
            </Link>
            <footer className="text-white">
              @2021-{new Date().getFullYear()} Seadronix All rights reserved.
            </footer>
          </div>
        </>
      )}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "none",
        }}
      >
        <source src={sea} type="video/mp4" />
      </video>
    </section>
  );
};

export default LoginForm;
