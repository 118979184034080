import { IMG_TYPE_LIST } from "../../lib/constants/constant";

export const COND_CLCT_COLS = {
  tab: [
    "data",
    "path",
    "port",
    "berth",
    "vessel",
    "date",
    "transferred",
    "tab_cnts",
    "cond_cnts",
    "clct_cmp_t",
    "module",
  ],
  hide: ["module"],
};

export const COND_DATA_COLS = {
  tab: [
    "sensor_name",
    "clct_t",
    "filename",
    "cond_rep",
    "cond_t",
    "transferred",
    "snow",
    "rain",
    "fog",
    "light",
    "backlight",
    "night",
    "smoke",
  ],
  tab2: ["sensor_name", "clct_t", "filename", "transferred"],
  tab3: [
    "sensor_name",
    "clct_t",
    "filename",
    "cond_rep",
    "cond_t",
    "transferred",
    "snow",
    "rain",
    "fog",
    "light",
    "backlight",
    "night",
    "smoke",
  ],
  hide: {
    table: {
      tab2: ["clct_t"],
      tab3: ["clct_t"],
    },
    thumb: {
      tab2: ["filename", "clct_t"],
      tab3: ["filename", "clct_t"],
    },
  },
};

export const makeImgTypeList = () => IMG_TYPE_LIST.filter((x) => ["orig_thumb"].includes(x.thumb));

export const COND_CAM_STATUS = [
  {
    ko: "전체",
    value: "1",
    name: "cond_tab1_cnt",
    col: "condTab1Cnt",
  },
  {
    ko: "수집환경 입력 대기",
    value: "2",
    name: "cond_tab2_cnt",
    col: "condTab2Cnt",
  },
  {
    ko: "수집환경 입력 완료",
    value: "3",
    name: "cond_tab3_cnt",
    col: "condTab3Cnt",
  },
];

export const COND_CAM_CMP_STATUS = [
  {
    ko: "전체",
    value: "1",
    name: "cond_tab1_cnt",
    col: "condTab1Cnt",
  },
  {
    ko: "수집환경 입력 완료",
    value: "3",
    name: "cond_tab3_cnt",
    col: "condTab3Cnt",
  },
];

export const COND_CAM_TAB = [
  {
    ko: "수집환경 입력 대기",
    value: "2",
    name: "cond_tab2_cnt",
    col: "condTab2Cnt",
  },
  {
    ko: "수집환경 입력 완료",
    value: "3",
    name: "cond_tab3_cnt",
    col: "condTab3Cnt",
  },
];
