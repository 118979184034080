import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { createColorArr, findDict } from "./StatsPage";
import { LoadFail } from "../../components/atoms/Status";

// Pie
export const PieChart = (props) => {
  const { contents } = props;

  const formatData = ({ chart_labels, chart_data }) => {
    // 라벨, 데이터를 묶어 새로운 배열 생성하여 정렬
    const sortedArr = [...chart_labels]
      .map((l, i) => [l, chart_data[i]])
      .sort((a, b) => b[1] - a[1]);
    // 정렬된 배열을 다시 라벨, 데이터로 쪼갬
    const labels = sortedArr.map((l) => findDict(l[0]));
    const data = sortedArr.map((l) => l[1]);
    const colorArr = createColorArr(labels.length);

    return {
      maintainAspectRatio: false,
      responsive: false,
      labels,
      datasets: [
        {
          data,
          backgroundColor: colorArr,
          hoverBackgroundColor: colorArr,
          barThickness: 30,
        },
      ],
    };
  };

  const data = formatData(contents);

  if (contents.chart_data.find((d) => d !== 0) === undefined) {
    return <LoadFail failType="데이터가 없습니다." />;
  }

  return (
    data && (
      <Pie
        data={data}
        spacing={0}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          borderWidth: 0,
          plugins: {
            legend: {
              display: true,
              position: "right",
              align: "start",
            },
            tooltip: {
              backgroundColor: "rgba(0,0,0,0.7)",
              cornerRadius: 2,
              titleColor: "#fff",
              bodyColor: "#fff",
              callbacks: {
                labelColor: (context) => {
                  return {
                    backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                  };
                },
              },
            },
          },
        }}
      />
    )
  );
};
