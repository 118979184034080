import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { formatAmountShort } from "../../lib/utils";
import { createColorArr, findDict } from "./StatsPage";

// Bar
export const BarChart = (props) => {
  const { contents, xName, stacked, sort } = props;

  const formatData = ({ chart_labels, chart_data }) => {
    const sorting = () => {
      // 라벨, 데이터를 묶어 새로운 배열 생성하여 정렬
      const sortedArr = [...chart_labels]
        .map((l, i) => [l, ...chart_data.map((d) => [d.label, d.data[i]])])
        .sort((a, b) => b[1][1] - a[1][1]);
      // 정렬된 배열을 다시 라벨, 데이터로 쪼갬
      const sortedLabels = sortedArr.map((l) => l[0]);
      const sortedData = [...chart_data].map((d, i) => {
        return {
          label: findDict(d.label),
          data: sortedArr.map((l) => l[i + 1][1]),
        };
      });

      return { sortedLabels, sortedData };
    };

    const { sortedLabels, sortedData } = sort
      ? sorting()
      : { sortedLabels: chart_labels, sortedData: chart_data };

    const labels = sortedLabels;
    const data = sortedData;

    const len = chart_data?.length;
    const colorArr = createColorArr(len);

    const datasets =
      labels?.length > 0
        ? data?.map((d, i) => {
            return {
              type: "bar",
              label: d.label,
              data: d.data,
              backgroundColor: colorArr[i],
              hoverBackgroundColor: colorArr[i],
              pointHoverBorderColor: colorArr[i],
            };
          })
        : [];

    return {
      axisTitles: {
        x: {
          display: true,
          drawTicks: true,
          text: xName,
          font: {
            weight: "bold",
          },
        },
        y: {
          display: false,
          drawTicks: false,
          text: "y",
          font: {
            weight: "bold",
          },
        },
      },
      labels,
      datasets,
    };
  };

  const data = formatData(contents);

  return (
    data && (
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked,
              title: data.axisTitles.x,
              beginAtZero: true,
              grid: {
                display: false,
                borderColor: "#C4C4C4",
                borderWidth: 1,
              },
            },
            y: {
              stacked,
              title: data.axisTitles.y,
              grid: {
                color: "#C4C4C4",
                lineWidth: 1,
                borderColor: "#fff",
                drawTicks: false,
              },
              ticks: {
                callback: formatAmountShort,
              },
            },
            all: {
              type: "linear",
              position: "right",
              display: false,
            },
          },
          plugins: {
            legend: {
              display: true,
              align: "end",
            },
            tooltip: {
              backgroundColor: "rgba(0,0,0,0.7)",
              cornerRadius: 2,
              titleColor: "#fff",
              bodyColor: "#fff",
              callbacks: {
                labelColor: (context) => {
                  return {
                    backgroundColor: context.dataset.backgroundColor,
                  };
                },
              },
            },
          },
        }}
      />
    )
  );
};
