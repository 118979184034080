export const MAIN_LIST = [
  {
    title: "[Camera] 수집환경 입력",
    name: "cond_latest_clct",
    path: "/condition/camera",
    tab: "2",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Camera Segmentation] 학습 데이터 선별",
    name: "cam_seg_latest_clct",
    path: "/validation/camera/segmentation",
    tab: "1",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Camera Segmentation] 데이터 작업 - 작업대기",
    name: "cam_seg_latest_dist_cmp",
    path: "/work/camera/segmentation",
    tab: "4",
    col: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "module",
      "dist_rep_name",
      "set_name",
      "dist_date",
      "cnt",
    ],
  },
  {
    title: "[Camera Segmentation] 데이터 작업 - 작업중",
    name: "cam_seg_latest_working",
    path: "/work/camera/segmentation",
    tab: "5",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Camera Segmentation] 데이터 작업 - 검수반려",
    name: "cam_seg_latest_return",
    path: "/work/camera/segmentation",
    tab: "7",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Camera Segmentation] 데이터 작업 - 작업회수",
    name: "cam_seg_latest_cncl",
    path: "/work/camera/segmentation",
    tab: "10",
    col: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "module",
      "dist_rep_name",
      "set_name",
      "cnt",
    ],
  },
  {
    title: "[Camera Segmentation] 데이터 작업 - QnA 답변",
    name: "cam_seg_latest_qna_answer",
    path: "/work/camera/segmentation",
    ref: "/ref",
    tab: "3",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Camera Segmentation] 데이터 검수 - 검수대기",
    name: "cam_seg_latest_check_req",
    path: "/check/camera/segmentation",
    tab: "6",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Camera Segmentation] 데이터 검수 - 작업회수",
    name: "cam_seg_latest_cncl_req",
    path: "/check/camera/segmentation",
    tab: "10",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Camera Segmentation] 데이터 검수 - QnA 질문",
    name: "cam_seg_latest_qna",
    path: "/check/camera/segmentation",
    ref: "/ref",
    tab: "3",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Camera Detection] 학습 데이터 선별",
    name: "cam_det_latest_clct",
    path: "/validation/camera/detection",
    tab: "1",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Camera Detection] 데이터 작업 - 작업대기",
    name: "cam_det_latest_dist_cmp",
    path: "/work/camera/detection",
    tab: "4",
    col: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "module",
      "dist_rep_name",
      "set_name",
      "dist_date",
      "cnt",
    ],
  },
  {
    title: "[Camera Detection] 데이터 작업 - 작업중",
    name: "cam_det_latest_working",
    path: "/work/camera/detection",
    tab: "5",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Camera Detection] 데이터 작업 - 검수반려",
    name: "cam_det_latest_return",
    path: "/work/camera/detection",
    tab: "7",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Camera Detection] 데이터 작업 - 작업회수",
    name: "cam_det_latest_cncl",
    path: "/work/camera/detection",
    tab: "10",
    col: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "module",
      "dist_rep_name",
      "set_name",
      "cnt",
    ],
  },
  {
    title: "[Camera Detection] 데이터 작업 - QnA 답변",
    name: "cam_det_latest_qna_answer",
    path: "/work/camera/detection",
    ref: "/ref",
    tab: "3",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Camera Detection] 데이터 검수 - 검수대기",
    name: "cam_det_latest_check_req",
    path: "/check/camera/detection",
    tab: "6",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Camera Detection] 데이터 검수 - 작업회수",
    name: "cam_det_latest_cncl_req",
    path: "/check/camera/detection",
    tab: "10",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Camera Detection] 데이터 검수 - QnA 질문",
    name: "cam_det_latest_qna",
    path: "/check/camera/detection",
    ref: "/ref",
    tab: "3",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Radar Segmentation] 학습 데이터 선별",
    name: "rad_seg_latest_clct",
    path: "/validation/radar/segmentation",
    tab: "1",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Radar Segmentation] 데이터 작업 - 작업대기",
    name: "rad_seg_latest_dist_cmp",
    path: "/work/radar/segmentation",
    tab: "4",
    col: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "module",
      "dist_rep_name",
      "set_name",
      "dist_date",
      "cnt",
    ],
  },
  {
    title: "[Radar Segmentation] 데이터 작업 - 작업중",
    name: "rad_seg_latest_working",
    path: "/work/radar/segmentation",
    tab: "5",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Radar Segmentation] 데이터 작업 - 검수반려",
    name: "rad_seg_latest_return",
    path: "/work/radar/segmentation",
    tab: "7",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Radar Segmentation] 데이터 작업 - 작업회수",
    name: "rad_seg_latest_cncl",
    path: "/work/radar/segmentation",
    tab: "10",
    col: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "module",
      "dist_rep_name",
      "set_name",
      "cnt",
    ],
  },
  {
    title: "[Radar Segmentation] 데이터 작업 - QnA 답변",
    name: "rad_seg_latest_qna_answer",
    path: "/work/radar/segmentation",
    ref: "/ref",
    tab: "3",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "set_name", "cnt"],
  },
  {
    title: "[Radar Segmentation] 데이터 검수 - 검수대기",
    name: "rad_seg_latest_check_req",
    path: "/check/radar/segmentation",
    tab: "6",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Radar Segmentation] 데이터 검수 - 작업회수",
    name: "rad_seg_latest_cncl_req",
    path: "/check/radar/segmentation",
    tab: "10",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
  {
    title: "[Radar Segmentation] 데이터 검수 - QnA 질문",
    name: "rad_seg_latest_qna",
    path: "/check/radar/segmentation",
    ref: "/ref",
    tab: "3",
    col: ["data", "path", "port", "berth", "vessel", "date", "module", "cnt"],
  },
];
