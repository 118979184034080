import { logout } from "../../lib/apis";
import { ImageSprite } from "../atoms/CommonAtoms";
import { CommonButton } from "../molecules/Buttons";

const ErrorPage = () => {
  const forceLogout = async (refresh) => {
    try {
      await logout(refresh);
    } catch (err) {
      console.log(err);
    }
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
    sessionStorage.removeItem("expired");
  };

  const refresh = sessionStorage.getItem("refresh");
  refresh && forceLogout(refresh);

  return (
    <div className="h-screen w-screen">
      <div className="flex h-[calc(100%-57px)] w-full flex-col items-center justify-center gap-[40px] text-[14px] text-gray700">
        <ImageSprite boxSize="IC_navlue" />
        <div>An unexpected error has occurred. Please contact the administrator.</div>
        <CommonButton name="Main" onClick={() => window.location.replace("/")} />
      </div>
    </div>
  );
};

export default ErrorPage;
