import { axiosInstance } from "../../components/templates/RootErrorBoundary";

// 삭제 내역 화면 데이터셋 리스트 조회
export const histClctList = async ({ sensor_code, ...params }) => {
  const { data } = await axiosInstance.get(`/del/${sensor_code}/sets/`, {
    params,
  });

  return data;
};

// 삭제 내역 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const histClctFilter = async ({ sensor_code, col }, params) => {
  const { data } = await axiosInstance.get(`/del/${sensor_code}/sets/cols/${col}/`, { params });

  return data;
};

// 삭제 내역 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const histFilter = async ({ sensor_code, set_id, col }, params) => {
  const { data } = await axiosInstance.get(`/del/${sensor_code}/datas/${set_id}/cols/${col}/`, {
    params,
  });

  return data;
};

// 삭제 내역 화면 데이터 리스트 조회
export const histList = async ({ sensor_code, set_id, view, ...params }) => {
  const { data } = await axiosInstance.get(`/del/${sensor_code}/datas/${set_id}/${view}/`, {
    params,
  });

  return data;
};
