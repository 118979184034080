import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ImageSprite } from "../../atoms/CommonAtoms";
import { LoaderLinear } from "../../atoms/Status";
import { CommonButton } from "../../molecules/Buttons";
import { FloatingRenderHeader } from "./FloatingRenderHeader";
import { FloatingRenderRow } from "./FloatingRenderRow";
import FloatingRenderTable from "./FloatingRenderTable";
import { formatAmountComma } from "../../../lib/utils";

const TablePagination = ({ count, page, rowsPerPage, onPageChange }) => {
  const lastPage = Math.ceil(count / rowsPerPage) - 1;

  return (
    <div className="flex items-center text-gray800">
      <ImageSprite
        handleClick={page === 0 ? null : (e) => onPageChange(e, 0)}
        iconType={page === 0 ? "IC_first_k opacity-30" : "IC_first_k"}
      />
      <ImageSprite
        handleClick={page === 0 ? null : (e) => onPageChange(e, page - 1)}
        iconType={page === 0 ? "IC_prev_k opacity-30" : "IC_prev_k"}
      />
      <div>{page + 1}</div>
      <div>&nbsp;/&nbsp;</div>
      <div>{lastPage + 1}</div>
      <ImageSprite
        handleClick={page >= lastPage ? null : (e) => onPageChange(e, page + 1)}
        iconType={page >= lastPage ? "IC_next_k opacity-30" : "IC_next_k"}
      />
      <ImageSprite
        handleClick={page >= lastPage ? null : (e) => onPageChange(e, Math.max(0, lastPage))}
        iconType={page >= lastPage ? "IC_last_k opacity-30" : "IC_last_k"}
      />
    </div>
  );
};

const FloatingRenderItem = (props) => {
  const { idx, floatingItem, handleCancel, handleDelete, handleFold } = props;
  const { status, singleProcess, sensorName, fold, text, contents, id, singleIndex, percent } =
    floatingItem;

  const rows = contents;
  const rowsPerPage = 100;
  const [page, setPage] = useState(0);
  const handleChangePage = (e, newPage) => setPage(newPage);

  const [currentHeader, setCurrentHeader] = useState([]);

  const PERCENT_COLS = [
    { name: "filename", ko: "파일명" },
    { name: "percent", ko: "진행률", type: "percent" },
    { name: "floatingStatus", ko: "진행상태" },
  ];

  const SENSORNAME_COLS = [
    { name: "sensorName", ko: "센서번호" },
    { name: "floatingStatus", ko: "진행상태" },
  ];

  const FILENAME_COLS = [
    { name: "filename", ko: "파일명" },
    { name: "floatingStatus", ko: "진행상태" },
  ];

  useEffect(() => {
    setCurrentHeader(singleProcess ? PERCENT_COLS : sensorName ? SENSORNAME_COLS : FILENAME_COLS);
  }, []);

  return (
    <div
      className={clsx(
        "flex flex-col border-b border-gray400 duration-[0.3s] last:border-b-0",
        fold ? "h-[49px]" : "h-[324px]"
      )}
    >
      <div className="flex h-[48px] items-center justify-between border-b-0 py-[8px] px-[16px]">
        <div className="flex items-center gap-[8px]">
          <div className="text-[14px] font-bold text-gray900">{text}</div>
        </div>
        <div className="flex flex-none items-center gap-[8px]">
          <div>
            {/* 진행률 */}
            {!singleProcess ? (
              // batch process 전체 진행률
              status === "업로드 진행중" ? ( // 파일 업로드 진행중
                percent === 100 ? (
                  <LoaderLinear />
                ) : (
                  <LoaderLinear variant="determinate" value={percent} />
                )
              ) : status === "진행중" ? ( // 추출, 추론, 검사 진행중
                <LoaderLinear hidden />
              ) : (
                // 완료
                <LoaderLinear variant="determinate" value={100} />
              )
            ) : (
              // sindle process 전체 진행률 직접 계산
              <LoaderLinear
                variant="determinate"
                value={Math.round((singleIndex / contents.length) * 100)}
              >
                {status === "전송 완료"
                  ? `성공: ${rows.filter((row) => row.floatingStatus === "성공").length}개, 실패 ${rows.filter((row) => row.floatingStatus !== "성공").length}개`
                  : `${formatAmountComma(singleIndex)}/${formatAmountComma(contents.length)}`}
              </LoaderLinear>
            )}
          </div>
          {/* 페이지네이션 */}
          <TablePagination
            count={contents?.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
          {/* 상태 */}
          {status}
          {/* 상태별 버튼 */}
          {status === "업로드 진행중" || status === "업로드 중" ? (
            // 파일 업로드만 취소 가능
            <CommonButton type="secondary" name="취소" onClick={() => handleCancel(id)} />
          ) : // 취소 불가능한 process 진행중일때는 아무 버튼도 출력하지 않음
          status === "진행중" ? null : (
            <CommonButton type="small" name="닫기" onClick={() => handleDelete(idx)} />
          )}
          {/* 최소화/최대화 */}
          <div className="cursor-pointer" onClick={() => handleFold(idx)}>
            <ImageSprite iconType={fold ? "IC_up_k" : "IC_down_k"} />
          </div>
        </div>
      </div>
      {!fold && (
        <FloatingRenderTable
          page={page}
          renderHeader={() => {
            return (
              <FloatingRenderHeader
                columns={currentHeader}
                wideCol={sensorName ? "sensorName" : "filename"}
              />
            );
          }}
        >
          {contents?.length > 0 &&
            rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <FloatingRenderRow
                  key={index}
                  currentPercent={row["floatingStatus"] === "전송" ? percent : null}
                  row={row}
                  index={index + page * rowsPerPage}
                  columns={currentHeader}
                  status={row["floatingStatus"]}
                />
              ))}
        </FloatingRenderTable>
      )}
    </div>
  );
};

export default React.memo(FloatingRenderItem);
