import { useState } from "react";
import { ImageSprite } from "../../atoms/CommonAtoms";
import ProfileModal from "./ProfileModal";

const CurrentUser = (props) => {
  const { state, contents } = props;
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {state === "hasValue" ? (
        <>
          <span>
            <span>로그인 시간 : </span>
            <span>{contents.last_login}</span>
          </span>
          <span>
            <span>{contents.display_name} 님</span>
          </span>
        </>
      ) : state === "loading" ? (
        <span>사용자 정보를 가져오고 있습니다.</span>
      ) : (
        <span>사용자 정보를 가져오는데 실패했습니다.</span>
      )}
      <ImageSprite handleClick={() => setModalOpen(true)} iconType="IC_profile_k" />

      {modalOpen && <ProfileModal closeModal={() => setModalOpen(false)} />}
    </>
  );
};

export default CurrentUser;
