import { logout } from "../../../lib/apis";
import { useModals } from "../../../lib/hooks";
import { removeToken } from "../../../lib/utils";
import { ImageSprite } from "../../atoms/CommonAtoms";
import { ConfirmModal } from "../../molecules/CommonModals";
import { clearAllStorage } from "../../../lib/storage/useStorage";

const Logout = (props) => {
  const { whenLogout } = props;
  const refresh = sessionStorage.getItem("refresh");

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const handleClick = async () => {
    setModalStatus("loading");
    whenLogout(true);
    try {
      await logout(refresh);
      closeModal();
    } catch (err) {
      console.log(err);
    }
    removeToken();
    clearAllStorage();
  };

  return (
    <>
      <ImageSprite handleClick={openModal} iconType="IC_logout_k" />

      {modal && (
        <ConfirmModal
          closeModal={closeModal}
          title="로그아웃"
          modalStatus={modalStatus}
          detail="진행 중인 작업이 전부 취소됩니다. 정말로 종료하시겠습니까?"
          handleClick={handleClick}
        />
      )}
    </>
  );
};

export default Logout;
