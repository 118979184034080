import { useState } from "react";
import { useModals, useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { CommonButton } from "../../molecules/Buttons";
import { ConfirmModal, EmptyModal } from "../../molecules/CommonModals";

// 입력: 선택한 아이템
export const ConfirmAction = ({
  count,
  detail,
  api,
  hasPath,
  tag,
  body,
  actionName,
  icon,
  type,
  blackBackground,
  tableModal,
  afterActionCallback,
  callback,
}) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [failType, setFailType] = useState("");
  const { listReload, modalReload } = useReload();

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const handleClick = async () => {
    setModalStatus("loading");
    console.log(body);
    try {
      let data;
      if (hasPath) {
        data = await api({ ...urlInfo, tag }, body);
      } else {
        data = await api(body);
      }

      console.log(data);
      closeModal();
      setModalStatus(null);
      afterActionCallback && afterActionCallback();
      callback && callback();
      tableModal ? modalReload() : listReload();
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      console.log(err_detail);
      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  return (
    <>
      <CommonButton name={actionName} onClick={openModal} icon={icon} type={type} />

      {modal &&
        (count === 0 ? (
          <EmptyModal closeModal={closeModal} blackBackground={blackBackground} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title={`${actionName} 정보 확인`}
            count={count}
            detail={detail}
            modalStatus={modalStatus}
            failType={failType}
            handleClick={handleClick}
            blackBackground={blackBackground}
          />
        ))}
    </>
  );
};
