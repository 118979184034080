import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import {
  GT_TAB_CAMDET_WORK,
  GT_TAB_CAMSEG_WORK,
  GT_TAB_RADSEG_WORK,
} from "../../lib/constants/constant";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { DownloadFusion, LrnInsertExport, LrnMask } from "./LrnActions";
import { lrnClctCntQuery, lrnClctListQuery } from "./Lrn.stores";
import { LRN_CLCT_COLS } from "./Lrn.constants";
import { lrnClctFilter } from "./Lrn.apis";

const LrnClctPage = () => {
  const {
    urlParams,
    queryParams: { tab, t },
  } = useDynamicRouting();
  const { sensor_code, gt_code } = urlParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    lrnClctCntQuery({ ...urlParams, tab, t })
  );

  const renderAction = ({ selectedItems, contents }) => {
    return (
      <>
        <SelectCnt count={selectedItems.length} />
        <LrnInsertExport selectedItems={selectedItems} />
        {gt_code === "seg" && (
          <LrnMask selectedItems={selectedItems} contents={contents?.results} />
        )}
        {sensor_code === "rad" && <DownloadFusion selectedItems={selectedItems} />}
      </>
    );
  };

  return (
    <CommonTemplate
      listQuery={lrnClctListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      filterApi={lrnClctFilter}
      fullColumnData={LRN_CLCT_COLS[`${sensor_code}_${gt_code}`].tab}
      columnData={LRN_CLCT_COLS[`${sensor_code}_${gt_code}`].tab}
      hideColumn={LRN_CLCT_COLS[`${sensor_code}_${gt_code}`].hide}
      tabData={[{ ko: "전체", value: "1" }]}
      groupColumnData={
        sensor_code === "rad"
          ? GT_TAB_RADSEG_WORK
          : gt_code === "det"
            ? GT_TAB_CAMDET_WORK
            : GT_TAB_CAMSEG_WORK
      }
      actions={(data) => renderAction(data)}
    />
  );
};

export default LrnClctPage;
