import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { LRN_DATA_COLS, makeImgTypeList } from "./Lrn.constants";
import { lrnIdsQuery, lrnListQuery, lrnPreviewQuery } from "./Lrn.stores";
import { lrnFilter } from "./Lrn.apis";

const LrnDataPage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;

  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    lrnIdsQuery({ sensor_code, gt_code, set_id, ...queryParams })
  );

  return (
    <CommonTemplate
      listQuery={lrnListQuery}
      contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
      filterApi={lrnFilter}
      previewQuery={lrnPreviewQuery}
      fullColumnData={LRN_DATA_COLS[`${sensor_code}_${gt_code}`].tab}
      columnData={LRN_DATA_COLS[`${sensor_code}_${gt_code}`].tab}
      hideColumn={LRN_DATA_COLS[`${sensor_code}_${gt_code}`].hide.table}
      hideThumbColumn={LRN_DATA_COLS[`${sensor_code}_${gt_code}`].hide.thumb}
      viewData
      tagType="meta"
      imgTypeData={makeImgTypeList(sensor_code, gt_code)}
    />
  );
};

export default LrnDataPage;
