import { useState } from "react";
import { TextField } from "@mui/material";
import { useModals, useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { dataDel } from "../../../lib/apis";
import { SelectWrap, SelectName } from "../../atoms/SelectBox";
import ModalContainer from "../../molecules/ModalContainer";
import { CommonButton } from "../../molecules/Buttons";
import { ModalBottom } from "../../molecules/Modals";
import CommonTableWrapper from "../CommonTableWrapper";
import { EmptyModal, SaveModal } from "../../molecules/CommonModals";

export const DataDelInsertPassword = ({ api, title, ids, closeAllModal }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [value, setValue] = useState({ password: null, del_cuz: null });
  const { listReload } = useReload();
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);

  const resetOpen = () => {
    setValue({ password: null, del_cuz: null });
    setFailType(null);
    setRequired(false);
    openModal();
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!value.password || !value.del_cuz) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await api(urlInfo, { ids, ...value });

      console.log(data);
      closeModal();
      closeAllModal();
      listReload();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";
      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name={title} onClick={resetOpen} type="modal_btn" icon="IC_delete_w" />

      {modal &&
        (ids.length === 0 ? (
          <EmptyModal detail="삭제 가능한 데이터가 없습니다." closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title="데이터 삭제 정보 입력"
            count={ids.length}
            detail="데이터를 삭제하기 위해서 비밀번호를 입력해 주세요."
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
          >
            <SelectWrap required={required && !value.password}>
              <SelectName name="비밀번호" />
              <TextField
                type="password"
                size="small"
                sx={{ width: 160 }}
                onChange={(e) => setValue({ ...value, password: e.target.value })}
              />
            </SelectWrap>
            <SelectWrap required={required && !value.del_cuz}>
              <SelectName name="삭제 사유" />
              <TextField
                hiddenLabel
                size="small"
                sx={{ width: 160 }}
                onChange={(e) => setValue({ ...value, del_cuz: e.target.value })}
              />
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};

// 입력: 선택한 아이템
// 출력: 응답받은 삭제 가능 여부 리스트
// 입력: 비밀번호
export const DelCheckAction = ({ api, contents, selectedItems, rgsDelApi, icon, type }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const FULL_COLUMNS = [
    { name: "filename", ko: "파일명" },
    { name: "result", ko: "삭제 가능 여부" },
  ];

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const [failType, setFailType] = useState("");
  const [tableContents, setTableContents] = useState(null);

  const resetOpen = () => {
    setTableContents(null);
    setModalStatus(null);

    selectedItems.length > 0 && getList();
    openModal();
  };

  // 삭제 불가능한 리스트만 오기때문에
  // 성공한것도 따로 찾아서 뿌려주기 위해 전체 데이터 리스트에서 선택한 아이템 찾아서 합침
  const makeList = (res) =>
    contents
      .filter((item) => selectedItems.includes(item.id))
      .map((item) => {
        const n = res.impossible_data.find((d) => d.filename === item.filename);

        return {
          filename: item.filename,
          result: n?.fail_type ? n.fail_type : "삭제 가능",
        };
      });

  const getList = async () => {
    setModalStatus("loading");

    try {
      const data = await api(urlInfo, { ids: selectedItems });
      console.log(data);

      const fullList = makeList(data);

      setTableContents({ ...data, data: fullList });
      setModalStatus("hasValue");
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  return (
    <>
      <CommonButton name="데이터 삭제" onClick={resetOpen} icon={icon} type={type} />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <ModalContainer
            modalType="table_modal"
            title={`데이터 삭제 정보 ${
              tableContents
                ? `(전체 ${tableContents.total_cnt}개 = 가능 ${tableContents?.possible_cnt}개 + 불가능 ${tableContents?.impossible_cnt}개)`
                : "(...)"
            }`}
            closeModal={closeModal}
            desc={{
              count: tableContents?.total_cnt || "...",
              text: "아래 리스트에서 삭제 가능여부를 확인해 주세요. 삭제 시 삭제 가능한 데이터만 삭제됩니다.",
            }}
          >
            <CommonTableWrapper
              header={FULL_COLUMNS}
              state={modalStatus}
              contents={tableContents?.data}
              tableModal
            />
            <ModalBottom hasStatus={{ modalStatus, failType }}>
              <DataDelInsertPassword
                closeAllModal={closeModal}
                api={rgsDelApi || dataDel}
                title={`${
                  tableContents?.possible_cnt || (tableContents?.possible_cnt === 0 ? "0" : "...")
                }개의 데이터 삭제`}
                ids={tableContents?.possible_ids}
              />
            </ModalBottom>
          </ModalContainer>
        ))}
    </>
  );
};

// 입력 : 선택한 아이템
// 출력 : 비밀번호 입력 후 삭제 가능 팝업
export const DataDeleteConfirmation = ({ api, title, selectedItems, icon, type }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [value, setValue] = useState({ password: null });
  const { listReload } = useReload();
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);

  const resetOpen = () => {
    setValue({ password: null });
    setFailType(null);
    setRequired(false);
    openModal();
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (!value.password) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      console.log({ clct_ids: selectedItems, ...value });
      const data = await api({ urlInfo, clct_ids: selectedItems, ...value });

      console.log(data);
      closeModal();
      listReload();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";
      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name={title} onClick={resetOpen} icon={icon} type={type} />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal detail="삭제 가능한 데이터가 없습니다." closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title="데이터 셋 삭제 정보 입력"
            count={selectedItems.length}
            detail="해당 데이터 셋을 삭제하면 데이터 셋 내의 모든 데이터가 삭제가 됩니다. 데이터를 삭제하기 위해서 비밀번호를 입력해 주세요."
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
          >
            <SelectWrap required={required && !value.password}>
              <SelectName name="비밀번호" />
              <TextField
                type="password"
                size="small"
                sx={{ width: 160 }}
                onChange={(e) => setValue({ ...value, password: e.target.value })}
              />
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};
