import { useCallback } from "react";
import clsx from "clsx";

export const ImageSprite = ({ handleClick, boxSize, iconType }) => {
  // 이미지 스프라이트 기법을 사용한 이미지 출력
  return (
    <div
      className={clsx(
        "image_box",
        "relative",
        handleClick && "cursor-pointer",
        boxSize,
        boxSize === "IC_navlue"
          ? "h-[105px] w-[360px]"
          : boxSize === "IC_navlue_s"
            ? "h-[20px] w-[70px]"
            : boxSize === "scale_large"
              ? "h-[36px] w-[36px]"
              : boxSize === "scale_small"
                ? "h-[18px] w-[18px]"
                : "h-[24px] w-[24px]"
      )}
      onClick={handleClick}
    >
      <div
        className={clsx("image_sprite", "absolute m-0 h-full w-full list-none p-0", iconType)}
      ></div>
    </div>
  );
};

export const HeaderBG = ({ handleClick }) => {
  // 메뉴 또는 모달이 열렸을때 바깥 클릭 시 닫히기 위한 백그라운드
  return <div className="absolute right-0 bottom-0 z-30 h-full w-full" onClick={handleClick}></div>;
};

export const NavBottom = ({ handleClick, name }) => {
  // nav 하단 메뉴
  return (
    <div
      className="flex h-[28px] cursor-pointer items-center justify-center hover:text-gray400"
      onClick={handleClick}
    >
      {name}
    </div>
  );
};

export const PreLine = ({ text }) => {
  // 공백을 있는 그대로 표시하고 자동 줄바꿈함
  return <div className="w-full whitespace-pre-wrap bg-gray100 p-[10px]">{text}</div>;
};

export const PageTitle = ({ handleClick, text }) => {
  // 레이아웃 상단 현재 페이지 출력 : 카테고리 > 메뉴 > 데이터셋 이름
  return (
    <span
      className="relative cursor-pointer hover:after:absolute hover:after:left-0 hover:after:bottom-[12px] hover:after:h-[2px] hover:after:w-full hover:after:bg-gray900"
      onClick={handleClick}
    >
      {text}
    </span>
  );
};

export const SelectCnt = ({ count }) => {
  // 선택한 데이터 수 출력
  return <span className="flex-none text-gray800">{`선택한 데이터 ${count}개`}</span>;
};

export const MiddlePoint = ({ white }) => {
  // 가운뎃점
  return (
    <div className={clsx("h-[4px] w-[4px] rounded-[2px]", white ? "bg-white" : "bg-gray900")}></div>
  );
};

export const ThumbImg = ({ children, cn }) => {
  // 썸네일 이미지
  return (
    <div className={clsx("flex items-center border border-gray400 bg-gray200", cn)}>{children}</div>
  );
};

export const StepIndicator = ({ step = 0, cn }) => {
  return (
    <div
      className={clsx(
        "flex h-[20px] w-[20px] items-center justify-center bg-primary2 text-white",
        cn
      )}
    >
      {step}
    </div>
  );
};

export const RangeNumberInput = ({
  label = "",
  value = 0,
  handleChange = () => {},
  min = 0,
  max = 100,
  step = 1,
  showRange = true,
  showNumber = true,
}) => {
  const onChange = useCallback(
    (event) => {
      handleChange(event.target.value);
    },
    [handleChange]
  );

  return (
    <div className="whtiespace-normal flex h-[48px] items-center px-[16px]">
      <label className="mr-[10px]" htmlFor="range-number-input">
        {label}
      </label>
      {showRange && (
        <input
          id="range-number-input"
          type="range"
          className={clsx(
            `ml-[12px] h-[3px] w-[110px] cursor-pointer bg-gray800 outline-0 [&::-webkit-slider-thumb]:-mt-0.5 [&::-webkit-slider-thumb]:h-3.5 [&::-webkit-slider-thumb]:w-3.5 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-primary [&::-webkit-slider-thumb]:transition-all [&::-webkit-slider-thumb]:duration-150 [&::-webkit-slider-thumb]:ease-in-out [&::-webkit-slider-thumb]:dark:bg-slate-700`
          )}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
        />
      )}
      {showNumber && (
        <input
          type="number"
          className={clsx(
            "ml-[12px] h-[24px] w-[48px] rounded-[4px] border border-solid px-2.5 py-2 text-[12px] text-gray800 [&::-webkit-inner-spin-button]:appearance-none"
          )}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};
