import { useState } from "react";
import clsx from "clsx";
import { Loader, LoadFail } from "../atoms/Status";
import CommonTableWrapper from "../organisms/CommonTableWrapper";
import RenderOptions from "../organisms/RenderOptions";
import { findColumns } from "../../pages/statistics/StatsPage";

export const StatsBox = (props) => {
  const { title, state, optionState, type, children } = props;

  return (
    <div className="relative flex h-full w-full flex-col flex-nowrap justify-between overflow-hidden bg-white pb-[20px]">
      <div className="h-[56px] px-[16px] leading-[56px]">
        <span className="text-[16px] font-bold text-gray900">
          {title}
          {type === "count" && " 학습 데이터"}
        </span>
      </div>
      <div
        className={clsx(
          type === "chart"
            ? "h-[calc(100%-30px)] px-[16px]"
            : type === "list"
              ? "h-[calc(100%-52px)]"
              : type === "count"
                ? "flex h-full items-center justify-center"
                : ""
        )}
      >
        {state === "loading" ? (
          <Loader />
        ) : state === "hasError" ? (
          optionState === "loading" ? (
            <Loader />
          ) : optionState === "hasValue" ? (
            <LoadFail failType="데이터가 없습니다." />
          ) : (
            <LoadFail failType="데이터를 불러오는데 실패했습니다." />
          )
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export const StatsItem = ({
  h,
  title,
  halfSet,
  hasOption,
  state,
  contents,
  header,
  stats,
  children,
  sortBy,
}) => {
  const [optionState, setOptionState] = useState();

  return (
    <div
      className={clsx(
        "relative flex flex-row items-center",
        h ? (h === "300" ? "h-[300px]" : h === "600" && "h-[600px]") : "h-[400px]",
        halfSet ? "items-start" : "space-between gap-[16px]",
        children && "w-full flex-nowrap overflow-hidden bg-white p-0"
      )}
    >
      <div className="absolute top-0 left-0 z-30 flex h-[56px] items-center gap-[4px] px-[16px]">
        <span className="mr-[16px] text-[16px] font-bold text-gray900">{title}</span>
        {/* 옵션 */}
        {hasOption ? (
          <RenderOptions
            hasOption={hasOption}
            handleOptionState={(state) => setOptionState(state)}
          />
        ) : null}
      </div>
      {/* 차트 */}
      {children ? (
        <StatsBox state={state} optionState={optionState} type="chart">
          {children}
        </StatsBox>
      ) : null}
      {/* 리스트 */}
      <StatsBox state={state} optionState={optionState} type="list">
        <CommonTableWrapper
          stats={stats ? stats : true}
          header={findColumns(header)}
          state={state}
          contents={
            sortBy
              ? contents?.table_data &&
                [...contents.table_data].sort((a, b) => b[sortBy] - a[sortBy])
              : contents?.table_data
          }
        />
      </StatsBox>
    </div>
  );
};

export const StatsItemRowSet = ({ h, children }) => {
  return (
    <div
      className={clsx(
        "relative flex flex-row justify-between gap-[16px]",
        h === "300" ? "h-[300px]" : h === "600" && "h-[600px]"
      )}
    >
      {children}
    </div>
  );
};
