const CHANGE_LOG = [
  {
    version: "v1.5.2",
    date: "24.7.1",
    full: `주요 개발 사항
업데이트 내용
- [공통] 썸네일뷰/미리보기 이미지 없을 경우 원본 이미지 조회
- [작업] 세그멘테이션 데이터 리스트 백그라운드 에러 컬럼 추가, 에러상세 백그라운드 에러 정보 추가
- [작업/검수] 세그멘테이션 라벨 이미지 변경 (에러검사 시 생성되는 semantic 이미지 → psd 이미지)
- [검수] 세그멘테이션 검수통과 후 업로드한 라벨 이미지 에러검사 기능 추가 (필수 x)
- [학습] 세그멘테이션 학습 리스트 다운로드 시 라벨 png 제외
- [기타] 벤치마크 dataset 접속 링크 추가 (화면은 오픈 예정 문구만)

개선 내용
- [공통] 파일 갱신 시 미리보기 이미지 갱신 안되는 오류 수정
- [선별] 세그멘테이션 자동선별과 검출기 자동추론 동시 실행 허용 (서로 다른 셋일 경우에만)
- [작업] 검출기 에러검사 시 bbox 카테고리별 색상 다르게 표시

디버깅 내용
- [검수] 작업회수 시 배분 여부 X로 변경안되는 오류 수정
    `,
  },
  {
    version: "v1.5.1",
    date: "24.6.1",
    full: `주요 개발 사항
업데이트 내용
- [메인] 검출기/레이다 최근 검수반려, 검수요청 리스트 추가
- [환경/선별] 원본 데이터 이관 여부 표시 추가
- [작업] 세그멘테이션 에러검사 버전 업데이트(v3.9.4, 레이어 중복 경고 및 레이어 중첩 오류 추가)
- [검수] 검출기 배분정보 입력 시, 트래킹여부 입력 제외 (에러검사 시 자동 저장)

개선 내용
- ISO27001 보안권고사항 적용 (DB 및 웹 서버 버전 업그레이드)
- [이미지 미리보기] 원본/추론이미지 비율 유지
- [이미지 미리보기] 옵션 변경: 밝기/대비/회색조 → 대비/명도/채도
- [공통] 2중 팝업창 시 검은색 배경 투명도 조절 
- [메인] 속도 개선
- [검수] 검출기 json 다운로드 시, 500개까지 파일 직접 다운로드 되도록 변경
- [cam/seg/데이터 작업] 전체 탭에 '그룹 에러' 컬럼 추가
- [cam/seg/데이터 작업] 인스펙터 결과 값에서 0 값은 gray 톤으로 변경
- [cam/det/데이터 검수] 작업 중에 있던 원본+라벨 다운로드 버튼 검수대기로 이동
- [rad/seg/데이터 검수] 작업자, 검수자로 테이블 컬럼 순서 변경

디버깅 내용
- [로그인] 5회 이상 실패 시, 로그인 안되도록 변경
- [등록/추출] 데이터셋 등록 or log 업로드 시, 모듈명을 제외한 정보가 일치하는 데이터셋이 존재할 경우 불가능하도록 변경

240612 수
- 작업자 대시보드 조회오류 수정하여 재배포
    `,
  },
  {
    version: "v1.5.0",
    date: "24.4.19",
    full: `주요 개발 사항
- [등록] 생성(G) 데이터 등록 추가
- [선별] 클라우드로 이관된 데이터 셋 표시
- [선별] 세그멘테이션 자동선별 시 자선 mask 데이터 업로드 및 conf, step 값 직접 조정
- [작업] 작업시작 전 전체 파일 한번에 다운로드 
- [작업] 데이터 리스트 연기 속성 정보 표시
- [작업/검수] 레이다 작업 및 검수 프로세스 통일화
- [작업/검수/학습] 재작업 프로세스 추가

개선 내용
- [공통] 업로드 시 실패/성공 정보 표시
- [공통] 미리보기 조회 시 GPS 좌표값 표시
- [선별] 자동선별 결과 분포 이미지 다운로드 권한 풀기
- [검수] 라벨데이터 업로드 시간과 학습데이터 갱신 시간 통합
- [학습] 최초 학습반영 날짜 있는 데이터 셋만 조회
    `,
  },
  {
    version: "v1.4.7",
    date: "24.1.11",
    full: `주요 개발 사항
업데이트 내용
- 수집환경 속성 매연 추가
- 검출기 클래스별 단가 입력 (추가 클래스: 선박, 매연, 부표)
- 검출기 세그멘테이션과 검수 프로세스 통일

개선 내용
- [검수] 세그멘테이션, 검출기 데이터셋 리스트 수집환경 컬럼 추가
- [검수] 검출기 데이터 리스트 수집환경 컬럼 추가  --> 추가
- [추출/등록/선별] 데이터셋 추출/등록완료 시 자동선별/추론 기능 삭제 (선별 화면에서 별도 버튼 클릭 필요)
- [검수] 세그멘테이션 데이터 리스트 수집환경 컬럼 추가
- [검수] 세그멘테이션/검출기 데이터셋 리스트 수집환경 정보 오류 수정
- [공통] 컬럼 업데이트
    `,
  },
  {
    version: "v1.4.6",
    date: "23.12.15",
    full: `주요 개발 사항
업데이트 내용
- [추출] 셋 추출완료 시 log 데이터 S3(AWS)로 이관
- [선별 세그멘테이션] 추론 이미지와 원본 이미지 비율 통일
- [선별 세그멘테이션] 추론 모델 업데이트(VaDA_231114_inf.pt)
- [선별 검출기] 데이터 리스트 전체 자동추론 버튼 추가
- [작업/검수] 가이드 데이터 업로드 시 zip 파일 허용
- [작업/검수 세그멘테이션/레이다] 썸네일 및 미리보기 뷰 난이도 정보 추가
- [작업/검수 검출기] gt 데이터 업로드 시 track id 중복검사 로직 추가
- [검수] 미리보기 뷰 작업자 정보 추가
- [검수 세그멘테이션/검출기] 데이터 셋 리스트 수집환경 정보 컬럼 추가

개선 내용
- [공통] 테이블/썸네일 뷰 컬럼 통일
- [추출/등록/선별 세그멘테이션/검출기] 셋 수집완료 시 자동으로 자동선별(추론)되는 플로우 변경 (자동선별(추론)이 진행되고 있지 않는 경우만 자동으로 진행)
- [환경/선별] 데이터 삭제 시 삭제 가능 여부에 자동 선별이 진행중이라는 문구 뜨도록 수정
- [작업 세그멘테이션] 데이터 리스트 속도 개선

디버깅 내용
- [환경] 자동선별 진행중인 셋 수집환경 입력완료 시 다시 대기 탭으로 돌아오는 오류 수정
- [선별 세그멘테이션] 자동선별 스크립트 오류 수정
- [검수 검출기] 우선순위 적용 오류 수정
    `,
  },
  {
    version: "v1.4.5",
    date: "23.11.12",
    full: `주요 개발 사항
- [공통] 모달 창 닫기 버튼과 X 버튼 둘 다 있을 경우 X 버튼 제거
- [공통] 상태별 데이터 수 컬럼 필터링 유지 오류 수정
- [환경] 수집환경 입력 완료 시 완료 탭으로 넘어가지 않는 오류 수정
- [선별 공통] 배분 여부 컬럼 정보 오류 수정
- [선별 공통] 평가용 데이터 선정, 평가용 데이터 수 컬럼 삭제
- [선별 공통] 평가용 데이터 선정 탭 삭제, 학습용 데이터 선정 -> 선별대기 탭 변경 
- [선별 세그멘테이션] 자동 선별 진행 중 컬럼 추가
- [선별 세그멘테이션] 선별대기 탭 자동 선별 버튼 추가 (자동 선별 실패 시 직접 클릭)
- [선별 검출기] 자동 추론 진행 중 컬럼 추가 (자동 추론 실패 시 기존 방식대로 페이지별 추론)
- [선별 세그멘테이션/검출기] 데이터 셋 추출 혹은 등록 완료 시 세그멘테이션 자동 선별 및 검출기 자동 추론 진행 (데이터가 2장 이상일 경우 진행)
- [작업 공통] QnA 탭 질문등록 시 png 아닌 파일을 업로드 할 경우 등록 성공 오류 수정
- [검수 공통] 배분대기 탭 배분정보 입력 및 삭제 창 삭제 버튼과 입력 버튼 중복 선택 오류 수정
- [검수 공통] 참고 영상 리스트 창 참고 영상 생성 버튼 클릭 시 무조건 실패로 뜨는 오류 수정
- [검수 공통] 참고영상 생성 정보 확인 창에서 센서번호를 선택하지 않고 확인을 누른 경우 센서번호를 입력해야 확인 버튼을 누를 수 있도록 변경
- [검수 검출기] 상태별 데이터 수, 선별 완료 시간 컬럼 필터링 오류 수정
- [검수 검출기] 가이드파일 다운로드 오류 수정
- [검수 검출기/레이다] 원본 다운로드 버튼 추가
- [학습 공통] 최근 학습반영 날짜 -> 최초 학습반영 날짜 컬럼 변경
    `,
  },
  {
    version: "v1.4.4.4",
    date: "23.09.11",
    full: `주요 개발 사항
- [대시보드] 조회 속도 개선
- [추출] 센서퓨전 셋 규정 수정
- [선별 공통] 선별 리셋 시 오류 수정
- [선별 세그멘테이션] 추론 속도 개선
    `,
  },
  {
    version: "v1.4.4.3",
    date: "23.08.12",
    full: `주요 개발 사항
- [추출] 빈 log 데이터 처리 추가
- [추출] 추출 시 첫 이미지 저조도 처리 후 추출하도록 변경 (기존 무조건 추출)
- [선별,학습 공통] 데이터 선별 리셋 시 학습 화면에 안 뜨도록 변경
- [작업,검수 검출기] bbox 글자 두께 얇게 조정, 트랙 아이디 잘리지 않도록 수정
- [검수 공통] 참고 영상 제작 기능 개선
    `,
  },
  {
    version: "v1.4.4.2",
    date: "23.07.30",
    full: `주요 개발 사항
- [로그인] 로그인 유효기간 만료 시 오류 해결
- [공통] 파일 다운로드 기능 개선
- [공통] 데이터 셋 리스트, 탭 수 및 컬럼 필터링 목록 조회 속도 개선
- [추출] 추출 시 log 파일의 오류 문구 추가
- [선별 세그멘테이션/검출기] 추론 후 바로 선별 시 DB 꼬임 현상 해결
- [작업 공통] 파일 다운로드 1분에 3회 제한
- [추출,선별,작업] 추출, 추론, 에러검사 시 중복 재실행 안되도록 막음
- [검수 공통] 대기 데이터만 있는 데이터 셋은 뜨지 않도록 수정
- [검수 공통] 참고 영상 데이터 바로 다운로드
- [검수,학습 레이다] 학습 반영 시 DB 꼬임 현상 해결
- [학습 공통] 학습용 데이터가 없는 데이터 셋은 뜨지 않도록 수정
- navlue 서버 다운 시 자동 restart 설정
- DB 쿼리 개선
    `,
  },
  {
    version: "v1.4.4.1",
    date: "23.06.20",
    full: `주요 개발 사항
- FE Sentry captureException 설정
- Docker log max-size 설정

버그 수정 사항
- 배분정보 입력 오류 수정
`,
  },
  {
    version: "v1.4.4",
    date: "23.06.19",
    full: `주요 개발 사항
- 작업회수 로직 오류 수정
- 미리보기 APIs 속도 개선
- 검수자 변경 수락/거절 APIs exception 추가
- 작업 회수 시 검수시간 리셋
- 라벨 데이터 업로드 시 bbox 바로 생성
- 레이다 선별 화면 데이터 삭제 오류 수정
- 데이터 셋 조회 쿼리 개선
- POST APIs 쿼리 개선
- 필터 적용 후 1페이지로 이동
- 데이터 삭제 모달 수정
- 일부 컬럼 기본값 숨김처리
- [공통] 썸네일 이미지 종류 디폴트 변경
- [공통] 필터링 리스트 출력 방식 변경
- [공통] count 컬럼 정렬 추가
- [공통] 실패 문구 구체적으로 변경
- [공통] 탭 및 컬럼 변경
- [메인] 기획 수정
- [데이터 삭제 내역] 메뉴 생성
- [작업] 검출기 작업 데이터 업로드 방식 변경
- [작업] 가공용 데이터 다운로드 기능 추가
- [배분->검수] 메뉴 통합
- [배분->검수] 작업 셋 선택 항목 비/활성화 표시
- [배분->검수] 배분정보 입력 기능 수정
- [검수] 검출기 json viewer 업데이트 및 미리보기 속도 개선
- [검수] 검출기 학습반영 일괄 처리 기능 추가
- [학습] 센서융합 데이터 다운로드 기능 추가
`,
  },
  {
    version: "v1.4.3.2",
    date: "23.05.19",
    full: `버그 수정 사항
- 추출 시 이미지 중복 처리 오류 수정
- 환경입력 화면 속성별 데이터 수 조회 오류 수정
- 세그멘테이션 학습 데이터 셋 리스트 컬럼 필터링 오류 수정`,
  },
  {
    version: "v1.4.3.1",
    date: "23.05.15",
    full: `버그 수정 사항
- 검수 원본 다운로드 에러 수정
- psd 인스펙터 에러참고 데이터 다운로드 에러 수정`,
  },
  {
    version: "v1.4.3",
    date: "23.05.02",
    full: `주요 개발 사항
- 로그인 화면 문구 삭제 및 색상 변경
- 날짜 필터링 방법 변경
- 필터링이 적용된 필터링 옵션 리스트 출력
- 페이지 상관없이 연속으로 미리보기 가능하도록 수정
- 리스트 렌더링 최적화 및 캐싱 컨트롤
- img onError 처리
- 미리보기 image-rendering 수정
- wsgi process over-creating 문제 해결
- [공통] 썸네일 조회 속도 개선
- [공통] 데이터 리스트 파일명 기본 이차 정렬 설정
- [공통] 가상, 오픈 컬럼 삭제
- [추출] 추출 속도 개선 (bag 확장자 변경 없이 바로 추출)
- [추출, 등록] 센서별 데이터 수 그룹 컬럼으로 수정
- [배분] 작업 셋 리스트 컬럼 추가 : 작업자, 난이도, 단가
- [작업] 작업 셋 탭 삭제 및 작업 셋 완료 기능 삭제
- [검수] 데이터 셋 탭 삭제 및 검수완료 기능 삭제
- [검수] 작업 셋 리스트 컬럼 
    - 삭제 : 배분담당자, 배분확정여부
    - 추가 : 작업자, 검수요청 날짜, 최근 검수반려 시간, 대금지급 날짜, 대금지급 비율, 총 객체 수(detection), track id 수(detection)
    - 수정 : 최신 검수날짜 -> 학습반영 날짜
- [검수] 정산 탭 삭제
- [검수] 기능 추가 : 작업회수, 대금지급 날짜 입력, 학습반영, 전체 검수자 변경 확인
- [검수] 데이터 리스트 컬럼 추가 : 검수 날짜, 대금지급 날짜, 학습 데이터 갱신 시간
- [학습] 데이터 셋 리스트 컬럼 추가 : 최근 학습 데이터 갱신 시간
- [학습] 미리보기 수집환경 메타데이터 추가

버그 수정 사항
- radio button style 버그 수정
- FE nginx header Cache-Control 추가`,
  },
  {
    version: "v1.4.2",
    date: "23.03.31",
    full: `주요 개발 사항
- 썸네일 필터링 기능 추가
- 참고 영상 제작 시 기존 영상 갱신되도록 변경
- 100개 이하는 데이터 파일 직접 다운로드
- [메인] 리스트 제목 해당 메뉴와 동일한 이름으로 변경
- [배분, 작업, 검수] 데이터 리스트 조회 쿼리 최적화
- [배분, 검수] 썸네일 리스트 작업자, 난이도 정보 추가
- [배분, 검수] 작업 셋 리스트 이름순 정렬
- [배분] 배분정보 리셋 기능 추가
- [배분] 작업셋 이름 수정 기능 업데이트
- [검수] 작업셋 이름 수정 기능 추가
- [검수] 정산 후 라벨 데이터 업로드/다운로드 기능 추가
- [검수] 작업 회수 시 배분대기 탭으로 이동
- [검수] 작업 셋 리스트 컬럼 정보 추가
- [학습] "라벨 데이터 버전" 컬럼 추가
- [학습] 학습리스트 export 시 "라벨 데이터 버전" 정보 추가

버그 수정 사항
- 학습 데이터 셋 리스트 조회 오류 수정
- 작업 회수 시 작업 셋 상태 업데이트`,
  },
  {
    version: "v1.4.1",
    date: "23.03.16",
    full: `주요 개발 사항
- [배분] Camera Detection, Radar Segmentation 원본 다운로드 버튼 추가

버그 수정 사항
- [검수] Camera Detection track id 수, 총 객체 수 컬럼 추가`,
  },
  {
    version: "v1.4",
    date: "23.03.10",
    full: `주요 개발 사항
- 내부 사용자는 모든 메뉴의 데이터 리스트 조회 가능
- 외부 사용자에게 [navlue 버전 변경 로그] 숨김
- 썸네일, 미리보기에서 이미지 종류 선택 가능
- 데이터 삭제 권한 확대 및 삭제 가능 여부 정보 추가
- 단일 파일 업로드로 변경 (파일 수 : 제한 없음, 파일 용량 : 2GB 제한) 
- 직접 다운로드 대신 파일경로 리스트 다운로드로 변경 (작업화면 제외)
- 페이지네이션 500개로 변경
- [메인] 데이터 리스트로 바로가기 추가
- [추출] 이미지 전처리 모듈 업데이트
- [추출] 단일 파일 추출로 변경
- [선별] [전체 학습용 데이터 선정으로 이동] 기능 추가
- [배분] 작업 셋 리스트 모달 추가 (조회, 생성, 삭제 등)
- [작업] [데이터 셋 가이드] 탭 추가
- [검수] 담당 고유 권한과 공통 권한 기능 분리
- [검수] 연속 이미지 조회를 위해 작업 셋 리스트에서 데이터 셋 리스트로 변경
- [검수] [배분대기] 탭 추가
- [검수] [데이터 셋 가이드] 탭 추가
- [검수] 검수자 변경 요청, 정산요청, 작업시작 리셋 등 기능 추가
- [검수] 작업 셋 리스트 모달 추가 (배분확정, 회수 등)

기타
- 네트워크 대역폭 변경
- 쿼리 최적화
- 마이그레이션`,
  },
  {
    version: "v1.3.0.4",
    date: "22.12.23",
    full: `사용성 개선 사항
- [등록] 센서번호 추가
- [작업] inspector 코드 업데이트 (v3.6, land bbox 표시 추가)

버그 수정 사항
- [학습] 데이터 리스트 조회 필터링 수정 (최종 검수 완료된 데이터만 조회)
- [환경] 데이터 삭제 시 해당 데이터 셋의 배분 완료 상태 확인 로직 추가

주요 개발 사항
- [추출] 추출 시 DB transaction 범위 변경 (전체 → 파일별 transaction)
- [검수, 학습] mask 생성 코드 업데이트 (inspector 코드와 버전 통일)

기타
- Camera Detection 3차 마이그레이션 (30,210장)`,
  },
  {
    version: "v1.3.0.3",
    date: "22.12.12",
    full: `사용성 개선 사항
- [UI] 객체 컬럼 그룹으로 분리
- [공통] 데이터 조회 시 ID 대신 이름 출력
- [환경] 배분대기 상태의 데이터까지 삭제 허용
- [배분] 본인 담당이 아닌 데이터도 배분 가능
- [배분] 배분 제외 시 난이도, 단가 정보 남아있도록 변경
- [작업] 센서번호 컬럼 추가
- [작업] 에러검사 실패 시 문구 수정
- [검수] 전체 탭에서 담당자/상태 상관없는 [원본 다운로드] 기능 추가
    
버그 수정 사항
- [공통] 컬럼 필터링 버그 수정
- [추출] LiDAR 원본 추출 시 버그 수정
- [작업] 참고영상 다운로드 권한 설정 버그 수정
- [학습] 학습 리스트 다운로드 다중 클릭 버그 수정

주요 개발 사항
- API 로깅 추가`,
  },
  {
    version: "v1.3.0.2",
    date: "22.11.30",
    full: `버그 수정 사항
- [UI] 썸네일 height 설정`,
  },
  {
    version: "v1.3.0.1",
    date: "22.11.22",
    full: `버그 수정 사항
- [학습] Camera Detection 학습 데이터 리스트 조회`,
  },
  {
    version: "v1.3",
    date: "22.11.21",
    full: `사용성 개선 사항
- [공통] 데이터셋 조회 속도 증가
- [공통] 진행 중인 작업 리스트 렌더링 최적화
- [선별] Camera Detection 추론 모델 업데이트
- [환경] 썸네일 뷰 맨 위로 버튼 추가
- [환경] 수집환경 수정 추가
- [배분] 참고영상 생성 속도 증가
- [배분] 난이도 L-1 추가
- [학습] 학습 리스트 다운로드 취소 추가
- [통계] 비율 소수점 둘째 자리까지 반올림 변경
- [통계] 선택 연도 기준 수집날짜 → 검수완료날짜 변경

주요 개발 사항
- 외부망 배포 (navlue.com)
- 웹 보안 강화
- 개인정보 처리방침 추가
- 비밀번호 변경 추가
- navlue 버전 변경로그 추가
- Chrome 외 브라우저 사용 제한
- [공통] 업로드 파일 확장자 및 파일명 확인 추가`,
  },
  {
    version: "v1.2.4.2",
    date: "22.09.28",
    full: `버그 수정 사항
- 미리보기 중앙정렬 및 zoom out 시 무한 스크롤되는 버그 수정
- 진행 중인 작업이 모달창을 가리는 버그 수정
- 같은 파일 업로드 시도할 때 반응이 없던 버그 수정
- 전체선택 버그 수정

사용성 개선 사항
- [공통] 데이터셋 리스트 조회 로딩시간 축소
- [공통] 썸네일 리스트 스크롤 시 위치 고정
- [공통] 탭별 컬럼 정보 다르게 출력
- [공통] 업로드 파일 확장자 제한
- [공통] 최소 해상도 설정 ( max width 960px )
- [공통] thumbnail view 일때 “컬럼선택” 선택박스 숨김
- [공통] 한 번에 업로드 파일 500개 제한
- [추출] 데이터셋 센서별 데이터 수 컬럼 GPS, IMU 정보 삭제
- [추출] 추출 시 중복 이미지 제외 처리
- [선별] 단축키 수정 ( 썸네일 리스트 대신, 미리보기에서 e[평가용], v[학습용] )
- [배분, 통계] 난이도 L0 추가
- [배분, 검수] 배분 회수 시 배분 탭으로 이동, 단가/난이도 정보 남기기
- [통계] inactive 작업자까지 모두 조회
- [작업] 에러검사 속도 향상`,
  },
  {
    version: "v1.2.4.1",
    date: "22.07.27",
    full: `버그 수정 사항
- 참고영상 생성 변경: 용량 축소, 확장자 avi->mp4 변경, fps 1->5 변경
- 데이터추출: 예외처리 추가, 멀티스레딩 처리 변경
- 진행 중인 작업 status 업데이트 버그 수정
    
사용성 개선 사항
- 참고영상 다운로드: 완료된 셋에서도 다운로드 가능 변경
- 에러 및 실패응답 문구 업데이트`,
  },
];

export default CHANGE_LOG;
