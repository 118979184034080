export class Category {
  static SENSOR = {
    CAMERA: "cam",
    RADAR: "rad",
  };

  static GT_CODE = {
    SEGMENTATiON: "seg",
    DETECTION: "det",
  };

  constructor(sensor, gtCode) {
    this.sensor = sensor;
    this.gtCode = gtCode;
  }

  valueOf() {
    return `${this.sensor}_${this.gtCode}`;
  }

  get title() {
    let sensorDesc = "";
    if (this.sensor === Category.SENSOR.CAMERA) {
      sensorDesc = "Camera";
    } else if (this.sensor === Category.SENSOR.RADAR) {
      sensorDesc = "Radar";
    }

    let gtDesc = "";
    if (this.gtCode === Category.GT_CODE.SEGMENTATiON) {
      gtDesc = "segmentation";
    } else if (this.gtCode === Category.GT_CODE.DETECTION) {
      gtDesc = "detection";
    }

    return `${sensorDesc} ${gtDesc}`;
  }

  get urlPath() {
    let sensorPath = "";
    if (this.sensor === Category.SENSOR.CAMERA) {
      sensorPath = "camera";
    } else if (this.sensor === Category.SENSOR.RADAR) {
      sensorPath = "radar";
    }

    let gtPath = "";
    if (this.gtCode === Category.GT_CODE.SEGMENTATiON) {
      gtPath = "segmentation";
    } else if (this.gtCode === Category.GT_CODE.DETECTION) {
      gtPath = "detection";
    }

    return `/${sensorPath}/${gtPath}`;
  }
}

export const CATEGORY = {
  [`${Category.SENSOR.CAMERA}_${Category.GT_CODE.SEGMENTATiON}`]: new Category(
    Category.SENSOR.CAMERA,
    Category.GT_CODE.SEGMENTATiON
  ),
  [`${Category.SENSOR.CAMERA}_${Category.GT_CODE.DETECTION}`]: new Category(
    Category.SENSOR.CAMERA,
    Category.GT_CODE.DETECTION
  ),
  [`${Category.SENSOR.RADAR}_${Category.GT_CODE.SEGMENTATiON}`]: new Category(
    Category.SENSOR.RADAR,
    Category.GT_CODE.SEGMENTATiON
  ),
};
