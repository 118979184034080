import React, { useState, useEffect, useMemo, useRef } from "react";
import { useRecoilValueLoadable } from "recoil";
import { DataGrid } from "@mui/x-data-grid";
import { Loader, LoadFail } from "../../../../../components/atoms/Status.js";
import { datasetListQuery } from "../../../Main.stores.js";
import { useDynamicRouting } from "../../../../../lib/context/useDynamicRouting";
import { Pagination } from "../Pagination.jsx";

const PAGE_LIMIT = 100;

export const DatasetList = ({ filters, category, storeSelectedDataset, setHoveredDataset }) => {
  const { linkTo } = useDynamicRouting();

  const combinedSensorGtCode = `${category.sensor}_${category.gtCode}`;

  const [page, setPage] = useState(1);

  const [sortModel, setSortModel] = useState({
    field: SORT_BY.SORT.clct_cmp_t.value,
    sort: SORT_BY.SORT_TYPE.desc,
  });

  const { state, contents } = useRecoilValueLoadable(
    datasetListQuery({
      menu: "vld",
      sensor_code: category.sensor,
      gt_code: category.gtCode,
      query: createFilterObject({ filters, sortModel, page }),
    })
  );

  const totalPage = useMemo(() => {
    if (state === "hasValue") {
      return Math.trunc(contents.count / PAGE_LIMIT) + 1;
    } else {
      return 1;
    }
  }, [state, contents]);

  useEffect(() => {
    setSortModel({
      field: SORT_BY.SORT[`${category.gtCode}_auto_vld_t`].value,
      sort: SORT_BY.SORT_TYPE.desc,
    });
  }, [category]);

  const clickTimeoutRef = useRef(null);

  const columns = [
    {
      field: FIELD.index.value,
      headerName: FIELD.index.label,
      width: "60",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const sortedRows = params.api.getSortedRows();
        const rowIndex = sortedRows.findIndex((row) => row.id === params.row.id);
        return (page - 1) * PAGE_LIMIT + rowIndex + 1;
      },
      sortable: false,
      filterable: false,
    },
    {
      field: FIELD.dataset_name.value,
      headerName: FIELD.dataset_name.label,
      width: "200",
    },
    {
      field: FIELD.date.value,
      headerName: FIELD.date.label,
      width: "100",
      headerAlign: "center",
      align: "center",
    },
    {
      field: FIELD.img_cnt.value,
      headerName: FIELD.img_cnt.label,
      width: "80",
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => addCommanToNumber(value),
      sortable: false,
    },
    {
      field: FIELD.clct_cmp_t.value,
      headerName: FIELD.clct_cmp_t.label,
      width: "160",
      headerAlign: "center",
      align: "center",
    },
    {
      field: FIELD[`${category.gtCode}_auto_status`].value,
      headerName: FIELD[`${category.gtCode}_auto_status`].label,
      renderCell: ({ value }) => {
        const style =
          value == 1
            ? "text-[#676767]"
            : value == 2
              ? "bg-[#C4C4C4] text-[white]"
              : value == 3
                ? "bg-primary text-[white]"
                : value == 4
                  ? "bg-[#081A51] text-[white]"
                  : "";

        return (
          <div>
            <span className={`inline-block rounded-[2px] px-[8px] ${style}`}>
              {value == 1
                ? "X"
                : value == 2
                  ? "예약"
                  : value == 3
                    ? "진행"
                    : value == 4
                      ? "완료"
                      : "-"}
            </span>
          </div>
        );
      },
      width: "96",
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: FIELD[`${category.gtCode}_auto_vld_t`].value,
      headerName: FIELD[`${category.gtCode}_auto_vld_t`].label,
      renderCell: (params) => {
        const { value } = params;
        return value ? <span>{value}</span> : <span>-</span>;
      },
      width: "160",
      headerAlign: "center",
      align: "center",
    },
    {
      field: FIELD[`${category.gtCode}_cmp_ratio`].value,
      headerName: FIELD[`${category.gtCode}_cmp_ratio`].label,
      renderCell: (params) => {
        const { value, row } = params;
        return value ? (
          <span
            title={value == 100 ? row[`${category.gtCode}_latest_vld_t`] : ""}
          >{`${value}%`}</span>
        ) : (
          <span>-</span>
        );
      },
      width: "110",
      headerAlign: "center",
      align: "center",
    },
    {
      field: FIELD[`${combinedSensorGtCode}_pri`].value,
      headerName: FIELD[`${combinedSensorGtCode}_pri`].label,
      renderCell: ({ value }) => {
        // 배열을 쉼표로 구분된 문자열로 변환
        return value
          ? [...Array(value)].map((_, index) => (
              <span
                key={index}
                className={value === 5 ? "text-[#2878FF]" : "text-[#676767]"}
                style={{ marginRight: "2px" }}
              >
                ●
              </span>
            ))
          : "-";
      },
      width: "110",
      flex: "1",
    },
  ];

  const handleRowClick = ({ row }) => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = null;
      return;
    }

    clickTimeoutRef.current = setTimeout(() => {
      storeSelectedDataset({ name: row.dataset_name, id: row.id });
      setHoveredDataset(null);
      clickTimeoutRef.current = null;
    }, 200);
  };

  const handleRowDoubleClick = ({ row }) => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = null;
    }
    linkTo({ pathname: `/validation${category.urlPath}/${row.id}/${row.dataset_name}` });
  };

  const handleRowEnter = ({ row }) => {
    setHoveredDataset({ name: row.dataset_name, id: row.id });
  };

  const handleRowLeave = () => {
    setHoveredDataset(null);
  };

  const handleSortModelChange = (newModel) => {
    if (newModel.length === 0) {
      setSortModel({ field: sortModel.field, sort: SORT_BY.SORT_TYPE.desc });
    } else {
      setSortModel(newModel[0]);
    }
    setPage(1);
  };

  return (
    <>
      <div className="flex-grow overflow-auto">
        {state === "hasError" ? (
          <ErrorOverlay />
        ) : (
          <div className="h-full w-full bg-white">
            <DataGrid
              style={{ minWidth: "1148px" }}
              rows={contents.results ?? []}
              columns={columns}
              loading={state === "loading"}
              getRowId={(row) => row.id}
              columnHeaderHeight={32}
              rowHeight={40}
              disableColumnMenu
              hideFooter
              onRowClick={handleRowClick}
              onRowDoubleClick={handleRowDoubleClick}
              sortingOrder={["desc", "asc", null]}
              sortModel={[sortModel]}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              slotProps={{
                row: {
                  onMouseEnter: (event) => {
                    const id = Number(event.currentTarget.getAttribute("data-id"));
                    handleRowEnter({ row: contents.results.find((r) => r.id === id) });
                  },
                  onMouseLeave: handleRowLeave,
                },
              }}
              slots={{
                columnSortedDescendingIcon: DownwardIcon,
                columnSortedAscendingIcon: UpwardIcon,
                columnUnsortedIcon: SortableIcon, // 새로 추가된 prop
                loadingOverlay: LoadingOverlay,
                NoRowsOverlay: EmptyOverlay,
              }}
              sx={customTableStyle}
            />
          </div>
        )}
      </div>
      <Pagination page={page} setPage={setPage} totalPage={totalPage} />
    </>
  );
};

const DownwardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3 5H13M3 8.4968H10M3 12L6 11.9947"
      stroke="#2B63C1"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UpwardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3 5H6.00176M3 8.4968H10.0041M3 12H13"
      stroke="#2B63C1"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SortableIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3254 2.62034L8.00001 2.34143L7.67461 2.62034L4.17461 5.62034C3.96495 5.80005 3.94067 6.1157 4.12038 6.32537C4.30009 6.53503 4.61574 6.55931 4.8254 6.3796L8.00001 3.65851L11.1746 6.3796C11.3843 6.55931 11.6999 6.53503 11.8796 6.32537C12.0593 6.1157 12.0351 5.80005 11.8254 5.62034L8.3254 2.62034ZM4.8254 9.62034C4.61574 9.44063 4.30009 9.46491 4.12038 9.67457C3.94067 9.88424 3.96495 10.1999 4.17461 10.3796L7.67461 13.3796L8.00001 13.6585L8.3254 13.3796L11.8254 10.3796C12.0351 10.1999 12.0593 9.88424 11.8796 9.67457C11.6999 9.46491 11.3843 9.44063 11.1746 9.62034L8.00001 12.3414L4.8254 9.62034Z"
      fill="#808080"
    />
  </svg>
);

const LoadingOverlay = () => (
  <div className="flex h-full items-center justify-center">
    <Loader />
  </div>
);

const ErrorOverlay = () => (
  <div className="flex h-full items-center justify-center">
    <LoadFail failType="데이터를 불러오는데 실패했습니다." />
  </div>
);

const EmptyOverlay = () => (
  <div className="flex h-full items-center justify-center">
    <div className="text-[16px] leading-[20px] tracking-[-0.32px] text-[#BEBEBE]">
      자동 선별 데이터가 없습니다.
    </div>
  </div>
);

const addCommanToNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const createFilterObject = ({ filters, sortModel, page }) => {
  const filterObject = {
    page,
    sort: SORT_BY.SORT[sortModel.field].apiKey,
    sortType: sortModel.sort,
  };

  if (filters.length > 0) {
    filterObject.dataset = filters.map((filter) => filter.join("/")).join(",");
  }
  return filterObject;
};

const FIELD = {
  index: {
    value: "index",
    label: "번호",
  },
  dataset_name: {
    value: "dataset_name",
    label: "데이터셋",
  },
  date: {
    value: "date",
    label: "날짜",
  },
  img_cnt: {
    value: "img_cnt",
    label: "데이터",
  },
  clct_cmp_t: {
    value: "clct_cmp_t",
    label: "등록완료 일시",
  },
  seg_auto_status: {
    value: "seg_auto_status",
    label: "자동선별",
  },
  det_auto_status: {
    value: "det_auto_status",
    label: "자동선별",
  },
  seg_auto_vld_t: {
    value: "seg_auto_vld_t",
    label: "자동선별 완료 일시",
  },
  det_auto_vld_t: {
    value: "det_auto_vld_t",
    label: "자동선별 완료 일시",
  },
  seg_cmp_ratio: {
    value: "seg_cmp_ratio",
    label: "선별완료",
  },
  det_cmp_ratio: {
    value: "det_cmp_ratio",
    label: "선별완료",
  },
  cam_seg_pri: {
    value: "cam_seg_pri",
    label: "우선순위",
  },
  cam_det_pri: {
    value: "cam_det_pri",
    label: "우선순위",
  },
};

const SORT_BY = {
  SORT: {
    [FIELD.dataset_name.value]: {
      value: FIELD.dataset_name.value,
      apiKey: "datasetName",
    },
    [FIELD.date.value]: {
      value: FIELD.date.value,
      apiKey: "date",
    },
    [FIELD.clct_cmp_t.value]: {
      value: FIELD.clct_cmp_t.value,
      apiKey: "clctCmpT",
    },
    [FIELD.seg_cmp_ratio.value]: {
      value: FIELD.seg_cmp_ratio.value,
      apiKey: "segCmpRatio",
    },
    [FIELD.cam_seg_pri.value]: {
      value: FIELD.cam_seg_pri.value,
      apiKey: "camSegPri",
    },
    [FIELD.seg_auto_vld_t.value]: {
      value: FIELD.seg_auto_vld_t.value,
      apiKey: "segAutoVldT",
    },
    [FIELD.det_auto_vld_t.value]: {
      value: FIELD.det_auto_vld_t.value,
      apiKey: "detAutoVldT",
    },
  },
  SORT_TYPE: {
    asc: "asc",
    desc: "desc",
  },
};

const customTableStyle = {
  "& .MuiDataGrid-columnHeader": {
    background: "#F2F2F2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    fontFamily: "Spoqa Han Sans Neo",
    color: "#676767",
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "-0.24px",
    fontWeight: "bold",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader--sorted": {
    color: "#2B63C1", // 선택된 정렬 열의 텍스트 색상
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    width: "100%",
    padding: "0 8px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    display: "flex",
    alignItems: "center",
    overflow: "visible", // 변경된 부분
  },
  "& .MuiDataGrid-sortIcon": {
    marginLeft: "4px",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Spoqa Han Sans Neo",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#333333",
    lineHeight: "15px",
    letterSpacing: "-0.24px",
    cursor: "pointer",
  },
  "& .MuiDataGrid-cell": {
    padding: "0 16px",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "auto",
  },
};
