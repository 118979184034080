import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { extrClctCntQuery, extrClctListQuery } from "./Extr.stores";
import { EXRT_DATA_TAB, EXTR_CLCT_COLS } from "./Extr.constants";
import { ExtrCmp, UploadBag } from "./ExtrActions";
import { extrClctFilter } from "./Extr.apis";

const ExtrClctPage = () => {
  const {
    urlParams,
    queryParams: { tab, t },
  } = useDynamicRouting();

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    extrClctCntQuery({ ...urlParams, tab, t })
  );

  return (
    <CommonTemplate
      listQuery={extrClctListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      filterApi={extrClctFilter}
      fullColumnData={EXTR_CLCT_COLS.tab}
      columnData={EXTR_CLCT_COLS.tab}
      tabData={[{ value: "1", ko: "전체" }]}
      groupColumnData={EXRT_DATA_TAB}
      actions={({ selectedItems }) => (
        <>
          <UploadBag />
          <SelectCnt count={selectedItems.length} />
          <ExtrCmp selectedItems={selectedItems} />
        </>
      )}
    />
  );
};

export default ExtrClctPage;
