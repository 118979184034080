import { axiosInstance } from "../../components/templates/RootErrorBoundary";

const getUrlParams = (tab) =>
  tab === "2"
    ? { sensor_code: "cam", gt_code: "seg" }
    : tab === "3"
      ? { sensor_code: "cam", gt_code: "det" }
      : tab === "4"
        ? { sensor_code: "rad", gt_code: "seg" }
        : null;

export const statsTotal = async ({ tab }) => {
  if (tab !== "1") return null;

  const { data } = await axiosInstance.get(`/stats/total/`);

  return data;
};

export const statsSensor = async ({ tab }) => {
  if (tab === "1") return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(`/stats/${sensor_code}/${gt_code}/`);

  return data;
};

export const statsCond = async ({ tab, berths }) => {
  if (tab === "1" || tab === "4" || !berths) return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(`/stats/${sensor_code}/${gt_code}/cond/${berths}/`);

  return data;
};

export const statsCondValues = async ({ tab }) => {
  if (tab === "1" || tab === "4") return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(`/stats/${sensor_code}/${gt_code}/cond/values/`);

  return data;
};

export const statsMonth = async ({ tab, years }) => {
  if (tab === "1" || !years) return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(`/stats/${sensor_code}/${gt_code}/month/${years}/`);

  return data;
};

export const statsMonthValues = async ({ tab }) => {
  if (tab === "1") return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(`/stats/${sensor_code}/${gt_code}/month/values/`);

  return data;
};

export const statsMonthWork = async ({ tab, years, months }) => {
  if (tab === "1" || !years || !months) return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(
    `/stats/${sensor_code}/${gt_code}/month_work/${years}/${months}/`
  );

  return data;
};

export const statsMonthWorkValues = async ({ tab }) => {
  if (tab === "1") return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(`/stats/${sensor_code}/${gt_code}/month_work/values/`);

  return data;
};

export const statsDist = async ({ tab, years, workers }) => {
  if (tab === "1" || !years || !workers) return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(
    `/stats/${sensor_code}/${gt_code}/dist/${years}/${workers}/`
  );

  return data;
};

export const statsDistValues = async ({ tab }) => {
  if (tab === "1") return null;
  const { sensor_code, gt_code } = getUrlParams(tab);

  const { data } = await axiosInstance.get(`/stats/${sensor_code}/${gt_code}/dist/values/`);

  return data;
};
