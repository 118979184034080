import { useState } from "react";

export const useHookModals = (initialState) => {
  const [modal, setModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(initialState);

  const openModal = () => setModal(true);
  const closeModal = () => {
    setModal(false);
    setModalStatus(null);
  };

  return { modal, openModal, closeModal, modalStatus, setModalStatus };
};
