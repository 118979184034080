import { useRef, useState } from "react";
import ModalContainer from "../../components/molecules/ModalContainer";
import { ModalBottom } from "../../components/molecules/Modals";
import FloatingItemCreator from "../../components/organisms/floating/FloatingItemCreator";
import CommonTableWrapper from "../../components/organisms/CommonTableWrapper";
import { ConfirmButton } from "../../components/organisms/actions";
import { CommonButton } from "../../components/molecules/Buttons";
import { AcceptModal, ConfirmModal, EmptyModal } from "../../components/molecules/CommonModals";
import { checkFile } from "../../lib/utils";
import { useModals, useFileUploader, useIdProcessor } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { EXTR_CHECK_COL } from "./Extr.constants";
import { extrLogCheck, extrLogUl, extrCmp, extrDataDel, extrLog } from "./Extr.apis";

export const UploadBag = () => {
  const FULL_COLUMNS = EXTR_CHECK_COL;
  const accept = "log";

  const { process } = useFileUploader();

  const fileInput = useRef();
  const uploadSource = useRef(null);
  const [failType, setFailType] = useState(null);
  const [selectFile, setSelectFile] = useState(null);
  const [checkState, setCheckState] = useState(null);
  const [checkContents, setCheckContents] = useState(null);
  const [floatingItemContents, setFloatingItemContents] = useState(null);
  const [checkFileResult, setCheckFileResult] = useState(false);
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetState = () => {
    setCheckFileResult(false);
    setFailType(null);
    setCheckState(null);
    setCheckContents(null);
    setModalStatus(null);
    setFloatingItemContents(null);
    uploadSource.current = null;
  };

  // 1. 버튼 클릭시 파일 선택 창 열기
  const handleFileInput = async (e) => {
    resetState();

    const files = e.target.files;
    const newFileList = Array.from(files);
    const len = files.length;

    // 2. 선택된 파일이 없으면 return
    if (len < 1) return null;

    // 3. 업로드 파일 체크
    const isFailed = checkFile(len, files, accept);
    if (isFailed) {
      setCheckFileResult(isFailed);
    } else {
      setCheckState("loading");

      // 4. 선택한 파일 정보로 진행 중인 작업 패널에 뿌릴 리스트를 생성
      let filenameArr = [];
      let uploadArr = [];

      for (let i = 0; i < len; i++) {
        filenameArr = [...filenameArr, files[i].name];
        uploadArr = [
          ...uploadArr,
          {
            filename: files[i].name,
            floatingStatus: "대기",
          },
        ];
      }

      // 5. log 파일 업로드 전 확인 단계 필요
      try {
        const data = await extrLogCheck({
          filenames: filenameArr,
        });

        setCheckState("hasValue");
        setCheckContents(data);

        setFloatingItemContents(uploadArr);
        setSelectFile(newFileList);
      } catch (err) {
        console.log(err?.response);

        let err_detail = err?.response?.data?.detail || "";

        setCheckState("hasError");
        setModalStatus("hasError");
        setFailType(err_detail);
      }
    }

    // 6. 모달 열기
    openModal();
  };

  // 7. 업로드 버튼 클릭
  const handleUploadFile = async (rowId) => {
    closeModal();
    process({
      rowId,
      api: extrLogUl,
      selectFile,
    });
  };

  return (
    <>
      <input
        type="file"
        name="file"
        ref={fileInput}
        onChange={handleFileInput}
        onClick={(e) => (e.target.value = null)}
        style={{ display: "none" }}
        multiple
      />
      <CommonButton
        name="log 데이터 업로드"
        icon="IC_upload_w"
        onClick={() => {
          fileInput.current.click();
        }}
      />

      {modal &&
        (checkFileResult ? (
          <AcceptModal closeModal={closeModal} accept={accept} checkFileResult={checkFileResult} />
        ) : (
          <ModalContainer
            modalType="table_modal"
            title="log 데이터 업로드 정보 확인"
            closeModal={closeModal}
            desc={{
              text: "선택한 데이터에 대한 수집 정보를 확인해 주세요. 업로드 후에는 수정할 수 없습니다.",
            }}
          >
            <CommonTableWrapper
              header={FULL_COLUMNS}
              state={checkState}
              contents={checkContents?.length > 0 && checkContents}
              tableModal
            />
            <ModalBottom hasStatus={{ modalStatus, failType }}>
              {checkState !== "loading" && modalStatus !== "hasError" ? (
                <FloatingItemCreator
                  panelname="log 데이터 업로드"
                  name="업로드"
                  handleUploadFile={handleUploadFile}
                  floatingItemContents={floatingItemContents}
                />
              ) : null}
            </ModalBottom>
          </ModalContainer>
        ))}
    </>
  );
};

export const ExtrCmp = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={extrCmp}
      body={{ clct_ids: selectedItems }}
      actionName="등록완료"
      icon="IC_complete_w"
    />
  );
};

export const ExtrLog = ({ selectedItems, contents }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { process } = useIdProcessor();
  const { modal, openModal, closeModal, modalStatus } = useModals(null);

  const [floatingItemContents, setFloatingItemContents] = useState(null);

  const addItem = () => {
    // 1. 선택한 아이디의 파일명을 찾기위해 정보 가져오기
    const files = contents.filter((item) => selectedItems.includes(item.id, 0));
    const len = files.length;

    // 2. 선택한 정보로 진행 중인 작업 패널에 뿌릴 리스트를 생성
    let uploadArr = [];
    for (let i = 0; i < len; i++) {
      uploadArr = [
        ...uploadArr,
        {
          id: files[i].id,
          filename: files[i].filename,
          floatingStatus: "추출대기",
        },
      ];
    }
    setFloatingItemContents(uploadArr);

    // 3. 모달 열기
    openModal();
  };

  const handleClick = async (rowId) => {
    closeModal();
    process({
      rowId,
      urlInfo,
      api: extrLog,
      ids: selectedItems,
    });
  };

  return (
    <>
      <CommonButton name="데이터 추출" icon="IC_data_w" onClick={addItem} />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title="데이터 추출 정보 확인"
            count={selectedItems.length}
            modalStatus={modalStatus}
            panelname="데이터 추출"
            handleUploadFile={handleClick}
            floatingItemContents={floatingItemContents}
          />
        ))}
    </>
  );
};

export const ExtrDel = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={extrDataDel}
      hasPath
      body={{ ids: selectedItems }}
      actionName="데이터 삭제"
      icon="IC_delete_w"
      type="secondary"
    />
  );
};
