import { useState } from "react";
import PRIVACY_POLICY from "../../../lib/constants/privacy";
import { logout, privacyPolicy } from "../../../lib/apis";
import { removeToken } from "../../../lib/utils";
import { PreLine } from "../../atoms/CommonAtoms";
import ModalContainer from "../../molecules/ModalContainer";
import { CommonButton } from "../../molecules/Buttons";
import { ModalBottom } from "../../molecules/Modals";

const PrivacyModal = (props) => {
  const { main, closeModal } = props;
  const refresh = sessionStorage.getItem("refresh");

  const [failType, setFailType] = useState("");
  const [modalStatus, setModalStatus] = useState("");

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await privacyPolicy({ agreed: true });

      console.log(data);
      closeModal && closeModal();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";
      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <ModalContainer modalType="privacy" title="개인정보 수집 및 이용약관" closeModal={() => null}>
      <ul className="h-[calc(80vh-136px)] overflow-y-auto px-[16px]">
        <PreLine text={PRIVACY_POLICY} />
      </ul>
      <ModalBottom hasStatus={main && { modalStatus, failType }}>
        {main ? (
          <>
            <CommonButton
              name="동의 안함"
              onClick={async () => {
                await logout(refresh);
                removeToken();
              }}
              type="secondary_modal_btn"
            />
            <CommonButton name="전체 동의" onClick={handleClick} type="modal_btn" />
          </>
        ) : null}
      </ModalBottom>
    </ModalContainer>
  );
};

export default PrivacyModal;
