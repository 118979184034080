import { axiosInstance } from "../components/templates/RootErrorBoundary";

// 토큰 생성(로그인)
export const login = async (username, password) => {
  const { data } = await axiosInstance.post("/members/login/", {
    username,
    password,
  });

  return data;
};

// 토큰 삭제(로그아웃)
export const logout = async (refresh) => {
  const { data } = await axiosInstance.post("/members/logout/", {
    refresh,
  });

  return data;
};

// 토큰 갱신
// export const refreshToken = async (refresh) => {
//   const { data } = await axiosInstance.post('/members/token/refresh/', {
//     refresh,
//   });

//   return data;
// };

// 내 계정정보 조회
export const userInfo = async () => {
  const { data } = await axiosInstance.get("/members/myinfo/");

  return data;
};

// 비밀번호 변경
export const passwordChange = async (body) => {
  const { data } = await axiosInstance.post("/members/password/change/", body);

  return data;
};

// 이용약관 동의
export const privacyPolicy = async (body) => {
  const { data } = await axiosInstance.post("/members/terms/", body);

  return data;
};

// 원본 데이터 폐기
export const dataDel = async (path, body) => {
  const { sensor_code, set_id, tab } = path;

  const { data } = await axiosInstance.post(
    `/clct/${sensor_code ? sensor_code : tab === "1" ? "cam" : "rad"}/datas/${set_id}/del/`,
    body
  );

  return data;
};
