import React, { useEffect } from "react";
import { Table, TableBody, TableContainer } from "@mui/material";

const FloatingRenderTable = (props) => {
  const { page, children, renderHeader } = props;

  let ulRef;

  useEffect(() => {
    ulRef.scrollTo(0, 0);
  }, [page]);

  return (
    <div className="h-[calc(100%-62px)] px-[16px]">
      <TableContainer
        className="scroll_head h-full overflow-y-auto border-t border-gray400"
        ref={(el) => (ulRef = el)}
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          {renderHeader()}
          <TableBody style={{ whiteSpace: "nowrap" }}>{children}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default React.memo(FloatingRenderTable);
