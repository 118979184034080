import { useState } from "react";
import { TextField } from "@mui/material";
import { useModals, useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { checkInputText } from "../../../lib/utils";
import { SelectWrap, SelectName } from "../../atoms/SelectBox";
import { CommonButton } from "../../molecules/Buttons";
import { EmptyModal, SaveModal } from "../../molecules/CommonModals";

// 입력: 작업셋 이름
export const WorkSetNameAction = (props) => {
  const { api, title, icon, selectedItems, tableContents } = props;

  const defaultValue =
    (selectedItems?.length > 0 &&
      tableContents?.results?.find((item) => item.id === selectedItems[0])?.name) ||
    "";

  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState(defaultValue || "");

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const { modalReload } = useReload();

  const resetOpen = () => {
    setValue(defaultValue);
    setModalStatus(null);
    setFailType(null);
    setRequired(false);
    openModal();
  };

  const checkInput = () => {
    if (!value) {
      setModalStatus("hasError");
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else if (value === defaultValue) {
      setModalStatus("hasError");
      setFailType("기존 작업 셋 이름과 달라야 합니다.");
      setRequired(true);
    } else if (checkInputText([value], true)) {
      setModalStatus("hasError");
      setFailType("대소문자 영문, 한글, 숫자, 특수문자(- _)만 사용가능합니다.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    const body = selectedItems ? { name: value, workset_id: selectedItems[0] } : { name: value };

    try {
      const data = await api(urlInfo, body);

      console.log(data);
      modalReload();
      closeModal();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  return (
    <>
      <CommonButton name={title} icon={icon} type="modal_btn" onClick={resetOpen} />

      {modal &&
        (selectedItems?.length === 0 ? (
          <EmptyModal closeModal={closeModal} blackBackground={20} />
        ) : selectedItems?.length > 1 ? (
          <EmptyModal
            detail="작업 셋 이름은 한 개씩 선택하여 수정해 주세요."
            closeModal={closeModal}
            blackBackground={20}
          />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title={`작업 셋 ${title}`}
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
            blackBackground={20}
          >
            <SelectWrap required={required && !value}>
              <SelectName name="작업 셋 이름" />
              <TextField
                hiddenLabel
                size="small"
                onChange={(e) => setValue(e.target.value)}
                inputProps={{ maxLength: 100 }}
                value={value}
              />
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};
