import React, { useState } from "react";
import { Category, CATEGORY } from "../../Category.js";
import { Summary } from "./Summary.jsx";
import { DatasetList } from "./DatasetList.jsx";

export const SummaryAndDatasetList = ({
  category,
  setCategory,
  filters,
  storeSelectedDataset,
  setHoveredDataset,
}) => {
  const handleClick = (category) => () => {
    setCategory(category);
  };

  return (
    <div className="flex h-full flex-[1_0_0%] flex-col gap-[16px] overflow-auto">
      <div className="flex h-full flex-grow flex-col gap-[16px] border border-[#e1e1e1] p-[16px]">
        <div className="flex justify-between gap-[16px]">
          <Summary
            category={CATEGORY[`${Category.SENSOR.CAMERA}_${Category.GT_CODE.SEGMENTATiON}`]}
            isSelected={category.gtCode === Category.GT_CODE.SEGMENTATiON}
            handleClick={handleClick(
              CATEGORY[`${Category.SENSOR.CAMERA}_${Category.GT_CODE.SEGMENTATiON}`]
            )}
          />
          <Summary
            category={CATEGORY[`${Category.SENSOR.CAMERA}_${Category.GT_CODE.DETECTION}`]}
            isSelected={category.gtCode === Category.GT_CODE.DETECTION}
            handleClick={handleClick(
              CATEGORY[`${Category.SENSOR.CAMERA}_${Category.GT_CODE.DETECTION}`]
            )}
          />
        </div>
        <DatasetList
          category={category}
          filters={filters}
          storeSelectedDataset={storeSelectedDataset}
          setHoveredDataset={setHoveredDataset}
        />
      </div>
    </div>
  );
};
