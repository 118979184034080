import { ConfirmAction } from "./ConfirmAction";
import { BatchDownloadAction } from "./BatchDownloadAction";
import { DownloadAllAction } from "./DownloadAllAction";
import { DownloadAction } from "./DownloadAction";
import { FloatingAction } from "./FloatingAction";
import { FloatingInsertAction } from "./FloatingInsertAction";
import { FloatingSelectAction } from "./FloatingSelectAction";
import { AcceptAction } from "./AcceptAction";
import { QnaAction } from "./QnaAction";
import { TableAction } from "./TableAction";
import { DelCheckAction, DataDeleteConfirmation } from "./DelCheckAction";
import { SelectInsertAction } from "./SelectInsertAction";
import { WorkSetNameAction } from "./WorkSetNameAction";
import { ErrorInspectAction } from "./ErrorInpectAction";

export const AcceptButton = AcceptAction;

export const BatchDownloadButton = BatchDownloadAction;

export const DownloadAllButton = DownloadAllAction;

export const ConfirmButton = ConfirmAction;

export const DelCheckButton = DelCheckAction;

export const DataDeleteConfirmationButton = DataDeleteConfirmation;

export const DownloadButton = DownloadAction;

export const FloatingButton = FloatingAction;

export const FloatingInsertButton = FloatingInsertAction;

export const FloatingSelectButton = FloatingSelectAction;

export const QnaButton = QnaAction;

export const SelectInsertButton = SelectInsertAction;

export const TableButton = TableAction;

export const WorkSetNameButton = WorkSetNameAction;

export const ErrorInspectButton = ErrorInspectAction;
