import { useRef, useState } from "react";
import clsx from "clsx";
import { useModals, useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { checkFile } from "../../../lib/utils";
import { ThumbImg } from "../../atoms/CommonAtoms";
import { EmptyImg } from "../../atoms/Status";
import ModalContainer from "../../molecules/ModalContainer";
import { CommonButton } from "../../molecules/Buttons";
import { ModalBottom } from "../../molecules/Modals";
import {
  SelectBox,
  SelectItem,
  SelectName,
  SelectRadio,
  SelectTitle,
  SelectWrap,
} from "../../atoms/SelectBox";
import { workWorkSetList } from "../../../pages/work/Work.apis";

export const QnaAction = ({ id, api, title, placeholder, type, icon }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const fileInput = useRef();
  const [text, setText] = useState(""); // 질문 또는 답변
  const [imgBase64, setImgBase64] = useState(""); // 이미지 파일 base64 (선택한 이미지 썸네일 출력용)
  const [imgFile, setImgFile] = useState(null); // 이미지 파일
  const [open, setOpen] = useState(false); // 작업셋 선택박스 열림 여부
  const [infoList, setInfoList] = useState([]); // 작업셋 리스트
  const [value, setValue] = useState(null); // 선택한 작업셋
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(false);
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);
  const { listReload } = useReload();

  const resetOpen = () => {
    // 질문등록일때는 작업셋 리스트 필요
    type === "question" && getInfoList();

    // 상태 리셋
    setValue(null);
    setOpen(false);
    setText("");
    setImgBase64("");
    setImgFile(null);
    setFailType("");
    setRequired(false);

    // 모달 오픈
    openModal();
  };

  const getInfoList = async () => {
    setModalStatus("loading");

    try {
      const data = await workWorkSetList(urlInfo);

      console.log(data.values.worksets);
      setInfoList(data.values.worksets);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const checkInput = () => {
    setModalStatus("hasError");

    if (type === "question" && !value) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else if (!text) {
      setFailType(type === "question" ? "질문을 입력해 주세요." : "답변을 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    setText(value);
  };

  const handleFileInput = async (e) => {
    const files = e.target.files;
    const newFileList = Array.from(files);
    const accept = "png";

    if (newFileList.length === 0) return;

    const isFailed = checkFile(files.length, files, accept);
    if (isFailed) {
      const t =
        isFailed === "invalidExt"
          ? "png 파일만 업로드 가능합니다."
          : isFailed === "invalidName" &&
            "파일명에는 대소문자 영문, 숫자, 특수문자(- _ .)만 사용가능합니다.";

      setFailType(t);
      setModalStatus("hasError");
      setImgBase64("");
    } else {
      // 이미지 파일 상태 업데이트
      let reader = new FileReader();

      reader.onloadend = () => {
        // 2. 읽기가 완료되면 아래코드가 실행됩니다.
        const base64 = reader.result;
        if (base64) {
          // 이미지 파일 base64 상태 업데이트
          setImgBase64(base64.toString());
          setFailType("");
          setModalStatus(null);
        }
      };

      if (files[0]) {
        // 1. 파일을 읽어 버퍼에 저장합니다.
        reader.readAsDataURL(files[0]);
      }
    }
    setImgFile(files);
  };

  const handleClick = async () => {
    setFailType(null);
    setModalStatus("loading");
    const formData = new FormData();
    imgFile && formData.append("file", imgFile[0]);
    formData.append(type, text);
    type === "question" && formData.append("workset_id", JSON.parse(value)[0]);

    try {
      const data = await api({ ...urlInfo, id }, formData);

      console.log(data);
      closeModal();
      listReload();
    } catch (err) {
      let err_detail = err?.response?.data?.detail || "";

      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  return (
    <>
      <CommonButton name={title} onClick={resetOpen} icon={icon} />

      {modal && (
        <ModalContainer
          modalType={type === "question" ? "question_modal" : "answer_modal"}
          title={title}
          closeModal={closeModal}
        >
          {/* 질문등록일때는 작업 셋 선택 */}
          {type === "question" && (
            <div
              className={clsx(
                "relative mx-[16px] mb-[16px] rounded-[2px] border border-gray400 px-[16px] py-[8px]",
                modalStatus,
                modalStatus === "loading" &&
                  "before:absolute before:left-0 before:top-0 before:z-10 before:h-full before:w-full before:bg-black/10"
              )}
            >
              <ul className="flex flex-col flex-nowrap justify-between bg-white">
                <SelectWrap required={required && !value}>
                  <SelectName name="작업 셋 이름" />
                  <SelectTitle
                    onClick={() => setOpen(open ? null : "set_id")}
                    name={JSON.parse(value)?.[1] || "선택"}
                    styleType="modal"
                  />
                  <SelectBox open={open === "set_id"} styleType="modal">
                    {infoList?.map((x, i) => (
                      <SelectItem key={i} styleType="list">
                        <SelectRadio
                          onChange={(e) => {
                            setValue(e.target.value);
                            setOpen(false);
                          }}
                          checked={JSON.parse(value)?.[1] === x[1]}
                          value={JSON.stringify(x)}
                        />
                        <span className="ml-[12px]">{x[1]}</span>
                      </SelectItem>
                    ))}
                  </SelectBox>
                </SelectWrap>
              </ul>
            </div>
          )}
          <div className="flex h-[unset] flex-row justify-between gap-[16px] px-[16px]">
            <input
              type="file"
              name="file"
              ref={fileInput}
              onChange={handleFileInput}
              style={{ display: "none" }}
              accept="image/*"
            />
            <div className="relative">
              {imgBase64 ? (
                <ThumbImg cn="w-[200px] h-[200px]">
                  <img
                    className="max-h-[198px] w-full object-contain"
                    src={imgBase64}
                    alt="preview"
                  />
                </ThumbImg>
              ) : (
                <EmptyImg cn="w-[200px] h-[200px]" />
              )}
              <CommonButton
                name="이미지 업로드"
                onClick={() => fileInput.current.click()}
                icon="IC_upload_w"
                type="small"
                cn="absolute left-[8px] bottom-[8px]"
              />
            </div>
            <textarea
              className={clsx(
                "h-[200px] w-full resize-none border bg-white p-[16px]",
                required && !text ? "border-error" : "border-gray400"
              )}
              placeholder={placeholder}
              onChange={handleInputChange}
              wrap="hard"
              rows="5"
              cols="20"
            />
          </div>
          <ModalBottom hasStatus={{ modalStatus, failType }}>
            <CommonButton name="등록" onClick={checkInput} type="modal_btn" />
          </ModalBottom>
        </ModalContainer>
      )}
    </>
  );
};
