import React, { useState, useRef } from "react";
import {
  Popper,
  Paper,
  MenuList,
  MenuItem as MuiMenuItem,
  Grow,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const SELECT_ALL = "전체";

const MenuItem = styled(MuiMenuItem)({
  height: "32px",
  minWidth: "65px",
  padding: "9px 16px 8px 16px",
  color: "#333",
  fontFamily: "Spoqa Han Sans Neo",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "15px",
  letterSpacing: "-0.24px",
});

const ScrollableMenuList = styled(MenuList)(({ theme }) => ({
  maxHeight: "240px",
  overflow: "auto",
}));

const StyledPaper = styled(Paper)({
  maxHeight: "232px",
  overflow: "hidden",
});

const SubFilter = ({ items, parentKey, onSelect, anchorEl, open, onClose }) => {
  const [openChildIndex, setOpenChildIndex] = useState(null);
  const menuItemRefs = useRef({});

  const handleMouseEnter = (index) => {
    setOpenChildIndex(index);
  };

  const handleMouseLeave = () => {
    setOpenChildIndex(null);
  };

  const handleClick = (key, value) => (event) => {
    event.stopPropagation();
    if (!Array.isArray(value) || value.length === 0) {
      onSelect(parentKey, value);
      onClose();
    }
  };

  return (
    <Popper open={open} anchorEl={anchorEl} placement="right-start" transition>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <StyledPaper>
            <ScrollableMenuList onMouseLeave={onClose}>
              <MenuItem
                onClick={() => {
                  onSelect(parentKey, SELECT_ALL);
                  onClose();
                }}
              >
                {SELECT_ALL}
              </MenuItem>
              {Object.entries(items).map(([key, value], index) => (
                <MenuItem
                  key={key}
                  ref={(el) => (menuItemRefs.current[key] = el)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onClick={handleClick(key, value)}
                >
                  {Array.isArray(value) && value.length > 0 ? key : value}
                  {Array.isArray(value) && value.length > 0 && openChildIndex === index && (
                    <SubFilter
                      items={value}
                      parentKey={key}
                      onSelect={(_, item) => onSelect(parentKey, key, item)}
                      anchorEl={menuItemRefs.current[key]}
                      open={true}
                      onClose={handleMouseLeave}
                    />
                  )}
                </MenuItem>
              ))}
            </ScrollableMenuList>
          </StyledPaper>
        </Grow>
      )}
    </Popper>
  );
};

export const Filter = ({ filterData, filters, setFilters, totalDataCount }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const buttonRef = useRef(null);
  const menuItemRefs = useRef({});

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl((prev) => (prev ? null : buttonRef.current));
  };

  const handleMouseEnter = (index) => {
    setOpenMenuIndex(index);
  };

  const handleMouseLeave = () => {
    setOpenMenuIndex(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
  };

  const handleSelect = (depth1, depth2, depth3) => {
    let newFilters = [];
    if (depth3 && depth3 !== SELECT_ALL) {
      newFilters = [depth1, depth2, depth3];
    } else if (depth2 && depth2 !== SELECT_ALL) {
      newFilters = [depth1, depth2];
    } else {
      newFilters = [depth1];
    }

    if (!filters.some((filter) => JSON.stringify(filter) === JSON.stringify(newFilters))) {
      setFilters((filters) => [...filters, newFilters]);
    }

    handleClose();
  };

  return (
    <div>
      <button
        ref={buttonRef}
        className="flex h-[30px] items-center gap-[4px] rounded-[16px] border px-[12px] text-[#888888]"
        onClick={handleClick}
      >
        {Boolean(anchorEl) ? <SelectedIcon /> : <FilterIcon />}
        <div className="flex gap-[2px]">
          필터링 :<span className="text-[#333333]">{filters.length}</span>개{" "}
        </div>
        ·{" "}
        <div className="flex gap-[2px]">
          데이터 :<span className="text-[#333333]">{totalDataCount}</span>개
        </div>
      </button>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" transition>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <StyledPaper>
                <ScrollableMenuList onMouseLeave={handleMouseLeave}>
                  {Object.entries(filterData).map(([key, value], index) => (
                    <MenuItem
                      key={key}
                      ref={(el) => (menuItemRefs.current[key] = el)}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (Object.keys(value).length === 0) {
                          handleSelect(key);
                        }
                      }}
                    >
                      {key}
                      {openMenuIndex === index && (
                        <SubFilter
                          items={value}
                          parentKey={key}
                          onSelect={handleSelect}
                          anchorEl={menuItemRefs.current[key]}
                          open={true}
                          onClose={handleMouseLeave}
                        />
                      )}
                    </MenuItem>
                  ))}
                </ScrollableMenuList>
              </StyledPaper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </div>
  );
};

const FilterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <mask fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3C14 2.44772 13.5523 2 13 2H3C2.44772 2 2 2.44772 2 3V3.87738C2 4.13978 2.10314 4.39167 2.28717 4.57872L5.46294 7.80649C5.64698 7.99354 5.75012 8.24543 5.75012 8.50783V13.4992C5.75012 13.8415 6.08635 14.0826 6.41056 13.9727L9.91055 12.787C10.1135 12.7182 10.2501 12.5277 10.2501 12.3134V8.50759C10.2501 8.24519 10.3533 7.9933 10.5373 7.80625L13.7128 4.57872C13.8969 4.39167 14 4.13978 14 3.87738V3Z"
      />
    </mask>
    <path
      d="M6.41056 13.9727L6.08968 13.0256H6.08968L6.41056 13.9727ZM9.91055 12.787L9.58968 11.8398L9.58968 11.8398L9.91055 12.787ZM13.7128 4.57872L13 3.87738L13.7128 4.57872ZM10.5373 7.80625L9.82447 7.10491L10.5373 7.80625ZM5.46294 7.80649L4.75012 8.50783L5.46294 7.80649ZM2.28717 4.57872L1.57435 5.28006L2.28717 4.57872ZM3 3H13V1H3V3ZM3 3.87738V3H1V3.87738H3ZM6.17577 7.10515L3 3.87738L1.57435 5.28006L4.75012 8.50783L6.17577 7.10515ZM4.75012 8.50783V13.4992H6.75012V8.50783H4.75012ZM4.75012 13.4992C4.75012 14.5261 5.7588 15.2494 6.73143 14.9198L6.08968 13.0256C6.41389 12.9158 6.75012 13.1569 6.75012 13.4992H4.75012ZM6.73143 14.9198L10.2314 13.7341L9.58968 11.8398L6.08968 13.0256L6.73143 14.9198ZM10.2314 13.7341C10.8404 13.5278 11.2501 12.9563 11.2501 12.3134H9.25012C9.25012 12.0991 9.3867 11.9086 9.58968 11.8398L10.2314 13.7341ZM11.2501 12.3134V8.50759H9.25012V12.3134H11.2501ZM13 3.87738L9.82447 7.10491L11.2501 8.50759L14.4257 5.28006L13 3.87738ZM13 3V3.87738H15V3H13ZM14.4257 5.28006C14.7937 4.90596 15 4.40219 15 3.87738H13L14.4257 5.28006ZM11.2501 8.50759L9.82447 7.10491C9.4564 7.47901 9.25012 7.98278 9.25012 8.50759H11.2501ZM4.75012 8.50783H6.75012C6.75012 7.98302 6.54384 7.47924 6.17577 7.10515L4.75012 8.50783ZM1 3.87738C1 4.40219 1.20628 4.90596 1.57435 5.28006L3 3.87738L3 3.87738H1ZM13 3H15C15 1.89543 14.1046 1 13 1V3ZM3 1C1.89543 1 1 1.89543 1 3H3V3V1Z"
      fill="#808080"
      mask="url(#path-1-inside-1_386_4613)"
    />
  </svg>
);

const SelectedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3C14 2.44772 13.5523 2 13 2H3C2.44772 2 2 2.44772 2 3V3.87738C2 4.13978 2.10314 4.39167 2.28717 4.57872L5.46294 7.80649C5.64698 7.99354 5.75012 8.24543 5.75012 8.50783V13.4992C5.75012 13.8415 6.08635 14.0826 6.41056 13.9727L9.91055 12.787C10.1135 12.7182 10.2501 12.5277 10.2501 12.3134V8.50759C10.2501 8.24519 10.3533 7.9933 10.5373 7.80625L13.7128 4.57872C13.8969 4.39167 14 4.13978 14 3.87738V3Z"
      fill="#808080"
    />
  </svg>
);
