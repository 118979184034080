import { useSetRecoilState } from "recoil";
import { infoReloadTriggerState, modalReloadTriggerState, reloadTriggerState } from "../stores";

export const useHookReload = () => {
  const listReloadTrigger = useSetRecoilState(reloadTriggerState);
  const modalReloadTrigger = useSetRecoilState(modalReloadTriggerState);
  const infoReloadTrigger = useSetRecoilState(infoReloadTriggerState);

  const reloadTrigger = (n) => n + 1;

  const listReload = () => listReloadTrigger(reloadTrigger);
  const modalReload = () => modalReloadTrigger(reloadTrigger);
  const infoReload = () => infoReloadTrigger(reloadTrigger);

  return { listReload, modalReload, infoReload };
};
