import { useEffect, useState } from "react";
import {
  SelectWrap,
  SelectBox,
  SelectItem,
  SelectRadio,
  SelectTitle,
} from "../../../../components/atoms/SelectBox";
import { ImageSprite } from "../../../../components/atoms/CommonAtoms";

export const Pagination = ({ page, setPage, totalPage }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [page]);

  const handleClick = (name) => {
    const current = Number(page);
    const last = totalPage;
    let value;

    switch (name) {
      case "first":
        value = 1;
        break;
      case "prev":
        if (current === 1) {
          value = 1;
        } else {
          value = current - 1;
        }
        break;
      case "next":
        if (current >= last) {
          value = last;
        } else {
          value = current + 1;
        }
        break;
      case "last":
        value = last;
        break;
      default:
        break;
    }
    setPage(value);
  };

  return (
    <div className="flex h-[30px] items-center justify-center gap-[8px]">
      <ImageSprite handleClick={() => handleClick("first")} iconType="IC_first_k" />
      <ImageSprite handleClick={() => handleClick("prev")} iconType="IC_prev_k" />

      <SelectWrap single styleType="pagination">
        <SelectTitle onClick={() => setOpen(!open)} name={page} styleType="pagination" />
        <SelectBox open={open} styleType="pagination">
          {Array(totalPage)
            .fill()
            .map((item, index) => (
              <SelectItem
                item={item}
                pagination
                key={index}
                checked={page === item}
                styleType="list"
                cn="ml-0"
              >
                <SelectRadio
                  onChange={(e) => {
                    setPage(item);
                    setOpen(false);
                  }}
                  value={item}
                  cn="hidden"
                />
              </SelectItem>
            ))}
        </SelectBox>
      </SelectWrap>
      <div className="text-gray800">/ {totalPage}</div>

      <ImageSprite handleClick={() => handleClick("next")} iconType="IC_next_k" />
      <ImageSprite handleClick={() => handleClick("last")} iconType="IC_last_k" />
    </div>
  );
};
