export const EXTR_CLCT_COLS = {
  tab: [
    "data",
    "path",
    "port",
    "berth",
    "vessel",
    "date",
    "sensor_cnts",
    "tab_cnts",
    "crt_t",
    "clct_cmp_t",
    "module",
  ],
};

export const EXTR_DATA_COLS = {
  tab1: ['filename', 'filesize', 'ul_rep', 'ul_t', 'extr_rep', 'extr_t'],
  tab2: ['filename', 'filesize', 'ul_rep', 'ul_t'],
  tab3: ['filename', 'filesize', 'ul_rep', 'ul_t', 'extr_rep', 'extr_t'],
  hide: { tab3: ['ul_rep', 'ul_t'] },
};

export const EXTR_CHECK_COL = [
  { name: "path", ko: "수집경로" },
  { name: "port", ko: "항만" },
  { name: "berth", ko: "선석" },
  { name: "vessel", ko: "선박 또는 지역" },
  { name: "module", ko: "모듈명" },
  { name: "date", ko: "날짜" },
  { name: "cnt", ko: "데이터 수" },
];

export const EXRT_DATA_TAB = [
  {
    ko: "전체",
    value: "1",
    name: "bag_tab1_cnt",
    col: "bagTab1Cnt",
  },
  {
    ko: "추출대기",
    value: "2",
    name: "bag_tab2_cnt",
    col: "bagTab2Cnt",
  },
  {
    ko: "추출완료",
    value: "3",
    name: "bag_tab3_cnt",
    col: "bagTab3Cnt",
  },
];
