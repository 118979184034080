import { Route, Switch } from "react-router-dom";
import LoginPage from "../../pages/login/LoginPage";
import { PAGE_LIST } from "../../lib/routes";
import PrivateRoute from "./PrivateRoute";
import NotFoundPage from "./NotFoundPage";
import DataSetPage from "../../pages/dataset/DataSetPage";
import ErrorPage from "./ErrorPage";

const Section = () => {
  return (
    <Switch>
      <Route path="/" component={LoginPage} exact />
      {PAGE_LIST.map(({ path, component }, i) => (
        <PrivateRoute key={i} path={path} component={component} exact />
      ))}
      <Route path="/dataset" component={DataSetPage} exact />
      <Route path="/error" component={ErrorPage} exact />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default Section;
