import { selectorFamily } from "recoil";
import { reloadTriggerState } from "../../lib/stores";
import { rgsCnt, rgsList, rgsClctCnt, rgsClctList, rgsPreview, rgsIds } from "./Rgs.apis";

export const rgsClctCntQuery = selectorFamily({
  key: "rgsClctCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await rgsClctCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const rgsClctListQuery = selectorFamily({
  key: "rgsClctListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await rgsClctList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const rgsPreviewQuery = selectorFamily({
  key: "rgsPreviewQuery",
  get: (params) => async () => {
    const res = await rgsPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const rgsCntQuery = selectorFamily({
  key: "rgsCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await rgsCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const rgsIdsQuery = selectorFamily({
  key: "rgsIdsQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await rgsIds(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const rgsListQuery = selectorFamily({
  key: "rgsListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await rgsList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});
