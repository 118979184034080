import { useEffect, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { useModals, useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import ModalContainer from "../../molecules/ModalContainer";
import CommonTableWrapper from "../CommonTableWrapper";
import { COLUMN_LIST } from "../../../lib/constants/constant";

// 출력: 응답받은 리스트
// 입력?: 선택한 아이템

export const ErrorInspectAction = ({
  id, // insp row id
  listQuery,
  actionName,
  detail,
}) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { modal, openModal, closeModal, setModalStatus } = useModals(null);
  const { modalReload } = useReload();

  const { state, contents } = useRecoilValueLoadable(listQuery({ ...urlInfo, ...id, modal }));

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    state === "hasValue" && firstRender === true && setFirstRender(false);
  }, [state]);

  const resetOpen = () => {
    setModalStatus(null);
    !firstRender && modalReload();
    openModal();
  };

  return (
    <>
      <div className="text-primary underline hover:font-bold" onClick={resetOpen}>
        에러 상세
      </div>

      {modal && (
        <ModalContainer
          modalType="error_inspect_modal"
          title={actionName}
          closeModal={closeModal}
          desc={{
            count: contents.count,
            text: detail,
          }}
          handleClick={modalReload}
        >
          <CommonTableWrapper
            header={RESULT_COLUMNS}
            state={state}
            contents={contents.results}
            showIndex={true}
            tableModal
          />
          <div className="h-[36px] w-full" />
          <CommonTableWrapper
            header={ERROR_COLUMNS}
            state={state}
            contents={convertErrorContents(contents.errs)}
            showIndex={false}
            tableModal
          />
        </ModalContainer>
      )}
    </>
  );
};

const RESULT_HEADERS = [
  "name",
  "obj_numbering_err",
  "layer_naming_err",
  "empty_layer",
  "labeling_err",
  "opacity_err",
  "invisible_layer",
  "group_err",
];

const RESULT_COLUMNS = COLUMN_LIST.filter((item) => RESULT_HEADERS.includes(item.name, 0));

const ERROR_COLUMNS = [
  { name: "error_type", ko: "에러 종류" },
  { name: "error_contents", ko: "에러 내용" },
];

const ERROR_HEADERS = {
  duplicate_warn: "중복 경고",
  hidden_err: "숨겨진 객체",
  bg_err: "백그라운드 에러",
};

const convertErrorContents = (errors) =>
  errors && Object.keys(ERROR_HEADERS).some((header) => errors[header] !== "")
    ? Object.entries(errors).map(([header, contents]) => ({
        error_type: ERROR_HEADERS[header],
        error_contents: contents,
      }))
    : [];
