import { ImageSprite } from "../atoms/CommonAtoms";

const NotFoundPage = () => {
  return (
    <div className="h-screen w-screen">
      <div className="flex h-[calc(100%-57px)] w-full flex-col items-center justify-center gap-[40px] text-[14px] text-gray700">
        <ImageSprite boxSize="IC_navlue" />
        <div>404 Not Found</div>
      </div>
    </div>
  );
};

export default NotFoundPage;
