import { useRecoilState } from "recoil";
import { modalOpenState } from "../../../lib/stores";
import { SelectBox, SelectItem, SelectRadio, SelectTitle, SelectWrap } from "../../atoms/SelectBox";

const ThumbnailList = (props) => {
  const { data, currentImgType, setCurrentImgType } = props;

  const [open, setOpen] = useRecoilState(modalOpenState);

  return (
    <SelectWrap single>
      <SelectTitle
        onClick={() => (open === "thumb" ? setOpen(null) : setOpen("thumb"))}
        name={currentImgType.ko}
      />
      {open === "thumb" && (
        <SelectBox open={open === "thumb"}>
          {data.map((item, index) => (
            <SelectItem key={index} item={item} index={index} styleType="list" cn="truncate">
              <SelectRadio
                onChange={(e) => {
                  setCurrentImgType(JSON.parse(e.target.value));
                  setOpen(false);
                }}
                checked={currentImgType.thumb === item.thumb}
                value={JSON.stringify(item)}
              />
            </SelectItem>
          ))}
        </SelectBox>
      )}
    </SelectWrap>
  );
};

export default ThumbnailList;
