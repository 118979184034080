import { axiosInstance } from "../../components/templates/RootErrorBoundary";

// 데이터 등록 화면 데이터셋 리스트 조회
export const rgsClctList = async ({ tab, ...params }) => {
  const { data } = await axiosInstance.get(`/clct/rgs/sets/${tab}/`, {
    params,
  });

  return data;
};

// 원본추출 화면 탭별 데이터 수 조회
export const rgsClctCnt = async ({ t }) => {
  const { data } = await axiosInstance.get(`/clct/rgs/sets/cnt/`, {
    params: { t },
  });

  return data;
};

// 데이터 등록 화면 데이터셋 생성
export const rgsClctCreate = async (body) => {
  const { data } = await axiosInstance.post(`/clct/rgs/sets/`, body);

  return data;
};

// 데이터 등록 화면 전체 등록 완료
export const rgsClctCmp = async (body) => {
  const { data } = await axiosInstance.post("/clct/rgs/sets/cmp/", body);

  return data;
};

// 데이터 등록 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const rgsClctFilter = async ({ tab, col }, params) => {
  const { data } = await axiosInstance.get(`/clct/rgs/sets/${tab}/cols/${col}/`, {
    params,
  });

  return data;
};

// 데이터 등록 화면 데이터 삭제
export const rgsClctDel = async (body) => {
  const { data } = await axiosInstance.post(`/clct/rgs/sets/del/`, body);

  return data;
};

// 데이터 등록 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const rgsClctGetInfo = async () => {
  const { data } = await axiosInstance.get(`/clct/rgs/sets/values/`);

  return data;
};

// 데이터 등록 화면 원본 미리보기
export const rgsPreview = async ({ sensor_code, id, img_type }) => {
  const { data } = await axiosInstance.get(`/clct/rgs/${sensor_code}/orig/${id}/pre/${img_type}/`);

  return data;
};

// 데이터 등록 화면 원본 데이터 리스트 id 조회
export const rgsIds = async ({ sensor_code, set_id, view, tab, ...params }) => {
  const { data } = await axiosInstance.get(`/clct/rgs/${sensor_code}/orig/${set_id}/ids/`, {
    params,
  });

  return data;
};

// 데이터 등록 화면 원본 데이터 리스트 조회
export const rgsList = async ({ sensor_code, set_id, view, tab, ...params }) => {
  const { data } = await axiosInstance.get(`/clct/rgs/${sensor_code}/orig/${set_id}/${view}/`, {
    params,
  });

  return data;
};

// 데이터 등록 화면 원본 탭별 데이터 수 조회
export const rgsCnt = async ({ sensor_code, set_id, t }) => {
  const { data } = await axiosInstance.get(`/clct/rgs/${sensor_code}/orig/${set_id}/cnt/`, {
    params: { t },
  });

  return data;
};

// 데이터 등록 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const rgsFilter = async ({ sensor_code, set_id, col }, tab, ...params) => {
  const { data } = await axiosInstance.get(`/clct/rgs/${sensor_code}/orig/${set_id}/cols/${col}/`, {
    params,
  });

  return data;
};

// 데이터 삭제 확인
export const rgsDelCheck = async ({ sensor_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/clct/rgs/${sensor_code}/orig/${set_id}/del/check/`,
    body
  );

  return data;
};

// 데이터 등록 화면 - 원본 업로드
export const rgsOrigUl = async ({ sensor_code, set_id }, body, { onUploadProgress }, source) => {
  const { data } = await axiosInstance.post(`/clct/rgs/${sensor_code}/orig/${set_id}/ul/`, body, {
    onUploadProgress,
    cancelToken: source.current.token,
  });

  return data;
};

// 데이터 등록 화면 - gt png 업로드
export const rgsSegGtUl = async ({ sensor_code, set_id }, body, { onUploadProgress }, source) => {
  const { data } = await axiosInstance.post(`/clct/rgs/${sensor_code}/seg/gt/${set_id}/ul/`, body, {
    onUploadProgress,
    cancelToken: source.current.token,
  });

  return data;
};

// 데이터 등록 화면 - gt json 업로드
export const rgsDetGtUl = async ({ sensor_code, set_id }, body, { onUploadProgress }, source) => {
  const { data } = await axiosInstance.post(`/clct/rgs/${sensor_code}/det/gt/${set_id}/ul/`, body, {
    onUploadProgress,
    cancelToken: source.current.token,
  });

  return data;
};

// 원본 데이터 폐기
export const rgsDataDel = async ({ sensor_code, set_id }, body) => {
  const { data } = await axiosInstance.post(`/clct/rgs/${sensor_code}/orig/${set_id}/del/`, body);

  return data;
};
