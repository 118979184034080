import { useRecoilValue } from "recoil";
import { useHotkeys } from "react-hotkeys-hook";
import { useReload } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { currentMenuState } from "../../lib/stores";
import { ImageSprite, PageTitle } from "../atoms/CommonAtoms";

const CurrentPage = () => {
  const currentMenu = useRecoilValue(currentMenuState);
  const { listReload } = useReload();
  const {
    linkTo,
    urlParams: { set_id, set_name },
  } = useDynamicRouting();

  useHotkeys("shift+r", () => listReload());

  return (
    <div className="flex h-[56px] leading-[56px]">
      <div className="flex items-center gap-[4px] overflow-hidden text-[18px] font-bold text-gray900 [&>*]:flex-none">
        {/* 카테고리 */}
        <span>{currentMenu.category}</span>
        <ImageSprite iconType="IC_next_k" />
        {/* 메뉴 */}
        <PageTitle
          handleClick={() => {
            if (set_id) {
              linkTo({
                pathname: currentMenu?.menu?.path,
                search: currentMenu?.menu?.query?.clct,
              });
            } else {
              listReload();
            }
          }}
          text={currentMenu.menu && currentMenu.menu.ko}
        />
        {/* 데이터셋 이름 */}
        {set_id && (
          <>
            <ImageSprite iconType="IC_next_k" />
            <PageTitle
              handleClick={() => {
                listReload();
              }}
              text={set_name}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CurrentPage;
