import { axiosInstance } from "../../components/templates/RootErrorBoundary";
import qs from "qs";

export const mainList = async () => {
  const { data } = await axiosInstance.get(`/main/`);

  return data;
};

export const datasetInfo = async ({ sensor_code, gt_code }) => {
  const { data } = await axiosInstance.get(`/main/vld/${sensor_code}/${gt_code}/sets/info/`);

  return data;
};

export const learnedHitmapPoints = async ({ sensor_code, gt_code, query }) => {
  const { data } = await axiosInstance.get(
    `/main/vld/${sensor_code}/${gt_code}/dist/?${qs.stringify(query)}`
  );

  return data;
};

export const datasetHitmapPoints = async ({ sensor_code, gt_code, clct_id }) => {
  const { data } = await axiosInstance.get(`/main/vld/${sensor_code}/${gt_code}/dist/${clct_id}/`);

  return data;
};

export const datasetList = async ({ menu, sensor_code, gt_code, query }) => {
  const { data } = await axiosInstance.get(
    `/main/${menu}/${sensor_code}/${gt_code}/sets/?${qs.stringify(query)}`
  );

  return data;
};

export const segFilterList = async () => {
  const { data } = await axiosInstance.get(`/main/vld/cols/`);

  return data;
};

export const checkFilterList = async () => {
  const { data } = await axiosInstance.get(`/main/check/cols/`);

  return data;
};

export const dataCountsInCoordinates = async ({ sensor_code, gt_code, clct_id, coords }) => {
  const { data } = await axiosInstance.post(
    `/main/vld/${sensor_code}/${gt_code}/dist/${clct_id}/cnt/`,
    {
      coords,
    }
  );

  return data;
};

export const updateThumbnailCounts = async ({ sensor_code, gt_code, clct_id }, body) => {
  const { data } = await axiosInstance.post(
    `/main/vld/${sensor_code}/${gt_code}/dist/${clct_id}/cnt/`,
    body
  );

  return data;
};

export const thumbnailsInCoordinates = async ({ sensor_code, gt_code, clct_id, query, coords }) => {
  const { data } = await axiosInstance.get(
    `/main/vld/${sensor_code}/${gt_code}/dist/${clct_id}/thumb/?${qs.stringify(query)}&coords=${JSON.stringify(coords)}`
  );

  return data;
};

export const imgPreview = async ({ sensor_code, gt_code, id, img_type }) => {
  const { data } = await axiosInstance.get(
    `/main/vld/${sensor_code}/${gt_code}/dist/${id}/pre/${img_type}/`
  );

  return data;
};
