import { selectorFamily } from "recoil";
import { reloadTriggerState } from "../../lib/stores";
import { lrnClctCnt, lrnClctList, lrnIds, lrnList, lrnPreview } from "./Lrn.apis";

export const lrnClctCntQuery = selectorFamily({
  key: "lrnClctCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await lrnClctCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const lrnClctListQuery = selectorFamily({
  key: "lrnClctListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await lrnClctList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const lrnPreviewQuery = selectorFamily({
  key: "lrnPreviewQuery",
  get: (params) => async () => {
    const res = await lrnPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const lrnIdsQuery = selectorFamily({
  key: "lrnIdsQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await lrnIds(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const lrnListQuery = selectorFamily({
  key: "lrnListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await lrnList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});
