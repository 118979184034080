import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import {
  GT_TAB_CAMDET_CHECK,
  GT_TAB_CAMSEG_CHECK,
  GT_TAB_RADSEG_CHECK,
} from "../../lib/constants/constant";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { checkClctCntQuery, checkClctListQuery } from "./Check.stores";
import { CHECK_CLCT_COLS } from "./Check.constants";
import { checkClctFilter } from "./Check.apis";

const CheckClctPage = () => {
  const {
    urlParams,
    queryParams: { tab, t },
  } = useDynamicRouting();
  const { sensor_code, gt_code } = urlParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    checkClctCntQuery({ ...urlParams, tab, t })
  );

  return (
    <CommonTemplate
      listQuery={checkClctListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      filterApi={checkClctFilter}
      fullColumnData={CHECK_CLCT_COLS[`${sensor_code}_${gt_code}`].tab}
      columnData={CHECK_CLCT_COLS[`${sensor_code}_${gt_code}`].tab}
      hideColumn={CHECK_CLCT_COLS[`${sensor_code}_${gt_code}`].hide}
      tabData={[{ value: "1", ko: "전체" }]}
      groupColumnData={
        sensor_code === "rad"
          ? GT_TAB_RADSEG_CHECK
          : gt_code === "det"
            ? GT_TAB_CAMDET_CHECK
            : GT_TAB_CAMSEG_CHECK
      }
    />
  );
};

export default CheckClctPage;
