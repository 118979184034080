import { useState } from "react";
import { useModals, useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { SelectWrap, SelectItem, SelectName, SelectRadio } from "../../atoms/SelectBox";
import { CommonButton } from "../../molecules/Buttons";
import { EmptyModal, SaveModal } from "../../molecules/CommonModals";

// 입력?: 선택한 아이템
// 입력: 수락 또는 거절
export const AcceptAction = ({ api, body, count, actionName, icon }) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { listReload } = useReload();

  const [failType, setFailType] = useState(null);
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState(null);

  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetOpen = () => {
    setValue(null);
    setModalStatus(null);
    setFailType(null);
    setRequired(false);
    openModal();
  };

  const checkInput = (id) => {
    setModalStatus("hasError");

    if (!value) {
      setModalStatus("hasError");
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick(id);
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await api(urlInfo, {
        ...body,
        accept: JSON.parse(value).value,
      });

      console.log(data);
      closeModal();
      listReload();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";
      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  const data = [
    { ko: "수락", value: "true" },
    { ko: "거절", value: "false" },
  ];

  return (
    <>
      <CommonButton name={actionName} icon={icon} onClick={resetOpen} />

      {modal &&
        (count === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={closeModal}
            title={`${actionName} 정보 확인`}
            count={count}
            modalStatus={modalStatus}
            handleClick={checkInput}
            failType={failType}
          >
            <SelectWrap required={required && !value}>
              <SelectName name="수락 여부" />
              {data.map((x, i) => (
                <SelectItem key={i} styleType="list">
                  <SelectRadio
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    checked={JSON.parse(value)?.value === x.value}
                    value={JSON.stringify(x)}
                  />
                  <span className="ml-[12px]">{x.ko}</span>
                </SelectItem>
              ))}
            </SelectWrap>
          </SaveModal>
        ))}
    </>
  );
};
