import clsx from "clsx";
import { formatAmountComma } from "../../lib/utils";
import { MiddlePoint, ImageSprite } from "../atoms/CommonAtoms";
import { ModalTop } from "./Modals";

const ModalContainer = (props) => {
  const { modalType, title, closeModal, children, desc, handleClick, blackBackground = 50 } = props;

  return (
    <div className={clsx("fixed inset-0 z-[90] flex items-center", `bg-black/${blackBackground}`)}>
      <div
        className={clsx(
          "mx-auto rounded-[3px] border border-gray400 bg-white",
          modalType,
          modalType === "privacy"
            ? "h-[80vh] w-[50vw] max-w-[900px]"
            : modalType === "question_modal"
              ? "h-[416px] w-[740px]"
              : modalType === "answer_modal"
                ? "h-[338px] w-[740px]"
                : modalType === "save_modal"
                  ? "h-auto w-[400px]"
                  : modalType === "table_modal"
                    ? "h-[452px] w-[930px]"
                    : modalType === "open_source_modal"
                      ? "h-[506px] w-[400px]"
                      : modalType === "error_inspect_modal"
                        ? "h-[800px] w-[930px]"
                        : modalType === "manual"
                          ? "h-[80vh] min-w-[900px] max-w-[900px]"
                          : "h-[186px] w-[400px]"
        )}
      >
        <ModalTop>
          <div className="flex gap-[8px] text-[18px] font-bold text-gray900">
            {title}
            {modalType === "table_modal" && handleClick && (
              <div className="flex cursor-pointer items-center gap-[4px]" onClick={handleClick}>
                <ImageSprite iconType="IC_reload_k" />
                <div className="text-[12px] font-normal text-gray900">새로고침</div>
              </div>
            )}
          </div>
          <div
            className={clsx("cursor-pointer", modalType === "privacy" && "hidden")}
            onClick={closeModal}
          >
            <ImageSprite iconType="IC_close_k" />
          </div>
        </ModalTop>
        {desc && (
          <ul
            className={clsx(
              "px-[16px]",
              modalType === "question_modal"
                ? "flex h-[unset] flex-row justify-between gap-[16px]"
                : "min-h-[48px]"
            )}
          >
            {(desc.count || desc.count === 0) && (
              <li className="flex items-center gap-[4px]">
                <MiddlePoint />
                <div>
                  {desc.listName || "선택한 데이터"} : {formatAmountComma(desc.count)}개
                </div>
              </li>
            )}
            {desc.text && <li>{desc.text}</li>}
          </ul>
        )}
        {children}
      </div>
    </div>
  );
};

export default ModalContainer;
