import { atom, selector } from "recoil";

// 모든 태그 상태를 위한 atom
export const AllTagState = atom({
  key: "AllTagState",
  default: {},
});

// 모든 태그 추가 함수
export const addAllTag = selector({
  key: "addAllTag",
  get: () => {},
  set: ({ set }, { id, value }) => {
    set(tagState, (prevAllTags) => ({
      ...prevAllTags,
      [id]: value,
    }));
  },
});

// 태그 상태를 위한 atom
export const tagState = atom({
  key: "tagState",
  default: {},
});

// 태그 관리를 위한 selector
export const tagSelector = selector({
  key: "tagSelector",
  get: ({ get }) => get(tagState),
  set: ({ set }, newValue) => set(tagState, newValue),
});

// 태그 추가 함수
export const addTag = selector({
  key: "addTag",
  get: () => {},
  set: ({ set }, { id, value }) => {
    set(tagState, (prevTags) => ({
      ...prevTags,
      [id]: value,
    }));
  },
});

// 태그 삭제 함수
export const removeTag = selector({
  key: "removeTag",
  get: () => {},
  set: ({ set }, id) => {
    set(tagState, (prevTags) => {
      const newTags = { ...prevTags };
      delete newTags[id];
      return newTags;
    });
  },
});

// 모든 태그 초기화 함수
export const resetTags = selector({
  key: "resetTags",
  get: () => {},
  set: ({ set, get }) => {
    const allTags = get(AllTagState);
    const currentTags = get(tagState);
    set(AllTagState, { ...allTags, ...currentTags });
    set(tagState, {});
  },
});
