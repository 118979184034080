import { useContext, useRef } from "react";
import axios from "axios";
import { NAVLUEContext } from "../../App";

const CancelToken = axios.CancelToken;

export const useHookIdProcessor = () => {
  const uploadSource = useRef(null);
  const { progressRows, dispatch } = useContext(NAVLUEContext);

  const process = async ({ rowId, urlInfo, api, ids }) => {
    console.log("====================== > hook floating promise start");

    // progressRows에서 현재 진행중인 작업 찾기
    const onGoingItem = progressRows.find((item) => item.id === rowId);

    let isCancel = false;
    const len = ids.length;

    onGoingItem.status = "진행중";
    onGoingItem.singleProcess = true;
    dispatch({ type: "progressRows", data: progressRows });

    console.log("=========================== > for 시작", rowId);
    // 파일을 하나씩 전송
    for (let j = 0; j < len; j++) {
      const body = { id: ids[j] };

      uploadSource.current = CancelToken.source();
      onGoingItem.token = uploadSource.current;
      onGoingItem.contents[j].floatingStatus = "추출중";
      dispatch({ type: "progressRows", data: progressRows });

      try {
        const data = await api(urlInfo, body, uploadSource);

        console.log(data);

        onGoingItem.contents[j].floatingStatus = "추출성공";
      } catch (err) {
        console.error(err);
        if (axios.isCancel(err)) {
          console.log("cancel");
          console.log("=========================== > 취소 감지", rowId);
          onGoingItem.contents[j].floatingStatus = `추출취소`;
          isCancel = true;
          break;
        } else {
          console.log(err?.response);
          let err_detail = err?.response?.data?.detail || "";
          onGoingItem.contents[j].floatingStatus = `추출실패 ${err_detail && `(${err_detail})`}`;
        }
      }
      onGoingItem.singleIndex = j + 1;

      // context update
      dispatch({ type: "progressRows", data: progressRows });
    }
    console.log("=========================== > for 종료", rowId);

    onGoingItem.status = isCancel ? "취소" : "완료";
    onGoingItem.done = true;
    dispatch({ type: "progressRows", data: progressRows });
  };

  return { process };
};
