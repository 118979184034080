import { useState } from "react";
import { useBatchIdProcessor, useModals } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { CommonButton } from "../../molecules/Buttons";
import { ConfirmModal, EmptyModal } from "../../molecules/CommonModals";

// 입력: 선택한 아이템
// 출력: 진행중인 작업에 추가
export const FloatingSelectAction = ({
  createMask,
  ids,
  contents,
  count,
  api,
  actionName,
  icon,
}) => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [failType, setFailType] = useState("");
  const { process } = useBatchIdProcessor();
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const [floatingItemContents, setFloatingItemContents] = useState(null);

  const addItem = () => {
    // 1. 전체 데이터 리스트에서 선택한 아이템 필터
    const selectedItems = contents.filter((item) => ids.includes(item.id, 0));
    const len = selectedItems.length;

    // 2. 진행중인 작업 리스트 생성
    let uploadArr = [];
    if (createMask) {
      // 마스크 생성 기능일때
      for (let i = 0; i < len; i++) {
        // 선택한 아이템들의 정보로 데이터셋 이름 생성
        let cols =
          selectedItems[i].path === "P"
            ? ["path", "port", "berth", "module", "date"]
            : ["path", "vessel", "module", "date"];

        const filename = cols.map((x) =>
          selectedItems[i][x]
            ? x === "date"
              ? selectedItems[i][x]
              : selectedItems[i][x] + "_"
            : ""
        );

        uploadArr = [
          ...uploadArr,
          {
            id: selectedItems[i].id,
            filename: filename.join(""),
            floatingStatus: "진행중",
          },
        ];
      }
    } else {
      for (let i = 0; i < len; i++) {
        uploadArr = [
          ...uploadArr,
          {
            id: selectedItems[i].id,
            filename: selectedItems[i].filename,
            floatingStatus: "진행중",
          },
        ];
      }
    }
    setFloatingItemContents(uploadArr);

    // 3. 모달 열기
    openModal();
  };

  const handleClick = async (rowId) => {
    closeModal();
    process({
      rowId,
      resType: "id",
      len: ids.length,
      urlInfo,
      api,
      body: createMask ? { clct_ids: ids } : { ids },
    });
  };

  return (
    <>
      <CommonButton name={actionName} icon={icon} onClick={addItem} />

      {modal &&
        (count === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <ConfirmModal
            closeModal={closeModal}
            title={`${actionName} 정보 확인`}
            count={count}
            modalStatus={modalStatus}
            failType={failType}
            panelname={actionName}
            handleUploadFile={handleClick}
            floatingItemContents={floatingItemContents}
          />
        ))}
    </>
  );
};
