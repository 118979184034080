import { selectorFamily } from "recoil";
import {
  checkClctCnt,
  checkClctList,
  checkCnt,
  checkIds,
  checkList,
  checkOrigVideoList,
  checkPreview,
  checkQnaPreview,
  checkRefCnt,
  checkRefIds,
  checkRefList,
  // checkVideoList,
  checkWorkSetList,
  checkInspectDetail,
} from "./Check.apis";
import { modalReloadTriggerState, reloadTriggerState } from "../../lib/stores";

export const checkClctCntQuery = selectorFamily({
  key: "checkClctCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await checkClctCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const checkClctListQuery = selectorFamily({
  key: "checkClctListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await checkClctList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const checkPreviewQuery = selectorFamily({
  key: "checkPreviewQuery",
  get: (params) => async () => {
    const res = await checkPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const checkCntQuery = selectorFamily({
  key: "checkCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await checkCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const checkIdsQuery = selectorFamily({
  key: "checkIdsQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await checkIds(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const checkListQuery = selectorFamily({
  key: "checkListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await checkList(params);
      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const checkWorkSetListQuery = selectorFamily({
  key: "checkWorkSetListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(modalReloadTriggerState);

      if (params.modal) {
        const res = await checkWorkSetList(params);

        if (res.error) {
          throw res.error;
        }
        return res;
      } else {
        throw new Error("modal 필수");
      }
    },
});

export const checkOrigVideoListQuery = selectorFamily({
  key: "checkOrigVideoListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(modalReloadTriggerState);

      if (params.modal) {
        const res = await checkOrigVideoList(params);

        if (res.error) {
          throw res.error;
        }
        return res;
      } else {
        throw new Error("modal 필수");
      }
    },
});

export const checkQnaPreviewQuery = selectorFamily({
  key: "checkQnaPreviewQuery",
  get: (params) => async () => {
    const res = await checkQnaPreview(params);

    if (res.error) {
      throw res.error;
    }
    return res;
  },
});

export const checkRefCntQuery = selectorFamily({
  key: "checkRefCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await checkRefCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const checkRefIdsQuery = selectorFamily({
  key: "checkRefIdsQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      if (params.tab === "3") {
        const res = await checkRefIds(params);
        if (res.error) {
          throw res.error;
        }

        return res;
      } else {
        return { state: null, contents: null };
      }
    },
});

export const checkRefListQuery = selectorFamily({
  key: "checkRefListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await checkRefList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const checkInspectDetailQuery = selectorFamily({
  key: "checkInspectDetailQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(modalReloadTriggerState);

      if (params.modal) {
        const res = await checkInspectDetail(params);

        if (res.error) {
          throw res.error;
        }
        return res;
      } else {
        throw new Error("modal 필수");
      }
    },
});
