import { useCallback, useEffect, useMemo, createContext, useContext } from "react";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import qs from "qs";
import { PAGE_LIST } from "../routes";
import { useStorage } from "../storage";

const DynamicRoutingContext = createContext();

export const DynamicRoutingProvider = ({ children }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const filterStorage = useStorage("filter");
  const sortStorage = useStorage("sort");

  const urlParams = useMemo(() => parsePathname(pathname), [pathname]);

  const queryParams = useMemo(() => {
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

    const isHistoryPage = urlParams.menu === "history";
    const isDataSetPage = urlParams.set_id && !urlParams.guide;
    const isExtractionPage = urlParams.menu === "extraction";

    queryParams.page = queryParams.page ?? "1";

    if (!isHistoryPage) {
      queryParams.tab = queryParams.tab ?? "1";
    }

    if (isDataSetPage && !isExtractionPage) {
      queryParams.view = queryParams.view ?? "table";
    } else {
      delete queryParams.view;
    }

    return queryParams;
  }, [search, urlParams]);

  useEffect(() => {
    const { menu, sensor_code, gt_code, set_id, guide } = urlParams;
    const { tab, page, sort, sortType, view, t, ...filters } = queryParams;
    filterStorage.store(
      { menu, sensor_code, gt_code, level: determinLevel(set_id), isGuide: !!guide, set_id },
      filters
    );
    sortStorage.store(
      { menu, sensor_code, gt_code, level: determinLevel(set_id), isGuide: !!guide, tab },
      { sort, sortType }
    );
  }, [urlParams, queryParams]);

  const linkTo = useCallback(
    ({ pathname, search }) => {
      const urlParams = parsePathname(pathname);
      const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
      const { menu, sensor_code, gt_code, set_id, guide } = urlParams;
      const { tab } = queryParams;
      const storedFilters = filterStorage.load({
        menu,
        sensor_code,
        gt_code,
        level: determinLevel(set_id),
        isGuide: !!guide,
        set_id,
      });
      const storedSort = sortStorage.load({
        menu,
        sensor_code,
        gt_code,
        level: determinLevel(set_id),
        isGuide: !!guide,
        tab,
      });

      const newQueryParams = { ...queryParams, ...storedFilters, ...storedSort };
      history.push({ pathname, search: qs.stringify(newQueryParams) });
    },
    [history, queryParams, urlParams]
  );

  const setQueryParams = useCallback(
    (params) => {
      const newQueryParams = { ...queryParams, ...params };
      newQueryParams.t = new Date().getTime();
      history.push({ search: qs.stringify(newQueryParams) });
    },
    [history, queryParams]
  );

  const delQueryParams = useCallback(
    (arr) => {
      const newQueryParams = { ...queryParams };
      arr.forEach((key) => {
        delete newQueryParams[key];
      });
      history.push({ search: qs.stringify(newQueryParams) });
    },
    [history, queryParams]
  );

  return (
    <DynamicRoutingContext.Provider
      value={{
        history,
        linkTo,
        urlParams,
        queryParams,
        setQueryParams,
        delQueryParams,
      }}
    >
      {children}
    </DynamicRoutingContext.Provider>
  );
};

export const useDynamicRouting = () => {
  return useContext(DynamicRoutingContext);
};

const parsePathname = (pathname) => {
  const pathTokens = pathname.split("/");
  const menu = pathTokens[1];
  const isGuidePage = pathTokens[pathTokens.length - 1] === "ref";

  const matchedPath = PAGE_LIST.map(({ path }) => matchPath(pathname, { path, exact: true })).find(
    (path) => path
  );

  const { params } = matchedPath ?? { params: {} };

  const paramKeys = ["sensor_code", "gt_code", "set_id", "set_name"];
  const urlParams = paramKeys.reduce(
    (acc, key) => {
      if (params[key]) {
        if (key === "sensor_code" || key === "gt_code") {
          acc[key] = params[key].slice(0, 3);
        } else if (key === "set_name") {
          acc[key] = params[key];
          const [dataSetType] = params[key].split("_");
          acc.dataSetType = dataSetType;
        } else {
          acc[key] = params[key];
        }
      }
      return acc;
    },
    { menu }
  );

  if (isGuidePage) {
    urlParams.guide = "ref";
  }

  return urlParams;
};

const determinLevel = (set_id) => {
  return set_id ? "DATASET" : "SET_LIST";
};
