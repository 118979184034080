import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { RgsClctCmp, RgsClctCreate, RgsClctDel } from "./RgsActions";
import { RGS_CLCT_COLS } from "./Rgs.constants";
import { rgsClctCntQuery, rgsClctListQuery } from "./Rgs.stores";
import { rgsClctFilter } from "./Rgs.apis";

const RgsClctPage = () => {
  const {
    urlParams,
    queryParams: { tab, t },
  } = useDynamicRouting();

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    rgsClctCntQuery({ ...urlParams, tab, t })
  );

  return (
    <CommonTemplate
      listQuery={rgsClctListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      filterApi={rgsClctFilter}
      fullColumnData={RGS_CLCT_COLS.tab}
      columnData={RGS_CLCT_COLS.tab}
      tabData={[
        { value: "2", ko: "Camera" },
        { value: "3", ko: "Radar" },
      ]}
      actions={({ selectedItems }) => (
        <>
          <RgsClctCreate />
          <SelectCnt count={selectedItems.length} />
          <RgsClctCmp selectedItems={selectedItems} />
          <RgsClctDel selectedItems={selectedItems} />
        </>
      )}
    />
  );
};

export default RgsClctPage;
