const EMPTY_STR = "EMPTY";

// sessionStorage에서 데이터를 가져오는 함수
const getFromSessionStorage = (key) => {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

// sessionStorage에 데이터를 저장하는 함수
const setToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

// 저장소 생성 함수
const createStorage = (categoryName, keyGeneratorArray) => {
  const keyGen = (obj) => {
    return keyGeneratorArray.map((key) => obj[key] ?? EMPTY_STR).join("-");
  };

  const getCategory = () => {
    return getFromSessionStorage(categoryName) || {};
  };

  const setCategory = (newValue) => {
    setToSessionStorage(categoryName, newValue);
  };

  const isMatchingKey = (key, partialKeyObj) => {
    const keyParts = key.split("-");
    return Object.entries(partialKeyObj).every(([objKey, objValue]) => {
      const index = keyGeneratorArray.indexOf(objKey);
      return index !== -1 && keyParts[index] === `${objValue}`;
    });
  };

  const filterCategory = (category, partialKeyObj, shouldKeep) => {
    return Object.entries(category).reduce((acc, [key, value]) => {
      if (shouldKeep ? !isMatchingKey(key, partialKeyObj) : isMatchingKey(key, partialKeyObj)) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  return {
    store: (keyObj, value) => {
      const key = keyGen(keyObj);
      const category = getCategory();
      setCategory({
        ...category,
        [key]: value,
      });
    },
    load: (keyObj) => {
      const key = keyGen(keyObj);
      const category = getCategory();
      return category[key] ?? null;
    },
    remove: (partialKeyObj) => {
      const category = getCategory();
      const newCategory = filterCategory(category, partialKeyObj, true);
      setCategory(newCategory);
    },
    exist: (partialKeyObj) => {
      const category = getCategory();
      return Object.entries(category).some(([key, value]) => {
        return isMatchingKey(key, partialKeyObj) && Object.keys(value).length > 0;
      });
    },
    clear: () => {
      sessionStorage.removeItem(categoryName);
    },
  };
};

// 저장소 관리 함수
const storageManager = (() => {
  const storages = {};

  return {
    createStorage: (categoryName, keyGeneratorArray) => {
      if (storages[categoryName]) {
        return storages[categoryName];
      }
      storages[categoryName] = createStorage(categoryName, keyGeneratorArray);
    },
    getStorage: (categoryName) => {
      if (!storages[categoryName]) {
        throw new Error(`Storage for category ${categoryName} does not exist`);
      }
      return storages[categoryName];
    },
    clearAll: () => {
      Object.keys(storages).forEach((categoryName) => {
        storages[categoryName].clear();
      });
    },
  };
})();

export const useStorage = (categoryName) => {
  const storage = storageManager.getStorage(categoryName);

  return {
    store: (keyObj, value) => storage.store(keyObj, value),
    load: (keyObj) => storage.load(keyObj),
    remove: (partialKeyObj) => storage.remove(partialKeyObj),
    exist: (partialKeyObj) => storage.exist(partialKeyObj),
    clear: () => storage.clear(),
  };
};

// 저장소 생성 예시
export const initializeStorage = (category, keyObj) => {
  storageManager.createStorage(category, keyObj);
};

// 모든 저장소 초기화 함수
export const clearAllStorage = () => {
  storageManager.clearAll();
};
