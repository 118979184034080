import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { LoaderLinear } from "../../atoms/Status";

export const FloatingRenderRow = React.memo(({ currentPercent, row, index, columns, status }) => {
  const renderPercentCol = (i, status) => {
    return (
      <TableCell key={i} scope="row">
        {
          // 추출
          status === "추출대기" || status === "추출취소" ? (
            <LoaderLinear hidden variant="determinate" value={0} />
          ) : status === "추출중" ? (
            <LoaderLinear hidden />
          ) : status === "추출성공" ? (
            <LoaderLinear variant="determinate" value={100} />
          ) : // 업로드
          status === "대기" || status === "취소" ? (
            <LoaderLinear variant="determinate" value={0} />
          ) : status === "전송" ? (
            currentPercent === 100 ? (
              <LoaderLinear />
            ) : (
              <LoaderLinear variant="determinate" value={currentPercent} />
            )
          ) : status === "진행중" ? null : (
            // 성공, 실패
            <LoaderLinear variant="determinate" value={100} />
          )
        }
      </TableCell>
    );
  };

  const renderCommonCol = (i, data) => {
    return (
      <TableCell key={i} scope="row">
        {data || "-"}
      </TableCell>
    );
  };

  return (
    <TableRow>
      <TableCell className="tablecell_index" scope="row">
        {index + 1}
      </TableCell>

      {columns?.map((col, idx) => {
        if (col.type === "percent") {
          return renderPercentCol(idx, status);
        } else {
          return renderCommonCol(idx, row[col.name]);
        }
      })}
    </TableRow>
  );
});
