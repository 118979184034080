import { useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import clsx from "clsx";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../atoms/Tooltip.js";
import IC_empty from "../../assets/images/IC_empty.png";
import { ImageSprite } from "../atoms/CommonAtoms.js";
import { ROWS_PER_PAGE } from "../../lib/constants/constant";
import { formatAmountComma } from "../../lib/utils";
import { useSelectTableRows, useShiftDownEvent } from "../../lib/hooks";
import { SelectCheckbox, SelectItem } from "../atoms/SelectBox";
import { Empty, Loader, LoadFail } from "../atoms/Status";
import { findDict } from "../../pages/statistics/StatsPage";

const CommonTableWrapper = (props) => {
  // <TableWrapper와 구분되는 CommonTableWrapper의 특징>
  // 1. 리스트 아이템 수가 적거나 한정적이어서 re-rendering을 방지하는 React.memo를 적용하지 않음
  // 2. 사용되는 컬럼 타입 종류가 적음
  // 3. modal, stats 테이블에서 쓰임

  const {
    header,
    state,
    contents,
    currentPage,
    // select
    select,
    selectedObj,
    setSelectedObj,
    allChecked,
    // modal
    tableModal,
    // stats
    stats,
    showIndex = true,
  } = props;

  let ulRef;
  const [isShiftDown, useShiftDown] = useShiftDownEvent();
  useShiftDown();

  useEffect(() => {
    const ref = ulRef;
    const handleSelectStart = (e) => {
      // shift시 select 방지
      if (isShiftDown) e.preventDefault();
    };

    ref.addEventListener("selectstart", handleSelectStart);
    return () => {
      ref.removeEventListener("selectstart", handleSelectStart);
    };
  }, [isShiftDown]);

  const { handleClickRow, handleClickHeaderCheckbox } = useSelectTableRows(
    contents?.length > 0 && contents,
    setSelectedObj,
    "obj"
  );

  const renderHeader = () => {
    return (
      <TableHead>
        <TableRow style={{ whiteSpace: "nowrap" }}>
          {!stats && showIndex && (
            <TableCell className="tablecell_index" variant="head">
              번호
            </TableCell>
          )}
          {select && state === "hasValue" && (
            <TableCell className="tablecell_checkbox" variant="head">
              <SelectItem>
                <SelectCheckbox
                  onChange={(e) => {
                    if (contents?.length > 0) {
                      handleClickHeaderCheckbox(e, allChecked);
                    }
                  }}
                  checked={allChecked}
                  value={allChecked}
                />
              </SelectItem>
            </TableCell>
          )}

          {header.map((head, i) => (
            <TableCell
              key={i}
              className={clsx(!stats && header.length - 1 === i && "w-[90%]")}
              variant="head"
              align={
                head.type === "num" || head.type === "strong" || head.type === "ratio"
                  ? "right"
                  : "inherit"
              }
            >
              <div className={clsx(!stats && "flex items-center gap-[6px]")}>{head.ko}</div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderMemberCol = (i, data) => {
    return (
      <TableCell key={i} scope="row">
        {data ? data.display_name : "-"}
      </TableCell>
    );
  };

  const renderArrCol = (i, data) => {
    const len = data?.length;

    return (
      <TableCell key={i} scope="row">
        {len && len > 0 ? data.map((item, index) => (index + 1 !== len ? `${item}, ` : item)) : "-"}
      </TableCell>
    );
  };

  const renderFindCol = (i, data) => {
    return (
      <TableCell key={i} scope="row">
        {data ? findDict(data) : "-"}
      </TableCell>
    );
  };

  const renderNumCol = (i, data) => {
    return (
      <TableCell key={i} scope="row" align="right">
        <span className={data === 0 ? "text-gray400" : ""}>
          {data === 0 ? "0" : data ? formatAmountComma(data) : "-"}
        </span>
      </TableCell>
    );
  };

  const renderCommonCol = (i, data) => {
    return (
      <TableCell key={i} scope="row">
        {data === true ? "O" : data === false ? "X" : data === 0 ? "0" : data ? data : "-"}
      </TableCell>
    );
  };

  const renderImageCol = (i, imageUrl) => {
    return (
      <TableCell key={i}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <ImageSprite boxSize="scale_small" iconType="IC_preview_s" />
            </TooltipTrigger>
            <TooltipContent>
              <img
                className={clsx("max-h-[398px] w-full object-contain")}
                src={`${process.env.REACT_APP_SERVER_BASE_URL}/${imageUrl}`}
                alt="mask_image"
                // 우클릭 방지
                onContextMenu={(e) => e.preventDefault()}
                onError={(e) => {
                  e.target.src = IC_empty;
                  e.target.className = "w-auto m-auto";
                }}
              />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
    );
  };

  const renderBody = () => {
    return (
      <TableBody className="whitespace-nowrap">
        {contents.map((row, i) => (
          <TableRow
            key={i}
            className={clsx(
              "hover:bg-gray100",
              stats === "total_row" && i === 0 && "bg-gray100 font-bold",
              select && "cursor-pointer"
            )}
            onClick={() => {
              select && handleClickRow(row.id, isShiftDown);
            }}
            // 우클릭 방지
            onContextMenu={(e) => e.preventDefault()}
          >
            {!stats && showIndex && (
              <TableCell className="tablecell_index" scope="row">
                {i + 1 + (currentPage ? currentPage - 1 : 0) * ROWS_PER_PAGE}
              </TableCell>
            )}
            {select && (
              <TableCell className="tablecell_checkbox" scope="row">
                <SelectItem>
                  <SelectCheckbox
                    checked={selectedObj.selectedItems?.includes(row.id) ? true : false}
                    tr
                  />
                </SelectItem>
              </TableCell>
            )}
            {header.map((col, i) => {
              switch (col.type) {
                case "member":
                  return renderMemberCol(i, row[col.name]);
                case "arr":
                  return renderArrCol(i, row[col.name]);
                case "num":
                case "strong":
                case "ratio":
                  return renderNumCol(i, row[col.name]);
                case "find":
                  return renderFindCol(i, row[col.name]);
                case "image":
                  return renderImageCol(i, row[col.name]);
                default:
                  return renderCommonCol(i, row[col.name]);
              }
            })}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <div className={clsx("px-[16px]", stats && "h-full")} ref={(el) => (ulRef = el)}>
      <TableContainer
        className={clsx(
          "scroll_head",
          "overflow-y-auto border-t border-gray400",
          tableModal ? "h-[266px]" : stats ? "h-full" : "h-[calc(100vh-220px)]"
        )}
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          {renderHeader()}
          {state === "hasValue" && contents?.length > 0 && renderBody()}
        </Table>
        {state === "hasValue" ? (
          contents?.length > 0 ? null : (
            <Empty />
          )
        ) : state === "loading" ? (
          <Loader />
        ) : (
          <LoadFail failType="데이터를 불러오는데 실패했습니다." />
        )}
      </TableContainer>
    </div>
  );
};

export default CommonTableWrapper;
