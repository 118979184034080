import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { NAVLUEContext } from "../../../App";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { currentMenuState } from "../../../lib/stores";
import { CommonButton } from "../../molecules/Buttons";

const FloatingItemCreator = (props) => {
  const { panelname, name, floatingItemContents, handleUploadFile, needCheckInput, beforeClick } =
    props;

  const {
    urlParams: { set_id, set_name },
  } = useDynamicRouting();
  const { progressRows, dispatch } = useContext(NAVLUEContext);
  const currentMenu = useRecoilValue(currentMenuState);

  const handleClick = () => {
    addItem();
  };

  const makeTitle = () => {
    return `${currentMenu.category} > ${currentMenu.menu.ko}${
      set_id ? ` > ${set_name}` : ""
    } [${panelname}]`;
  };

  const addItem = () => {
    const newId = getId();
    const newTitle = makeTitle();

    progressRows.push({
      id: newId, // 진행중인 패널 id
      text: newTitle, // 진행중인 작업의 메뉴와 기능 정보
      contents: floatingItemContents, // 진행중인 작업 아이템 리스트
      singleIndex: 0, // single process에서 몇번째 파일이 진행중인지 알기 위한 index
      percent: 0, // 진행률
      token: null, // cancel token
      singleProcess: false, // single process 여부
      sensorName: false, // 센서번호 사용 여부
      fold: false, // 최소화 여부
      done: false, // 완료 여부
      status: null, // 상태
    });
    dispatch({ type: "progressRows", data: progressRows });

    handleUploadFile(newId);
  };

  return (
    <CommonButton
      name={name}
      onClick={needCheckInput ? beforeClick : handleClick}
      type="modal_btn"
    />
  );
};

// 고유한 Id 생성을 위한 유틸리티
let id = 0;
function getId() {
  return id++;
}

export default FloatingItemCreator;
