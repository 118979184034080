import { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useRecoilValueLoadable } from "recoil";
import { learnedHitmapPointsQuery, datasetHitmapPointsQuery } from "../../../Main.stores.js";
import HitmapCanvas from "./HitmapCanvas.jsx";

export const FeatureSpace = forwardRef(function FeatureSpace(
  {
    category,
    filters,
    hoveredDataset,
    selectedDataset,
    // selectedSector,
    // setSelectedSector,
    selectedCell,
    setSelectedCell,
    setNumberOfLearnedPoints,
  },
  ref
) {
  const hitmapCanvasRef = useRef(null);

  const { state: learnedState, contents: learnedPoints } = useRecoilValueLoadable(
    learnedHitmapPointsQuery({
      sensor_code: category.sensor,
      gt_code: category.gtCode,
      query:
        filters.length > 0 ? { dataset: filters.map((filter) => filter.join("/")).join(",") } : {},
    })
  );

  const { state: datasetState, contents: dataset } = useRecoilValueLoadable(
    datasetHitmapPointsQuery({
      sensor_code: category.sensor,
      gt_code: category.gtCode,
      clct_id: hoveredDataset?.id ?? selectedDataset?.id ?? 0,
    })
  );

  const handleClickButton = () => {
    hitmapCanvasRef.current.handleResetSector();
    // setSelectedSector(null);
    setSelectedCell(null);
  };

  useEffect(() => {
    const hitmap = hitmapCanvasRef.current;
    if (learnedState === "hasValue") {
      hitmap.drawlearnedPoints(learnedPoints);
      setNumberOfLearnedPoints(learnedPoints.length);
    }
    return () => {
      if (learnedState === "hasValue") {
        hitmap.clearlearnedPoints();
      }
    };
  }, [learnedState, learnedPoints]);

  useEffect(() => {
    const hitmap = hitmapCanvasRef.current;
    if (datasetState === "hasValue") {
      hitmap.drawDataset(dataset);
    }
    return () => {
      if (datasetState === "hasValue") {
        hitmap.clearDataset();
      }
    };
  }, [datasetState, dataset]);

  useImperativeHandle(ref, () => {
    return {
      activateInteraction: (datasetId) => {
        hitmapCanvasRef.current.activateInteraction(datasetId);
      },
      deactivateInteraction: () => {
        hitmapCanvasRef.current.deactivateInteraction();
      },
      handleResetCell: () => {
        hitmapCanvasRef.current.handleResetCell();
      },
      drawPoint: ({ coords, auto, added, excluded }) => {
        hitmapCanvasRef.current.drawPoint({ coords, auto, added, excluded });
      },
      clearPoint: ({ coords }) => {
        hitmapCanvasRef.current.clearPoint({ coords });
      },
    };
  });

  return (
    <div className="flex h-full flex-[1_0_0%] flex-col gap-[16px] overflow-auto">
      <div className="flex h-full flex-grow flex-col gap-[16px] border border-[#e1e1e1] p-[16px]">
        <div className="flex justify-between">
          <span className="text-[18px] font-bold leading-[23px] tracking-[-0.36px] text-[#333]">
            Feature space
          </span>
          <div className="flex gap-[8px]">
            <InstructionText
              selectedDataset={selectedDataset}
              // selectedSector={selectedSector}
              selectedCell={selectedCell}
            />
            {/* {selectedSector === null ? (
            <button
              disabled
              className="flex h-[30px] items-center justify-center gap-[8px] rounded-[3px] border bg-[#D0D0D0] pr-[12px] pl-[16px]"
            >
              <div className="text-[12px] font-normal leading-[15px] tracking-[-0.24px] text-[#FFF]">
                구역 선택 취소
              </div>
              <div
                className={`h-[16px] w-[16px] bg-[url("../icons/icon_cancel_disabled.png")]`}
              ></div>
            </button>
          ) : (
            <button
              className="flex h-[30px] items-center justify-center gap-[8px] rounded-[3px] border border-[#2B63C1] pr-[12px] pl-[16px]"
              onClick={handleClickButton}
            >
              <div className="text-[12px] font-normal leading-[15px] tracking-[-0.24px] text-[#2B63C1]">
                구역 선택 취소
              </div>
              <div
                className={`h-[16px] w-[16px] bg-[url("../icons/icon_cancel_default.png")]`}
              ></div>
            </button>
          )} */}
          </div>
        </div>
        <HitmapCanvas
          category={category}
          // setSelectedSector={setSelectedSector}
          setSelectedCell={setSelectedCell}
          ref={hitmapCanvasRef}
        />
        <div className="flex h-[30px] justify-center gap-[16px]">
          <div className="flex items-center gap-[4px]">
            <div className="h-[12px] w-[12px] rounded-full bg-[rgba(0,0,0,0.16)]"></div>
            <div className="text-[14px] leading-[18px] tracking-[-0.28px] text-[#333]">
              학습데이터
            </div>
          </div>
          <div className="flex items-center gap-[4px]">
            <div className="h-[12px] w-[12px] rounded-full border border-[2px] border-[#2878FF] bg-[white]"></div>
            <div className="text-[14px] leading-[18px] tracking-[-0.28px] text-[#333]">
              자동선별데이터
            </div>
          </div>
          <div className="flex items-center gap-[4px]">
            <TriangleIcon />
            <div className="text-[14px] leading-[18px] tracking-[-0.28px] text-[#333]">
              추가데이터
            </div>
          </div>
          <div className="flex items-center gap-[4px]">
            <div className="h-[12px] w-[12px] rounded-full border border-[2px] border-[#FF6363] bg-[white]"></div>
            <div className="text-[14px] leading-[18px] tracking-[-0.28px] text-[#333]">
              탈락데이터
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const InstructionText = ({ selectedDataset, selectedSector, selectedCell }) => {
  if (selectedDataset === null) {
    return (
      <div className="flex items-center gap-[2px] text-[14px] leading-[18px] tracking-[-0.28px] text-[#E20000]">
        <div className={`h-[24px] w-[24px] bg-[url("../icons/icon_alert.png")]`} />
        비교할 데이터 셋을 선택하세요.
      </div>
    );
  }

  if (selectedSector === null) {
    return (
      <div className="flex items-center gap-[2px] text-[14px] leading-[18px] tracking-[-0.28px] text-[#E20000]">
        <div className={`h-[24px] w-[24px] bg-[url("../icons/icon_alert.png")]`} />
        확인할 구역을 선택하세요.
      </div>
    );
  }

  if (selectedCell === null) {
    return (
      <div className="flex items-center gap-[2px] text-[14px] leading-[18px] tracking-[-0.28px] text-[#E20000]">
        <div className={`h-[24px] w-[24px] bg-[url("../icons/icon_alert.png")]`} />
        확인할 셀을 선택하세요.
      </div>
    );
  }

  return (
    <div className="flex items-center gap-[2px] text-[14px] leading-[18px] tracking-[-0.28px] text-[#00BF40]">
      <div className={`h-[24px] w-[24px] bg-[url("../icons/icon_check.png")]`} />
      선택한 셀의 샘플 데이터를 확인하세요.
    </div>
  );
};

const TriangleIcon = ({ size = 12, borderColor = "#00BF40", backgroundColor = "white" }) => {
  const svgSize = size;

  const trianglePath = `M${svgSize / 2},1 L${svgSize - 1},${svgSize - 1} L1,${svgSize - 1} Z`;

  return (
    <div className="inline-block" style={{ width: `${size}px`, height: `${size}px` }}>
      <svg width={svgSize} height={svgSize} viewBox={`0 0 ${svgSize} ${svgSize}`}>
        <path d={trianglePath} fill={backgroundColor} stroke={borderColor} strokeWidth="2" />
      </svg>
    </div>
  );
};
