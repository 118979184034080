import React, { useContext } from "react";
import { useRecoilState } from "recoil";
import clsx from "clsx";
import { NAVLUEContext } from "../../../App";
import { floatingMinState } from "../../../lib/stores";
import { ImageSprite } from "../../atoms/CommonAtoms";
import FloatingRenderItem from "./FloatingRenderItem";

const Floating = () => {
  const [min, setMin] = useRecoilState(floatingMinState);
  const { progressRows, dispatch } = useContext(NAVLUEContext);

  const handleDelete = (idx) => {
    progressRows.splice(idx, 1);

    dispatch({ type: "progressRows", data: progressRows });
  };

  const handleCancel = (floatingId) => {
    const floatingItem = progressRows.find((item) => item.id === floatingId);
    floatingItem.token.cancel(`요청이 취소되었습니다. (${floatingId})`);

    dispatch({ type: "progressRows", data: progressRows });
  };

  const handleFold = (idx) => {
    progressRows[idx].fold = !progressRows[idx].fold;

    dispatch({ type: "progressRows", data: progressRows });
  };

  return (
    progressRows?.length > 0 && (
      <div
        className={clsx(
          "fixed right-[20px] bottom-[20px] z-[70] rounded-[3px] border border-gray500 bg-white duration-[0.5s]",
          min ? "max-h-[65px] w-[300px]" : "min-h-[65px] w-[930px]"
        )}
        style={min ? {} : { maxHeight: window.innerHeight - 40 }}
      >
        <div
          className={clsx(
            "flex h-[64px] items-center justify-between border-b border-gray400 px-[20px]",
            min && "bg-gray900"
          )}
        >
          <div
            className={clsx(
              "text-[18px]",
              min ? "font-normal text-white" : "font-bold text-gray900"
            )}
          >
            진행중인 작업 ({progressRows.length})
          </div>
          <div className="cursor-pointer" onClick={() => setMin(!min)}>
            <ImageSprite iconType={min ? "IC_max_w" : "IC_min_k"} />
          </div>
        </div>
        <div
          className="overflow-y-scroll duration-[0.3s]"
          style={min ? { display: "none" } : { maxHeight: window.innerHeight - 40 - 66 }}
        >
          {progressRows.map((item, idx) => (
            <FloatingRenderItem
              key={item.id}
              idx={idx}
              floatingItem={item}
              handleCancel={handleCancel}
              handleDelete={handleDelete}
              handleFold={handleFold}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default Floating;
