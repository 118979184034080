import clsx from "clsx";
import { ImageSprite } from "../atoms/CommonAtoms";

export const CommonButton = (props) => {
  const { onClick, name, icon, type, cn } = props;

  return (
    <div
      className={clsx(
        cn,
        type,
        onClick && "cursor-pointer",
        "flex h-[30px] flex-none items-center justify-center gap-[8px] rounded-[2px] bg-primary px-[16px] text-[12px] leading-[24px] text-white",
        type === "tertiary" && "!bg-gray500",
        type === "secondary" && "!bg-gray800",
        type === "small" && "h-[30px] text-[12px] font-normal",
        type === "unfill" && "border border-primary !bg-[unset] text-primary",

        type === "modal_btn" && "mr-[8px] !h-[40px] min-w-[72px] flex-none px-[16px] text-[16px]",
        type === "secondary_modal_btn" &&
          "mr-[8px] !h-[40px] min-w-[72px] flex-none !bg-gray800 px-[16px] text-[16px]",
        type === "tertiary_modal_btn" &&
          "mr-[8px] !h-[40px] min-w-[72px] flex-none !bg-gray500 px-[16px] text-[16px]",
        type === "list_button" &&
          "!justify-start whitespace-nowrap !bg-[white] !px-[16px] !text-primary hover:!bg-[rgb(242,242,242)]"
      )}
      onClick={onClick}
    >
      <span>{name}</span>

      {icon && <ImageSprite iconType={icon} />}
    </div>
  );
};

export const SafeURL = ({ dangerousURLs, name, modalStatus, onClick, icon, type }) => {
  const handleDownload = () => {
    // 각 zip 파일에 대해 다운로드 트리거
    const downloadFile = (index) => {
      if (index >= dangerousURLs.length) {
        return;
      }

      const url = dangerousURLs[index];
      const link = document.createElement("a");
      link.href = url;
      link.download = "file.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // 다음 파일 다운로드 트리거
      setTimeout(() => {
        downloadFile(index + 1);
      }, 1000 * 5);
    };

    downloadFile(0);
    onClick();
  };

  return (
    <button
      download
      className={clsx(
        "flex cursor-pointer items-center justify-center gap-[8px] px-[8px] text-[12px] leading-[24px] text-white",
        modalStatus === "loading" ? "bg-gray500" : "bg-primary",
        type === "modal_btn" && "mr-[8px] !h-[40px] min-w-[72px] flex-none px-[16px] text-[16px]"
      )}
      onClick={handleDownload}
    >
      <span>{modalStatus === "loading" ? "다운로드 준비중" : name}</span>
      {icon && <ImageSprite iconType="IC_download_w" />}
    </button>
  );
};

export const CommonDownloadButton = (props) => {
  const { downloadUrls, name, modalStatus, onClick, icon, type } = props;

  return (
    <SafeURL
      dangerousURLs={downloadUrls}
      name={name}
      modalStatus={modalStatus}
      onClick={onClick}
      icon={icon}
      type={type}
    />
  );
};
