import React, { useState, useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";
import { useSelectTableRows, useShiftDownEvent } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { modalOpenState } from "../../../lib/stores";
import { SelectBox, SelectCheckbox, SelectItem, SelectTitle } from "../../atoms/SelectBox";
import { useStorage } from "../../../lib/storage";

const EMPTY_ARR = [];

const HideCol = ({ data, hideColumn, updateColumn }) => {
  // 0. 컬럼 리스트에서 name만 사용
  const dataNames = useMemo(() => data?.map((item) => item.name) ?? EMPTY_ARR, [data]);
  let ulRef;
  const [open, setOpen] = useRecoilState(modalOpenState);
  const { urlParams, queryParams } = useDynamicRouting();

  const [selectedObj, setSelectedObj] = useState({
    selectedItems: [],
    lastSelectedItem: null,
  });
  const { handleClickRow, handleClickHeaderCheckbox } = useSelectTableRows(
    dataNames,
    setSelectedObj
  );
  const [isShiftDown, useShiftDown] = useShiftDownEvent();

  const columnStorage = useStorage("column");

  useShiftDown();

  const allChecked = useMemo(
    () => dataNames.length === selectedObj.selectedItems.length,
    [dataNames, selectedObj.selectedItems]
  );

  useEffect(() => {
    const { menu, sensor_code, gt_code, set_id, guide } = urlParams;
    const { tab } = queryParams;

    const storedColumns = columnStorage.load({
      menu,
      sensor_code,
      gt_code,
      level: determinLevel(set_id),
      isGuide: !!guide,
      tab,
    });
    let selectedItems = [];
    if (storedColumns) {
      selectedItems = storedColumns;
    } else {
      if (hideColumn) {
        selectedItems = dataNames.filter((n) => !hideColumn.includes(n));
      } else {
        selectedItems = dataNames;
      }
    }
    // 1. 컬럼이 변경되면 숨김컬럼을 제외하고 일단 전부 선택함
    setSelectedObj({
      selectedItems,
      lastSelectedItem: null,
    });
  }, [dataNames, hideColumn, urlParams, queryParams]);

  useEffect(() => {
    const { menu, sensor_code, gt_code, set_id, guide } = urlParams;
    const { tab } = queryParams;

    columnStorage.store(
      { menu, sensor_code, gt_code, level: determinLevel(set_id), isGuide: !!guide, tab },
      selectedObj.selectedItems
    );
    updateColumn(selectedObj.selectedItems);
  }, [selectedObj.selectedItems, urlParams, queryParams]);

  useEffect(() => {
    const ref = ulRef;
    const handleSelectStart = (e) => {
      // shift시 select 방지
      if (isShiftDown) e.preventDefault();
    };

    ref.addEventListener("selectstart", handleSelectStart);
    return () => {
      ref.removeEventListener("selectstart", handleSelectStart);
    };
  }, [isShiftDown]);

  return (
    <div className="relative" ref={(el) => (ulRef = el)}>
      <SelectTitle
        onClick={() => (open === "col" ? setOpen(null) : setOpen("col"))}
        name="컬럼 선택"
      />
      <SelectBox open={open === "col"}>
        <SelectItem item={{ ko: "전체" }} styleType="list" cn="truncate">
          <SelectCheckbox
            onChange={(e) => handleClickHeaderCheckbox(e, allChecked)}
            checked={allChecked}
            value={allChecked}
          />
        </SelectItem>
        {data.map((item, index) => (
          <SelectItem
            key={index}
            item={item}
            styleType="list"
            cn="truncate"
            onClick={() => handleClickRow(item.name, isShiftDown)}
          >
            <SelectCheckbox
              onChange={() => handleClickRow(item.name, isShiftDown)}
              checked={selectedObj.selectedItems?.includes(item.name) ? true : false}
              value={item.name}
              styleType="list"
            />
          </SelectItem>
        ))}
      </SelectBox>
    </div>
  );
};

const determinLevel = (set_id) => {
  return set_id ? "DATASET" : "SET_LIST";
};

export default HideCol;
