import { useEffect, useState } from "react";
import { SelectWrap, SelectBox, SelectItem, SelectRadio, SelectTitle } from "../../atoms/SelectBox";
import { ImageSprite } from "../../atoms/CommonAtoms";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";

const Pagination = (props) => {
  const { data } = props;
  const [open, setOpen] = useState(false);
  const {
    queryParams: { page },
    setQueryParams,
  } = useDynamicRouting();

  useEffect(() => {
    setOpen(false);
  }, [page]);

  // 좌우 버튼으로 페이지 변경
  const handleClick = (name) => {
    const current = Number(page);
    const last = data.length;
    let value;

    switch (name) {
      case "first":
        value = 1;
        break;
      case "prev":
        if (current === 1) {
          value = 1;
        } else {
          value = current - 1;
        }
        break;
      case "next":
        if (current >= last) {
          value = last;
        } else {
          value = current + 1;
        }
        break;
      case "last":
        value = last;
        break;
      default:
        break;
    }
    setQueryParams({ page: value });
  };

  return (
    <div className="flex h-[54px] items-center justify-center gap-[8px] pb-[8px]">
      <ImageSprite handleClick={() => handleClick("first")} iconType="IC_first_k" />
      <ImageSprite handleClick={() => handleClick("prev")} iconType="IC_prev_k" />

      <SelectWrap single styleType="pagination">
        <SelectTitle onClick={() => setOpen(!open)} name={page} styleType="pagination" />
        <SelectBox open={open} styleType="pagination">
          {data.map((item, index) => (
            <SelectItem
              item={item}
              pagination
              key={index}
              checked={page === item}
              styleType="list"
              cn="ml-0"
            >
              <SelectRadio
                onChange={(e) => {
                  setQueryParams({ page: e.target.value });
                  setOpen(false);
                }}
                value={item}
                cn="hidden"
              />
            </SelectItem>
          ))}
        </SelectBox>
      </SelectWrap>
      <div className="text-gray800">/ {data.length}</div>

      <ImageSprite handleClick={() => handleClick("next")} iconType="IC_next_k" />
      <ImageSprite handleClick={() => handleClick("last")} iconType="IC_last_k" />
    </div>
  );
};

export default Pagination;
