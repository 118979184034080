import { Link } from "react-router-dom";
import clsx from "clsx";
import { useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { formatAmountComma } from "../../../lib/utils";
import { currentMenuState } from "../../../lib/stores";
import { useRecoilValue } from "recoil";

const Tab = (props) => {
  const { data, state, contents, link } = props;
  const { listReload } = useReload();
  const {
    history,
    linkTo,
    urlParams: { sensor_code, gt_code, set_id },
    queryParams: { tab },
    setQueryParams,
  } = useDynamicRouting();
  const { menu } = useRecoilValue(currentMenuState);

  return (
    <div className="transparent_scroll flex h-[28px] overflow-hidden bg-gray100 hover:overflow-x-auto">
      {data.map((item, index) => (
        <div
          key={index}
          className={clsx(
            "mr-[20px] h-full flex-none cursor-pointer text-[14px] hover:border-b-[2px] hover:border-primary hover:text-primary",
            tab === item.value ? "border-b-[2px] border-primary text-primary" : "text-gray800"
          )}
          onClick={() => {
            tab === item.value
              ? listReload()
              : history.location.pathname.slice(-3) === "ref" // [작업, 검수] 가이드 리스트
                ? setQueryParams({
                    tab: item.value,
                    page: "1",
                    sort: item.value === "3" ? "postT" : "guideUlT",
                  })
                : menu.en === "Validation" && !set_id // [선별] 데이터셋 리스트
                  ? setQueryParams({
                      tab: item.value,
                      page: "1",
                      sort:
                        item.value === "3"
                          ? sensor_code === "rad"
                            ? "radSegVldCmpT"
                            : gt_code === "det"
                              ? "detVldCmpT"
                              : "camSegVldCmpT"
                          : "clctCmpT",
                      sortType: "desc",
                    })
                  : contents
                    ? setQueryParams({ tab: item.value, page: "1" })
                    : setQueryParams({ tab: item.value });
          }}
        >
          {item.ko}
          {state &&
            (state === "hasValue"
              ? `(${formatAmountComma(
                  // 페이지마다 다 다른 타입으로 오기때문에... 매번 바뀌기 떄문에...
                  typeof contents === "number" ? contents : contents[item.value]
                )})`
              : "(...)")}
        </div>
      ))}
      {link && (
        <div className="mr-[20px] h-full flex-none text-[14px] text-gray800 hover:border-b-[2px] hover:border-primary hover:text-primary">
          <div
            onClick={() => {
              linkTo({
                pathname:
                  link.name === "[작업 가이드 및 QnA]"
                    ? `${history.location.pathname}/ref`
                    : `${history.location.pathname.slice(0, -4)}`,
                search: link.search,
              });
            }}
          >
            {link.name}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tab;
