import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useRecoilValueLoadable } from "recoil";
import { useModals, useReload } from "../../../lib/hooks";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import ModalContainer from "../../molecules/ModalContainer";
import { CommonButton } from "../../molecules/Buttons";
import { ModalBottom } from "../../molecules/Modals";
import CommonTableWrapper from "../CommonTableWrapper";

// 출력: 응답받은 리스트
// 입력?: 선택한 아이템
export const TableAction = forwardRef(function TableAction(
  {
    cols,
    select,
    listQuery,
    actionName,
    listName,
    detail,
    icon,
    type,
    selectedObj,
    setSelectedObj,
    children,
    getTableContents,
    showIndex,
  },
  ref
) {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const { modal, openModal, closeModal, setModalStatus } = useModals(null);
  const { modalReload } = useReload();

  const { state, contents } = useRecoilValueLoadable(listQuery({ ...urlInfo, modal }));

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    state === "hasValue" && firstRender === true && setFirstRender(false);
  }, [state]);

  const resetOpen = () => {
    setModalStatus(null);
    !firstRender && modalReload();
    openModal();
  };

  const all_checked =
    (setSelectedObj &&
      contents.results?.length &&
      contents.results?.length === selectedObj.selectedItems?.length) ||
    false;

  useEffect(() => {
    setSelectedObj &&
      setSelectedObj({
        selectedItems: [],
        lastSelectedItem: null,
      });
    state === "hasValue" && getTableContents && getTableContents(contents);
  }, [state, contents]);

  useImperativeHandle(ref, () => {
    return {
      reload() {
        modalReload();
      },
    };
  });

  return (
    <>
      <CommonButton name={actionName} onClick={resetOpen} icon={icon} type={type} />
      {modal && (
        <ModalContainer
          modalType="table_modal"
          title={actionName}
          closeModal={closeModal}
          desc={{
            listName,
            count: contents.count,
            text: detail,
          }}
          handleClick={modalReload}
        >
          <CommonTableWrapper
            header={cols}
            select={select}
            state={state}
            contents={contents.results}
            selectedObj={selectedObj}
            setSelectedObj={setSelectedObj}
            allChecked={all_checked}
            showIndex={showIndex}
            tableModal
          />
          <ModalBottom
            hasStatus={{
              modalStatus: state,
              failType: state === "hasError" && "error!",
            }}
          >
            {children}
          </ModalBottom>
        </ModalContainer>
      )}
    </>
  );
});
