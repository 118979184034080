import {
  makeImgTypeList,
  RGS_DATA_COLS,
  RGS_CAM_TAB,
  RGS_RAD_TAB,
  RGS_SET_TYPE,
} from "./Rgs.constants";
import { RgsOrigDel, RgsOrigUpload, RgsSegLabelUpload, RgsDetLabelUpload } from "./RgsActions";
import { rgsFilter } from "./Rgs.apis";
import { rgsListQuery, rgsCntQuery, rgsPreviewQuery, rgsIdsQuery } from "./Rgs.stores";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { useRecoilValueLoadable } from "recoil";

const RgsDataPage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id, dataSetType } = urlParams;
  const { tab, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    rgsCntQuery({ ...urlParams, tab, t })
  );
  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    rgsIdsQuery({ sensor_code, set_id, ...queryParams })
  );

  return (
    <CommonTemplate
      listQuery={rgsListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
      filterApi={rgsFilter}
      previewQuery={rgsPreviewQuery}
      fullColumnData={RGS_DATA_COLS[dataSetType].tab}
      columnData={RGS_DATA_COLS[dataSetType].tab}
      hideColumn={RGS_DATA_COLS[dataSetType].hide.table.tab}
      hideThumbColumn={RGS_DATA_COLS[dataSetType].hide.thumb.tab}
      tabData={sensor_code === "cam" ? RGS_CAM_TAB : RGS_RAD_TAB}
      viewData
      imgTypeData={makeImgTypeList(dataSetType)}
      actions={({ contents, selectedItems }) => (
        <>
          <RgsOrigUpload />
          {dataSetType === RGS_SET_TYPE.GENERATED && <RgsSegLabelUpload />}
          {dataSetType === RGS_SET_TYPE.GENERATED && <RgsDetLabelUpload />}
          <SelectCnt count={selectedItems?.length} />
          <RgsOrigDel contents={contents.results} selectedItems={selectedItems} />
        </>
      )}
    />
  );
};

export default RgsDataPage;
