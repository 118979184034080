import { useRecoilValue } from "recoil";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { currentMenuState } from "../stores";
import {
  CONDITIONS,
  CAM_SEG_CONDITIONS,
  CAM_DET_CONDITIONS,
  LRN_CONDITIONS,
  ORIGS,
  SENSORS,
} from "../constants/constant";

const determineCondCntColumns = ({ menuName, sensor_code, gt_code }) => {
  let columns = CONDITIONS;
  if (menuName === "Learning") columns = LRN_CONDITIONS;
  else if (menuName === "Check") {
    if (sensor_code === "cam" && gt_code === "seg") {
      columns = CAM_SEG_CONDITIONS;
    } else if (sensor_code === "cam" && gt_code === "det") {
      columns = CAM_DET_CONDITIONS;
    }
  }
  return columns;
};

export const useHookGroupColumn = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };
  const { sensor_code, gt_code } = urlInfo;
  const { menu } = useRecoilValue(currentMenuState);

  // 그룹 컬럼은 하위 컬럼으로 바꿔준다
  const spliceCol = (cols, groupColumnData) => {
    const fn = (arr, col, add) => {
      const idx = arr.findIndex((item) => item.name === col);
      idx > 0 && arr.splice(idx, 1, ...add);

      return arr;
    };

    const arr1 = fn(cols, "sensor_cnts", SENSORS);
    const arr2 = fn(arr1, "img_cnts", ORIGS);
    const arr3 = menu.en === "Work" ? arr2 : fn(arr2, "tab_cnts", groupColumnData);
    const arr4 = fn(
      arr3,
      "cond_cnts",
      determineCondCntColumns({ menuName: menu.en, sensor_code, gt_code })
    );

    return arr4;
  };

  return { spliceCol };
};
