import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalOpenState } from "../../../lib/stores";
import { HeaderBG, ImageSprite } from "../../atoms/CommonAtoms";
import CurrentUser from "./CurrentUser";
import Navigation from "./Navigation";
import Logout from "./Logout";

const Header = (props) => {
  const { state, contents, whenLogout } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [openModal, setOpenModal] = useRecoilState(modalOpenState);

  return (
    <>
      <header className="relative flex h-[40px] w-full items-center justify-between border-b border-gray400 px-[16px] text-gray900">
        <div className="flex items-center gap-[12px]">
          <ImageSprite handleClick={() => setShowMenu(!showMenu)} iconType="IC_menu_b" />
          <ImageSprite boxSize="IC_navlue_s" iconType="IC_navlue_s" />
        </div>
        <div className="flex items-center gap-[12px]">
          <CurrentUser state={state} contents={contents} />
          <Logout whenLogout={whenLogout} />
        </div>
        <Navigation state={state} showMenu={showMenu} setShowMenu={setShowMenu} />
      </header>

      {showMenu && <HeaderBG handleClick={() => setShowMenu(!showMenu)} />}
      {openModal && <HeaderBG handleClick={() => setOpenModal(null)} />}
    </>
  );
};

export default Header;
