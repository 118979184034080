import { useContext, useRef } from "react";
import axios from "axios";
import { NAVLUEContext } from "../../App";

const CancelToken = axios.CancelToken;

export const useHookFileUploader = () => {
  const uploadSource = useRef(null);
  const { progressRows, dispatch } = useContext(NAVLUEContext);

  const process = async ({
    rowId,
    urlInfo,
    api,
    selectFile,
    sensor_name,
    workset_id,
    afterSuccessCallback = () => {},
  }) => {
    console.log("====================== > hook floating promise start");

    // progressRows에서 현재 진행 중인 작업 찾기
    const onGoingItem = progressRows.find((item) => item.id === rowId);

    let isCancel = false;
    const len = selectFile.length;

    // status, singleProcess 업데이트
    onGoingItem.status = "업로드 중";
    onGoingItem.singleProcess = true;
    dispatch({ type: "progressRows", data: progressRows });

    console.log("=========================== > for 시작", rowId);
    // 파일을 하나씩 전송
    for (let j = 0; j < len; j++) {
      // 선택한 파일 FormData에 담기
      const formData = new FormData();
      formData.append("file", selectFile[j]);
      sensor_name && formData.append("sensor_name", sensor_name);
      workset_id && formData.append("workset_id", workset_id);

      // onUploadProgress 함수 담기
      const config = {
        onUploadProgress: function (progressEvent) {
          onGoingItem.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          dispatch({ type: "progressRows", data: progressRows });
        },
      };

      // cancel token, status 설정
      uploadSource.current = CancelToken.source();
      onGoingItem.token = uploadSource.current;
      onGoingItem.contents[j].floatingStatus = "전송";
      dispatch({ type: "progressRows", data: progressRows });

      try {
        let data;
        if (urlInfo) {
          data = await api(urlInfo, formData, config, uploadSource);
        } else {
          data = await api(formData, config, uploadSource);
        }

        console.log(data);

        onGoingItem.contents[j].floatingStatus = "성공";
      } catch (err) {
        console.error(err);
        if (axios.isCancel(err)) {
          console.log("=========================== > 취소 감지", rowId);
          onGoingItem.contents[j].floatingStatus = `취소`;
          isCancel = true;
          break;
        } else {
          let err_detail = err?.response?.data?.detail || "";
          onGoingItem.contents[j].floatingStatus = `실패 ${err_detail && `(${err_detail})`}`;
        }
      }
      onGoingItem.singleIndex = j + 1;

      // context update
      dispatch({ type: "progressRows", data: progressRows });
    }
    console.log("=========================== > for 종료", rowId);

    onGoingItem.status = isCancel ? "취소" : "전송 완료";
    onGoingItem.done = true;
    if (onGoingItem.status === "전송 완료") {
      onGoingItem.contents.sort((a, b) => {
        if (a.floatingStatus !== "성공" && b.floatingStatus === "성공") return -1;
        if (a.floatingStatus === "성공" && b.floatingStatus !== "성공") return 1;
        return a.filename.localeCompare(b.filename);
      });
      afterSuccessCallback();
    }
    dispatch({ type: "progressRows", data: progressRows });
  };

  return { process };
};
