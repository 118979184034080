import { ImageSprite } from "../atoms/CommonAtoms";

const InvalidPage = () => {
  return (
    <div className="h-screen w-screen">
      <div className="flex h-[calc(100%-57px)] w-full flex-col items-center justify-center gap-[40px] text-[14px] text-gray700">
        <ImageSprite boxSize="IC_navlue" />
        <div className="text-center">
          This service only works on the Chrome browser for PC.
          <br />
          @2021-{new Date().getFullYear()} Seadronix All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default InvalidPage;
