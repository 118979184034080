import { selectorFamily } from "recoil";
import { extrClctCnt, extrClctList, extrCnt, extrList } from "./Extr.apis";
import { reloadTriggerState } from "../../lib/stores";

export const extrClctCntQuery = selectorFamily({
  key: "extrClctCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await extrClctCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const extrClctListQuery = selectorFamily({
  key: "extrClctListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await extrClctList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const extrCntQuery = selectorFamily({
  key: "extrCntQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await extrCnt(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});

export const extrListQuery = selectorFamily({
  key: "extrListQuery",
  get:
    (params) =>
    async ({ get }) => {
      get(reloadTriggerState);

      const res = await extrList(params);

      if (res.error) {
        throw res.error;
      }
      return res;
    },
});
