import { atom, selector } from "recoil";

// 모든 난이도 상태를 위한 atom
export const AllLevelState = atom({
  key: "AllLevelState",
  default: {},
});

// 모든 난이도 추가 함수
export const addAllLevel = selector({
  key: "addAllLevel",
  get: () => {},
  set: ({ set }, { id, value }) => {
    set(AllLevelState, (prevAllLevels) => ({
      ...prevAllLevels,
      [id]: value,
    }));
  },
});

// 난이도 상태를 위한 atom
export const levelState = atom({
  key: "levelState",
  default: {},
});

// 난이도 관리를 위한 selector
export const levelSelector = selector({
  key: "levelSelector",
  get: ({ get }) => get(levelState),
  set: ({ set }, newValue) => set(levelState, newValue),
});

// 난이도 추가 함수
export const addLevel = selector({
  key: "addLevel",
  get: () => {},
  set: ({ set }, { id, value }) => {
    set(levelState, (prevLevels) => ({
      ...prevLevels,
      [id]: value,
    }));
  },
});

// 난이도 삭제 함수
export const removeLevel = selector({
  key: "removeLevel",
  get: () => {},
  set: ({ set }, id) => {
    set(levelState, (prevLevels) => {
      const newLevels = { ...prevLevels };
      delete newLevels[id];
      return newLevels;
    });
  },
});

// 모든 난이도 초기화 함수
export const resetLevels = selector({
  key: "resetLevels",
  get: () => {},
  set: ({ set, get }) => {
    const allLevels = get(AllLevelState);
    const currentLevels = get(levelState);
    set(AllLevelState, { ...allLevels, ...currentLevels });
    set(levelState, {});
  },
});
