import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import clsx from "clsx";
import MENU_LIST from "../../../lib/constants/menulist";
import OPEN_SOURCE from "../../../lib/constants/opensource";
import CHANGE_LOG from "../../../lib/constants/changelog";
import { currentUserState } from "../../../lib/stores";
import { useDynamicRouting } from "../../../lib/context/useDynamicRouting";
import { ImageSprite, NavBottom } from "../../atoms/CommonAtoms";
import { Loader, LoadFail } from "../../atoms/Status";
import OpensourceModal from "./OpensourceModal";
import ChangelogModal from "./ChangelogModal";
import PrivacyModal from "./PrivacyModal";
import ManualModal from "./ManualModal";

const Navigation = (props) => {
  const { state, showMenu, setShowMenu } = props;
  const pathname = window.location.pathname;
  const { permissions, internal } = useRecoilValue(currentUserState);
  const [menu, setMenu] = useState([]);
  const [modalOpen, setModalOpen] = useState(null);
  const { linkTo } = useDynamicRouting();

  useEffect(() => {
    if (internal === true) {
      setMenu([...MENU_LIST]);
    } else if (internal === false) {
      const temp = MENU_LIST.map((i) => {
        return {
          category: i.category,
          icon: i.icon,
          menu_list: i.menu_list.filter(
            (j) => j.permission === "all" || permissions.includes(j.permission, 0)
          ),
        };
      }).filter((i) => i.menu_list.length > 0);

      setMenu([...temp]);
    }
  }, [internal, permissions]);

  const NAV_BOTTOM_LIST = [
    {
      ko: "navlue 매뉴얼",
      en: "manual",
      external: true,
    },
    {
      ko: "navlue 버전",
      en: "changelog",
      external: false,
    },
    {
      ko: "개인정보처리방침",
      en: "privacy",
      external: true,
    },
    {
      ko: "오픈소스 라이선스",
      en: "license",
      external: true,
    },
  ];

  return (
    <>
      <nav
        className={clsx(
          "absolute top-[40px] z-50 flex h-[calc(100vh-40px)] w-[260px] flex-col justify-between gap-[16px] overflow-y-scroll bg-gray900 text-white",
          showMenu ? "left-0 duration-300" : "left-[-100%] duration-700"
        )}
      >
        {state === "loading" ? (
          <Loader styleType="nav" />
        ) : state === "hasError" ? (
          <LoadFail failType="데이터를 불러오는데 실패했습니다." />
        ) : (
          <>
            <div className="pl-[16px] pr-[8px] pt-[16px]">
              {menu.map((c, i) => (
                <div className="mb-[12px]" key={i} onClick={() => setShowMenu(false)}>
                  <div className="flex h-[28px] flex-row items-center gap-[8px] text-gray700">
                    <ImageSprite boxSize="scale_small" iconType={c.icon} />
                    <span>{c.category}</span>
                  </div>
                  {c.menu_list.map((m, j) => (
                    <div
                      key={j}
                      onClick={() => {
                        linkTo({ pathname: m.path, search: m.query?.clct || "" });
                      }}
                      className={clsx(
                        "flex h-[28px] cursor-pointer items-center pl-[26px] hover:bg-gray700 hover:text-white",
                        pathname.includes(m.path) ? "bg-gray700 text-white" : "text-gray400"
                      )}
                    >
                      {m.ko}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="flex flex-col pb-[16px] text-gray800">
              {NAV_BOTTOM_LIST.map((x, i) =>
                internal === false && !x.external ? null : (
                  <NavBottom key={i} name={x.ko} handleClick={() => setModalOpen(x.en)} />
                )
              )}
              <div className="flex h-[28px] items-center justify-center">
                @2021-{new Date().getFullYear()} Seadronix All rights reserved.
              </div>
            </div>
          </>
        )}
      </nav>

      {modalOpen === "license" ? (
        <OpensourceModal
          closeModal={() => setModalOpen(null)}
          title="오픈소스 라이선스"
          contents={OPEN_SOURCE}
        />
      ) : modalOpen === "manual" ? (
        <ManualModal
          closeModal={() => setModalOpen(null)}
          title="navlue 매뉴얼"
          permissions={permissions}
        />
      ) : modalOpen === "changelog" ? (
        <ChangelogModal
          closeModal={() => setModalOpen(null)}
          title="navlue 버전"
          contents={CHANGE_LOG}
        />
      ) : (
        modalOpen === "privacy" && <PrivacyModal closeModal={() => setModalOpen(null)} />
      )}
    </>
  );
};

export default Navigation;
