import { TextField } from "@mui/material";
import { useState } from "react";
import { logout, passwordChange } from "../../../lib/apis";
import { removeToken } from "../../../lib/utils";
import { SelectName, SelectWrap } from "../../atoms/SelectBox";
import { SaveModal } from "../../molecules/CommonModals";

const ProfileModal = (props) => {
  const { main, closeModal } = props;
  const refresh = sessionStorage.getItem("refresh");

  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(false);
  const [value, setValue] = useState({
    old_password: "",
    new_password1: "",
    new_password2: "",
  });
  const [modalStatus, setModalStatus] = useState("");

  const checkInput = () => {
    setFailType("");
    setModalStatus("hasError");

    if (!value.old_password || !value.new_password1 || !value.new_password2) {
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");

    try {
      const data = await passwordChange(value);

      console.log(data);
      closeModal();
      setModalStatus("");
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";
      setFailType(err_detail);
      setModalStatus("hasError");
    }
  };

  const handleClose = async () => {
    if (!main) {
      closeModal();
    } else {
      await logout(refresh);
      removeToken();
    }
  };

  const INPUT_LIST = [
    { ko: "현재 비밀번호", en: "old_password" },
    { ko: "새 비밀번호", en: "new_password1" },
    { ko: "비밀번호 확인", en: "new_password2" },
  ];

  return (
    <SaveModal
      title="비밀번호 변경"
      detail={
        main
          ? "최초 로그인 시 반드시 8자 이상의 비밀번호로 변경 후 사용할 수 있습니다. 비밀번호를 변경해 주세요."
          : "비밀번호를 변경할 수 있습니다."
      }
      closeModal={handleClose}
      modalStatus={modalStatus}
      handleClick={checkInput}
      failType={failType}
      profile
    >
      {INPUT_LIST.map((x, i) => (
        <SelectWrap key={i} required={required && !value[x.en]}>
          <SelectName name={x.ko} />
          <TextField
            type="password"
            size="small"
            sx={{ width: 160 }}
            onChange={(e) => setValue({ ...value, [x.en]: e.target.value })}
          />
        </SelectWrap>
      ))}
    </SaveModal>
  );
};

export default ProfileModal;
