import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { EXRT_DATA_TAB, EXTR_DATA_COLS } from "./Extr.constants";
import { ExtrLog, ExtrDel } from "./ExtrActions";
import { extrFilter } from "./Extr.apis";
import { extrCntQuery, extrListQuery } from "./Extr.stores";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";

const ExtrDataPage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { tab, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    extrCntQuery({ ...urlParams, tab, t })
  );

  return (
    <CommonTemplate
      listQuery={extrListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      filterApi={extrFilter}
      fullColumnData={EXTR_DATA_COLS.tab1}
      columnData={EXTR_DATA_COLS[`tab${tab}`]}
      hideColumn={EXTR_DATA_COLS.hide[`tab${tab}`]}
      tabData={EXRT_DATA_TAB}
      groupColumnData={EXRT_DATA_TAB}
      actions={({ selectedItems, contents }) =>
        tab === "2" && (
          <>
            <SelectCnt count={selectedItems.length} />
            <ExtrLog selectedItems={selectedItems} contents={contents.results} />
            <ExtrDel selectedItems={selectedItems} />
          </>
        )
      }
    />
  );
};

export default ExtrDataPage;
